import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { useState, useEffect } from 'react'
import { gsap } from 'gsap'
import Divider from 'observian-ui/lib/divider'

import ContactForm from 'components/Form/Contact'
import ObservianLogoGate from 'components/SVG/observianLogoGate'
import {
  AdminProduct,
  FormsProduct,
  ChatProduct,
  KeepProduct,
  SlidesProduct,
  MeetProduct,
  SitesProduct,
  VoiceProduct,
  CalendarProduct,
  SheetsProduct,
  DriveProduct,
  GmailProduct,
  DocsProduct,
} from 'components/SVG/google'

import style from './hero.module.css'

const Hero = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  let isMobile = false
  let isTablet = false

  useEffect(() => {
    setLogos()
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  function handleWindowSizeChange() {
    if (768 >= window.innerWidth) {
      if (1024 <= window.innerHeight) {
        isTablet = true
      } else {
        isMobile = true
      }
    }

    setLogos()
  }

  let setLogos = () => {
    if (!isMobile && !isTablet) {
      gsap.to('.adminProduct', { x: -475, y: -275 })
      gsap.to('.chatProduct', { x: -550, y: -175 })
      gsap.to('.keepProduct', { x: -430, y: -150 })
      gsap.to('.formsProduct', { x: -500, y: 20 })
      gsap.to('.slidesProduct', { x: -400, y: 100 })
      gsap.to('.meetProduct', { x: -550, y: 125 })
      gsap.to('.sitesProduct', { x: -700, y: 75 })
      gsap.to('.voiceProduct', { x: 500, y: -175 })
      gsap.to('.calendarProduct', { x: 600, y: -25 })
      gsap.to('.sheetsProduct', { x: 450, y: 0 })
      gsap.to('.driveProduct', { x: 575, y: 100 })
      gsap.to('.gmailProduct', { x: 400, y: 150 })
      gsap.to('.docsProduct', { x: 600, y: 200 })
    } else if (!isTablet && isMobile) {
      gsap.to('.adminProduct', { x: -150, y: -150 })
      gsap.to('.chatProduct', { x: -175, y: -55 })
      gsap.to('.keepProduct', { x: -160, y: 25 })
      gsap.to('.formsProduct', { x: -150, y: 150 })
      gsap.to('.slidesProduct', { x: 185, y: 100 })
      gsap.to('.meetProduct', { x: 175, y: -130 })
      gsap.to('.sitesProduct', { x: 165, y: 0 })
      gsap.to('.voiceProduct', { x: 185, y: 225 })
      gsap.to('.calendarProduct', { x: -75, y: 100 })
      gsap.to('.sheetsProduct', { x: -130, y: 255 })
      gsap.to('.driveProduct', { x: 200, y: -250 })
      gsap.to('.gmailProduct', { x: 50, y: 150 })
    } else {
      gsap.to('.adminProduct', { x: -300, y: -150 })
      gsap.to('.chatProduct', { x: -325, y: -55 })
      gsap.to('.keepProduct', { x: -300, y: 25 })
      gsap.to('.formsProduct', { x: -300, y: 150 })
      gsap.to('.slidesProduct', { x: 300, y: 100 })
      gsap.to('.meetProduct', { x: 325, y: -130 })
      gsap.to('.sitesProduct', { x: 165, y: 0 })
      gsap.to('.voiceProduct', { x: 185, y: 225 })
      gsap.to('.calendarProduct', { x: -175, y: 100 })
      gsap.to('.sheetsProduct', { x: -200, y: 225 })
      gsap.to('.driveProduct', { x: 300, y: -250 })
      gsap.to('.gmailProduct', { x: 300, y: -15 })
    }
  }

  return (
    <section className={style.hero}>
      <ObservianLogoGate className={style.observianLogoGate} />
      <div className={style.content}>
        <header>
          <h1 className={style.title}>
            <text style={{ textDecoration: 'underline' }}>
              Save Money and Time
              <br />
            </text>{' '}
            <h3 className={style.titleText}>
              Discounted Google Workspace Licensing <br />
              Expert Service
            </h3>
          </h1>
        </header>
        <p className={style.description}>
          Observian has partnered with Google to bring our customers best in class productivity and
          collaboration with Google. See how these amazing tools paired with our expertise can help
          transform your business.
        </p>
        <Button
          className={style.button}
          label="Talk with our Google Experts"
          onClick={() => setShowModal(true)}
        />
        <img
          alt="premier-partner"
          src="/images/logos/google/gsuite/gpremierpartner.png"
          width="300px"
          height="146px"
          className={style.premierPartner}
        />
        <div className="adminProduct" style={{ position: 'absolute' }}>
          <AdminProduct />
        </div>
        <div className="chatProduct" style={{ position: 'absolute' }}>
          <ChatProduct />
        </div>
        <div className="keepProduct" style={{ position: 'absolute' }}>
          <KeepProduct />
        </div>
        <div className="formsProduct" style={{ position: 'absolute' }}>
          <FormsProduct />
        </div>
        <div className="slidesProduct" style={{ position: 'absolute' }}>
          <SlidesProduct />
        </div>
        <div className="meetProduct" style={{ position: 'absolute' }}>
          <MeetProduct />
        </div>
        <div className="sitesProduct" style={{ position: 'absolute' }}>
          <SitesProduct />
        </div>
        <div className="voiceProduct" style={{ position: 'absolute' }}>
          <VoiceProduct />
        </div>
        <div className="calendarProduct" style={{ position: 'absolute' }}>
          <CalendarProduct />
        </div>
        <div className="sheetsProduct" style={{ position: 'absolute' }}>
          <SheetsProduct />
        </div>
        <div className="driveProduct" style={{ position: 'absolute' }}>
          <DriveProduct />
        </div>
        <div className="gmailProduct" style={{ position: 'absolute' }}>
          <GmailProduct />
        </div>
        <div className="docsProduct" style={{ position: 'absolute' }}>
          <DocsProduct />
        </div>
      </div>

      <Divider />
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-workspace-hero-cta"
            inquiryPlaceholder="I'd like to talk about your Google Workspace offerings."
            isLandingPageForm
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default Hero
