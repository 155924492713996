import { useState } from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'
import Gears from 'components/SVG/gears'
import TextUnderline from 'components/SVG/textUnderline'
import WhatWeDoLaser from 'components/SVG/lasers/whatWeDo'

import stats from '../../../../static/images/datadog/stats.svg'

import style from './cloudmonitoring.module.css'

const CloudMonitoring = () => {
  const [modal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  return (
    <section className={style.cloudMonitoring}>
      <div className={style.topTriangle} />
      <div className={style.monitoringContainer}>
        <WhatWeDoLaser
          type="top"
          className={style.laserTop}
          classNames={{ glow: style.orangeGlow }}
        />
        <div className={style.leftContainer}>
          <img src={stats} alt="stats-img" className={style.stats} />
          <Gears
            className={style.gears}
            gearClassName={{ topGear: style.topGear, bottomGear: style.bottomGear }}
          />
        </div>
        <div className={style.rightContainer}>
          <div className={style.headerTextContainer}>
            <h3 className={style.headerTextMain}>24/7 Managed Security</h3>
          </div>
          <TextUnderline className={style.textUnderline} />

          <div className={style.mainTextContainer}>
            <h3 className={style.colorWhite}>Rapid Customer Onboarding</h3>
            <p className={style.colorWhite}>
              We have been there and done that, with Observian you will be up and running in no
              time.
            </p>
            <h3 className={style.colorWhite}>Custom Reporting &amp; Dashboard Creation</h3>
            <p className={style.colorWhite}>
              Every business is unique, let us help build out Datadog to what fits your team best.
            </p>
            <h3 className={style.colorWhite}>
              Datadog Managed Security Information Event Management (SIEM)
            </h3>
            <p className={style.colorWhite}>
              Real-time threat detection across operational and security logs.
            </p>
            <h3 className={style.colorWhite}>Active Threat Hunting and Logic Creation</h3>
            <p className={style.colorWhite}>
              The team at Observian will actively monitor your environment and help you focus on
              what matters most.
            </p>
            <h3 className={style.colorWhite}>Monthly Security &amp; Compliance Reviews</h3>
            <p className={style.colorWhite}>
              Observian arms your team with monthly assessments that include Point-in-Time security
              assessments and continous compliance framework monitoring.
            </p>
          </div>
          <Button
            className={style.button}
            label="Lets Talk Managed Security"
            onClick={() => window.location.href = buildFormUrl([
              {
                key: 'CTA',
                value: 'Datadog - Lets Talk Managed Security'
              }
            ])}
          />
        </div>
        <WhatWeDoLaser className={style.laserBottom} />
      </div>
      <div className={style.bottomStyling} />
      {modal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={modal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            isLandingPage
            ctaIntentSuffix="modal-datadog-features-contact-top-cta"
            inquiryPlaceholder="I would like to learn more about Observian's Managed Security Service with Datadog."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default CloudMonitoring
