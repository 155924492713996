import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'

import style from './basicSEOPage.module.css'

const SEOBasicTemplate = ({ description, logo, name, url }) => (
  <div className={style.basicSEOPage}>
    <div className={style.seoBackground}>
      <div className={style.seoContent}>
        <header className={style.header}>
          {logo ? (
            <a rel="noopener noreferrer" href={url}>
              <img className={style.partnersLogo} src={logo} srcSet={`${logo} 2x`} alt={name} />
            </a>
          ) : null}
        </header>
        <h1 className={style.title}>
          <a rel="noopener noreferrer" href="https://github.com/observian/secret-awsome">
            {name}
          </a>{' '}
          by{' '}
          <a rel="noopener noreferrer" href="https://github.com/observian/">
            Observian
          </a>
        </h1>
        <p className={style.description}>{description}</p>
        <img
          className={style.preview}
          src="/images/tools/secret-awsome.png"
          alt="Secret AWSome App"
        />
        <Button
          className={style.button}
          variant="secondary"
          label="Download"
          href="https://github.com/observian/secret-awsome/releases"
        />
        <div className={style.moreInfo}>
          <h2>More Informationte</h2>
          <div className={style.links}>
            <a
              className={style.link}
              rel="noopener noreferrer"
              href="https://github.com/observian/secret-awsome"
            >
              GitHub Repo
            </a>
            <a
              className={style.link}
              rel="noopener noreferrer"
              href="https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-paramstore.html"
            >
              AWS Systems Manager Parameter Store Information
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

SEOBasicTemplate.defaultProps = {
  name: 'Secret AWSome',
  description: '',
  logo: false,
  url: '',
}

SEOBasicTemplate.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  url: PropTypes.string,
}

export default SEOBasicTemplate
