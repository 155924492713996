import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { useState } from 'react'
import Divider from 'observian-ui/lib/divider'

import ContactForm from 'components/Form/Contact'
import ObservianLogoGate from 'components/SVG/observianLogoGate'
import AWSMainCloud from 'components/SVG/clouds/awsMain'

import style from './hero.module.css'

const Hero = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <section className={style.hero}>
      <ObservianLogoGate className={style.observianLogoGate} />
      <div className={style.content}>
        <header>
          <h1 className={style.title}>
            <text style={{ textDecoration: 'underline' }}>Save time and money</text> while
            migrating, designing or optimizing your cloud architecture
          </h1>
        </header>
        <AWSMainCloud className={style.mainCloud} />
        <p className={style.description}>
          Let our Certified AWS experts guide you through the cloud journey. Whether you’re looking
          to the cloud for innovation, cost savings, agility, ops efficiency, or all the above, we
          can help you do it right.
        </p>
        <Button
          className={style.button}
          label="Talk with our AWS Experts"
          onClick={() => setShowModal(true)}
        />
      </div>
      <Divider />
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-aws-hero-cta"
            isLandingPageForm
            inquiryPlaceholder="I'd like to talk about your AWS offerings."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default Hero
