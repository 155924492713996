import { Component } from 'react'
import { gsap, Power0 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import Layout from 'components/Layout'
import AWSPageTemplate from 'templates/Page/AWS'
import CostOptimization from 'components/SVG/clouds/costOptimization'

class CostOptimizationPage extends Component {
  componentDidMount() {
    gsap.registerPlugin(DrawSVGPlugin)
    const cost = gsap.timeline()
    const cost1 = gsap.timeline({ repeat: -1 })
    cost.to(
      '.line2',
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.65 },
      0
    )

    cost.from(
      '.line1',
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.85 },
      0
    )

    cost.to(
      '.dollar1',
      { x: '+=8', y: '-=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0
    )

    cost.to(
      '.dollar2',
      { x: '-=5', y: '+=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0
    )

    cost1.fromTo('.dollars', { opacity: 0.25 }, { opacity: 1, duration: 0.25, stggaer: 0.25 })
  }

  render() {
    return (
      <Layout
        title="AWS Cost Optimization | Cloud Cost Optimization"
        description="Observian specializes in AWS cost optimization to make sure companies are only paying for the AWS services they need. Schedule a conversation today!"
      >
        <AWSPageTemplate
          name="AWS Cost Optimization"
          title="Cloud Cost Optimization"
          content="
          <p>The most important aspect of your business is a healthy bottom line. There are two ways to accomplish this: generating revenue and reducing overhead costs. Cloud computing is a big overhead cost and requires continuous monitoring and adjustment to ensure resources are being used correctly and are not being wasted. Observian specializes in helping companies analyze their AWS services and pricing options to make sure they are only paying for what they need. Using tools such as <a href='/partners/cloudability'>Cloudability</a> and <a href='/partners/cloudcheckr'>CloudCheckr</a>, we create visibility into your cloud computing spend so that we may optimize your AWS costs. </p>
          <br />
          <p> We are so confident we can save you money, and in some cases, drastically reduce your monthly costs, we’ll happily analyze your infrastructure for free. A quick <a href='/contact'>conversation</a> with one of our AWS cost optimization <a href='/about'>experts</a> could immediately impact your cloud price and performance. When price decreases and performance improves, you help your bottom line.</p>
          <br />
          <p> Here are some questions to ask yourself about different areas of your business, and the answers may help you find out why you need AWS cost optimization. </p>
          <br />
          <h2> Cloud Cost Optimization Oversight </h2>
          <br />
          <br />
          <h3> Are you utilizing a Cloud Asset Management tool?  </h3>
          <p> Using a cloud asset management tool can ensure that you have a strong handle on your data. Gain insight and visibility with what you’re spending, what you’re using, and what things are changing. </p>
          <br />
          <h3> Do you have a content distribution strategy? </h3>
          <p>At Observian, we help you identify and understand how and where you are distributing your content. Optimizing a content distribution strategy can have a huge impact on storage and distribution costs. Our team's goal is to reconfigure this content so that it is used for the purpose of saving you costs and used to its full potential. </p>
          <br />
          <h2> Optimizing AWS Resources</h2>
          <br />
          <br />
          <h3> Are you using the most up-to-date services? </h3>
          <p>As technology moves rapidly, we help you stay on the most up-to-date services. This will ensure that you continually get the best performance at the lowest price. </p>
          <br />
          <h3> Do you have unused, idle, and/or misused resources? </h3>
          <p>One of the key components of AWS optimization is making sure you are using all your resources. It is important to identify all AWS computing resources to ensure that they are not idle or no longer in use. Our team helps to ensure these resources are correctly configured to deliver the performance and service that you need.</p>
          <br />
          <h3>Are you managing Reserved Instances (RI)? </h3>
          <p>Using prepaid <a href='https://aws.amazon.com/ec2/pricing/reserved-instances/' target='_blank'>reserved instances</a> (RIs) helps you use AWS resources at a lower price. We help you recognize how much you’ll need and when you need them so that you may get the best deal.</p>
          <br />
          <h3> Are you utilizing Spot Instances? </h3>
          <p>Utilizing <a href='https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-spot-instances.html' target='_blank'>spot instances</a> will allow you to request unused instances at steep discounts. Allowing yourself to be flexible about when your applications run, spot instances are a very cost-effective option.</p>
          <br />
          <h2>AWS Storage Optimization</h2>
          <br />
          <br />
          <h3>Are you optimizing your storage services? </h3>
          <p>Amazon presents many levels of storage services at different prices. Choosing the right storage strategy will help you save money and ensure availability. Our team will help you identify and analyze the right service for you. <a href='/contact'>Talk to an expert</a> to see which storage plan will get you on the right track.</p>"
          headerIcon={<CostOptimization />}
          inquiryPlaceholder="Hi! We could use some help utilizing our cloud resources more efficiently and reducing costs..."
        />
      </Layout>
    )
  }
}

export default CostOptimizationPage
