import TextUnderline from 'components/SVG/textUnderline'
import ObservianInTheCloud from 'components/SVG/observianInTheCloud'

import style from './migrate.module.css'

const Migrate = () => (
  <section className={style.migrate}>
    <div className={style.migrateContainer}>
      <div className={style.leftContainer}>
        <div className={style.headerTextContainer}>
          <h3 className={style.headerTextMain}>Migrate to the Cloud with Confidence</h3>
        </div>
        <TextUnderline className={style.textUnderline} />

        <div className={style.mainTextContainer}>
          <p className={style.mainText}>
            Datadog is the perfect tool for any organization looking to make a cloud-based migration
            easy. With more than 250 supported integrations, as well as their ability to handle
            dynamic environments and microservices natively without penalizing you with additional
            workarounds or extra costs— Datadog and Observian have your back when it comes to
            migrating to the cloud.
          </p>
        </div>
      </div>
      <div className={style.rightContainer}>
        <ObservianInTheCloud className={style.inTheCloud} />
      </div>
    </div>
  </section>
)

export default Migrate
