const SvgDataRetention = (props) => (
  <svg width="169px" height="169px" viewBox="0 0 169 169" {...props}>
    <defs>
      <linearGradient
        x1="15.4356061%"
        y1="77.6314217%"
        x2="114.318971%"
        y2="30.5054387%"
        id="data-retention-a"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="96.8002019%"
        y1="40.4430266%"
        x2="16.8773886%"
        y2="60.980557%"
        id="data-retention-b"
      >
        <stop stopColor="#1C3148" offset="0%" />
        <stop stopColor="#1C3047" offset="4.60318317%" />
        <stop stopColor="#0F172D" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-883 -3023) translate(106 2702) translate(0 272) translate(742 14)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M119.625 189.885c38.804 0 70.26-31.456 70.26-70.26s-31.456-70.26-70.26-70.26-70.26 31.456-70.26 70.26 31.456 70.26 70.26 70.26z"
        stroke="#FFF"
        strokeWidth={1.5}
        strokeDasharray={3}
      />
      <circle
        fill="#F2EED1"
        fillRule="nonzero"
        opacity={0.11}
        transform="rotate(-45 119.624 119.625)"
        cx={119.624269}
        cy={119.625}
        r={84.375}
      />
      <circle
        fill="#F2EED1"
        fillRule="nonzero"
        opacity={0.11}
        transform="rotate(-45 119.624 119.625)"
        cx={119.624269}
        cy={119.625}
        r={64.605}
      />
      <path
        d="M185.542 63.93H53.7a6.345 6.345 0 00-6.345 6.337v84.983a6.338 6.338 0 006.345 6.33h50.828l-1.726 17.25h-5.857v4.455h45.36v-4.455h-5.91l-2.063-17.25h51.21a6.338 6.338 0 006.345-6.33V70.267a6.338 6.338 0 00-6.345-6.337z"
        fill="url(#data-retention-a)"
      />
      <path
        fill="url(#data-retention-b)"
        fillRule="nonzero"
        d="M55.0125 70.2675H184.23000000000002V147.0675H55.0125z"
      />
      <path
        d="M191.887 155.25v-1.808H47.355v1.808a6.338 6.338 0 006.345 6.33h131.842a6.338 6.338 0 006.345-6.33z"
        fill="#F2EED1"
      />
      <path
        fill="#10172D"
        opacity={0.476256606}
        d="M55.0125 147.0675L184.23 70.2675 184.23 147.0675z"
      />
      <path fill="#5B4559" fillRule="nonzero" d="M96.945 178.7925H142.305V183.2475H96.945z" />
      <path
        d="M119.625 115.68a3.315 3.315 0 00-3.315 3.315v9.502a3.315 3.315 0 106.623 0v-9.502a3.308 3.308 0 00-3.308-3.315z"
        fill="#F2EED1"
        fillRule="nonzero"
      />
      <path
        d="M128.52 115.68a3.315 3.315 0 00-3.315 3.315v9.502a3.315 3.315 0 106.622 0v-9.502a3.308 3.308 0 00-3.307-3.315zM122.933 128.498v-9.503a3.308 3.308 0 00-3.308-3.315v16.132a3.308 3.308 0 003.308-3.315z"
        fill="#E1DEB4"
        fillRule="nonzero"
      />
      <path
        d="M110.722 115.68a3.308 3.308 0 00-3.307 3.315v9.502a3.315 3.315 0 106.622 0v-9.502a3.315 3.315 0 00-3.315-3.315z"
        fill="#F2EED1"
        fillRule="nonzero"
      />
      <circle
        fill="#F2EED1"
        fillRule="nonzero"
        transform="rotate(-76.72 119.62 107.02)"
        cx={119.619755}
        cy={107.020422}
        r={19.65}
      />
      <path
        d="M119.625 87.382v39.293c10.7-.21 19.266-8.943 19.266-19.646 0-10.703-8.565-19.437-19.266-19.647z"
        fill="#E1DEB4"
        fillRule="nonzero"
      />
      <path
        d="M114.968 102.75v5.205s0 1.448-2.723 1.448h-4.56s-3.067.06-1.23-3.113c1.838-3.172 3.968-6.172 3.968-6.172s.165-.503 1.754-.503c1.59 0 2.79.068 2.79 3.135zM119.625 111.892h-.165c-1.59 0-1.762.368-1.762.368s-.75 1.957-1.628 4.5c-.877 2.542 1.223 2.25 1.223 2.25h2.332v-7.118z"
        fill="#5B4559"
        fillRule="nonzero"
      />
      <path
        d="M124.275 102.75v5.205s0 1.448 2.722 1.448h4.56s3.068.06 1.23-3.113c-1.837-3.172-3.96-6.172-3.96-6.172s-.172-.503-1.762-.503-2.79.068-2.79 3.135zM119.625 111.892h.157c1.59 0 1.763.368 1.763.368s.75 1.957 1.627 4.5c.878 2.542-1.222 2.25-1.222 2.25h-2.325v-7.118z"
        fill="#3E2F3C"
        fillRule="nonzero"
      />
      <path
        d="M95.528 81.382a3.045 3.045 0 00-.856 2.535 3.045 3.045 0 101.785 5.175 2.79 2.79 0 00.518-.75l2.175 2.175 2.16-2.16-2.175-2.107c.262-.134.502-.306.712-.51a3.06 3.06 0 10-4.32-4.328v-.03zM92.138 132.563c.668.66 1.602.977 2.534.862a3 3 0 00.856 2.527 3.055 3.055 0 004.32-4.32 2.79 2.79 0 00-.713-.517l2.175-2.175-2.16-2.19-2.175 2.175a2.925 2.925 0 00-.517-.75 3.055 3.055 0 00-4.32 4.32v.067zM143.715 81.382c.66.668.976 1.604.855 2.535a3.045 3.045 0 11-1.785 5.175 2.79 2.79 0 01-.517-.75l-2.175 2.175-2.16-2.16 2.174-2.174c-.26-.136-.5-.308-.712-.51a3.06 3.06 0 114.32-4.328v.037zM147.105 132.563a3.075 3.075 0 01-2.535.862 3 3 0 01-.855 2.527 3.055 3.055 0 01-4.32-4.32c.21-.207.45-.381.712-.517l-2.174-2.175 2.16-2.16 2.175 2.175c.132-.275.306-.529.517-.75a3.055 3.055 0 014.32 4.32v.037z"
        fill="#F2EED1"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgDataRetention
