import { func } from 'prop-types'
import Button from 'observian-ui/lib/button'

import SvgSoftwareFailure from './assets/SoftwareFailure'
import SvgHardwareFailure from './assets/HardwareFailure'
import SvgPowerOutage from './assets/PowerOutage'
import SvgNaturalDisaster from './assets/NaturalDisaster'
import SvgHumanError from './assets/HumanError'
import SvgEmailPhishing from './assets/EmailPhishing'
import SvgMaliciousInsiders from './assets/MaliciousInsiders'
import SvgDataRetention from './assets/DataRetention'
import SvgMalewareViruses from './assets/MalwareViruses'
import { buildFormUrl } from '../../../../utils/formRedirect'
import style from './GetCovered.module.css'

const GetCovered = ({ setShowModal }) => (
  <section className={style.wrapper}>
    <div className={style.container}>
      <h1 className={style.yes}>
        <span>Yes</span> - you need this.
      </h1>
      <p className={style.p}>
        Or at least some variation of this to ensure your company is protected. Observian has put
        together a package that covers all the bases you need with{' '}
        <strong>best in class products & support</strong> at a <strong>discounted price</strong>.
      </p>
      <p className={style.sass}>
        <strong>SAAS</strong> (Office 365 / Google Workspaces) <strong>RESPONSIBILITY</strong>
      </p>
      <div className={style.icons}>
        <div>
          <SvgSoftwareFailure />
          <p>Software Failure</p>
        </div>
        <div>
          <SvgHardwareFailure />
          <p>Hardware Failure</p>
        </div>
        <div>
          <SvgPowerOutage />
          <p>Power Outage</p>
        </div>
        <div>
          <SvgNaturalDisaster />
          <p>Natural Disaster</p>
        </div>
      </div>
      <h2 className={style.responsibility}>Your Responsibility</h2>
      <div className={style.respBox}>
        <div className={style.humanError}>
          <SvgHumanError />
          <p>Human Error</p>
        </div>
        <div>
          <SvgMaliciousInsiders />
          <p>Malicious Insiders</p>
        </div>
        <div className={style.email}>
          <SvgEmailPhishing />
          <p>Email Phishing</p>
        </div>
        <div>
          <SvgDataRetention />
          <p>Data Retention</p>
        </div>
        <div>
          <SvgMalewareViruses />
          <p>Malware / Viruses</p>
        </div>
      </div>
      <div className={style.getStarted}>
        <Button
          varient="primary"
          label="Get Covered By Observian"
          onClick={() => window.location.href = buildFormUrl([
            {
              key: 'CTA',
              value: `Managed Productivity - Get Covered By Observian`
            }
          ])}
        />
      </div>
    </div>
  </section>
)

GetCovered.propTypes = {
  setShowModal: func.isRequired,
}

export default GetCovered
