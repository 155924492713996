import * as React from 'react'

const ManagedProductivitySvg = (props) => (
  <svg width="374px" height="24px" viewBox="0 0 374 24" {...props}>
    <defs>
      <linearGradient
        x1="14.8581364%"
        y1="50.7535005%"
        x2="85.3734672%"
        y2="49.2402223%"
        id="mp-non-p-a"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
      <path
        d="M163.02 21.39v-6.6h1.59c4.59 0 7.65-3.03 7.65-7.26 0-4.14-2.88-7.14-7.74-7.14h-8.82v21h7.32zm.69-12.12h-.69V6.09h.75c.9 0 1.53.66 1.53 1.59 0 1.02-.75 1.59-1.59 1.59zm17.04 12.12v-6.78l2.97 6.78h8.25l-4.8-8.79c1.77-.99 3.33-2.97 3.33-5.4 0-3.81-2.88-6.81-7.8-6.81h-9.33v21h7.38zm.9-12.36h-.9V6.09h.9c.96 0 1.5.75 1.5 1.47s-.54 1.47-1.5 1.47zm21.03 12.75c7.17 0 11.61-4.77 11.61-10.92C214.29 4.8 209.85 0 202.68 0c-7.17 0-11.61 4.8-11.61 10.86 0 6.15 4.44 10.92 11.61 10.92zm0-6.27c-2.37 0-3.66-1.92-3.66-4.65 0-2.64 1.29-4.59 3.66-4.59 2.34 0 3.66 1.95 3.66 4.59 0 2.73-1.32 4.65-3.66 4.65zm20.25 5.88c7.05 0 11.73-4.05 11.73-10.53 0-6.57-4.74-10.47-11.73-10.47h-7.5v21h7.5zm.33-6h-.45v-9h.45c2.19 0 3.63 1.41 3.63 4.47 0 3.06-1.44 4.53-3.63 4.53zm22.2 6.39c6.3 0 9.72-3.57 9.72-8.64V.39h-7.62v12.93c0 1.44-.84 2.19-2.1 2.19-1.23 0-2.04-.75-2.04-2.19V.39h-7.62v12.75c0 5.07 3.39 8.64 9.66 8.64zm22.17 0c3.3 0 5.43-.84 7.17-2.07l-2.25-6.03c-.96.87-2.34 1.65-4.08 1.65-2.34 0-4.2-1.74-4.2-4.47 0-2.61 1.8-4.41 4.2-4.41 1.62 0 2.91.63 3.93 1.62l2.28-6C273 .9 270.81 0 267.63 0c-6.84 0-11.31 4.83-11.31 10.86 0 6.54 4.74 10.92 11.31 10.92zm19.86-.39V6.3h4.14V.39h-15.87V6.3h4.11v15.09h7.62zm13.08 0v-21h-7.62v21h7.62zm16.41 0l7.62-21h-8.01l-3.33 12.3-3.33-12.3h-8.25l7.62 21h7.68zm16.35 0v-21h-7.62v21h7.62zm13.08 0V6.3h4.14V.39h-15.87V6.3h4.11v15.09h7.62zm19.44 0v-8.97L372.42.39h-8.13l-2.1 5.97-2.1-5.97h-8.43l6.57 12.03v8.97h7.62z"
        fill="#04E6AE"
        transform="translate(-224 -1491) translate(27 1459) translate(198 33)"
      />
      <path
        d="M7.41 21.39V11.28l2.07 8.52h6.72l2.07-8.52v10.11h7.41v-21h-9.54l-3.3 11.94L9.54.39H0v21h7.41zm26.76 0l.93-3.21h4.92l.93 3.21h7.8l-7.26-21h-7.5l-7.26 21h7.44zm4.56-8.55h-2.37l1.2-4.32 1.17 4.32zm18.6 8.55v-9.87l4.56 9.87h7.71v-21h-7.53v9.87L57.51.39H49.8v21h7.53zm20.76 0l.93-3.21h4.92l.93 3.21h7.8l-7.26-21h-7.5l-7.26 21h7.44zm4.56-8.55h-2.37l1.2-4.32 1.17 4.32zm20.28 8.94c5.37 0 8.37-1.86 10.2-3.45V9h-9.18v5.07h2.37v.81c-.39.24-1.23.57-2.58.57-2.58 0-4.23-1.86-4.23-4.59 0-2.61 1.8-4.41 4.2-4.41 1.68 0 2.85.63 3.87 1.53l4.23-4.56C110.1 1.65 107.37 0 102.87 0c-6.78 0-11.31 4.83-11.31 10.86 0 6.21 4.32 10.92 11.37 10.92zm24.81-.39v-5.91h-5.52v-1.89h4.92V8.04h-4.92V6.3h5.52V.39h-13.11v21h13.11zm9.3 0c7.05 0 11.73-4.05 11.73-10.53 0-6.57-4.74-10.47-11.73-10.47h-7.5v21h7.5zm.33-6h-.45v-9h.45c2.19 0 3.63 1.41 3.63 4.47 0 3.06-1.44 4.53-3.63 4.53z"
        stroke="url(#mp-non-p-a)"
        strokeWidth={0.6}
        fillOpacity={0.75}
        fill="url(#mp-non-p-a)"
        transform="translate(-224 -1491) translate(27 1459) translate(198 33)"
      />
    </g>
  </svg>
)

export default ManagedProductivitySvg
