import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import style from './quote.module.css'

const PureQuote = ({ data }) => (
  <section className={style.section}>
    <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="" />
  </section>
)

PureQuote.defaultProps = {
  data: {},
}

PureQuote.propTypes = {
  data: PropTypes.object,
}

const query = graphql`
  {
    file(relativePath: { eq: "services/google/google-services-quote.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`

export const Quote = (props) => (
  <StaticQuery query={query} render={(data) => <PureQuote data={data} {...props} />} />
)

export default Quote
