import Layout from 'components/Layout'
import { CurrentCustomer, Hero, O365Migration, Offer } from 'components/Page/Office365'

const Office365Page = () => (
  <Layout
    title="Office 365"
    description="Observian has partnered with Microsoft to bring our customers productivity and collaboration with Office 365. See how these Microsoft tools paired with our expertise can help transform your business."
    keywords="Microsoft, Microsoft Partner,office365,office 365,Azure cloud partner,Office products,office 365 products,office migration,office 365 migration, office365 migration, exchange server migration, exchange migration"
  >
    <Hero />
    <O365Migration />
    <CurrentCustomer />
    <Offer />
  </Layout>
)

export default Office365Page
