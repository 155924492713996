import CVELogo from 'components/SVG/cveLogo'

import style from './cve.module.css'

const CVE = () => (
  <section className={style.cve}>
    <header className={style.header}>
      <p className={style.subHeading}>
        Observian, Inc. is proud to be backed by Cache Valley Electric of Logan, Utah. CVE has six
        operating divisions to fulfill all the requirements you need. As one of the nation&apos;s
        premier electrical contractors, CVE will maximize your time, money and resources on any
        given project.
      </p>
      <span className={style.heading}>Powered By</span>
      <a
        className={style.linkCVE}
        href="https://www.cve.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open CVE website in new tab"
      >
        <CVELogo className={style.cveLogo} />
      </a>
    </header>
  </section>
)

export default CVE
