import Divider from 'observian-ui/lib/divider'

import style from './hero.module.css'

const Hero = () => (
  <section className={style.hero}>
    <span role="presentation" alt="Hero Background Left" className={style.topLeftBG} />
    <span role="presentation" alt="Hero Background Right" className={style.bottomRightBG} />
    <Divider />
  </section>
)

Hero.defaultProps = {}

Hero.propTypes = {}

export default Hero
