import { HeroGoogle, ManagedCloud, ManagedSecurity } from '../../components/Page/ManagedService'
import Layout from '../../components/Layout'

const ManagedServicePage = () => (
  <Layout
    title="Observian Managed GCP Services"
    description="Observian offers managed cloud services for all public cloud providers. We are experts in all things cloud, including DevOps, Big Data, Security, Migration, and more."
  >
    <HeroGoogle />
    <ManagedCloud />
    <ManagedSecurity />
  </Layout>
)

export default ManagedServicePage
