import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import style from './partnerBenefits.module.css'

class PurePartnerBenefits extends Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  handleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  }

  handleIsSuccessful = (value) => {
    this.setState({ isSuccessful: value })
  }

  render() {
    const { data } = this.props
    const { showModal, isSuccessful } = this.state

    return (
      <section className={style.section}>
        <div className={style.container}>
          <div className={style.infographic}>
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="" />
          </div>
          <div className={style.content}>
            <div className={style.header}>
              <h2 className={style.title}>Partner Benefits</h2>
              <h4 className={style.subTitle}>Why should you use a Google Partner?</h4>
              <TextUnderline />
            </div>
            <div className={style.specificBenefits}>
              <div className={style.columns}>
                <span>Expert Support</span>
                <span>Proof of Concept Credits</span>
                <span>Free Workshops</span>
              </div>
              <div className={style.columns}>
                <span>Free Assessments</span>
                <span>Customer Events</span>
              </div>
            </div>
            <p className={style.description}>
              Whether you are new to the cloud or a seasoned cloud enterprise, we’re confident we
              can provide the resources you need to improve your cloud experience. Our goal is to
              help you develop usable, repeatable and automated cloud solutions while educating and
              supporting your teams needs. We will keep you up to date on top technologies and
              releases in the Google Cloud ecosystem so you can stay on the cutting edge of
              development and cloud operations. We are your partners in the cloud.
            </p>
            <Button
              label="Contact Observian Today"
              className={style.button}
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: 'Google Cloud - Contact Observian Today'
                }
              ])}
            />
            {showModal ? (
              <ContactModal
                showClose
                shouldCloseOnOverlayClick
                onClose={this.handleModal}
                onDismiss={this.handleModal}
                isOpen={showModal}
                closeButtonPosition="right"
                ariaHideApp={false}
                success={isSuccessful}
              >
                <ContactForm
                  showLogo
                  showContactInfo
                  headerTitle="Contact Us"
                  buttonLabel="Send"
                  ctaIntentSuffix="modal-google-partnerbenefits"
                  inquiryPlaceholder="I'm interested in learning how Observian can help my company."
                  isSuccessfulCallback={(v) => {
                    this.handleIsSuccessful(v)
                    if (!v) {
                      this.handleModal()
                    }
                  }}
                />
              </ContactModal>
            ) : null}
          </div>
        </div>
      </section>
    )
  }
}

PurePartnerBenefits.defaultProps = {
  data: {},
}

PurePartnerBenefits.propTypes = {
  data: PropTypes.object,
}

const query = graphql`
  {
    file(relativePath: { eq: "services/google/google-p-services.png" }) {
      childImageSharp {
        gatsbyImageData(width: 450, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`

export const PartnerBenefits = (props) => (
  <StaticQuery query={query} render={(data) => <PurePartnerBenefits data={data} {...props} />} />
)

export default PartnerBenefits
