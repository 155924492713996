import Layout from 'components/Layout'
import BasicSEOPage from 'templates/SEO/Basic'

const ToolsBasicSEOPage = () => (
  <>
    <Layout
      title="Secret AWSome App"
      description="Secret AWSome app provides an easy and secure integration to help manage your AWS Systems Manager Parameter Store on macOS, Linux, and Windows devices."
      keywords="OSS, open source, open-source, secret, secret awsome, secret awesome, systems manager parameter store, macOS, Linux, Windows, OS, aws systems manager parameter store"
    >
      <BasicSEOPage
        description="The Secret AWSome app provides an easy and secure integration to help manage your AWS
              Systems Manager Parameter Store on macOS, Linux, &amp; Windows devices. The Secret
              AWSome app allows you to easily add or clone parameters to multiple regions with a few
              clicks. It reads/writes to your local AWS credentials file for easy integration into
              AWS."
      />
    </Layout>
  </>
)

export default ToolsBasicSEOPage
