import PropTypes from 'prop-types'

const Cloud404 = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="847"
    height="654"
    viewBox="0 0 847 654"
  >
    <defs>
      <filter
        id="observian_404-a"
        width="113.1%"
        height="129.2%"
        x="-6.5%"
        y="-10.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="20" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        />
      </filter>
      <linearGradient id="observian_404-c" x1="15.436%" x2="114.319%" y1="90.518%" y2="21.413%">
        <stop offset="0%" stopColor="#FFC42A" />
        <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
      </linearGradient>
      <linearGradient id="observian_404-e" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#050505" stopOpacity="0.5" />
        <stop offset="100%" stopOpacity="0.5" />
      </linearGradient>
      <filter
        id="observian_404-f"
        width="279.1%"
        height="151.1%"
        x="-89.6%"
        y="-25.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="20" />
      </filter>
      <linearGradient id="observian_404-g" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-115 -76)">
      <g fill="#344960">
        <text
          fill="#344960"
          fontFamily="GeomanistUltra, Geomanist"
          fontSize="400"
          fontWeight="600"
          letterSpacing="9.6"
          filter="url(#observian_404-a)"
        >
          <tspan x="150.2" y="380">
            404
          </tspan>
        </text>
        <text
          fill="#344960"
          fontFamily="GeomanistUltra, Geomanist"
          fontSize="400"
          fontWeight="600"
          letterSpacing="9.6"
        >
          <tspan x="150.2" y="380">
            404
          </tspan>
        </text>
      </g>
      <image
        width="540"
        height="288"
        x="269"
        y="263"
        xlinkHref="/images/observian_floating_cloud.png"
      />
      <polygon
        points="522 475 571.795 598.858 527.944 592.031 574.466 706 542.412 603.579 589 613.261 552.926 494.066 543.672 492.952 541.966 486.654 538.277 478.63"
        fill="url(#observian_404-c)"
      />
      <polygon
        points="522 475 571.795 598.858 527.944 592.031 574.466 706 542.412 603.579 589 613.261 552.926 494.066 543.672 492.952 541.966 486.654 538.277 478.63"
        fill="url(#observian_404-e)"
      />
      <polygon
        fill="url(#observian_404-c)"
        points="516 473 565.795 597.174 521.944 590.33 575.053 708 536.412 601.907 583 611.614 547.499 494.236 546.662 493.076 540.572 483.421 537.351 476.928"
      />
      <polygon
        id="flashglow"
        fill="url(#observian_404-c)"
        points="516 473 565.795 597.174 521.944 590.33 575.053 708 536.412 601.907 583 611.614 547.499 494.236 546.662 493.076 540.572 483.421 537.351 476.928"
        filter="url(#observian_404-f)"
      />
      <polygon
        fill="url(#observian_404-g)"
        points="157 322.144 201.235 372 298 306 201.235 350.508 181.608 322.144"
        opacity="0.238"
      />
      <polygon
        fill="url(#observian_404-g)"
        points="759 118 696 145.236 736.246 241 714.541 149.235 746.749 137.073"
        opacity="0.144"
      />
      <polygon
        fill="url(#observian_404-g)"
        points="905.606 284.113 928.363 311.098 820 297.606 962 331 905.606 263"
        opacity="0.201"
      />
      <polygon
        fill="url(#observian_404-g)"
        points="438 145 458.046 81 554 125.999 458.046 113"
        opacity="0.201"
      />
    </g>
  </svg>
)

Cloud404.defaultProps = {
  className: '',
}

Cloud404.propTypes = {
  className: PropTypes.string,
}

export default Cloud404
