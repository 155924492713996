import PropTypes from 'prop-types'

const RainDrops = ({ className, rainGroups }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1388"
    height="974"
    viewBox="0 0 1388 974"
  >
    <defs>
      <linearGradient id="raindrops-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="raindrops-b"
        width="422%"
        height="241.4%"
        x="-161%"
        y="-70.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-c"
        width="422%"
        height="241.4%"
        x="-161%"
        y="-70.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-d"
        width="422%"
        height="241.4%"
        x="-161%"
        y="-70.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-e"
        width="422%"
        height="241.4%"
        x="-161%"
        y="-70.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="raindrops-f"
        width="275.8%"
        height="177.2%"
        x="-87.9%"
        y="-38.6%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="6.875" />
      </filter>
      <filter
        id="raindrops-g"
        width="708.6%"
        height="367.2%"
        x="-304.3%"
        y="-133.6%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-h"
        width="350.1%"
        height="209.8%"
        x="-125%"
        y="-54.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-i"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-j"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3.85" />
      </filter>
      <filter
        id="raindrops-k"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="5.25" />
      </filter>
      <filter
        id="raindrops-l"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="8.75" />
      </filter>
      <filter
        id="raindrops-m"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="8.75" />
      </filter>
      <filter
        id="raindrops-n"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="4.594" />
      </filter>
      <filter
        id="raindrops-o"
        width="588.2%"
        height="314.4%"
        x="-244.1%"
        y="-107.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-p"
        width="695.7%"
        height="361.6%"
        x="-297.8%"
        y="-130.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-q"
        width="695.7%"
        height="361.6%"
        x="-297.8%"
        y="-130.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-r"
        width="695.7%"
        height="361.6%"
        x="-297.8%"
        y="-130.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-s"
        width="695.7%"
        height="361.6%"
        x="-297.8%"
        y="-130.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-t"
        width="695.7%"
        height="361.6%"
        x="-297.8%"
        y="-130.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="12.5" />
      </filter>
      <filter
        id="raindrops-u"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="5.4" />
      </filter>
      <filter
        id="raindrops-v"
        width="332.6%"
        height="202.1%"
        x="-116.3%"
        y="-51.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="9" />
      </filter>
    </defs>
    <g fill="url(#raindrops-a)" fillRule="evenodd" opacity="0.392" transform="translate(27 7)">
      <polygon
        className={rainGroups.rainGroup1}
        points="21.211 564.12 .767 596.754 8.705 617.17 24.06 602.247"
        filter="url(#raindrops-b)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="195.911 715.048 175.466 747.682 183.404 768.098 198.76 753.175"
        filter="url(#raindrops-c)"
        opacity="0.185"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="96.079 226.275 75.634 258.909 83.572 279.325 98.928 264.401"
        filter="url(#raindrops-d)"
        opacity="0.273"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="1104.296 188.465 1087.94 214.572 1094.291 230.905 1106.575 218.966"
        filter="url(#raindrops-e)"
        opacity="0.492"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="1341.933 527.522 1321.336 560.4 1329.334 580.969 1344.804 565.934"
        filter="url(#raindrops-f)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="1000.137 771.126 989.32 788.392 993.52 799.194 1001.645 791.298"
        filter="url(#raindrops-g)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="970.272 437.354 943.949 479.371 954.17 505.658 973.941 486.443"
        filter="url(#raindrops-h)"
        opacity="0.292"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="308.858 225.289 280.562 270.455 291.549 298.712 312.801 278.057"
        filter="url(#raindrops-i)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="400.432 .693 391.717 14.604 395.101 23.308 401.646 16.946"
        filter="url(#raindrops-j)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="1235.806 47.978 1223.921 66.947 1228.536 78.815 1237.462 70.14"
        filter="url(#raindrops-k)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="849.215 897.699 829.408 929.315 837.099 949.095 851.975 934.636"
        filter="url(#raindrops-l)"
        opacity="0.595"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="519.215 757.699 499.408 789.315 507.099 809.095 521.975 794.636"
        filter="url(#raindrops-m)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="295.166 899.905 284.768 916.504 288.805 926.888 296.616 919.297"
        filter="url(#raindrops-n)"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="698.988 129.252 685.505 150.774 690.74 164.239 700.867 154.397"
        filter="url(#raindrops-o)"
        opacity="0.244"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="385.478 695.815 374.427 713.454 378.718 724.489 387.018 716.423"
        filter="url(#raindrops-p)"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="509.763 686.375 498.713 704.013 503.004 715.048 511.303 706.982"
        filter="url(#raindrops-q)"
        opacity="0.105"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="816.74 647.094 805.69 664.733 809.98 675.768 818.28 667.701"
        filter="url(#raindrops-r)"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="642.623 724.637 631.573 742.275 635.864 753.31 644.163 745.244"
        filter="url(#raindrops-s)"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="690.26 632.757 679.21 650.396 683.5 661.431 691.8 653.365"
        filter="url(#raindrops-t)"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="147.598 502.711 135.374 522.223 140.121 534.43 149.302 525.507"
        filter="url(#raindrops-u)"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="1239.136 726.878 1218.763 759.397 1226.674 779.742 1241.975 764.871"
        filter="url(#raindrops-v)"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="21.211 564.12 .767 596.754 8.705 617.17 24.06 602.247"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="195.911 715.048 175.466 747.682 183.404 768.098 198.76 753.175"
        opacity="0.185"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="96.079 226.275 75.634 258.909 83.572 279.325 98.928 264.401"
        opacity="0.273"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="1104.296 191.265 1087.94 217.372 1094.291 233.705 1106.575 221.766"
        opacity="0.492"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="1341.933 527.522 1321.336 560.4 1329.334 580.969 1344.804 565.934"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="1000.137 771.126 989.32 788.392 993.52 799.194 1001.645 791.298"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="970.272 437.354 943.949 479.371 954.17 505.658 973.941 486.443"
        opacity="0.292"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="308.858 225.289 280.562 270.455 291.549 298.712 312.801 278.057"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="400.432 .693 391.717 14.604 395.101 23.308 401.646 16.946"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="1235.806 47.978 1223.921 66.947 1228.536 78.815 1237.462 70.14"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="849.215 897.699 829.408 929.315 837.099 949.095 851.975 934.636"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="519.215 757.699 499.408 789.315 507.099 809.095 521.975 794.636"
        opacity="0.595"
      />
      <polygon
        className={rainGroups.rainGroup2}
        points="295.166 899.905 284.768 916.504 288.805 926.888 296.616 919.297"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="147.598 508.311 135.374 527.823 140.121 540.03 149.302 531.107"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="1239.136 726.878 1218.763 759.397 1226.674 779.742 1241.975 764.871"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="698.988 129.252 685.505 150.774 690.74 164.239 700.867 154.397"
        opacity="0.244"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="385.478 695.815 374.427 713.454 378.718 724.489 387.018 716.423"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="509.763 686.375 498.713 704.013 503.004 715.048 511.303 706.982"
        opacity="0.105"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="816.74 647.094 805.69 664.733 809.98 675.768 818.28 667.701"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="642.623 724.637 631.573 742.275 635.864 753.31 644.163 745.244"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="477.157 611.025 466.107 628.663 470.397 639.698 478.697 631.632"
        opacity="0.727"
      />
      <polygon
        className={rainGroups.rainGroup1}
        points="690.26 632.757 679.21 650.396 683.5 661.431 691.8 653.365"
      />
    </g>
  </svg>
)

RainDrops.defaultProps = {
  className: '',
  rainGroups: {
    rainGroup1: 'rainGroup1',
    rainGroup2: 'rainGroup2',
  },
}

RainDrops.propTypes = {
  className: PropTypes.string,
  rainGroups: PropTypes.shape({
    rainGroup1: PropTypes.string,
    rainGroup2: PropTypes.string,
  }),
}

export default RainDrops
