import PropTypes from 'prop-types'

import LaptopImage from '../../images/home/observian-aws-laptop.png'

const Laptop = ({ className, graphShadowClassName, graphClassName }) => (
  <svg
    className={className}
    width="771px"
    height="485px"
    viewBox="0 0 771 485"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <radialGradient
        cx="36.0650061%"
        cy="3.2015535%"
        fx="36.0650061%"
        fy="3.2015535%"
        r="135.592465%"
        gradientTransform="translate(0.360650,0.032016),scale(0.443135,1.000000),rotate(132.480764),translate(-0.360650,-0.032016)"
        id="laptop-radialGradient-1"
      >
        <stop stopColor="#EC7572" offset="0%" />
        <stop stopColor="#6FDD88" offset="100%" />
      </radialGradient>
      <filter
        x="-11.7%"
        y="-26.3%"
        width="123.3%"
        height="152.7%"
        filterUnits="objectBoundingBox"
        id="laptop-filter-2"
      >
        <feGaussianBlur stdDeviation="10" in="SourceGraphic" />
      </filter>
    </defs>
    <g id="laptop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="laptop_group_1" transform="translate(-539.000000, -4132.000000)">
        <g id="laptop_group_2" transform="translate(-49.000000, 3977.000000)">
          <image
            id="laptop_image"
            transform="translate(972.086291, 399.862784) rotate(-3.000000) translate(-972.086291, -399.862784) "
            x="574.6875"
            y="175.970714"
            width="794.797582"
            height="447.784141"
            xlinkHref={LaptopImage}
          />
          <g
            id="costline"
            transform="translate(1142.500000, 424.500000) rotate(-3.000000) translate(-1142.500000, -424.500000) translate(1000.000000, 341.000000)"
          >
            <path
              d="M43.5,0.5 L1.5,166.5"
              id="laptop_left_line"
              stroke="#979797"
              opacity="0.256170743"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5,166.5 L232.5,149.5"
              id="laptop_bottom_line"
              stroke="#979797"
              opacity="0.256170743"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M113.5,163.5 L115.816205,153.771938"
              id="laptop_bottom_marker_line"
              stroke="#DCDCDC"
              strokeLinecap="square"
            />
            <path
              d="M21.2781771,70.24198 L31.2543177,69.5516033"
              id="laptop_left_marker_line"
              stroke="#DCDCDC"
              strokeLinecap="square"
            />
            <path
              className={graphClassName}
              d="M10.1113281,133.513672 C46.1582031,123.111328 50.9609375,22.8359375 88.6386719,12.1835937 C102.193431,8.35135814 110.007829,67.0394618 116.921875,71.7998598 C129.226584,80.271789 137.684354,41.3787199 163.480469,54.7363281 C203.771484,75.5996094 245.152344,68.0626658 284.316406,72.758793"
              id="laptop_graph_line"
              stroke="url(#laptop-radialGradient-1)"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              filter="url(#laptop-filter-2)"
            />
            <path
              className={graphShadowClassName}
              d="M0.111328125,123.513672 C36.1582031,113.111328 40.9609375,12.8359375 78.6386719,2.18359375 C92.1934312,-1.64864186 100.007829,57.0394618 106.921875,61.7998598 C119.226584,70.271789 127.684354,31.3787199 153.480469,44.7363281 C193.771484,65.5996094 235.152344,58.0626658 274.316406,62.758793"
              id="laptop_graph_shadow_line"
              stroke="url(#laptop-radialGradient-1)"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Laptop.defaultProps = {
  className: '',
  graphClassName: '',
  graphShadowClassName: '',
}

Laptop.propTypes = {
  className: PropTypes.string,
  graphClassName: PropTypes.string,
  graphShadowClassName: PropTypes.string,
}

export default Laptop
