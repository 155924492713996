export const playAnimation = (tl) => {
  if (tl && !!tl.length) {
    return tl.map((t) => t.play())
  }

  return tl.play()
}

export const pauseAnimation = (tl) => {
  if (tl && !!tl.length) {
    return tl.map((t) => t.pause())
  }

  return tl.pause()
}
