const TechLinesSvg = (props) => (
  <svg width="393px" height="423px" viewBox="0 0 393 423" {...props}>
    <g
      transform="translate(235 -552) scale(-1 1) rotate(-14 2480.221 955.589)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      opacity={0.108407054}
    >
      <g transform="matrix(-1 0 0 1 240.645 .796)" stroke="#03BB8B">
        <path d="M49.7717204 355.311158L108.641247 296.441632 78.0790591 265.879444 97.7402251 246.218278 214.19671 129.761793" />
        <path d="M29.7717204 355.311158L88.6412469 296.441632 58.0790591 265.879444 77.7402251 246.218278 194.19671 129.761793 142.884154 78.4492371" />
        <path d="M9.7717204 355.311158L68.6412469 296.441632 38.0790591 265.879444 57.7402251 246.218278 174.19671 129.761793 122.884154 78.4492371" />
        <path d="M51.3908419 136.123882L41.3691406 126.102181 114.842958 52.1381349 63.5304023 0.825579028" />
        <path d="M0.41796875 287.6875L69.2874953 218.817973 38.7253074 188.255786" />
        <path d="M69.7717204 355.311158L128.641247 296.441632 98.0790591 265.879444 147.740225 216.218278" />
        <path d="M109.77172 355.311158L168.641247 296.441632 138.079059 265.879444 187.740225 216.218278 238.951856 267.429908" />
      </g>
      <circle fill="#03BB8B" cx={92.6462484} cy={216.623658} r={2} />
      <circle fill="#03BB8B" cx={146.646248} cy={191.623658} r={2} />
      <circle fill="#03BB8B" cx={26.6462484} cy={130.623658} r={2} />
      <circle fill="#03BB8B" cx={97.6462484} cy={79.6236581} r={2} />
      <circle fill="#03BB8B" cx={117.646248} cy={79.6236581} r={2} />
      <circle fill="#03BB8B" cx={177} cy={2} r={2} />
      <circle fill="#03BB8B" cx={2.08787996} cy={267.470322} r={2} />
      <path
        stroke="#03BB8B"
        d="M166.892578 114.71875L237.983669 185.809841 314.032163 187.108987"
      />
    </g>
  </svg>
)

export default TechLinesSvg
