import { func } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../../utils/formRedirect'

import TechLines from 'components/SVG/techLines2'

import SvgManagedProductivityPlus from './ManagedProductivityPlus'
import TextSvg from './TextSvg'
import Price from './Price'
import style from './PackageOptions.module.css'

const PackageOptions = ({ setShowModal }) => {
  const data = useStaticQuery(graphql`
    {
      googleworkspace: file(relativePath: { eq: "services/managed/googleworkspace.png" }) {
        childImageSharp {
          gatsbyImageData(width: 469, placeholder: TRACED_SVG, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <section className={style.wrapper}>
      <TechLines className={style.techLines} />

      <div className={style.top}>
        <div className={style.img}>
          <GatsbyImage image={data.googleworkspace.childImageSharp.gatsbyImageData} alt="" />
        </div>
        <h2>Package Options</h2>
      </div>
      <div className={style.middle}>
        <p className={style.text1}>
          These packages are in addition to your monthly office or workspace subscription.
        </p>
        <p className={style.text3}>
          If you don’t currently have a Google Workspace or Microsoft Office subscription, get
          discounted licensing with Observian - <strong>Office 365</strong> |{' '}
          <strong>Google Workspace</strong>
        </p>
      </div>
      <div className={style.boxes}>
        <div className={style.leftBox}>
          <div className={style.content}>
            <TextSvg className={style.titleSvg} />
            <div className={style.priceWrapper}>
              <Price price="$7.75" />
            </div>
            <div className={style.price}>
              <h2>$7 / User</h2>
              <span>(Monthly)</span>
            </div>
            <div className={style.contentBottom}>
              <div>
                <div>
                  <h3>Observian Support</h3>
                  <ul>
                    <li>Admin level support of managed systems</li>
                    <li>Monthly Review</li>
                    <li>Quarterly Security Audit </li>
                    <li>Discounted Professional Services</li>
                    <li>Discounted Licensing</li>
                  </ul>
                </div>
                <div>
                  <h3>Datto Infinite Cloud Retention</h3>
                  <ul>
                    <li>Granular restore and export</li>
                    <li>Automated Continuous Backup</li>
                    <li>On-Demand Backup</li>
                    <li>Unlimited Storage</li>
                    <li>Infinite Data Retention</li>
                    <li>Cross User Restore</li>
                    <li>Retain inactive/deleted user data</li>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <h3>Proofpoint Security Awareness</h3>
                  <ul>
                    <li>Phishing Simulations</li>
                    <li>End User Training Programs</li>
                    <li>Automatic Training Enrollment </li>
                  </ul>
                </div>
                <div>
                  <h3>Proofpoint Essentials</h3>
                  <ul>
                    <li>Available End User Portal</li>
                    <li>Emergency Inbox</li>
                    <li>Email spooling</li>
                    <li>Instant Replay</li>
                    <li>Intuitive Management Portal</li>
                    <li>Reporting</li>
                    <li>Email Logs</li>
                    <li>Anti Virus</li>
                    <li>Spam Filtering</li>
                    <li>Outbound Filtering</li>
                    <li>Imposter Email Protection</li>
                    <li>Data Loss Prevention</li>
                    <li>URL Defense</li>
                    <li>Attachment Defense</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <svg viewBox="0 0 801 805" className={style.leftBoxBG}>
            <defs>
              <linearGradient
                x1="12.8266191%"
                y1="84.9243045%"
                x2="55.4875604%"
                y2="45.7459481%"
                id="mp-left-a"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d="M4 0h791a4 4 0 014 4v778.945a4 4 0 01-4.055 4L287.695 780h0L4.32 802.655A4 4 0 010 798.667V4a4 4 0 014-4z"
              transform="translate(-26 -1458) translate(27 1459)"
              fill="url(#mp-left-a)"
              stroke="url(#mp-left-a)"
              strokeWidth={1.1}
              fillRule="evenodd"
              fillOpacity={0.25}
            />
          </svg>
        </div>
        <div className={style.rightBoxes}>
          <div className={style.rightTop}>
            <div className={style.title}>
              <SvgManagedProductivityPlus checks={1} className={style.titleSvg} />
            </div>
            <div className={style.priceWrapper}>
              <Price price="$8.75" />
            </div>
            <div className={style.price}>
              <h2>$8 / User</h2>
              <span>(Monthly)</span>
            </div>
            <div className={style.points}>
              <h3>Encryption</h3>
              <ul>
                <li>Advanced Attachment Defense</li>
                <li>End-to-End Email Encryption</li>
                <li>Social Media Account Protection</li>
              </ul>
            </div>
            <div className={style.bottom}>Most Common</div>
          </div>
          <div className={style.rightMiddle}>
            <div className={style.title}>
              <SvgManagedProductivityPlus checks={2} className={style.titleSvg} />
            </div>
            <div className={style.priceWrapper}>
              <Price price="$10.33" />
            </div>
            <div className={style.price}>
              <h2>$9 / User</h2>
              <span>(Monthly)</span>
            </div>
            <div className={style.points}>
              <h3>Email Archiving</h3>
              <ul>
                <li>Tamper Proof</li>
                <li>Offsite</li>
                <li>Search and eDiscovery</li>
                <li>Unlimited Storage up to 10 years</li>
              </ul>
            </div>
          </div>
          <div className={style.rightBottom}>
            <p>
              Don’t see a package that fits your needs? We can work with you to create a package for
              your unique needs.
            </p>
          </div>
        </div>
      </div>
      <div className={style.getStarted}>
        <Button 
          varient="primary" 
          label="Get Started" 
          onClick={() => window.location.href = buildFormUrl([
            {
              key: 'CTA',
              value: `Managed Productivity - Get Started`
            }
          ])}
        />
      </div>
    </section>
  )
}

PackageOptions.propTypes = {
  setShowModal: func.isRequired,
}

export default PackageOptions
