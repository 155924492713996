import { Component } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Card from 'observian-ui/lib/card'
import { Social } from 'observian-ui/lib/svg/icons'
import clsx from 'clsx'
import capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

import Ribbon from 'components/SVG/ribbon'

import style from './team.module.css'

class Team extends Component {
  state = {
    avatar: {},
    amazon: {},
    microsoft: {},
    cisco: {},
    defaultAvatar: '/images/default/avatar.png',
    docker: {},
    google: {},
    security: {},
  }

  handleMouseEnter = (type, index) => {
    this.setState({
      [type]: {
        [index]: true,
      },
    })
  }

  handleMouseLeave = (type, index) => {
    this.setState({
      [type]: {
        [index]: false,
      },
    })
  }

  renderCerts = (member) => {
    const { state } = this
    const certifications = member.certifications[0] || []
    return !isEmpty(certifications)
      ? map(certifications, (certValues, cert) => (
          //  eslint-disable-next-line react/jsx-indent
          <div
            role="presentation"
            key={`cert:${cert}`}
            className={`${style.award} ${certValues ? '' : style.nocerts}`}
            onMouseEnter={() => this.handleMouseEnter(cert, member.employeeId)}
            onMouseLeave={() => this.handleMouseLeave(cert, member.employeeId)}
          >
            {certValues && !isEmpty(certValues) ? (
              <Ribbon type={cert} className={style.ribbon} />
            ) : null}
            <div className={clsx(style.certs, state[cert][member.employeeId] && style[cert])}>
              <h3>{`${capitalize(cert)} Certifications`}</h3>
              <ul>
                {certValues &&
                  !isEmpty(certValues) &&
                  map(certValues, (certName, certNameIndex) => (
                    <li key={`cert:${certNameIndex + 1}`}>
                      <h5>{certName}</h5>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))
      : null
  }

  renderTeamPhoto = (photo, photoHover, employeeId) => {
    const { avatar, defaultAvatar } = this.state

    const avatarPhoto = photo || defaultAvatar
    const avatarHover = photoHover || defaultAvatar
    return avatar[employeeId] ? avatarHover : avatarPhoto
  }

  render() {
    const { members } = this.props
    return (
      <div className={style.team}>
        <div className={style.theTeamText}>
          <svg
            className={style.teamText}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="880"
            height="208"
            viewBox="0 0 880 208"
          >
            <defs>
              <text
                id="the-team-b"
                fill="#F9FAFA"
                fontFamily="GeomanistUltra, Geomanist"
                fontSize="180"
                fontWeight="600"
                letterSpacing="4.32"
              >
                <tspan x="4" y="175">
                  THE
                </tspan>
                <tspan x="375" y="175">
                  TEAM
                </tspan>
              </text>
              <filter
                id="the-team-a"
                width="112.5%"
                height="126%"
                x="-6%"
                y="-8%"
                filterUnits="objectBoundingBox"
              >
                <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                  stdDeviation="15"
                />
                <feColorMatrix
                  in="shadowBlurOuter1"
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <g fill="#F9FAFA" fillRule="evenodd" opacity="0.1">
              <use filter="url(#the-team-a)" xlinkHref="#the-team-b" />
              <use xlinkHref="#the-team-b" />
            </g>
          </svg>
        </div>
        <div className={style.masonryWall}>
          {map(
            sortBy(members, (member) => member.employeeId),
            (member) =>
              !isEmpty(member) && true === member.isEmployee ? (
                <div
                  role="presentation"
                  className={clsx(style.cardContainer, style[`team${member.employeeId}`])}
                  key={`team:member:card:${member.employeeId}`}
                  onMouseEnter={() => this.handleMouseEnter('avatar', member.employeeId)}
                  onMouseLeave={() => this.handleMouseLeave('avatar', member.employeeId)}
                >
                  <Card square disabled className={style.card}>
                    <div
                      className={style.photo}
                      style={{
                        backgroundImage: `url(${this.renderTeamPhoto(
                          member.photos.about.fixed.src,
                          member.photos.aboutHover.fixed.src,
                          member.employeeId
                        )})`,
                      }}
                    />
                    <div className={style.awards}>
                      {/* {map(
                        member.certifications,
                        ([certValues], cert) => console.log({ certValues, cert }),
                        // eslint-disable function-paren-newline
                      )} */}
                      {!isEmpty(member.certifications) ? this.renderCerts(member) : null}
                    </div>
                    <div className={style.content}>
                      <h3 className={style.name}>{`${member.firstName} ${member.lastName}`}</h3>
                      <h5 className={style.title}>{member.title}</h5>
                      <span className={style.bio}>{member.bio.bio}</span>
                      <div className={style.social}>
                        {map(
                          member.socialLinks,
                          (url, type) =>
                            url && !!url.length ? (
                              <Social
                                key={`social:icon:${type}`}
                                type={type}
                                url={url}
                                className={style.icon}
                                color="#344960"
                              />
                            ) : null
                          // eslint-disable function-paren-newline
                        )}
                      </div>
                    </div>
                  </Card>
                  <svg
                    className={style.lightning}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="319"
                    height="52"
                    viewBox="0 0 319 52"
                  >
                    <defs>
                      <linearGradient
                        id="employee-card-lightning"
                        x1="15.4%"
                        x2="114.3%"
                        y1="90.5%"
                        y2="21.4%"
                      >
                        <stop offset="0%" stopColor="#FFC42A" />
                        <stop offset="100%" stopColor="#FF0061" stopOpacity="0.95" />
                      </linearGradient>
                    </defs>
                    <polygon
                      fill="url(#employee-card-lightning)"
                      fillRule="evenodd"
                      points="0 51.867 88.868 17.74 207.077 34.804 319.199 0 176.198 51.867 101.359 24.804"
                    />
                  </svg>
                </div>
              ) : null
          )}
        </div>
      </div>
    )
  }
}

Team.defaultProps = {}

Team.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Team
