import PropTypes from 'prop-types'

const AWSPageTemplate = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1138"
    height="118"
    viewBox="0 0 1138 118"
  >
    <defs>
      <linearGradient id="aws-page-template-a" x1="15.436%" x2="114.319%" y1="90.518%" y2="21.413%">
        <stop offset="0%" stopColor="#FFC42A" />
        <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
      </linearGradient>
      <filter
        id="aws-page-template-b"
        width="105.4%"
        height="242%"
        x="-2.7%"
        y="-71%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
    </defs>
    <g fill="url(#aws-page-template-a)" transform="translate(7 23)">
      <polygon points="949.567 26.306 334.071 9.479 .537 47.577 334.071 24.528 970.22 51.734 1117.562 9.479" />
      <polygon
        points="949.567 36.308 334.071 19.481 .397 53.577 334.071 34.531 970.22 61.736 1117.562 19.481"
        filter="url(#aws-page-template-b)"
        opacity=".382"
        transform="rotate(-2 558.98 40.609)"
      />
    </g>
  </svg>
)

AWSPageTemplate.defaultProps = {
  className: '',
}

AWSPageTemplate.propTypes = {
  className: PropTypes.string,
}

export default AWSPageTemplate
