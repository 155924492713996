import { Hero, GoogleCloudPartner } from 'components/Page/Google/Essentials/'
import Layout from 'components/Layout'
import { CurrentCustomer, OfficeMigration } from 'components/Page/Google/GSuite'

const GSuiteEssentialsPage = () => (
  <Layout
    title="G Suite Essentials"
    description=""
    keywords="google,google partner,gsuite,g suite,google cloud partner,gsuite products,g suite products,gsuite migration,g suite migration"
  >
    <Hero />
    <CurrentCustomer />
    <OfficeMigration title="Coming from Office 365?" />
    <GoogleCloudPartner />
  </Layout>
)

export default GSuiteEssentialsPage
