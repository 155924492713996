import PropTypes from 'prop-types'

const GraphOutline = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1513"
    height="1301"
    viewBox="0 0 1513 1301"
  >
    <defs>
      <linearGradient
        id="background_outline-gradient"
        x1="96.8%"
        x2="16.877%"
        y1="22.945%"
        y2="81.085%"
      >
        <stop offset="0%" stopColor="#1C3148" />
        <stop offset="4.603%" stopColor="#1C3047" />
        <stop offset="100%" stopColor="#0F172D" />
      </linearGradient>
      <filter
        id="background_outline"
        width="638.5%"
        height="638.5%"
        x="-269.2%"
        y="-192.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M0.737448696,747.575789 L169.580674,872.490956 L287.047786,976.113966 L452.147092,921.153061 L621.357196,540.077817 L762.733229,222.030364 L980.755724,139.973564 C1170.37205,171.281474 1265.91491,186.724219 1267.38432,186.301797 C1268.85372,185.879376 1335.39228,123.841712 1467,0.188806774 L1512.79002,164.315667 L1512.79002,1300.01986 L0.737448696,1300.01986 L0.737448696,747.575789 Z"
        fill="url(#background_outline-gradient)"
        opacity="0.335"
      />
      <g transform="translate(280 134)">
        <circle cx="6.568" cy="839.918" r="6.5" fill="#000" filter="url(#background_outline)" />
        <circle cx="6.568" cy="839.918" r="6.5" fill="#1D354F" />
        <circle cx="172.047" cy="787.806" r="6.5" fill="#000" filter="url(#background_outline)" />
        <circle cx="172.047" cy="787.806" r="6.5" fill="#1D354F" />
        <g>
          <circle cx="485.5" cy="89.473" r="6.5" fill="#000" filter="url(#background_outline)" />
          <circle cx="485.5" cy="89.473" r="6.5" fill="#1D354F" />
        </g>
        <g>
          <circle cx="698.5" cy="7.473" r="6.5" fill="#000" filter="url(#background_outline)" />
          <circle cx="698.5" cy="7.473" r="6.5" fill="#1D354F" />
        </g>
        <g>
          <circle cx="986.5" cy="50.473" r="6.5" fill="#000" filter="url(#background_outline)" />
          <circle cx="986.5" cy="50.473" r="6.5" fill="#1D354F" />
        </g>
        <g>
          <circle cx="1182.5" cy="-126.527" r="6.5" fill="#000" filter="url(#background_outline)" />
          <circle cx="1182.5" cy="-126.527" r="6.5" fill="#1D354F" />
        </g>
      </g>
    </g>
  </svg>
)

GraphOutline.defaultProps = {
  className: '',
}

GraphOutline.propTypes = {
  className: PropTypes.string,
}

export default GraphOutline
