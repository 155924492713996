import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import clsx from 'clsx'
import Cloud from 'components/SVG/cloud'
import TextUnderline from 'components/SVG/textUnderline'
import Form from 'components/Form'

import style from './hero.module.css'

class Hero extends Component {
  state = {
    showModal: false,
  }

  handleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  }

  render() {
    const {
      eventName,
      eventDate,
      eventDateSecondLine,
      eventDateThirdLine,
      eventLocation,
      eventImage,
      eventDescription,
      eventFormTitle,
      eventFormCTA,
      formId,
      isCancelled,
      eventInquiryMessage,
    } = this.props
    const { showModal } = this.state

    return (
      <div className={style.container}>
        <span role="presentation" alt="Hero Background Left" className={style.topLeftBG} />
        <span role="presentation" alt="Hero Background Right" className={style.bottomRightBG} />
        <Cloud type="outline" className={style.cloud} />
        <div className={style.content}>
          <header className={style.header}>
            <h1>You’re Invited!</h1>
            <h3>{eventName}</h3>
            <TextUnderline />
          </header>
          <svg
            className={style.image}
            xmlns="http://www.w3.org/2000/svg"
            width="727"
            height="450"
            viewBox="0 0 727 450"
          >
            <defs>
              <linearGradient id="jazz-event-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
                <stop offset="0%" stopColor="#1D8DBA" />
                <stop offset="100%" stopColor="#03ECAE" />
              </linearGradient>
              <filter
                id="jazz-event-b"
                width="108.6%"
                height="165.1%"
                x="-4.3%"
                y="-32.5%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
              </filter>
              <filter
                id="jazz-event-c"
                width="132%"
                height="162.5%"
                x="-16%"
                y="-31.2%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
              </filter>
            </defs>
            <g fill="url(#jazz-event-a)" transform="translate(15 25)">
              <image
                transform="translate(72 5)"
                id="jazz-event-image"
                width="600"
                height="350"
                xlinkHref={eventImage}
              />
              <polygon
                points="80.456 291.623 1.934 383.859 701.3 326.407 66.985 355.571 80.456 337.481"
                filter="url(#jazz-event-b)"
                opacity=".492"
                transform="rotate(-3 351.617 337.741)"
              />
              <polygon points="90.456 281.623 11.934 373.859 711.3 316.407 76.985 345.571 90.456 327.481" />
              <polygon
                points="495.643 0 657.148 23.615 657.148 96.063 682.9 0"
                filter="url(#jazz-event-c)"
                opacity=".385"
              />
              <polygon points="485.643 0 647.148 23.615 647.148 96.063 672.9 0" />
            </g>
          </svg>
          <div className={clsx(style.section, style.when)}>
            <h4 className={style.description}>When:</h4>
            <p className={style.info}>{isCancelled ? 'TBD' : eventDate}</p>
            {eventDateSecondLine ? (
              <p className={style.info}>{isCancelled ? '' : eventDateSecondLine}</p>
            ) : null}
            {eventDateThirdLine ? (
              <p className={style.info}>{isCancelled ? '' : eventDateThirdLine}</p>
            ) : null}
          </div>
          <div className={clsx(style.section, style.where)}>
            <h4 className={style.description}>Where:</h4>
            <p className={style.info}>
              <a
                className={style.infoLink}
                href={`https://maps.google.com/maps?q=${eventLocation.replace(/\s/, '+')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {eventLocation}
              </a>
            </p>
          </div>
          <div className={clsx(style.section, style.what)}>
            <h4 className={style.description}>What:</h4>
            <p className={style.info}>{eventDescription}</p>
          </div>
          <Button
            variant="primary"
            label="RSVP"
            className={style.button}
            onClick={this.handleModal}
          />
        </div>
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          isOpen={showModal}
          onClose={this.handleModal}
        >
          <Form
            showLogo={false}
            isSpecialEvent
            formId={formId}
            title={eventFormTitle}
            onDismiss={this.handleModal}
            inquiryTitle="Other information?"
            inquiryPlaceholder={eventInquiryMessage}
            isSuccessfulMessage="Your RSVP has been confirmed!"
            ctaIntentSuffix={eventFormCTA}
            buttonLabel="RSVP"
          />
        </ContactModal>
      </div>
    )
  }
}

Hero.defaultProps = {
  eventName: 'Utah Jazz vs. Los Angeles Clippers',
  eventDate: 'February 27th, 5:30 PM MST',
  eventDateSecondLine: '',
  eventDateThirdLine: '',
  eventInquiryMessage:
    'Please let us know about any special dietary restrictions, or other important information.',
  eventLocation: 'Vivint Smart Home Arena',
  eventImage: '/static/images/events/jazz-image.png',
  eventDescription:
    "Join AppDynamics and Observian for a discussion on how APM's can help give insight to your business and applications. This is a great opportunity to have open discussions with IT experts and your peers in the industry. After an hour presentation and food, guests will be invited to socialize in a suite and watch the Jazz take on the Clippers.",
  eventFormTitle: 'Jazz Event RSVP Form',
  eventFormCTA: 'rsvp-jazz-event-hero',
  isCancelled: false,
}

Hero.propTypes = {
  formId: PropTypes.string.isRequired,
  eventName: PropTypes.string,
  eventDate: PropTypes.string,
  eventDateSecondLine: PropTypes.string,
  eventInquiryMessage: PropTypes.string,
  eventDateThirdLine: PropTypes.string,
  eventLocation: PropTypes.string,
  eventImage: PropTypes.string,
  eventDescription: PropTypes.string,
  eventFormTitle: PropTypes.string,
  eventFormCTA: PropTypes.string,
  isCancelled: PropTypes.bool,
}

export default Hero
