import { Component } from 'react'
import { gsap, Power0 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import Layout from 'components/Layout'
import AWSPageTemplate from 'templates/Page/AWS'
import CloudMigration from 'components/SVG/clouds/cloudMigration'

class CloudMigrationPage extends Component {
  componentDidMount() {
    gsap.registerPlugin(DrawSVGPlugin)
    const cloud = gsap.timeline({ repeat: -1 })
    const cloud1 = gsap.timeline({ repeat: -1 })
    const cloud2 = gsap.timeline({ repeat: -1 })
    const cloud3 = gsap.timeline({ repeat: -1 })

    cloud.to('.line1', 0.15, { opacity: 1 })
    cloud.fromTo(
      '.line2',
      { drawSVG: '0% 0%', opacity: 0 },
      { drawSVG: '0% 5%', opacity: 1, ease: Power0.easeNone, duration: 0.1 }
    )

    cloud.fromTo(
      '.line2',
      { drawSVG: '0% 10%' },
      { drawSVG: '50% 50%', ease: Power0.easeNone, duration: 0.35 }
    )

    cloud.to('.line2', 0.15, { opacity: 0 })

    cloud1.to('.line3', 0.1, { opacity: 1 })

    cloud1.fromTo(
      '.line4',
      { drawSVG: '0% 0%', opacity: 0 },
      { opacity: 1, drawSVG: '0% 5%', ease: Power0.easeNone, duration: 0.1 }
    )

    cloud1.fromTo(
      '.line4',
      { drawSVG: '0% 5%' },
      { drawSVG: '50% 50%', ease: Power0.easeNone, duration: 0.45 }
    )

    cloud1.to('.line4', { opacity: 0, duration: 0.1 })

    cloud2.to('.smallImage', {
      x: '+=10',
      y: '+=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 3,
    })

    cloud3.to('.largeImage', {
      x: '+=5',
      y: '+=8',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 4,
    })
  }

  render() {
    return (
      <Layout
        title="Cloud Migration Services in AWS"
        description="The path to cloud adoption is unique for every organization; Observian offers cloud migration services perfectly tailored to your business. Learn more!"
      >
        <AWSPageTemplate
          name="AWS Cloud Migration Services"
          title="AWS Cloud Migration Strategy"
          content="
          <p>
          From idea to implementation in seconds. That’s what cloud computing with AWS can do for you. Spending weeks spinning up servers on-premise are a thing of the past, replaced by cloud solutions that increase innovation, agility, and speed, which in turn, dramatically lowers overhead IT costs. Our AWS cloud migration services team can help.
          <br />
          <br />
          The path to cloud adoption is unique for every organization; however, we have seen common drivers that customers consistently apply when migrating to the cloud:
          </p>
          <ul>
          <li>Reduce Operational Costs</li>
          <br />
          <li>Increase Workforce Productivity</li>
          <br />
          <li>Eliminate Redundant Costs</li>
          <br />
          <li>Lessen Operational Risk</li>
          <br />
          <li>Enhance Business Agility</li>
          </ul>
          <br />
          <p>
          Regardless of how much new adoption you are looking to do, Observian has been there before. We can help you rapidly transform any aspect of your technical operations to the cloud.
          <br />
          To help your transformation, Observian has created a phased approach to our cloud migration services strategy (Assess, Plan and Educate, Execute, Optimize).
          <br />
          <br />
          These phases focus on a successful migration to AWS to ensure critical checkpoints are completed before moving onto the next task. However, migrations are rarely large, one-time events, so we use these phases cyclically, moving back to earlier phases to ensure accurate execution and optimization.
          </p>
          <br />
          <h3>Phase 1: Assess</h3>
          <p>
          Observian works with your team (be sure to have identified key departmental stakeholders for inclusion) to audit your existing environments, gathering technical parameters, business goals, and potential roadblocks.
          </p>
          <br />
          <h3>Phase 2: Plan & Educate</h3>
          <p>
          Together, we develop a cloud adoption strategy and establish a detailed migration plan, heavily considering the user experience, and ensuring operator disruptions are avoided or kept to an absolute minimum.
          <br />
          Leveraging our experience, and combined with the collection of AWS 90+ services and best practices, Observian creates a detailed design for your AWS framework and application deployments. We provide ongoing internal education to get your team up to speed and keep them there.
          </p>
          <br />
          <h3>Phase 3: Execute</h3>
          <p>
          Observian architects your AWS platform and configures all cloud services, to create a functional, scalable, and secure home for your business workloads.
          <br />
          Your applications and systems are seamlessly migrated to the AWS cloud platform, often with no measurable disruption to existing operations.
          </p>
          <br />
          <h3>Phase 4: Optimize</h3>
          <p>
          After migration, we proactively monitor systems and servers to ensure services are performing as expected, and resources are optimized, and being used at the right levels.
          </p>
          <br />
          <br />
          <p>
          Obervian can help institute regular KPI reviews, continuing to define the short and long-term roadmaps, establishing ideas for the next phases of cloud transformation.
          <br />
          <br />
          Moving to the cloud isn't easy, even if you've been there before. But this is where we live. Contact Us today to chat about how we can help you migrate to the cloud.
          </p>"
          headerIcon={<CloudMigration />}
          inquiryPlaceholder="Hi! We could use some help with migrating our current on-premise infastructure to the cloud..."
        />
      </Layout>
    )
  }
}

export default CloudMigrationPage
