import { object, func } from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// import scrollTo from 'gatsby-plugin-smoothscroll'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../utils/formRedirect'

import ObservianLogoGateMSFT from 'components/SVG/observianLogoGateMSFT'
import TechLines from 'components/SVG/techLines'

import SvgListIcon from './ListIcon'
import SvgManagedProductivityText from './assets/ManagedProductivityText'
import style from './hero.module.css'

const PureHeroManagedProductivity = ({ data, setShowModal }) => {
  const heroImage = data.hero.childImageSharp.gatsbyImageData
  return (
    <section className={style.hero}>
      <ObservianLogoGateMSFT
        className={style.observianLogoGate}
        // firstSpin={style.firstSpin}
        // secondSpin={style.secondSpin}
      />

      <TechLines className={style.techLines} />

      <div className={style.heroContent}>
        <div className={style.left}>
          <SvgManagedProductivityText className={style.titleSvg} />
          <h3 className={style.subTitle}>Data Retention & Security For Saas Applications</h3>
          <p className={style.heroText}>
            Observian’s Managed Productivity offering ensures your company always has the tools and
            data it needs to stay active and productive.
            <br />
            <strong>All starting at $7 per user monthly.</strong>
          </p>
          <div className={style.getStarted}>
            <Button 
              varient="primary" 
              label="Get Started" 
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Managed Productivity - Get Started`
                }
              ])}
            />
          </div>
          <ul className={style.heroList}>
            <li>
              <SvgListIcon /> Installation &amp; Setup Included.
            </li>
            <li>
              <SvgListIcon /> We Monitor, Review &amp; Support.
            </li>
            <li>
              <SvgListIcon />
              You Save Money &amp; Time.
            </li>
          </ul>
        </div>

        <div className={style.right}>
          <GatsbyImage image={heroImage} alt="" />
        </div>
      </div>
    </section>
  )
}

PureHeroManagedProductivity.propTypes = {
  data: object.isRequired,
  setShowModal: func.isRequired,
}

const query = graphql`
  {
    hero: file(relativePath: { eq: "services/managed/productivity.png" }) {
      childImageSharp {
        gatsbyImageData(width: 826, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`

export const HeroManagedProductivity = (props) => (
  <StaticQuery
    query={query}
    render={(data) => <PureHeroManagedProductivity data={data} {...props} />}
  />
)

export default HeroManagedProductivity
