import { useState } from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect'

import Cloud from 'components/SVG/cloud'
import ContactForm from 'components/Form/Contact'

import style from './war.module.css'

const War = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  return (
    <section className={style.section}>
      <div className={style.left}>
        <Cloud withLasers type="outline" className={style.cloud} />
        <div className={style.badge}>
          <svg
            width="284px"
            height="385px"
            viewBox="0 0 284 385"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <filter
                x="-40.9%"
                y="-28.4%"
                width="181.7%"
                height="156.8%"
                filterUnits="objectBoundingBox"
                id="filter-122"
              >
                <feOffset dx="0" dy="25" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  stdDeviation="12.5"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  values="0 0 0 0 0.0156862745   0 0 0 0 0.901960784   0 0 0 0 0.682352941  0 0 0 0.212986233 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                  result="shadowMatrixOuter1"
                />
                <feMerge>
                  <feMergeNode in="shadowMatrixOuter1" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <linearGradient
                x1="38.5127581%"
                y1="84.9243045%"
                x2="61.5939675%"
                y2="16.3295925%"
                id="linearGradient-222"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="28.9530152%"
                y1="84.9243045%"
                x2="71.2425282%"
                y2="16.3295925%"
                id="linearGradient-322"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="12.4534884%"
                y1="84.9243045%"
                x2="87.8953488%"
                y2="16.3295925%"
                id="linearGradient-422"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
            </defs>
            <g id="About" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group" transform="translate(24.000000, -1.000000)">
                <g
                  id="certhover-copy"
                  filter="url(#filter-122)"
                  transform="translate(0.600000, 0.788193)"
                >
                  <polyline
                    id="Fill-468"
                    fill="#FFFFFE"
                    points="126.414 65.448 117.342 46.701 108.324 65.4381 87.678 68.2515 102.726 82.6425 99.009 103.1202 117.342 93.285 135.675 103.1202 132.003 82.6578 146.997 68.2515 126.414 65.448"
                  />
                  <path
                    d="M148.761,230.2695 L155.232,310.203 L170.379,301.4721 L203.031,307.2051 L186.264,212.0877 C175.095,220.2408 162.432,226.4661 148.761,230.2695"
                    id="Fill-469"
                    fill="url(#linearGradient-222)"
                  />
                  <path
                    d="M48.411,212.0877 L31.644,307.2051 L64.296,301.4721 L79.443,310.203 L85.914,230.2695 C72.243,226.4661 59.58,220.2417 48.411,212.0877"
                    id="Fill-470"
                    fill="url(#linearGradient-222)"
                  />
                  <path
                    d="M117.342,234.5436 C106.452,234.5436 95.913,233.0514 85.914,230.2695 L77.238,337.3866 L117.342,323.2404 L157.437,337.3866 L148.761,230.2695 C138.762,233.0514 128.223,234.5436 117.342,234.5436"
                    id="Fill-471"
                    fill="url(#linearGradient-322)"
                  />
                  <path
                    d="M60.651,117.5427 C60.651,61.38 61.173,60.8508 117.342,60.8508 C173.502,60.8508 174.033,61.38 174.033,117.5427 C174.033,173.7063 173.502,174.2355 117.342,174.2355 C61.173,174.2355 60.651,173.7063 60.651,117.5427 Z M148.761,230.2695 C162.432,226.4661 175.095,220.2408 186.264,212.0877 C215.406,190.8072 234.342,156.3894 234.342,117.5427 C234.342,52.9245 181.953,0.5418 117.342,0.5418 C52.722,0.5418 0.342,52.9245 0.342,117.5427 C0.342,156.3894 19.278,190.8072 48.411,212.0877 C59.58,220.2417 72.243,226.4661 85.914,230.2695 C95.913,233.0514 106.452,234.5436 117.342,234.5436 C128.223,234.5436 138.762,233.0514 148.761,230.2695 Z"
                    id="Fill-472"
                    fill="#F6FBFA"
                  />
                  <path
                    d="M71.4639037,194.036091 C65.7535725,190.627357 60.46155,186.60501 55.665,182.0547 L55.665,182.0547 C51.633,178.2333 47.961,174.0465 44.694,169.5393 L44.694,169.5393 L44.0412194,168.625165 C41.4525621,164.951744 39.1340769,161.076462 37.107,157.0248 L37.107,157.0248 L36.6114479,156.018517 C34.8171875,152.316169 33.27375,148.475175 31.995,144.5094 L31.995,144.5094 L31.6462382,143.40099 C30.5075455,139.695925 29.6075455,135.887809 28.953,131.9949 L28.953,131.9949 L28.7733787,130.878721 C28.1977438,127.150483 27.8533636,123.346309 27.747,119.4804 L27.747,119.4804 L27.7226667,118.662133 C27.704,118.1168 27.684,117.5712 27.684,117.0216 C27.684,67.5072 67.824,27.3681 117.342,27.3681 C166.851,27.3681 206.991,67.5072 206.991,117.0216 C206.991,117.846 206.955,118.6614 206.928,119.4804 L206.928,119.4804 L206.891189,120.638306 C206.743983,124.491726 206.352,128.281991 205.722,131.9949 L205.722,131.9949 C205.002,136.2771 203.985,140.4567 202.68,144.5094 L202.68,144.5094 L202.326589,145.587871 C201.0055,149.530844 199.40775,153.347775 197.568,157.0248 L197.568,157.0248 C195.381,161.4141 192.834,165.5964 189.981,169.5393 L189.981,169.5393 L189.322216,170.436455 C186.22284,174.603116 182.7738,178.48806 179.019,182.0547 L179.019,182.0547 L178.257478,182.76877 C173.408175,187.263268 168.06015,191.225295 162.306,194.5692 L162.306,194.5692 L161.608194,194.970613 C148.545945,202.407186 133.443474,206.6751 117.342,206.6751 C100.944,206.6751 85.59,202.2534 72.369,194.5692 L72.369,194.5692 Z"
                    id="Combined-Shape"
                    fill="url(#linearGradient-422)"
                  />
                  <polyline
                    id="Path-105"
                    fill="#FFFFFF"
                    points="129.449561 60.5168069 129.280135 60.657614 93.8995117 90.0619045 66.825 135.669326 89.2479004 133.563614 129.280135 60.657614"
                  />
                  <polygon
                    id="Path-106"
                    fill="#FFFFFF"
                    points="133.187109 73.0562893 173.475 143.078262 96.6216797 142.711563 144.994482 136.890762 117.3375 104.531631"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className={style.right}>
        <header className={style.header}>
          <h2>Certified Azure Experts</h2>
          <p>
            We’ve been there, done that with Azure and all things Microsoft. We can help you avoid
            common pitfalls that cost you time and money. Not only does our staff understand Azure
            Architecture, but the majority have an extensive background in C#, .NET and .NET Core.
            Our team of certified Azure experts can help guide you through any part of your journey
            in the cloud.
          </p>
          <Button 
            label="Talk to an Expert" 
            onClick={() => window.location.href = buildFormUrl([
              {
                key: 'CTA',
                value: `Azure - Talk to an Expert`
              }
            ])}
          />
        </header>
        {showModal ? (
          <ContactModal
            showClose
            shouldCloseOnOverlayClick
            onClose={() => setShowModal(false)}
            onDismiss={() => setShowModal(false)}
            isOpen={showModal}
            closeButtonPosition="right"
            ariaHideApp={false}
            success={isSuccessful}
          >
            <ContactForm
              showLogo
              headerTitle="Contact Us"
              buttonLabel="Send"
              ctaIntentSuffix="modal-azure-expert"
              inquiryPlaceholder="I'd like to talk to an expert about Azure!"
              isSuccessfulCallback={(v) => {
                setIsSuccessful(v)
                if (!v) {
                  setShowModal(false)
                }
              }}
            />
          </ContactModal>
        ) : null}
      </div>
    </section>
  )
}

export default War
