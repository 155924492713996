import { string } from 'prop-types'

import style from './price.module.css'

const Price = ({ price }) => (
  <div className={style.wrapper}>
    <span className={style.price}>{price}</span>
    <span>
      Software Only <br /> MSRP
    </span>
  </div>
)

Price.propTypes = {
  price: string.isRequired,
}

export default Price
