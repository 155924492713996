import { isStaging, isProd } from 'utils/env'
import {
  Hero,
  PartnerBenefits,
  Certifications,
  Quote,
  GSuite,
  Services,
} from 'components/Page/Google/GCP'
import Layout from 'components/Layout'

const testFormId = '99faa487-a1fe-4185-8a80-c04500f95b39'
const formId = isProd()
  ? !isStaging()
    ? '9c90d69f-a796-4d5e-9026-e795fd170091'
    : testFormId
  : testFormId

const GooglePage = () => (
  <Layout
    title="GCP Consulting Services in Utah"
    description="Observian offers advanced GCP consulting services in Utah. We do all things cloud, including DevOps, Big Data, Security, Migration, and more. Learn more!"
  >
    <Hero />
    <PartnerBenefits />
    <Certifications formId={formId} />
    <Quote />
    <Services />
    <GSuite formId={formId} />
  </Layout>
)

export default GooglePage
