import Layout from 'components/Layout'
import Hirogen from 'templates/SEO/Hirogen'
import BasicSEOPage from 'templates/SEO/Basic'

const ToolsBasicsSEOPage = () => (
  <>
    <Layout
      title="Observian Tools for Cloud Applications"
      description="Secret AWSome app provides an easy and secure integration to help manage your AWS Systems Manager Parameter Store on macOS, Linux, and Windows devices."
      keywords="Cognito, security, vulnerability, aws, OSS, open source, open-source, secret, secret awsome, secret awesome, systems manager parameter store, macOS, Linux, Windows, OS, aws systems manager parameter store"
    >
      <Hirogen
        description="AWS Cognito pentesting tool in an easy-to-use UI! It supports unauthenticated identities, direct registration and login with Cognito User Pools, as well as identities authenticated through Google Federated Auth and Login with Google and Amazon. Once you punch in the basic details, it'll even gather STS credentials for you, do a spot-check on permissions, and expose the AWS command line for each identity right in the UI."
        name="Hirogen"
      />
      <BasicSEOPage
        description="The Secret AWSome app provides an easy and secure integration to help manage your AWS
              Systems Manager Parameter Store on macOS, Linux, &amp; Windows devices. The Secret
              AWSome app allows you to easily add or clone parameters to multiple regions with a few
              clicks. It reads/writes to your local AWS credentials file for easy integration into
              AWS."
      />
    </Layout>
  </>
)

export default ToolsBasicsSEOPage
