import { useState } from 'react'
import ContactModal from 'observian-ui/lib/modal/contact'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import monitors from '../../../../static/images/datadog/monitors.png'

import style from './features.module.css'

const Features = () => {
  const [modal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <section className={style.features}>
      <div className={style.triangleContainer} />

      <div className={style.featuresContainer}>
        <div className={style.leftContainer}>
          <div className={style.headerTextContainer}>
            <h2 className={style.headerTextMain}>Datadog Optimization</h2>
            <p className={style.headerTextSub}>For new &amp; existing Datadog customers.</p>
          </div>
          <TextUnderline className={style.textUnderline} />

          <div className={style.mainTextContainer}>
            <h3>Cost Optimization</h3>
            <p>
              Stop paying high overage fees, with Observian we will match your rate even on any
              overages for the month.
            </p>
            <h3>Tool Consolidation</h3>
            <p>
              Everyone knows tool sprawl can be a real pain. With Datadog you can take care of
              everything from Infastructure, Security, to user experience.
            </p>
            <h3>Managed Billing</h3>
            <p>
              Observian will help build a plan that suites your needs and build automatic
              notifications so you are always in the loop and keeping costs in line.
            </p>
            <h3>Rapid Onboarding</h3>
            <p>
              Observian has baseline tooling to get you up and running fast and will be the front
              line support for your teams.
            </p>
            <Button
              className={style.button}
              label="Schedule a Conversation"
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: 'Datadog - Schedule a Conversation'
                }
              ])}
            />
          </div>
        </div>
        <div className={style.rightContainer}>
          <img src={monitors} alt="datadog-monitors" className={style.monitors} />
        </div>
      </div>
      {modal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={modal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            isLandingPage
            ctaIntentSuffix="modal-datadog-features-contact-top-cta"
            inquiryPlaceholder="I'd like to see how Observian and Datadog can help my business."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default Features
