import * as React from 'react'

function CalendarProduct(props) {
  return (
    <svg width={62} height={63} viewBox="0 0 62 63" xmlns="http://www.w3.org/2000/svg">
      <title>{'BC130172-9C42-4110-B438-7C4BD0146D39'}</title>
      <g fillRule="nonzero" fill="none" opacity={0.153}>
        <path fill="#FFF" d="M47.408 15.305H14.98v32.42h32.427z" />
        <path
          d="M21.633 40.454c-1.212-.818-2.051-2.014-2.51-3.594l2.814-1.16c.255.973.7 1.727 1.337 2.262.632.535 1.403.798 2.302.798.92 0 1.711-.28 2.372-.839.66-.559.993-1.272.993-2.135 0-.884-.349-1.605-1.046-2.164-.697-.56-1.573-.84-2.618-.84H23.65V30h1.46c.9 0 1.657-.243 2.273-.73.616-.486.924-1.15.924-1.997 0-.754-.275-1.354-.826-1.804-.552-.45-1.249-.676-2.096-.676-.827 0-1.484.218-1.97.66a3.878 3.878 0 00-1.062 1.625l-2.785-1.159c.37-1.045 1.046-1.97 2.04-2.768.992-.798 2.261-1.2 3.801-1.2 1.14 0 2.165.22 3.073.661.908.442 1.621 1.054 2.136 1.832.515.782.77 1.657.77 2.63 0 .993-.24 1.832-.717 2.52a4.944 4.944 0 01-1.764 1.585v.166c.92.385 1.67.973 2.262 1.763.588.79.884 1.735.884 2.837s-.28 2.087-.84 2.95c-.559.863-1.333 1.544-2.314 2.038-.985.495-2.091.746-3.32.746-1.422.004-2.736-.405-3.947-1.224zM38.912 26.498l-3.089 2.232-1.544-2.342 5.541-3.996h2.124V41.24h-3.032z"
          fill="#4285F4"
        />
        <path fill="#34A853" d="M47.408 47.724H14.98v14.589h32.427z" />
        <path
          d="M47.408.716H5.253A4.862 4.862 0 00.389 5.579v42.145H14.98v-32.42h32.427V.717z"
          fill="#4285F4"
        />
        <path
          d="M.389 47.724v9.726a4.862 4.862 0 004.864 4.863h9.728V47.724H.389z"
          fill="#188038"
        />
        <path fill="#FBBC04" d="M62 15.305H47.408v32.42H62z" />
        <path d="M62 15.305V5.579A4.862 4.862 0 0057.136.716h-9.728v14.589H62z" fill="#1967D2" />
        <path fill="#EA4335" d="M47.408 62.313L62 47.724H47.408z" />
      </g>
    </svg>
  )
}

export default CalendarProduct
