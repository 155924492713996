import { Component } from 'react'
import Divider from 'observian-ui/lib/divider'
import { gsap, Elastic } from 'gsap'
import Button from 'observian-ui/lib/button'
import Modal from 'observian-ui/lib/modal'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect';

import TechLines from 'components/SVG/techLines'
import AzureMainCloud from 'components/SVG/clouds/azureMain'
import ObservianLogoGateMSFT from 'components/SVG/observianLogoGateMSFT'
import ContactForm from 'components/Form/Contact'

import style from './hero.module.css'

class Hero extends Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  componentDidMount() {
    gsap.from(`.${style.mainCloud}`, 4, {
      y: '-=50',
      x: '+=5',
      rotation: '+=2',
      opacity: 0,
      ease: Elastic.easeInOut.config(1, 0.9),
    })
    gsap.from(`.${style.laser1}`, 4, {
      y: '+=50',
      x: '-=5',
      rotation: '-=5',
      transformOrigin: '50% 50%',
      opacity: 0,
      ease: Elastic.easeInOut.config(1, 0.9),
    })
    gsap.from(`.${style.laser2}`, 4, {
      y: '+=50',
      x: '-=5',
      rotation: '-=10',
      transformOrigin: '50% 50%',
      opacity: 0,
      ease: Elastic.easeInOut.config(1, 0.9),
    })
    gsap.from(`.${style.laser3}`, 4, {
      y: '+=50',
      x: '-=5',
      rotation: '-=15',
      transformOrigin: '50% 50%',
      opacity: 0,
      ease: Elastic.easeInOut.config(1, 0.9),
    })

    const tl2 = gsap.timeline({ paused: false, repeat: 4, yoyo: true })
    tl2.to(`.${style.firstSpin}`, 16, {
      rotation: 360,
      transformOrigin: '50% 50%',
      ease: Elastic.easeInOut.config(1, 0.4),
    })
    tl2.to(
      `.${style.secondSpin}`,
      16,
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        ease: Elastic.easeInOut.config(1, 0.4),
      },
      '-=16'
    )
  }

  handleOpenModal = () => {
    this.setState({
      showModal: true,
    })
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    })
  }

  handleSetSuccessful = (v) => {
    this.setState({
      isSuccessful: v,
    })
  }

  render() {
    const { showModal, isSuccessful } = this.state
    return (
      <section className={style.hero}>
        <ObservianLogoGateMSFT
          className={style.observianLogoGate}
          firstSpin={style.firstSpin}
          secondSpin={style.secondSpin}
        />
        <div className={style.content}>
          <header>
            <h1 className={style.title}>Azure Consulting Services</h1>
          </header>
          <p className={style.description}>
            Observian has partnered with Microsoft to bring digital transformation to your business.{' '}
            <br />
            <br />
            <Button
              role="button"
              label="Schedule a Discovery Call"
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Azure - Schedule a Discovery Call`
                }
              ])}
            />
          </p>
        </div>
        <TechLines className={style.techLinesRight} />
        {showModal ? (
          <Modal isTransparent isOpen={showModal} onClose={(e) => this.handleCloseModal(e)}>
            <ContactModal
              showClose
              shouldCloseOnOverlayClick
              onClose={(e) => this.handleCloseModal(e)}
              onDismiss={(e) => this.handleCloseModal(e)}
              isOpen={showModal}
              closeButtonPosition="right"
              ariaHideApp={false}
              success={isSuccessful}
            >
              <ContactForm
                showLogo
                headerTitle="Contact Us"
                buttonLabel="Send"
                ctaIntentSuffix="modal-aws-partners-benefits"
                inquiryPlaceholder="I'd like to schedule a discovery call with Observian."
                isSuccessfulCallback={(v) => {
                  this.handleSetSuccessful(v)
                  if (!v) {
                    this.handleCloseModal(false)
                  }
                }}
              />
            </ContactModal>
          </Modal>
        ) : null}
        <Divider>
          <AzureMainCloud
            className={style.mainCloud}
            laser1={style.laser1}
            laser2={style.laser2}
            laser3={style.laser3}
          />
        </Divider>
      </section>
    )
  }
}

export default Hero
