import CaseStudy from 'observian-ui/lib/caseStudy'

import icon from '../../../../static/images/FileIcon.svg'

import style from './CaseStudies.module.css'

const caseStudies = [
  {
    logo: icon,
    title: 'AWS MSSP Level 1',
    description: 'Product Intelligence Platform Securely Scales on Observian Managed Security.',
    url: 'https://observian-casestudies.s3.amazonaws.com/ANON-Observian-MSSP-CaseStudy21.pdf',
  },
  {
    logo: icon,
    title: 'AWS Managed Security Service',
    description:
      'Booming San Francisco SaaS Startup Achieves SOC2 Compliance with Observian Managed Services',
    url: 'https://observian-casestudies.s3.amazonaws.com/Anonymized-SF-SaaS-Startup-MSSP-CaseStudy.pdf',
  },
  {
    logo: icon,
    title: 'AWS Migration',
    description: 'Prestige Saves Over 1 Million Annually with the help of Observian and AWS',
    url: 'https://observian-casestudies.s3.amazonaws.com/PrestigeAurora.pdf',
  },
]

const CaseStudies = () => (
  <section className={style.wrapper}>
    <div className={style.caseStudySection}>
      <div className={style.caseStudy}>
        <div className={style.caseStudyContent}>
          <h2>Case Studies For AWS Managed Security</h2>
          <div className={style.caseStudies}>
            {caseStudies.map(({ logo, description: caseStudyDescription, url, title }, index) => (
              <CaseStudy
                downloadText="Download"
                key={`case:study:${index + 1}`}
                title={title}
                description={caseStudyDescription}
                url={url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CaseStudies
