import * as React from 'react'

function SheetsProduct(props) {
  return (
    <svg width={48} height={64} viewBox="0 0 48 64" xmlns="http://www.w3.org/2000/svg">
      <title>{'2BAF3A4C-FFEE-4BE1-BFF5-1876FA8ECCB6'}</title>
      <g opacity={0.153}>
        <g opacity={0.9} fillRule="nonzero" fill="none">
          <path
            d="M34.851 24.538H9.63V43.99h28.824V24.538h-3.603zm0 15.85H13.233V28.14h21.618v12.248z"
            fill="#FFF"
          />
          <path
            d="M42.778 63.442H5.307a4.322 4.322 0 01-4.324-4.323V4.366A4.322 4.322 0 015.307.043h25.941l15.853 15.85v43.226a4.322 4.322 0 01-4.323 4.323z"
            fill="#00AC47"
          />
          <path fill="#00832D" d="M31.248.043l15.853 15.85H31.248z" />
          <path
            d="M34.851 24.538H9.63v20.893H38.454V24.538h-3.603zM13.233 28.14h9.008v5.043h-9.008V28.14zm0 13.688v-5.043h9.008v5.043h-9.008zm21.618 0h-9.007v-5.043h9.007v5.043zm0-8.645h-9.007V28.14h9.007v5.043z"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}

export default SheetsProduct
