import { gql } from '@apollo/client'

export const GET_BLOG_POSTS = gql`
  query BLOG_POSTS($offset: Int, $limit: Int, $search: String, $topicIdFilter: [String]) {
    allBlogPosts: blogPosts(
      offset: $offset
      limit: $limit
      search: $search
      state: PUBLISHED
      topicIdFilter: $topicIdFilter
    ) {
      limit
      offset
      total
      pages
      posts {
        postId
        slug
        postBody
        name
        topicIds
        metaDescription
        htmlTitle
        featuredImage
        publishDate
        blogAuthor {
          authorId
          avatar
          fullName
          email
        }
      }
    }
    allAuthors: blogPostAuthors {
      authors {
        authorId
        avatar
        email
        fullName
        slug
        displayName
      }
    }
    allTopics: blogPostTopics {
      topics {
        topicId
        name
        slug
      }
    }
  }
`

export default GET_BLOG_POSTS
