import { Component } from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'

import style from './offer.module.css'

class O365Migration extends Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  handleIsSuccessful = (value) => {
    this.setState({ isSuccessful: value })
  }

  handleModal = (key) => {
    this.setState((state) => ({
      [key]: !state[key],
    }))
  }

  render() {
    const { showModal, isSuccessful } = this.state

    return (
      <section>
        <svg
          viewBox="0 0 1488 173"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className={style.divider}
        >
          <g id="Office365" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
              id="Path-6-Copy-2"
              fill="#FFFFFF"
              points="0 19.1382746 307.002426 0 611.429143 30.0600392 1057.60562 10.1594306 1358.49007 31.7024719 1488 19.1382746 1488 173 0 173"
            />
          </g>
        </svg>
        <div className={style.container}>
          <div className={style.offer}>
            <h2>What Does Observian Provide as a Microsoft Partner?</h2>
            <p>
              As a Microsoft partner, we provide top tier support for Office 365 and Azure products
              to make sure you’re successful with these solutions. With our Office 365 migration
              solution, migrating is simple, and Observian is here to help with every step along the
              way. Our goal is to make your use of Microsoft products as easy and seamless as
              possible.
            </p>
            <br />
            <br />
            <h2>Start your Office 365 Migration</h2>
            <p>
              Get the most from Office 365 and Observian all in one. We’re happy to help you get
              started.
            </p>
            <br />
            <br />
            <Button
              className={style.button}
              label="Learn More"
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Office365 - Learn More`
                }
              ])}
            />
          </div>

          {showModal ? (
            <ContactModal
              showClose
              shouldCloseOnOverlayClick
              onClose={() => this.handleModal('showModal')}
              onDismiss={() => this.handleModal('showModal')}
              isOpen={showModal}
              closeButtonPosition="right"
              ariaHideApp={false}
              success={isSuccessful}
            >
              <ContactForm
                showLogo
                showContactInfo
                headerTitle="Contact Us"
                buttonLabel="Send"
                ctaIntentSuffix="modal-office365-migration"
                inquiryPlaceholder="I'd love to learn more about this Office365 free migration offer."
                isSuccessfulCallback={(v) => {
                  this.handleIsSuccessful(v)
                  if (!v) {
                    this.handleModal('showModal')
                  }
                }}
              />
            </ContactModal>
          ) : null}
        </div>
      </section>
    )
  }
}

export default O365Migration
