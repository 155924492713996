import { Component } from 'react'
import { TimelineMax, Power0 } from 'gsap'

import Layout from 'components/Layout'
import ServicesPageTemplate from 'templates/Page/Services'
import DevOps from 'components/SVG/clouds/devOps'

class TrainingWorkshopsPage extends Component {
  componentDidMount() {
    const securityCompliance = new TimelineMax()
    securityCompliance.to('.key, .lock', 2, {
      x: '+=5',
      y: '-=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
    })

    securityCompliance.to(
      '.safe',
      5,
      { x: '-=10', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone },
      0
    )
  }

  render() {
    return (
      <Layout title="Training & Workshops" description="">
        <ServicesPageTemplate
          name="Training & Workshops"
          title="Training & Workshops"
          content="
          <h2>Expert Training and Workshops from Observian</h2>
          <p>Let Observian level up your staff in the latest in best practices and cutting edge cloud tech. It doesn't matter if you're on prem or a multi cloud company, Observian can tailor a training program that suits your team.</p>
          <ul>
          <li>Docker</li>
          <li>Container Orchestration</li>
          <li>Best Practices in Cloud</li>
          <li>Serverless</li>
          <li>Security & Compliance</li>
          <li>Core AWS</li>
          <li>Core GCP</li>
          <li>Cloud Tooling</li>
          </ul>
          <p>Our workshops are full 8 hour sessions that can be done at your office or at our locations.</p>"
          headerIcon={<DevOps />}
          inquiryPlaceholder="Hi! We could use some help with training and workshops for our workforce..."
        />
      </Layout>
    )
  }
}

export default TrainingWorkshopsPage
