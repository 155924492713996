import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import clsx from 'clsx'
import Contact from 'observian-ui/lib/svg/icons/contact'
import { Form as FinalForm, Field } from 'react-final-form'
import omit from 'lodash/omit'

import { mutateQL } from 'api/graphql'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isEmail, isRequired } from 'utils/validation'

import submitGsuiteFormMutation from './mutateQuery'
import style from './gsuite.module.css'

const GSuiteForm = ({ className, isSuccessfulMessage, ctaIntent, ctaIntentSuffix, formId }) => {
  const [isSuccessful, setIsSuccessful] = useState(false)

  const handleOnSubmit = async (event) => {
    const [firstName, lastName = ''] = event.fullName.split(' ')
    const currentCtaIntent =
      0 === ctaIntentSuffix.length ? event.ctaIntent : `${event.ctaIntent}-${ctaIntentSuffix}`

    const validEvent = omit(event, 'fullName')
    const allFormData = {
      ...validEvent,
      firstName,
      lastName,
      ctaIntent: currentCtaIntent,
      formId,
      type: 'gsuite',
    }

    try {
      const res = await mutateQL({
        query: submitGsuiteFormMutation,
        inputKey: 'form',
        inputData: allFormData,
      })

      if (200 === res.status) {
        return setIsSuccessful(true)
      }

      return console.error(res.data.message || 'Unknown Error.')
    } catch (error) {
      return console.error(error)
    }
  }

  return (
    <HubspotTracking>
      {({ context }) =>
        !isSuccessful ? (
          <FinalForm
            validateOnBlur
            onSubmit={(e) => handleOnSubmit({ ...e, context })}
            initialValues={{ ctaIntent }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <div className={clsx(className, style.container)}>
                <form onSubmit={handleSubmit} className={style.form}>
                  <Field name="fullName" placeholder="Name" validate={isRequired}>
                    {({ input, meta, placeholder }) => (
                      <div className={style.inputContainer}>
                        <Contact type="person" className={style.inputIcon} />
                        <input {...input} id={input.name} placeholder={placeholder} />
                        {meta.error && meta.touched && (
                          <span data-testid="form-fullName-error" className={style.validationError}>
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="email" validate={isEmail} placeholder="Email">
                    {({ input, meta, placeholder }) => (
                      <div className={style.inputContainer}>
                        <Contact type="email-alt" className={style.inputIcon} />
                        <input {...input} id={input.name} type="email" placeholder={placeholder} />
                        {meta.error && meta.touched && (
                          <span data-testid="form-email-error" className={style.validationError}>
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="companyDomain" placeholder="Company Domain" validate={isRequired}>
                    {({ input, placeholder }) => (
                      <div className={style.inputContainer}>
                        <Contact type="computer" className={style.inputIcon} />
                        <input {...input} id={input.name} placeholder={placeholder} />
                      </div>
                    )}
                  </Field>
                  <Field name="ctaIntent" type="hidden">
                    {({ input }) => <input {...input} type="hidden" />}
                  </Field>
                  <Button
                    className={style.button}
                    variant="primary"
                    label="Submit"
                    disabled={pristine || submitting}
                  />
                </form>
              </div>
            )}
          </FinalForm>
        ) : (
          <div className={style.success} data-testid="form-success-message">
            <span>{isSuccessfulMessage}</span>
            <Button
              variant="cancel"
              label="Done"
              onClick={() => setIsSuccessful(false)}
              className={style.successButtonCTA}
            />
          </div>
        )
      }
    </HubspotTracking>
  )
}

GSuiteForm.defaultProps = {
  className: '',
  isSuccessfulMessage: "Your inquiry has been sent, we'll be in touch soon!",
  ctaIntent: 'gsuite-essentials-form',
  ctaIntentSuffix: '',
}

GSuiteForm.propTypes = {
  formId: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
}

export default GSuiteForm
