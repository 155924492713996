import PropTypes from 'prop-types'

const ParticlesComp = ({ className, particles, type }) => {
  switch (type) {
    case 'small':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="555"
          height="134"
          viewBox="0 0 555 134"
        >
          <defs>
            <linearGradient id="small_particles-a" x1="0%" x2="102%" y1="0%" y2="101%">
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
            </linearGradient>
          </defs>
          <g
            fill="url(#small_particles-a)"
            fillRule="evenodd"
            opacity="0.255"
            transform="translate(0 2)"
          >
            <polygon
              className={particles.particle}
              stroke="url(#small_particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="116.064 2.41 125.027 16.54 107.102 16.54"
              transform="rotate(-13 116.064 9.475)"
            />
            <polygon
              className={particles.particle1}
              stroke="url(#small_particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="543.544 109.379 554.618 126.84 532.469 126.84"
              transform="rotate(-95 543.544 118.11)"
            />
            <polygon
              className={particles.particle2}
              stroke="url(#small_particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="117.27 110.275 120.931 116.301 113.608 116.301"
              transform="rotate(-40 117.27 113.288)"
            />
            <polygon
              className={particles.particle3}
              stroke="url(#small_particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="405.619 2.472 410.961 11.265 400.276 11.265"
              transform="rotate(-26 405.619 6.868)"
            />
            <circle className={particles.particle4} cx="3.463" cy="53.509" r="2.69" />
            <circle
              className={particles.particle5}
              cx="482.663"
              cy="92.31"
              r="2.69"
              transform="rotate(-40 527.662 92.31)"
            />
          </g>
        </svg>
      )

    default:
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="595"
          height="332"
          viewBox="0 0 595 332"
        >
          <defs>
            <linearGradient id="particles-a" x1="0%" x2="102%" y1="0%" y2="101%">
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
            </linearGradient>
          </defs>
          <g fill="url(#particles-a)" fillRule="evenodd" opacity="0.255">
            <polygon
              className={particles.particle}
              stroke="url(#particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="116.064 63.41 125.027 77.54 107.102 77.54"
              transform="rotate(-13 116.064 70.475)"
            />
            <polygon
              className={particles.particle1}
              stroke="url(#particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="222.305 271.034 229.23 282.431 215.381 282.431"
              transform="rotate(20 222.305 276.733)"
            />
            <polygon
              className={particles.particle2}
              stroke="url(#particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="583.544 170.379 594.618 187.84 572.469 187.84"
              transform="rotate(-95 583.544 179.11)"
            />
            <polygon
              className={particles.particle3}
              stroke="url(#particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="7.27 321.275 10.931 327.301 3.608 327.301"
              transform="rotate(-58 7.27 324.288)"
            />
            <polygon
              className={particles.particle4}
              stroke="url(#particles-a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              points="405.619 63.472 410.961 72.265 400.276 72.265"
              transform="rotate(-26 405.619 67.868)"
            />
            <circle className={particles.particle5} cx="3.463" cy="114.509" r="2.69" />
            <circle className={particles.particle6} cx="417.663" cy="303.31" r="2.69" />
            <circle className={particles.particle7} cx="207.662" cy="3.31" r="2.69" />
          </g>
        </svg>
      )
  }
}

ParticlesComp.defaultProps = {
  className: '',
  type: '',
  particles: {
    particle: 'particle',
    particle1: 'particle1',
    particle2: 'particle2',
    particle3: 'particle3',
    particle4: 'particle4',
    particle5: 'particle5',
    particle6: 'particle6',
    particle7: 'particle7',
  },
}

ParticlesComp.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  particles: PropTypes.shape({
    particle: PropTypes.string,
    particle1: PropTypes.string,
    particle2: PropTypes.string,
    particle3: PropTypes.string,
    particle4: PropTypes.string,
    particle5: PropTypes.string,
    particle6: PropTypes.string,
    particle7: PropTypes.string,
  }),
}

export default ParticlesComp
