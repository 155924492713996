const SvgNaturalDisaster = (props) => (
  <svg width="50px" height="76px" viewBox="0 0 50 76" {...props}>
    <defs>
      <path
        id="natural-disaster-a"
        d="M0.000267800346 0.4586L49.3619416 0.4586 49.3619416 75 0.000267800346 75z"
      />
      <linearGradient
        x1="32.0082427%"
        y1="90.5184659%"
        x2="83.4798672%"
        y2="21.4133523%"
        id="natural-disaster-c"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-1025 -2747) translate(106 2702) translate(208 44) translate(711 1) translate(0 .542)">
        <mask id="natural-disaster-b" fill="#fff">
          <use xlinkHref="#natural-disaster-a" />
        </mask>
        <path
          d="M48.839 41.954a10.8 10.8 0 00-5.033-6.143c-13.95-9.034-10.293-20.894-10.293-20.894-2.97 3.61-5.08 7.018-6.527 10.198C22.982 10.494 29.993.459 29.993.459-15.687 29.836 4.396 64.345 4.4 64.352 7.648 70.674 14.227 75 21.82 75c10.713 0 19.412-8.608 19.57-19.283.15-.041.3-.078.448-.126 5.697-1.833 8.833-7.94 7-13.638"
          fill="#FEC02A"
          mask="url(#natural-disaster-b)"
        />
      </g>
      <path
        d="M43.805 42.294a6.895 6.895 0 00-4.025-3.083c-9.99-3.58-9.449-11.485-9.449-11.485-2.864 5.816-3.345 10.438-2.732 14.015-12.426-12.97-6.8-25.756-6.8-25.756-28.645 27.613-9.302 49.553-9.298 49.558 3.233 4.08 8.577 6.253 14.034 5.172 7.771-1.539 12.822-9.085 11.283-16.856a14.934 14.934 0 00-.298-1.214 6.878 6.878 0 004.924-.853 6.92 6.92 0 002.361-9.498"
        fill="url(#natural-disaster-c)"
        transform="translate(-1025 -2747) translate(106 2702) translate(208 44) translate(711 1)"
      />
      <path
        d="M15.428 36.057s-1.248 9.33 10.318 14.338a8.195 8.195 0 014.536 3.963 8.223 8.223 0 01-3.527 11.078 8.22 8.22 0 01-8.55-.61c-.005-.004-14.15-9.002-2.777-28.769"
        fill="#F62D53"
        transform="translate(-1025 -2747) translate(106 2702) translate(208 44) translate(711 1)"
      />
    </g>
  </svg>
)

export default SvgNaturalDisaster
