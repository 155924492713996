const SvgEmailPhising = (props) => (
  <svg width="154px" height="169px" viewBox="0 0 154 169" {...props}>
    <defs>
      <linearGradient
        x1="14.8581364%"
        y1="85.1559232%"
        x2="85.3734672%"
        y2="14.5512004%"
        id="email-phishing-a"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="49.8612038%"
        y1="30.46875%"
        x2="51.5607302%"
        y2="86.6260593%"
        id="email-phishing-b"
      >
        <stop stopColor="#5B4559" offset="0%" />
        <stop stopColor="#3E2F3C" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="-66.299746%"
        y1="-64.3147464%"
        x2="193.64945%"
        y2="191.337942%"
        id="email-phishing-c"
      >
        <stop stopColor="#F5C268" offset="0%" />
        <stop stopColor="#D89144" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="62.9366325%"
        y1="32.1442125%"
        x2="31.4316474%"
        y2="77.3244782%"
        id="email-phishing-d"
      >
        <stop stopColor="#5B4559" offset="0%" />
        <stop stopColor="#3E2F3C" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-634 -3003) translate(106 2702) translate(0 272) translate(528 29)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#F2EED1"
        fillRule="nonzero"
        transform="rotate(-.07 102.471 112.622)"
        d="M51.2350239 78.2872268H153.7075239V146.9572268H51.2350239z"
      />
      <path fill="#E1DEB4" d="M51.2775 147.0225L102.4725 112.6275 153.7425 146.9025z" />
      <path fill="#E1DEB4" d="M51.195 78.3525L102.4725 112.6275 153.6675 78.2325z" />
      <path
        fill="#8C8970"
        opacity={0.21}
        d="M116.895 99.8475L84.765 78.315 51.195 78.3525 51.2775 147.0225 116.895 146.9475z"
      />
      <path
        fill="#F2EED1"
        fillRule="nonzero"
        d="M-7.10542736e-14 99.8475H102.47249999999993V168.51749999999998H-7.10542736e-14z"
      />
      <path fill="#E1DEB4" d="M-7.10542736e-14 168.5175L51.2325 134.1825 102.4725 168.5175z" />
      <path fill="#FFF" d="M-7.10542736e-14 99.8475L51.2325 134.1825 102.4725 99.8475z" />
      <path fill="#F2EED1" d="M102.4725 99.8475L51.2325 65.5125 -7.10542736e-14 99.8475z" />
      <circle
        fill="url(#email-phishing-a)"
        fillRule="nonzero"
        cx={101.085}
        cy={85.7775}
        r={28.8825}
      />
      <path
        d="M107.505 74.918a14.955 14.955 0 00-6.487.69 12.24 12.24 0 00-8.085 15.75c1.35 4.184 4.544 5.857 7.86 4.784a7.89 7.89 0 005.31-6.06h.097c1.132 2.626 3.263 3.286 5.662 2.513 5.513-1.845 8.175-7.845 5.76-15.345-2.775-8.573-11.197-12.66-20.182-9.75-11.528 3.75-16.155 15.21-12.75 25.62 3.24 9.998 12.653 13.657 21.127 10.92a22.043 22.043 0 008.738-4.86 1.897 1.897 0 00-.113-2.085 1.433 1.433 0 00-1.762-.578 17.415 17.415 0 01-6.87 4.028c-7.605 2.46-14.633-.623-17.392-9.142-3.15-9.75 1.702-18.076 9.51-20.603 7.807-2.528 13.822 1.2 15.967 7.853 1.905 5.864.06 9.562-2.152 10.222-1.448.412-2.25-.847-2.715-4.028l-1.523-9.93zm-3.495 9.75c.502 3.112-.75 6.15-2.603 6.75-1.68.547-2.947-.398-3.577-2.34-1.267-3.93.308-8.1 3.675-9.196a6.855 6.855 0 011.65-.307l.855 5.093z"
        fill="#F2EED1"
        fillRule="nonzero"
      />
      <path
        d="M40.432 39.645a24.9 24.9 0 00-12.75 41.64l4.193-2.813a19.965 19.965 0 0113.642-34.447v-19.38h-5.085v15z"
        fill="url(#email-phishing-b)"
        fillRule="nonzero"
      />
      <path
        d="M44.25 24.645h-2.475v-4.95h2.475v4.95zm0-9.907h-2.475v-4.95h2.475v4.95zm0-9.9h-2.475V0h2.475v4.838z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        fill="url(#email-phishing-c)"
        fillRule="nonzero"
        d="M38.55 24.645H47.4075V32.3475H38.55z"
      />
      <path
        fill="#3E2F3C"
        fillRule="nonzero"
        d="M40.4325 32.3475H45.525V34.934999999999995H40.4325z"
      />
      <path
        d="M74.4 61.297l-9.593-11.91-2.01 14.7 2.813-.675c.346 10.96-8.227 20.14-19.185 20.543l-.36 4.957A24.893 24.893 0 0070.5 62.25l3.9-.953z"
        fill="url(#email-phishing-d)"
        fillRule="nonzero"
      />
      <path
        fill="#C6C29B"
        fillRule="nonzero"
        d="M46.425 83.955L46.065 88.9125 45.525 91.4625 44.82 89.4225 46.065 80.31z"
      />
    </g>
  </svg>
)

export default SvgEmailPhising
