import PropTypes from 'prop-types'

const Beam = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    viewBox="0 0 168 297"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="168px"
    height="297px"
  >
    <defs>
      <linearGradient
        id="linearGradientFooter"
        x1="12.4534884%"
        x2="87.8953488%"
        y1="84.9243045%"
        y2="16.3295925%"
      >
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        filterUnits="objectBoundingBox"
        height="123.1%"
        id="filter-2Footer"
        width="156.3%"
        x="-28.1%"
        y="-11.5%"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        filterUnits="objectBoundingBox"
        height="123.1%"
        id="filter-3Footer"
        width="156.3%"
        x="-28.1%"
        y="-11.5%"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
    </defs>
    <g id="Landing" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-70.000000, -5124.000000)" fill="url(#linearGradientFooter)">
        <g id="footer_links_beam" transform="translate(-94.000000, 4906.000000)">
          <g id="footer_links_beam_group" transform="translate(126.000000, 234.000000)">
            <polygon
              points="131.355469 269.5 56.3828125 172.191406 123.097656 17.4726562 163.042969 9.75 127.285156 23.3203125 72.6601562 167.03125"
              transform="translate(109.712891, 139.625000) rotate(-7.000000) translate(-109.712891, -139.625000) "
              filter="url(#filter-2Footer)"
              opacity="0.8"
            />
            <polygon
              points="131.355469 269.5 56.3828125 172.191406 123.097656 17.4726562 163.042969 9.75 127.285156 23.3203125 72.6601562 167.03125"
              transform="translate(109.712891, 139.625000) rotate(-29.000000) translate(-109.712891, -139.625000) "
              filter="url(#filter-3Footer)"
              opacity="0.210541214"
            />
            <polygon points="141.355469 269.5 66.3828125 172.191406 133.097656 17.4726562 173.042969 9.75 137.285156 23.3203125 82.6601562 167.03125" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Beam.defaultProps = {
  className: '',
}

Beam.propTypes = {
  className: PropTypes.string,
}

export default Beam
