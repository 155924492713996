const TechLines2Svg = (props) => (
  <svg width="344px" height="522px" viewBox="0 0 344 522" {...props}>
    <g
      transform="translate(172 -1194) translate(-172 1194)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g opacity={0.0692522321} transform="translate(.507 .888)" stroke="#979797">
        <path d="M0.01953125 519.828125L189.449219 304.679687 189.449219 53.5537571 122.5 0.73828125" />
        <path d="M20.0195312 519.828125L209.449219 304.679687 209.449219 53.5537571 142.5 0.73828125" />
        <path d="M40.0195312 519.828125L229.449219 304.679687 229.449219 53.5537571 162.5 0.73828125" />
        <path d="M80.0195312 519.828125L269.449219 304.679687 269.449219 53.5537571 342.5 0.73828125" />
        <path d="M100.019531 519.828125L289.449219 304.679687 289.449219 53.5537571" />
        <path d="M60.0195312 519.828125L249.449219 304.679687 249.449219 53.5537571" />
      </g>
      <circle fill="#2F3A4C" cx={124.057007} cy={2.38776809} r={1.5} />
      <circle fill="#2F3A4C" cx={144.057007} cy={2.38776809} r={1.5} />
      <circle fill="#2F3A4C" cx={164.057007} cy={2.38776809} r={1.5} />
      <circle fill="#2F3A4C" cx={249.96228} cy={52.5473633} r={1.5} />
      <circle fill="#2F3A4C" cx={342.468628} cy={1.85473633} r={1.5} />
      <circle fill="#2F3A4C" cx={289.96228} cy={52.5473633} r={1.5} />
    </g>
  </svg>
)

export default TechLines2Svg
