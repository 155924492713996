import { func } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../utils/formRedirect'

import SvgCheckmark from './Checkmark'
import style from './RapidOnboarding.module.css'

const RapidOnboarding = ({ setShowModal }) => {
  const data = useStaticQuery(graphql`
    {
      rocketCloud: file(relativePath: { eq: "services/managed/rocket-cloud.png" }) {
        childImageSharp {
          gatsbyImageData(width: 735, placeholder: TRACED_SVG, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.content}>
          <h2>Rapid Onboarding</h2>
          <GatsbyImage
            image={data.rocketCloud.childImageSharp.gatsbyImageData}
            alt=""
            className={style.rocketCloud}
          />
          <p>
            Observian leverages AWS native automation, authentication, and authorization concepts to
            allow for rapid onboarding, secure remote assistance, and automated deployment of
            security services all with minimal overhead.
          </p>
        </div>
        <ul className={style.list}>
          <li>
            <SvgCheckmark /> Frictionless setup
          </li>
          <li>
            <SvgCheckmark /> Scale to hundreds of accounts
          </li>
          <li>
            <SvgCheckmark />
            Be up and running within minutes
          </li>
        </ul>
        <br />
        <br />
        <Button 
          varient="primary" 
          label="Let's Get Started" 
          onClick={() => window.location.href = buildFormUrl([
            {
              key: 'CTA',
              value: 'AWS - Managed Security Fortinet - Let\'s Get Started'
            }
          ])}
        />
      </div>
    </div>
  )
}

RapidOnboarding.propTypes = {
  setShowModal: func.isRequired,
}
export default RapidOnboarding
