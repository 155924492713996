import { Component } from 'react'
import Button from 'observian-ui/lib/button'
import Modal from 'observian-ui/lib/modal'
import { gsap, Elastic, Power4 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { ScrollixGSAPAnimations } from 'observian-ui/lib/scrollix'
import Form from 'components/Form'
import Laptop from 'components/SVG/laptop'
import BigData from 'components/SVG/bigData'
import BG from 'components/SVG/background'
import canUseDOM from 'utils/canUseDOM'
import { buildFormUrl } from '../../../utils/formRedirect'

import style from './bigData.module.css'

class Offerings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      costModal: false,
      dataModal: false,
    }

    this.observers = []
  }

  componentDidMount() {
    gsap.registerPlugin(DrawSVGPlugin)
    const tlBigData = gsap.timeline()
    tlBigData.fromTo(
      `.${style.topLine}`,
      { x: 200, y: 200 },
      { x: 0, y: 0, ease: Power4.easeOut, duration: 2 },
      0
    )
    tlBigData.fromTo(
      `.${style.bottomLine}`,
      { x: -200, y: -200 },
      { x: 0, y: 0, ease: Power4.easeOut, duration: 2 },
      0
    )
    tlBigData.fromTo(
      `.${style.big}`,
      { x: '-=200' },
      { opacity: 0.45, x: 0, ease: Elastic.easeOut.config(1, 0.8), duration: 4 },
      0.85
    )
    tlBigData.fromTo(
      `.${style.data}`,
      { x: '+=200' },
      { opacity: 0.45, x: 0, ease: Elastic.easeOut.config(1, 0.8), duration: 4 },
      0.85
    )
    tlBigData.fromTo(
      `.${style.bdPart}`,
      { opacity: 0 },
      { opacity: 1, x: -20, y: -50, rotation: 360, transformOrigin: '50% 50%', duration: 5 },
      0.85
    )
    tlBigData.fromTo(
      `.${style.bdPart1}`,
      { opacity: 0, x: -50 },
      { opacity: 1, x: 20, y: 50, rotation: 360, transformOrigin: '50% 50%', duration: 5 },
      0.85
    )
    tlBigData.fromTo(
      `.${style.bdPart2}`,
      { opacity: 0, x: 20, y: 50 },
      { opacity: 1, x: -20, y: -20, rotation: 360, transformOrigin: '50% 50%', duration: 5 },
      0.85
    )
    tlBigData.fromTo(
      `.${style.bdPart3}`,
      { opacity: 0, x: 20, y: 50 },
      { opacity: 1, x: -30, y: 20, rotation: 360, transformOrigin: '50% 50%', duration: 5 },
      0.85
    )

    if (canUseDOM) {
      const drawSVG = window.DrawSVGPlugin // eslint-disable-line
    }

    const tlCost = gsap.timeline()
    tlCost.to(`.${style.money}`, { x: 200, duration: 4 })
    tlCost.fromTo(
      `.${style.graph}, .${style.graphShadow}`,
      { opacity: 1, drawSVG: '0%' },
      { drawSVG: '100%', ease: Power4.easeOut, duration: 4 },
      0
    )

    this.observers = [
      new ScrollixGSAPAnimations({
        root: null,
        threshold: 0.5,
        targetElement: `.${style.bigData}`,
        targetTween: tlBigData,
        onlyOnce: true,
      }),
      new ScrollixGSAPAnimations({
        threshold: 0.5,
        targetElement: `.${style.costOptimatization}`,
        targetTween: tlCost,
        onlyOnce: true,
      }),
    ]
  }

  componentWillUnmount() {
    if (this.observers && !!this.observers.length) {
      this.observers.map((observer) => observer.destroy())
    }
  }

  handleModal = (context, value) => {
    this.setState((state) => ({
      ...state,
      [context]: value,
    }))
  }

  render() {
    const { costModal, dataModal } = this.state

    return (
      <section className={style.offerings}>
        <div className={style.bigData}>
          <BigData
            className={style.bigDataSVG}
            outline={{ topLine: style.topLine, bottomLine: style.bottomLine }}
            textClassName={{ big: style.big, data: style.data }}
            particleClassName={{
              part: style.bdPart,
              part1: style.bdPart1,
              part2: style.bdPart2,
              part3: style.bdPart3,
            }}
          />
          <header className={style.header}>
            <h2 className={style.heading}>Big Data Analytics</h2>
            <p className={style.subHeading}>
              Data is key. Once you are able to access, store, and query your datasets efficiently,
              you can put your data to work for your business. Let us help you discover the
              Microsoft Tooling around Big Data & Analytics.
            </p>
            <Button
              variant="primary"
              className={style.bigDataButtonCTA}
              label="Let's Talk Big Data"
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Azure - Let\'s Talk Big Data`
                }
              ])}
            />
            {dataModal ? (
              <Modal isOpen={dataModal} onClose={() => this.handleModal('dataModal', false)}>
                <Form
                  onDismiss={() => this.handleModal('dataModal', false)}
                  inquiryPlaceholder="Hi! So tell me more about your experience with big data..."
                />
              </Modal>
            ) : null}
          </header>
        </div>

        <div className={style.costOptimatization}>
          <BG type="teal" className={style.headerBackground} moneyClassName={style.money} />

          <header className={style.header}>
            <h2 className={style.heading}>Cost Optimization</h2>
            <p className={style.subHeading}>
              Operating in the cloud is different than operating with on-premise servers. Cloud
              services only run efficiently with proper application architecture. Monitoring this
              creates better visibility into what you are spending, which leads to operational
              savings for your company. Our Azure customers get free tooling to help them monitor
              spend and find areas where they can save.
            </p>
            <Button
              variant="primary"
              label="Learn More"
              className={style.costButtonCTA}
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Azure - Cost Optimization - Learn More`
                }
              ])}
            />
            {costModal ? (
              <Modal isOpen={costModal} onClose={() => this.handleModal('costModal', false)}>
                <Form
                  onDismiss={() => this.handleModal('costModal', false)}
                  inquiryPlaceholder="Hi! We could use some help utilizing our cloud resources more efficiently and reducing costs..."
                />
              </Modal>
            ) : null}
          </header>

          <Laptop
            className={style.laptop}
            graphClassName={style.graph}
            graphShadowClassName={style.graphShadow}
          />
        </div>
      </section>
    )
  }
}

export default Offerings
