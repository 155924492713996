import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import style from './styles.module.css'

const CoverageCounsling = () => {
  const data = useStaticQuery(graphql`
    {
      AWSPartnerNetwork: file(relativePath: { eq: "services/managed/aws-partner-box.png" }) {
        childImageSharp {
          gatsbyImageData(width: 253, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
      backgroundImage: file(relativePath: { eq: "services/managed/consulting-partner-bg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 506, placeholder: DOMINANT_COLOR, layout: FIXED)
        }
      }
    }
  `)
  return (
    <div className={style.outerWrapper}>
      <div className={style.container}>
        <GatsbyImage
          image={data.AWSPartnerNetwork.childImageSharp.gatsbyImageData}
          alt=""
          className={style.AWSPartnerNetwork}
        />
      </div>
      <div className={style.wrapper}>
        <GatsbyImage
          image={data.backgroundImage.childImageSharp.gatsbyImageData}
          alt=""
          className={style.backgroundImage}
        />
        <div className={style.container}>
          <div className={style.headerContainer}>
            <div className={style.header}>
              <h2>Advanced threat detection and remediation</h2>
              <p>
                Packed with advanced security features, the Observian Managed Security Service is a
                Fortinet CNP solution - combining our managed security service with Fortinet
                next-generation firewalls, unified threat management (UTM) border protection,
                intrusion prevention systems (IPS) and advanced threat protection to create
                comprehensive security coverage.{' '}
              </p>

              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/qB8o8r5tKbw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />

              {/* <Button
                varient="primary"
                outline
                label="See How Observian's MSSP Helped a Prominent Healthcare Company"
              /> */}
            </div>
          </div>
        </div>
        <div className={style.bottomDivider} />
      </div>
    </div>
  )
}
export default CoverageCounsling
