import * as React from 'react'

function KeepProduct(props) {
  return (
    <svg width={32} height={45} viewBox="0 0 32 45" xmlns="http://www.w3.org/2000/svg">
      <title>{'CC17946A-1EC2-41C6-ABC2-D294140DF648'}</title>
      <g opacity={0.153}>
        <g opacity={0.65} fillRule="nonzero" fill="none">
          <path
            d="M28.734 44.27H2.972A2.971 2.971 0 010 41.298V3.655A2.971 2.971 0 012.972.683h17.835l10.9 10.897v29.718a2.971 2.971 0 01-2.973 2.972z"
            fill="#FFBA00"
          />
          <path fill="#FF9500" d="M20.807.683l10.9 10.897h-10.9z" />
          <path
            fill="#FFF"
            d="M19.321 29.906h-6.936v2.477h6.936zM15.853 15.542a6.44 6.44 0 00-3.43 11.888h6.86a6.44 6.44 0 00-3.43-11.887z"
          />
        </g>
      </g>
    </svg>
  )
}

export default KeepProduct
