import PropTypes from 'prop-types'

const Lock = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="211"
    height="378"
    viewBox="0 0 211 378"
  >
    <defs>
      <linearGradient id="lock-b" x1="26.505%" x2="73.714%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="lock-d" x1="36.155%" x2="63.974%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="lock-g" x1="49.862%" x2="50.139%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" opacity=".04">
      <g transform="translate(0 111.178)">
        <mask id="lock-c" fill="#fff">
          <polygon points=".003 .011 210.613 .011 210.613 266.25 .003 266.25" />
        </mask>
        <path
          fill="url(#lock-b)"
          d="M208.47315,29.99625 C208.00365,29.5185 207.45765,29.09025 206.8329,28.73025 L160.2174,1.935 C155.7699,-0.63225 150.2904,-0.63 145.84365,1.94025 L7.33365,81.85125 L0.00300014174,81.85125 L0.00300014174,231.7305 C-0.0111,233.778 0.9909,235.8315 3.0189,237.00075 L49.36965,263.64375 C55.39515,267.1215 62.82015,267.1185 68.8434,263.637 L206.8374,184.02 C209.3424,182.5725 210.59865,180.045 210.61065,177.513 L210.6129,177.513 L210.6129,27.47025 L208.47315,29.99625 Z"
          mask="url(#lock-c)"
        />
      </g>
      <path
        fill="url(#lock-d)"
        d="M68.843475,224.8869 L206.837475,145.27065 C209.355225,143.8149 210.612225,141.27165 210.612225,138.72765 L210.612225,288.69165 L210.609975,288.69165 C210.598725,291.22365 209.342475,293.7504 206.837475,295.19865 L68.843475,374.8149 C68.103975,375.2424 67.343475,375.61665 66.566475,375.9384 L66.566475,226.0104 C67.343475,225.68715 68.103975,225.31365 68.843475,224.8869"
      />
      <g transform="translate(210 138.179)">
        <mask id="lock-f" fill="#fff">
          <polygon points=".235 .132 .613 .132 .613 .882 .235 .882" />
        </mask>
        <path
          fill="#C9DAFC"
          d="M0.608175,0.475275 L0.612675,0.470025 L0.612675,0.544275 C0.612675,0.521025 0.608925,0.497775 0.608175,0.475275"
          mask="url(#lock-f)"
        />
      </g>
      <path
        fill="url(#lock-g)"
        d="M49.369875,224.8935 L47.335875,223.7205 L47.335875,373.6485 L49.369875,374.82225 C51.643125,376.134 54.115875,376.94925 56.637375,377.271 L56.637375,227.343 C54.115875,227.02125 51.643125,226.206 49.369875,224.8935"
      />
      <path
        fill="#1C75BC"
        d="M206.838375 289.37955L68.843625 368.9958C62.820375 372.47805 55.395375 372.48105 49.369875 369.0033L3.018375 342.36105C.991125 341.19105-.011625 339.13755.00320919577 337.09005L.00320919577 339.6903C-.011625 341.7378.991125 343.7913 3.018375 344.96055L49.369875 371.60355C55.395375 375.0813 62.820375 375.07905 68.843625 371.5968L206.838375 291.9798C209.342625 290.53155 210.598875 288.0048 210.610125 285.4728L210.612375 285.4728 210.612375 282.87255 210.610125 282.87255C210.598875 285.40455 209.342625 287.9313 206.838375 289.37955M206.838375 148.857825L68.843625 228.474825C62.820375 231.957075 55.395375 231.959325 49.369875 228.481575L3.018375 201.839325C.991125 200.669325-.011625 198.615825.00320919577 196.568325L.00320919577 199.168575C-.011625 201.216825.991125 203.270325 3.018375 204.438825L49.369875 231.081825C55.395375 234.560325 62.820375 234.557325 68.843625 231.075075L206.838375 151.458825C209.342625 150.009825 210.598875 147.483075 210.610125 144.951825L210.612375 144.951825 210.612375 142.350825 210.610125 142.350825C210.598875 144.882825 209.342625 147.409575 206.838375 148.857825"
      />
      <g transform="translate(0 102.928)">
        <mask id="lock-i" fill="#fff">
          <polygon points="0 .535 210.613 .535 210.613 124.572 0 124.572" />
        </mask>
        <path
          fill="#6BD7F5"
          d="M206.8332,29.2536 L160.2177,2.45835 C155.76945,-0.10815 150.28995,-0.1059 145.84395,2.4636 L3.0147,84.87135 C-1.0068,87.19635 -1.00455,93.00135 3.0177,95.3226 L49.36995,121.96485 C55.39545,125.44335 62.8197,125.44035 68.8437,121.9581 L206.8377,42.34185 C211.8732,39.43035 211.87095,32.16135 206.8332,29.2536"
          mask="url(#lock-i)"
        />
      </g>
      <path
        fill="#394372"
        d="M139.97775,216.95115 C130.50525,222.4194 122.84775,235.68015 122.878408,246.56715 C122.8995,254.0649 126.5595,258.48015 131.9325,258.7164 L123.04875,306.4209 L140.2035,296.51715 L157.35825,286.61265 L148.23525,249.3039 C153.5865,242.8764 157.20975,234.2559 157.188,226.7589 C157.15725,215.87115 149.4525,211.4799 139.97775,216.95115"
      />
      <path
        fill="#6BD7F5"
        d="M133.631325,294.282375 L150.786825,284.377875 L141.663825,247.069875 C147.015075,240.642375 150.638325,232.021875 150.616575,224.524125 C150.603825,220.042875 149.289075,216.664875 147.087075,214.619625 C153.023325,214.300875 157.165575,218.784375 157.188075,226.759125 C157.209075,234.256125 153.585825,242.876625 148.235325,249.303375 L157.358325,286.612125 L140.202825,296.516625 L123.048825,306.421125 L124.305825,299.666625 L133.631325,294.282375 Z"
      />
      <path
        fill="#27A8DF"
        d="M123.0486,306.420825 L131.9331,258.716325 C126.5601,258.480075 122.9001,254.064825 122.878258,246.567075 C122.8476,235.679325 130.5051,222.419325 139.9776,216.951075 C142.7436,215.353575 145.3581,214.596825 147.6756,214.596825 C153.29685,214.596825 157.1661,219.049575 157.1886,226.758825 C157.2096,234.255825 153.58635,242.876325 148.2351,249.303825 L157.3581,286.612575 L140.20335,296.517075 L123.0486,306.420825 Z M118.7076,312.558075 L124.6206,309.144075 L141.77535,299.239575 L158.9301,289.335825 L160.9716,288.156075 L160.4121,285.865575 L151.65735,250.059825 C157.05735,243.045075 160.3536,234.248325 160.3326,226.749075 C160.3191,222.303075 159.1596,218.521575 156.9801,215.814075 C154.7151,213.001575 151.40985,211.452825 147.6756,211.452825 L147.67485,211.452825 C144.71385,211.452825 141.5946,212.386575 138.4056,214.227825 C127.90185,220.292325 119.69985,234.501075 119.73435,246.576075 C119.7546,253.999575 123.0066,259.389075 128.2716,261.203325 L119.9571,305.844825 L118.7076,312.558075 Z"
      />
      <path
        fill="#5A5A8C"
        d="M29.9841 200.2014C29.9841 204.79065 36.82635 208.51065 45.2661 208.51065 53.70585 208.51065 60.5481 204.79065 60.5481 200.2014 60.5481 195.61365 53.70585 191.8929 45.2661 191.8929 36.82635 191.8929 29.9841 195.61365 29.9841 200.2014M150.138675 130.194975C150.138675 134.784225 156.980925 138.504225 165.420675 138.504225 173.861175 138.504225 180.702675 134.784225 180.702675 130.194975 180.702675 125.607225 173.861175 121.886475 165.420675 121.886475 156.980925 121.886475 150.138675 125.607225 150.138675 130.194975"
      />
      <g transform="translate(30.75 .178)">
        <mask id="lock-k" fill="#fff">
          <polygon points=".439 .572 148.677 .572 148.677 206.836 .439 206.836" />
        </mask>
        <path
          fill="#B8BCFC"
          d="M55.69575,10.685175 C75.13125,-0.471075 98.2815,-3.648825 117.68625,7.559925 C137.09175,18.768675 148.677,39.467925 148.677,61.877925 L148.677,129.676425 L148.67475,129.676425 C148.63125,133.852425 142.3935,137.229675 134.7015,137.229675 C127.00875,137.229675 120.77025,133.852425 120.7275,129.676425 L120.72525,129.676425 L120.72525,61.877925 C120.72525,49.571175 114.363,38.547675 103.70625,32.392425 C93.04875,26.237175 80.322,28.748175 69.66075,34.897425 C44.205,49.580175 28.3905,87.019425 28.3905,116.408175 L28.3905,199.240425 C28.3905,203.435925 22.13325,206.836425 14.415,206.836425 C6.696,206.836425 0.43875,203.435925 0.43875,199.240425 L0.43875,116.408175 C0.43875,77.060925 18.21075,32.200425 55.69575,10.685175"
          mask="url(#lock-k)"
        />
      </g>
      <g transform="translate(30.75 .178)">
        <mask id="lock-m" fill="#fff">
          <polygon points=".438 .572 148.677 .572 148.677 206.836 .438 206.836" />
        </mask>
        <path
          fill="#ECF3FA"
          d="M55.69575,10.685175 C75.13125,-0.471075 98.2815,-3.648825 117.687,7.559925 C137.09175,18.768675 148.677,39.467925 148.677,61.877925 L148.677,129.676425 L148.67475,129.676425 C148.64475,132.582675 145.61175,135.101175 141.17775,136.364175 L141.17775,65.690925 C141.17775,45.548175 131.2785,26.943675 114.69825,16.868925 C98.118,6.794175 78.336,9.650175 61.7295,19.677675 C29.7015,39.016425 14.51625,79.337925 14.51625,114.702675 L14.51625,206.836425 C14.4825,206.836425 14.44875,206.836425 14.415,206.836425 C6.696,206.836425 0.438,203.435925 0.438,199.240425 L0.438,116.408175 C0.438,77.060925 18.21075,32.200425 55.69575,10.685175"
          mask="url(#lock-m)"
        />
      </g>
    </g>
  </svg>
)

Lock.defaultProps = {
  className: '',
}

Lock.propTypes = {
  className: PropTypes.string,
}

export default Lock
