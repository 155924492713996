export const isEmail = (value) => {
  const pattern =
    "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
  const regex = new RegExp(pattern, 'i')

  return regex.test(value) ? undefined : 'Enter a valid email address'
}

export const isPhoneNumber = (value) => {
  if (undefined === value) return undefined
  const pattern = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'
  const regex = new RegExp(pattern, 'i')

  return regex.test(value) ? undefined : 'Enter a valid phone number'
}

export const mustBeNumber = (value) => (Number.isNaN(value) ? 'Must be a number' : undefined)

export const minValue = (min, message) => (value) =>
  Number.isNaN(value) || value >= min ? undefined : `${message}`

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const isRequired = (value) => (value ? undefined : 'Required')
