import { useState } from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import ContactForm from 'components/Form/Contact'
import ObservianSecurity from 'components/SVG/observianSecurity'
import ObservianDude from 'components/SVG/observianDude'
import Lock from 'components/SVG/lock'
import { buildFormUrl } from '../../../utils/formRedirect'

import style from './securityAssessment.module.css'

const SecurityAssessment = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  return (
    <section className={style.section}>
      <svg
        className={style.blueAngledLine}
        xmlns="http://www.w3.org/2000/svg"
        width="245"
        height="49"
        viewBox="0 0 245 49"
      >
        <defs>
          <linearGradient
            id="blue-angled-line-a"
            x1="12.453%"
            x2="87.895%"
            y1="51.357%"
            y2="48.692%"
          >
            <stop offset="0%" stopColor="#1D8DBA" />
            <stop offset="100%" stopColor="#03ECAE" />
          </linearGradient>
        </defs>
        <polygon
          fill="url(#blue-angled-line-a)"
          points="0 48.267 98.264 0 244.892 12.984 103.852 18.502"
        />
      </svg>
      <div className={style.top} />
      <div className={style.contentContainer}>
        <div className={style.content}>
          <Lock className={style.lock} />
          <div className={style.left}>
            <header className={style.header}>
              <h2>Azure Security Audit</h2>
              <p>
                Protect your data, network, and applications with the help from our security experts
                and through our network of security software partners. Observian can help you gain a
                clear understanding of the overall Security Posture of your Azure account(s) and see
                how you stack up against{' '}
                <b>NIST, HIPAA, GDPR Readiness, SOC2, ISO 27001, PCI-DSS</b> compliance.
              </p>
              <Button
                label="Let's Talk Security"
                outline
                onClick={() => window.location.href = buildFormUrl([
                  {
                    key: 'CTA',
                    value: `Azure - Let\'s Talk Security`
                  }
                ])}
              />
            </header>
          </div>
          <div className={style.right}>
            <ObservianDude className={style.dude} />
            <ObservianSecurity className={style.security} />
          </div>
        </div>
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-azure-security-assessment"
            inquiryPlaceholder="I'd like to talk Azure Security with Observian and perhaps get an assessment done."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
      <div className={style.bottom} />
    </section>
  )
}

export default SecurityAssessment
