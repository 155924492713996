import {
  Hero,
  Quote,
  ProvidedServices,
  PartnerSites,
  EngagementProcess,
  Sites,
} from 'components/Page/AWSLanding'
import Layout from 'components/Layout'

const AWSPage = () => (
  <Layout
    title="Amazon Web Services"
    description="Observian offers advanced AWS consulting services in Utah. We do all things cloud, including DevOps, Big Data, Security, Migration, and more. Learn more!"
    headerProps={{ isLandingPage: true }}
    footerProps={{ isLandingPage: true }}
  >
    <Hero />
    <PartnerSites />
    <Quote />
    <ProvidedServices />
    <EngagementProcess />
    <Sites />
  </Layout>
)

export default AWSPage
