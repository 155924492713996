import style from './partnerSites.module.css'

const PartnerSites = () => (
  <div>
    <section className={style.section}>
      <img className={style.image} src="/images/logos/taxhawk-logo.png" alt="taxhawk-logo" />
      <img
        className={style.image}
        src="/images/logos/synergysuite-logo.jpg"
        alt="synergysuite-logo"
      />
      <img className={style.image} src="/images/logos/aviacode-logo.jpg" alt="aviacode-logo" />
      <img className={style.image} src="/images/logos/upscript-logo.jpg" alt="upscript-logo" />
    </section>
    <section className={style.section2}>
      <img className={style.image} src="/images/logos/yoli-logo.png" alt="yoli-logo" />
      <img className={style.image} src="/images/logos/cve.png" alt="cve" />
      <img src="/images/logos/wsm-logo.png" alt="wsm-logo" width="160px" height="160px" />
    </section>
  </div>
)

export default PartnerSites
