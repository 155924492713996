import axios from 'axios'

import { BASE_ENV_URL } from './helper'

export const queryQL = async ({ query, headers = {} }) => {
  const response = await axios({
    url: `${BASE_ENV_URL}/graphql`,
    method: 'POST',
    headers,
    data: {
      query,
    },
  })

  return response
}

export const mutateQL = async ({ query, inputKey, inputData, headers = {} }) => {
  const response = await axios({
    url: `${BASE_ENV_URL}/graphql`,
    method: 'POST',
    headers,
    data: {
      query,
      variables: {
        [inputKey]: inputData,
      },
    },
  })

  return response
}
