import { Component } from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import style from './o365Migration.module.css'
import products from './officeProducts'

class O365Migration extends Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  handleIsSuccessful = (value) => {
    this.setState({ isSuccessful: value })
  }

  handleModal = (key) => {
    this.setState((state) => ({
      [key]: !state[key],
    }))
  }

  render() {
    const { showModal, isSuccessful } = this.state

    return (
      <section className={style.container}>
        <div className={style.content}>
          <header className={style.header}>
            <h2>FREE Office 365 Migration</h2>
            <TextUnderline />
          </header>
          <img
            role="presentation"
            className={style.cloudMig}
            src="/images/office365/pngcloud.png"
            alt="Office 365 Migration"
          />
          <p className={style.description}>
            Observian can get your team up and running on Office 365 quickly and securely from any
            version of Microsoft Exchange or other platforms. With our white glove service, you can
            be sure of a successful migration without any downtime or data loss. We offer{' '}
            <b>FREE Migration</b> with the purchase of any annual contract through Observian.
          </p>
          <Button
            className={style.button}
            label="Ready to be more productive?"
            onClick={() => window.location.href = buildFormUrl([
              {
                key: 'CTA',
                value: `Office365 - Ready to be more productive?`
              }
            ])}
          />
        </div>

        {showModal ? (
          <ContactModal
            showClose
            shouldCloseOnOverlayClick
            onClose={() => this.handleModal('showModal')}
            onDismiss={() => this.handleModal('showModal')}
            isOpen={showModal}
            closeButtonPosition="right"
            ariaHideApp={false}
            success={isSuccessful}
          >
            <ContactForm
              showLogo
              showContactInfo
              headerTitle="Contact Us"
              buttonLabel="Send"
              ctaIntentSuffix="modal-office365-migration"
              inquiryPlaceholder="I'd love to learn more about this Office365 free migration offer."
              isSuccessfulCallback={(v) => {
                this.handleIsSuccessful(v)
                if (!v) {
                  this.handleModal('showModal')
                }
              }}
            />
          </ContactModal>
        ) : null}

        <div className={style.products}>
          {products.map((product) => (
            <div className={style.product} key={`product:${product.name}`}>
              <img className={style.icon} src={product.icon} alt={product.name} />
              <div className={style.name}>{product.name}</div>
              <div className={style.productDescription}>{product.description}</div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

export default O365Migration
