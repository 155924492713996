import PropTypes from 'prop-types'

import CloudImage from '../../../images/home/observian-cloud.png'

const MainCloud = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1494"
    height="401"
    viewBox="0 0 1494 401"
  >
    <defs>
      <linearGradient id="main-cloud-a" x1="0%" x2="102%" y1="0%" y2="101%">
        <stop offset="0%" stopColor="#237BAE" />
        <stop offset="100%" stopColor="#00FFA2" stopOpacity=".946" />
      </linearGradient>
      <filter
        id="main-cloud-b"
        width="126.5%"
        height="242%"
        x="-13.3%"
        y="-71%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="main-cloud-c" x1="0%" x2="102%" y1="0%" y2="101%">
        <stop offset="0%" stopColor="#FFC42A" />
        <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
      </linearGradient>
      <filter
        id="main-cloud-d"
        width="104%"
        height="134.9%"
        x="-2%"
        y="-17.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="main-cloud-e"
        width="104%"
        height="134.9%"
        x="-2%"
        y="-17.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="main-cloud-f"
        width="137.8%"
        height="263.8%"
        x="-18.9%"
        y="-81.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="main-cloud-g"
        width="120.1%"
        height="170.2%"
        y="-35.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="main-cloud-h"
        width="133.5%"
        height="278.5%"
        x="-16.8%"
        y="-89.3%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="main-cloud-i" x1="0%" x2="73.95%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#237BAE" />
        <stop offset="100%" stopColor="#00FFA2" stopOpacity=".946" />
      </linearGradient>
      <filter
        id="main-cloud-j"
        width="166.7%"
        height="331.5%"
        x="-33.3%"
        y="-115.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="8.29" />
      </filter>
      <filter
        id="main-cloud-k"
        width="133.1%"
        height="144.4%"
        x="-16.6%"
        y="-22.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="main-cloud-l" x1="0%" x2="60.209%" y1="0%" y2="41.143%">
        <stop offset="0%" stopColor="#237BAE" />
        <stop offset="100%" stopColor="#00FFA2" stopOpacity=".946" />
      </linearGradient>
      <filter
        id="main-cloud-m"
        width="219%"
        height="152.3%"
        x="-59.5%"
        y="-26.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-2 -12)">
      <polygon
        fill="url(#main-cloud-a)"
        points="1171.742 182.828 1219.164 173.988 1280.879 140.57 1397.957 168.777 1280.879 148.738 1219.164 182.828"
      />
      <polygon
        fill="url(#main-cloud-a)"
        points="1171.742 192.828 1219.164 183.988 1280.879 150.57 1397.957 178.777 1280.879 158.738 1219.164 192.828"
        filter="url(#main-cloud-b)"
        transform="rotate(8 1284.85 171.7)"
      />
      <polygon
        fill="url(#main-cloud-c)"
        points="5.465 160.063 258.633 103 482.848 220.336 740.941 208.758 863.695 246.148 1091.5 181.414 1253.711 149.777 1489.902 199.797 1253.711 160.063 1091.5 190.98 863.695 275.145 747.684 220.336 388.797 239.613 258.633 112.5"
        filter="url(#main-cloud-d)"
        opacity=".512"
        transform="rotate(3 747.684 189.072)"
      />
      <polygon
        fill="url(#main-cloud-c)"
        points="5.465 160.063 258.633 103 482.848 220.336 740.941 208.758 863.695 246.148 1091.5 181.414 1253.711 149.777 1489.902 199.797 1253.711 160.063 1091.5 190.98 863.695 275.145 747.684 220.336 388.797 239.613 258.633 112.5"
        filter="url(#main-cloud-e)"
        opacity=".15"
        transform="rotate(8 747.684 189.072)"
      />
      <polygon
        fill="url(#main-cloud-c)"
        points="5.465 160.063 258.633 103 482.848 220.336 740.941 208.758 863.695 246.148 1091.5 181.414 1253.711 149.777 1489.902 199.797 1253.711 160.063 1091.5 190.98 863.695 275.145 747.684 220.336 388.797 239.613 258.633 112.5"
      />
      <polygon fill="url(#main-cloud-a)" points="46.367 173.797 180.043 137.176 204.941 146.844" />
      <polygon
        fill="url(#main-cloud-a)"
        points="822.508 270.012 1025.852 184.559 1121.738 201.422 1032.227 196.016 966.66 214.898"
      />
      <polygon
        fill="url(#main-cloud-a)"
        points="295.32 194.711 361.332 205.191 474.164 217.973 443.367 228.324 361.332 211.518"
      />
      <polygon
        fill="url(#main-cloud-a)"
        points="36.367 183.797 170.043 147.176 194.941 156.844"
        filter="url(#main-cloud-f)"
        transform="rotate(-11 115.654 165.486)"
      />
      <polygon
        fill="url(#main-cloud-a)"
        points="812.508 280.012 1015.852 194.559 1111.738 211.422 1022.227 206.016 956.66 224.898"
        filter="url(#main-cloud-g)"
        transform="rotate(-11 962.123 237.285)"
      />
      <polygon
        fill="url(#main-cloud-a)"
        points="285.32 224.711 351.332 235.191 464.164 247.973 433.367 258.324 351.332 241.518"
        filter="url(#main-cloud-h)"
        opacity=".6"
        transform="rotate(-11 374.742 241.518)"
      />
      <g transform="translate(376.532 51)">
        <image width="684.65" height="360.956" x=".425" y=".778" xlinkHref={CloudImage} />
        <polygon
          fill="url(#main-cloud-i)"
          points="61.685 208.89 97.087 223.393 136.302 223.393 97.087 230.374"
        />
        <polygon
          fill="url(#main-cloud-i)"
          points="61.685 208.89 97.087 223.393 136.302 223.393 97.087 230.374"
          filter="url(#main-cloud-j)"
          opacity=".673"
          transform="rotate(-11 98.994 219.632)"
        />
        <polygon
          fill="url(#main-cloud-a)"
          points="386.284 248.841 498.249 230.011 554.595 169.521 543.583 113.672 567.523 181.256 486.987 242.974"
        />
        <polygon
          fill="url(#main-cloud-a)"
          points="376.284 248.841 488.249 230.011 544.595 169.521 533.583 113.672 557.523 181.256 476.987 242.974"
          filter="url(#main-cloud-k)"
          opacity=".434"
        />
        <polygon
          fill="url(#main-cloud-l)"
          points="206.58 146.569 206.133 140.579 200.459 64.429 250.888 31.897 250.888 38.401 212.087 64.429"
          filter="url(#main-cloud-m)"
        />
        <polygon
          fill="url(#main-cloud-l)"
          points="208.58 136.569 208.133 130.579 202.459 54.429 252.888 21.897 295.778 18.306 275.3 29.268 252.888 28.401 214.087 54.429"
        />
      </g>
    </g>
  </svg>
)

MainCloud.defaultProps = {
  className: '',
}

MainCloud.propTypes = {
  className: PropTypes.string,
}

export default MainCloud
