import { Component } from 'react'
import Button from 'observian-ui/lib/button'
import { ScrollixGSAPAnimations } from 'observian-ui/lib/scrollix'
import Modal from 'observian-ui/lib/modal'
import { gsap, Elastic } from 'gsap'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import style from './ourApproach.module.css'

class OurApproach extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      isSuccessful: false,
    }

    this.observer = null
  }

  componentDidMount() {
    gsap.set(`.${style.planSteps}`, { opacity: 1 })
    const tl = gsap.timeline()
    tl.fromTo(
      `.${style.assess}`,
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, ease: Elastic.easeOut.config(1, 0.5), duration: 1.2 }
    )
    tl.fromTo(
      `.${style.lineTop}`,
      { opacity: 0, x: 20, y: -30 },
      { opacity: 1, x: 0, y: 0, duration: 0.25 },
      '-=0.5'
    )
    tl.fromTo(
      `.${style.plan}`,
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, ease: Elastic.easeOut.config(1, 0.5), duration: 1.2 },
      '-=.25'
    )
    tl.fromTo(
      `.${style.lineMiddle}`,
      { opacity: 0, x: -20, y: -30 },
      { opacity: 1, x: 0, y: 0, duration: 0.25 },
      '-=0.5'
    )
    tl.fromTo(
      `.${style.execute}`,
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, ease: Elastic.easeOut.config(1, 0.5), duration: 1.2 },
      '-=.25'
    )
    tl.fromTo(
      `.${style.lineBottom}`,
      { opacity: 0, x: 20, y: -30 },
      { opacity: 1, x: 0, y: 0, duration: 0.25 },
      '-=0.5'
    )
    tl.fromTo(
      `.${style.optimize}`,
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, ease: Elastic.easeOut.config(1, 0.5), duration: 1.2 },
      '-=.25'
    )

    this.observer = new ScrollixGSAPAnimations({
      threshold: 0.5,
      targetElement: `.${style.header}`,
      targetTween: tl,
      onlyOnce: true,
    })
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.destroy()
    }
  }

  handleModal = (context, value) => {
    this.setState((state) => ({
      ...state,
      [context]: value,
    }))
  }

  handleSetSuccessful = (v) => {
    this.setState({
      isSuccessful: v,
    })
  }

  render() {
    const { showModal, isSuccessful } = this.state

    return (
      <section className={style.ourApproach}>
        <svg
          className={style.planSteps}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="288"
          height="532"
          viewBox="0 0 288 532"
        >
          <defs>
            <linearGradient
              id="ourapproach_red"
              x1="15.436%"
              x2="114.319%"
              y1="90.518%"
              y2="21.413%"
            >
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity="0.95" />
            </linearGradient>
            <linearGradient
              id="ourapproach_blue"
              x1="35.084%"
              x2="87.895%"
              y1="123.914%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polygon
              className={style.lineTop}
              fill="url(#ourapproach_blue)"
              points="113.997 58.453 50.56 169.539 64.282 180.34"
            />
            <polygon
              className={style.lineMiddle}
              fill="url(#ourapproach_blue)"
              points="53.646 190.207 110.857 328.988 128.111 317.816"
            />
            <polygon
              className={style.lineBottom}
              fill="url(#ourapproach_red)"
              points="117.236 326.086 47.56 460.055 70.005 472.031"
            />
            <g alt="Assess" className={style.assess}>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistUltra, Geomanist"
                fontSize="27"
              >
                <tspan x="176" y="52.264">
                  Assess
                </tspan>
              </text>
              <circle
                cx="118.5"
                cy="46.764"
                r="13.5"
                fill="#000"
                filter="url(#ourapproach_text-d)"
              />
              <circle
                cx="118.5"
                cy="46.764"
                r="15.5"
                stroke="#1DECB6"
                strokeWidth="4"
                fill="url(#ourapproach_blue)"
              />
              <circle
                cx="118.5"
                cy="46.764"
                r="20.5"
                stroke="#1DECB6"
                strokeOpacity=".218"
                strokeWidth="14"
              />
              <circle
                cx="118.5"
                cy="46.764"
                r="17.5"
                stroke="#1DECB6"
                strokeOpacity=".178"
                strokeWidth="8"
              />
            </g>
            <g alt="Plan and Educate" className={style.plan}>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistUltra, Geomanist"
                fontSize="27"
              >
                <tspan x="111" y="192.264">
                  Plan &amp; Educate
                </tspan>
              </text>
              <circle
                cx="52.573"
                cy="186.726"
                r="13.5"
                fill="#000"
                filter="url(#ourapproach_text-g)"
              />
              <circle
                cx="52.573"
                cy="186.726"
                r="15.5"
                stroke="#1DECB6"
                strokeWidth="4"
                fill="url(#ourapproach_blue)"
              />
              <circle
                cx="52.573"
                cy="186.726"
                r="20.5"
                stroke="#1DECB6"
                strokeOpacity=".218"
                strokeWidth="14"
              />
              <circle
                cx="52.573"
                cy="186.726"
                r="17.5"
                stroke="#1DECB6"
                strokeOpacity=".178"
                strokeWidth="8"
              />
            </g>
            <g alt="Execute" className={style.execute}>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistUltra, Geomanist"
                fontSize="27"
              >
                <tspan x="181" y="332.264">
                  Execute
                </tspan>
              </text>
              <circle
                cx="118.5"
                cy="326.726"
                r="13.5"
                fill="#000"
                filter="url(#ourapproach_text-i)"
              />
              <circle
                cx="118.5"
                cy="326.726"
                r="15.5"
                stroke="#1DECB6"
                strokeWidth="4"
                fill="url(#ourapproach_blue)"
              />
              <circle
                cx="118.5"
                cy="326.726"
                r="20.5"
                stroke="#1DECB6"
                strokeOpacity=".218"
                strokeWidth="14"
              />
              <circle
                cx="118.5"
                cy="326.726"
                r="17.5"
                stroke="#1DECB6"
                strokeOpacity=".178"
                strokeWidth="8"
              />
            </g>
            <g alt="Optimize" className={style.optimize}>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistUltra, Geomanist"
                fontSize="27"
              >
                <tspan x="118.5" y="471.132">
                  Optimize
                </tspan>
              </text>
              <circle
                cx="58.5"
                cy="466.726"
                r="13.5"
                fill="#000"
                filter="url(#ourapproach_red_circle)"
              />
              <circle
                cx="58.5"
                cy="466.726"
                r="15.5"
                stroke="#FF385B"
                strokeWidth="4"
                fill="url(#ourapproach_red)"
              />
              <circle
                cx="58.5"
                cy="466.726"
                r="20.5"
                stroke="#FFA235"
                strokeOpacity=".316"
                strokeWidth="14"
              />
              <circle
                cx="58.5"
                cy="466.726"
                r="17.5"
                stroke="#FF4D53"
                strokeOpacity=".253"
                strokeWidth="8"
              />
            </g>
          </g>
        </svg>
        <header className={style.header}>
          <h1 className={style.heading}>Cloud Services</h1>
          <h5 className={style.subHeading}>We Are Your Partners In The Cloud</h5>
          <TextUnderline className={style.textUnderline} />
          <p className={style.description}>
            Whether you are new to the cloud, working with older technologies, or a seasoned cloud
            enterprise, we provide the resources needed to improve your cloud operations. Our goal
            of helping you develop useful, repeatable, and automated cloud solutions is augmented
            with ongoing support and education. We keep you up-to-date on emerging technologies and
            releases in the GCP (Google Cloud Platform) and AWS (Amazon Web Services) ecosystems,
            propelling you to the top of development and cloud experience. Observian provides cloud
            services in Utah and nationwide. We are your partners in the cloud.
          </p>
          <Button
            variant="primary"
            label="Schedule a Conversation"
            className={style.buttonCTA}
            onClick={() => window.location.href = buildFormUrl([
              {
                key: 'CTA',
                value: 'Home Page - Schedule a Conversation'
              }
            ])}
          />
          {showModal ? (
            <Modal
              isTransparent
              isOpen={showModal}
              onClose={() => this.handleModal('showModal', false)}
            >
              <ContactModal
                showClose
                shouldCloseOnOverlayClick
                onClose={() => this.handleModal('showModal', false)}
                onDismiss={() => this.handleModal('showModal', false)}
                isOpen={showModal}
                closeButtonPosition="right"
                ariaHideApp={false}
                success={isSuccessful}
              >
                <ContactForm
                  showLogo
                  headerTitle="Contact Us"
                  buttonLabel="Send"
                  ctaIntentSuffix="modal-aws-partners-benefits"
                  inquiryPlaceholder="I'd like to learn more about Observian cloud services."
                  isSuccessfulCallback={(v) => {
                    this.handleSetSuccessful(v)
                    if (!v) {
                      this.handleModal('showModal', false)
                    }
                  }}
                />
              </ContactModal>
            </Modal>
          ) : null}
        </header>
      </section>
    )
  }
}

export default OurApproach
