import { Component } from 'react'
import { gsap, Back, Power0, Power4 } from 'gsap'
import { SplitText } from 'gsap/SplitText'

import Lasers from 'components/SVG/lasers/heroLasers'
import PB from 'components/SVG/partnersBoxes'
import BGLines from 'components/SVG/backgroundLines'

import style from './hero.module.css'

class Hero extends Component {
  componentDidMount() {
    gsap.registerPlugin(SplitText)
    const tweenOptions = { yoyo: true, repeat: -1 }
    const tlOptions = { opacity: 0, ease: Back.easeOut.config(1, 3) }
    const easeOptions = { ease: Power4.easeOut }

    const triggerElements = () => {
      gsap.to(
        `.${style.tri},
        .${style.tri1},
        .${style.tri2},
        .${style.tri3},
        .${style.tri4},
        .${style.oval}`,
        {
          rotation: 360,
          ease: Power0.easeNone,
          transformOrigin: '50% 50%',
          repeat: -1,
          duration: 20,
        }
      )
    }

    gsap.set(
      `.${style.partners},
      .${style.laser},
      .${style.laser1},
      .${style.laser2},
      .${style.laser3},
      .${style.laser4}`,
      { perspective: 300 }
    )

    const tl = gsap.timeline({ paused: true, onComplete: triggerElements })
    gsap.from(`.${style.laser}, .${style.laser1}`, {
      rotation: -2,
      x: '-=200',
      transformOrigin: '50% 50%',
      rotationX: -10,
      duration: 2,
    })

    gsap.from(`.${style.laser2}, .${style.laser3}, .${style.laser4}`, {
      rotation: 2,
      x: '-=250',
      transformOrigin: '50% 50%',
      rotationX: -10,
      duration: 2,
    })

    const SplitChars = new SplitText(`.${style.text}, .${style.header}, .${style.subHeader}`, {
      type: 'words',
    }) // eslint-disable-line

    gsap.fromTo(
      `.${style.hLine}`,
      { y: -100, opacity: 0 },
      { y: 0, opacity: 0.07, ...easeOptions, duration: 3 }
    )
    gsap.fromTo(
      `.${style.hLine1}`,
      { y: -200, opacity: 0 },
      { y: 0, opacity: 0.15, ...easeOptions, duration: 3 }
    )
    gsap.fromTo(
      `.${style.hLine2}`,
      { y: 200, opacity: 0 },
      { y: 0, opacity: 0.125, ...easeOptions, duration: 6 }
    )
    gsap.fromTo(
      `.${style.hLine3}`,
      { y: -20, opacity: 0 },
      { y: 0, opacity: 0.12, ...easeOptions, duration: 2 }
    )
    gsap.fromTo(
      `.${style.hLine4}`,
      { y: -400, opacity: 0 },
      { y: 0, opacity: 0.1, ...easeOptions, duration: 6 }
    )
    gsap.fromTo(
      `.${style.vLine}`,
      { x: -200, opacity: 0 },
      { x: 0, opacity: 0.68, ...easeOptions, duration: 1 }
    )
    gsap.fromTo(
      `.${style.vLine1}`,
      { x: 500, opacity: 0 },
      { x: 0, opacity: 0.85, ...easeOptions, duration: 3 }
    )
    gsap.fromTo(
      `.${style.vLine2}`,
      { x: 1500, opacity: 0 },
      { x: 0, opacity: 0.75, ...easeOptions, duration: 5 }
    )
    gsap.from(SplitChars.words, { opacity: 0, x: '-=5', duration: 1, stagger: 0.15 })
    setTimeout(() => tl.play(), 2000)

    const floatBoxes = () => {
      SplitChars.revert()

      gsap.to('#alert-logic', { x: '+=10', y: '-=10', ...tweenOptions, duration: 7 })
      gsap.to('#dynatrace', { x: '+=25', y: '+=25', ...tweenOptions, duration: 7 })
      gsap.to('#docker', { x: '+=15', y: '-=10', ...tweenOptions, duration: 7 })
      gsap.to('#cloudability', { x: '-=5', y: '+=20', ...tweenOptions, duration: 7 })
      gsap.to('#splunk', { x: '+=15', y: '-=5', ...tweenOptions, duration: 7 })
      gsap.to('#appdynamics', { x: '-=5', y: '+=20', ...tweenOptions, duration: 7 })
      gsap.to('#sumo-logic', { x: '+=10', y: '+=10', ...tweenOptions, duration: 7 })
      gsap.to('#cloudcheckr', { x: '+=10', y: '+=10', ...tweenOptions, duration: 7 })
      gsap.to('#cloudendure', { x: '+=10', y: '+=15', ...tweenOptions, duration: 7 })

      gsap.fromTo(
        `.${style.partners}`,
        { rotationY: 0, rotationX: 0 },
        { rotationY: 10, rotationX: 10, ...tweenOptions, duration: 10 }
      )
    }

    tl.from(
      `.${style.tri},
      .${style.tri1},
      .${style.tri2},
      .${style.tri3},
      .${style.tri4},
      .${style.oval}`,
      {
        opacity: 0,
        rotation: 360,
        transformOrigin: '50% 50%',
        duration: 10,
      },
      0
    )

    // Logo Boxes
    tl.from('#alert-logic', { x: '+=70', y: '-=50', ...tlOptions, duration: 4 }, 0)
    tl.from('#dynatrace', { x: '-=50', y: '+=30', ...tlOptions, duration: 4 }, 0)
    tl.from('#docker', { x: '+=50', y: '-=30', ...tlOptions, duration: 4 }, 0)
    tl.from('#cloudability', { x: '-=10', y: '+=80', ...tlOptions, duration: 4 }, 0)
    tl.from('#splunk', { x: '+=60', y: '-=20', ...tlOptions, duration: 4 }, 0)
    tl.from('#appdynamics', { x: '-=70', y: '+=60', ...tlOptions, duration: 4 }, 0)
    tl.from('#sumo-logic', { x: '-=40', y: '+=40', ...tlOptions, duration: 4 }, 0)
    tl.from('#cloudcheckr', { x: '+=70', y: '+=20', ...tlOptions, duration: 4 }, 0)
    tl.from('#cloudendure', { x: '+=30', y: '+=50', ...tlOptions, duration: 4 }, 0)

    // Non-Logo Boxes
    tl.from('#box', { x: ' += 40', y: ' -= 10', ...tlOptions, duration: 7 }, 0)
    tl.from('#box1', { x: ' -= 20', y: ' -= 10', ...tlOptions, duration: 7 }, 0)
    tl.from('#box2', { x: '-=30', y: '-=20', ...tlOptions, duration: 7 }, 0)
    tl.from('#box3', { x: '-=20', y: '-=10', ...tlOptions, duration: 7 }, 0)
    tl.from('#box4', { x: '+=40', y: '-=10', ...tlOptions, duration: 7 }, 0)
    tl.from('#box5', { x: '-=10', y: '-=20', ...tlOptions, duration: 7 }, 0)
    tl.from('#box6', { x: '-=20', y: '-=10', ...tlOptions, duration: 7 }, 0)
    tl.from('#box7', { x: '-=20', y: '-=10', ...tlOptions, duration: 7 }, 0)
    tl.from('#box8', { x: '-=20', y: '-=10', ...tlOptions, duration: 7, onComplete: floatBoxes }, 0)
  }

  render() {
    return (
      <div className={style.hero}>
        <BGLines
          className={style.backgroundLines}
          linesClassName={{
            hLine: style.hLine,
            hLine1: style.hLine1,
            hLine2: style.hLine2,
            hLine3: style.hLine3,
            hLine4: style.hLine4,
            vLine: style.vLine,
            vLine1: style.vLine1,
            vLine2: style.vLine2,
          }}
        />
        <div className={style.heroContainer}>
          <div className={style.title}>
            <Lasers
              className={style.lasers}
              lasersClassName={{
                laser: style.laser,
                laser1: style.laser1,
                laser2: style.laser2,
                laser3: style.laser3,
                laser4: style.laser4,
              }}
            />
            <header>
              <h1 className={style.header}>Cloud Software Partners</h1>
              <p className={style.subHeader}>
                Observian has the partnerships in place to help your company succeed.
              </p>
            </header>
          </div>
          <div className={style.partnersContainer}>
            <span className={style.text}>Dozens of partnerships...</span>
            <div className={style.partners}>
              <PB
                className={style.boxes}
                attrClassName={{
                  tri: style.tri,
                  tri1: style.tri1,
                  tri2: style.tri2,
                  tri3: style.tri3,
                  tri4: style.tri4,
                  oval: style.oval,
                }}
              />
            </div>
            <span className={style.text}>...with best in industry companies.</span>
          </div>
        </div>
      </div>
    )
  }
}

Hero.defaultProps = {}

Hero.propTypes = {}

export default Hero
