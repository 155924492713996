import Layout from 'components/Layout'
import Error404 from 'components/Page/Error404'

const Error404Page = () => (
  <Layout
    title="404 | Page Not Found"
    description="Looks like you found a page that doesn't exist, while you are more than welcome to stay we recommend you return to the homepage."
    footerProps={{ darkBackground: true }}
  >
    <Error404 />
  </Layout>
)

export default Error404Page
