import { Component } from 'react'
import clsx from 'clsx'
import { gsap, Elastic } from 'gsap'
import { Physics2DPlugin } from 'gsap/Physics2DPlugin'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import Logo from 'observian-ui/lib/logo'
import Button from 'observian-ui/lib/button'
import Divider from 'observian-ui/lib/divider'
import ContactModal from 'observian-ui/lib/modal/contact'
import ContactForm from 'components/Form/Contact'
import GoogleForm from 'components/Form/Google'
import canUseDOM from 'utils/canUseDOM'
import { buildFormUrl } from '../../../../utils/formRedirect'

import style from './hero.module.css'

class Hero extends Component {
  state = {
    showModal: false,
  }

  handleIsSuccessful = (value) => {
    this.setState({ isSuccessful: value })
  }

  handleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
      isSuccessful: false,
    }))
  }

  componentDidMount = () => {
    if (canUseDOM) {
      gsap.registerPlugin(Physics2DPlugin)
      const tl = gsap.timeline()
      const confettiElement = document.querySelector(`.${style.confetti}`)
      const confettiContainer = document.createElement('div')
      confettiContainer.className = style.confettiContainer
      confettiElement.appendChild(confettiContainer)

      const clearItems = () => {
        while (confettiContainer.firstChild) {
          confettiContainer.removeChild(confettiContainer.firstChild)
        }
      }
      gsap.set(`.${style.image}`, { perspective: 400 })

      this.timeout = setTimeout(() => {
        gsap.set(`.${style.image}`, { opacity: 1 })
        gsap.from(`.${style.image}`, {
          x: '-=550',
          y: '-=120',
          rotationX: -90,
          rotationY: 20,
          opacity: 0,
          ease: Elastic.easeOut.config(1, 1),
          duration: 3,
          stagger: 0.07,
        })
      }, 1900)

      const clearConfetti = debounce(clearItems, 3000, { leading: false, trailing: true })

      const confetti = () => {
        const dots = gsap.timeline({ onStart: clearConfetti })

        const colors = ['#04EAAE', '#1B92B9', '#1D354F', '#2EFFF8', '#F7FBFB']

        times(45, () => {
          const dot = document.createElement('div')
          dot.className = style.dot
          confettiContainer.appendChild(dot)
          const color = colors[(Math.random() * colors.length) | 0] // eslint-disable-line

          gsap.set(dot, {
            backgroundColor: color,
            rotation: Math.floor(Math.random() * 160) + 1,
            width: Math.floor(Math.random() * 16) + 4,
            height: Math.floor(Math.random() * 16) + 4,
            x: Math.floor(Math.random() * 16),
            y: '+=40',
          })

          dots.to(
            dot,
            {
              rotation: Math.floor(Math.random() * 360) + 1,
              physics2D: {
                velocity: Math.floor(Math.random() * 500) + 100,
                angle: Math.floor(Math.random() * 360) + 1,
                gravity: 700,
              },
              opacity: 0,
              duration: 5,
            },
            0.05
          )
        })
        return dots
      }

      tl.fromTo(`.${style.contentItem}`, { opacity: 0 }, { opacity: 1, duration: 1, stagger: 0.4 })
      tl.fromTo(
        `.${style.confetti}, .${style.thumb}`,
        { scale: 0, transformOrigin: '50% 50%', opacity: 0 },
        {
          opacity: 1,
          scale: 1,
          ease: Elastic.easeOut.config(1, 0.7),
          onStart: confetti,
          duration: 1.25,
        },
        '-=1'
      )
      tl.to(`.${style.title}, .${style.description}, .${style.button}`, { opacity: 1, duration: 1 })
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout.id)
    }
  }

  render() {
    const { showModal, isSuccessful } = this.state

    return (
      <section className={style.hero}>
        <div className={style.container}>
          <div className={style.content}>
            <header className={style.header}>
              <img
                className={clsx(style.contentItem, style.gsuite)}
                src="/images/logos/google/gsuite/gworkspace/gworkspace.png"
                alt="GSuite"
              />
              <span className={clsx(style.contentItem, style.add)}>+</span>
              <Logo className={clsx(style.contentItem, style.logo)} />
              <span className={clsx(style.contentItem, style.equals)}> = </span>
              <div className={style.contentItem}>
                <div className={style.confetti} />
                <img className={style.thumb} alt="Thumb Emoji" src="/images/thumb.png" />
                <svg
                  className={style.thumbBG}
                  width="116px"
                  height="123px"
                  viewBox="0 0 116 123"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <linearGradient
                      x1="12.4534884%"
                      y1="84.9243045%"
                      x2="87.8953488%"
                      y2="16.3295925%"
                      id="thumbEmojiBG"
                    >
                      <stop stopColor="#1D8DBA" offset="0%" />
                      <stop stopColor="#03ECAE" offset="100%" />
                    </linearGradient>
                  </defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity="0.289248512"
                  >
                    <g
                      transform="translate(-471.000000, -265.000000)"
                      fill="url(#thumbEmojiBG)"
                      fillRule="nonzero"
                    >
                      <g transform="translate(471.000000, 265.000000)">
                        <polygon points="37.5605469 41.7484304 12.2792969 41.7484304 24.9199219 60.1510051 0.91015625 84.213855 32.8730469 88.882459 32.8730469 123.023553 76.3339844 96.6850861 111.083984 96.6850861 72.4667969 72.2844662 115.433594 39.7129531 72.4667969 43.4166951 76.3339844 0.310770615" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </header>
            <h1 className={style.title}>Observian is Proud to Be a Premier Google Partner.</h1>
            <p className={style.description}>
              Observian has partnered with Google to bring our customers best in class productivity
              and collaboration with Google. See how these amazing tools paired with our expertise
              can help transform your business.
            </p>
            <Button
              className={style.button}
              label="Get a Google Workspace Quote"
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Google Workspace - Get a Google Workspace Quote`
                }
              ])}
            />
            {showModal ? (
              <ContactModal
                showClose
                shouldCloseOnOverlayClick
                onClose={this.handleModal}
                onDismiss={this.handleModal}
                isOpen={showModal}
                closeButtonPosition="right"
                ariaHideApp={false}
                success={isSuccessful}
              >
                <GoogleForm
                  isSuccessfulCallback={(v) => {
                    this.handleIsSuccessful(v)
                    if (!v) {
                      this.handleModal()
                    }
                  }}
                />
              </ContactModal>
            ) : null}
          </div>
          <div className={style.images}>
            <div className={clsx(style.image, style.admin)}>
              <svg width="100%" height="100%" viewBox="0 0 44 39">
                <g
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  opacity={0.591315774}
                >
                  <path
                    d="M12.88 19.32l4.48 8.12H3.92L.708 21.617a4.48 4.48 0 01.116-4.526L4.48 11.2h12.88l-4.48 8.12zM26.32 11.2l4.48 8.12 6.72-11.48-3.19-5.583A4.48 4.48 0 0030.44 0h-6.92l-6.16 11.2h8.96z"
                    fill="#2684FC"
                    transform="translate(-824 -166) translate(824 166)"
                  />
                  <path
                    d="M17.36 11.2L23.52 0H13.481a4.48 4.48 0 00-3.889 2.257L4.48 11.2h12.88z"
                    fill="#0066DA"
                    transform="translate(-824 -166) translate(824 166)"
                  />
                  <path
                    d="M26.32 27.44h-8.96l6.44 11.172h6.907a4.48 4.48 0 003.893-2.263l3.48-6.109-7.28-10.92-4.48 8.12z"
                    fill="#2684FC"
                    transform="translate(-824 -166) translate(824 166)"
                  />
                  <path
                    d="M23.8 38.64H12.78a4.48 4.48 0 01-3.915-2.304L3.92 27.44h13.44l6.44 11.2zM30.8 19.32l6.72-11.48 5.423 9.264a4.48 4.48 0 01.04 4.455L37.8 30.8l-7-11.48z"
                    fill="#0066DA"
                    transform="translate(-824 -166) translate(824 166)"
                  />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.chat)}>
              <svg width="100%" height="100%" viewBox="0 0 76 80">
                <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
                  <path
                    d="M17.25 42.75V17H6c-3.315 0-6 2.685-6 6v53.255c0 2.675 3.23 4.01 5.12 2.12L17.5 66h35.25c3.315 0 6-2.685 6-6V48.75h-35.5c-3.315 0-6-2.685-6-6z"
                    fill="#00AC47"
                    transform="translate(-1024 -156) translate(1024 156)"
                  />
                  <path
                    d="M70 0H23.25c-3.315 0-6 2.685-6 6v11h35.5c3.315 0 6 2.685 6 6v25.75H70c3.315 0 6-2.685 6-6V6c0-3.315-2.685-6-6-6z"
                    fill="#5BB974"
                    transform="translate(-1024 -156) translate(1024 156)"
                  />
                  <path
                    d="M52.75 17h-35.5v25.75c0 3.315 2.685 6 6 6h35.5V23c0-3.315-2.685-6-6-6z"
                    fill="#00832D"
                    transform="translate(-1024 -156) translate(1024 156)"
                  />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.sheets)}>
              <svg width="100%" height="100%" viewBox="0 0 52 71">
                <g
                  transform="translate(-900 -236) translate(900 236)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  opacity={0.9}
                >
                  <path d="M37.6 27.2h-28v21.6h32V27.2h-4zm0 17.6h-24V31.2h24v13.6z" fill="#FFF" />
                  <path
                    d="M46.4 70.4H4.8A4.799 4.799 0 010 65.6V4.8C0 2.148 2.148 0 4.8 0h28.8l17.6 17.6v48c0 2.652-2.148 4.8-4.8 4.8z"
                    fill="#00AC47"
                  />
                  <path fill="#00832D" d="M33.6 0L51.2 17.6 33.6 17.6z" />
                  <path
                    d="M37.6 27.2h-28v23.2h32V27.2h-4zm-24 4h10v5.6h-10v-5.6zm0 15.2v-5.6h10v5.6h-10zm24 0h-10v-5.6h10v5.6zm0-9.6h-10v-5.6h10v5.6z"
                    fill="#FFF"
                  />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.voice)}>
              <svg width="100%" height="100%" viewBox="0 0 128 101">
                <g
                  transform="translate(-1196 -198) translate(1191 166)"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  fillRule="evenodd"
                >
                  <path d="M0 0H138.24V138.24H0z" />
                  <path
                    d="M126.713 0c-.159 27.634-18.195 51.048-43.193 59.386a63.693 63.693 0 01-20.16 3.254C28.49 62.647.194 34.632 0 0v.36c0 34.79 28.368 63 63.36 63a63.693 63.693 0 0020.16-3.254c25.106-8.374 43.2-31.961 43.2-59.746 0-.122-.007-.238-.007-.36z"
                    transform="translate(5.76 69.12)"
                    fill="#000"
                    fillRule="nonzero"
                    opacity={0.1}
                  />
                  <g fillRule="nonzero">
                    <path
                      d="M.396 16.862c-1.411 8.59 1.152 19.08 7.114 29.045l7.315-3.427 4.615-7.92-7.51-10.39L.62 15.74c-.08.374-.166.74-.224 1.122z"
                      fill="#00832D"
                      transform="translate(28.8 31.68)"
                    />
                    <path
                      d="M28.058 18.331L14.148 4.385a5.154 5.154 0 00-7.294 0l-.446.403C3.434 7.762 1.505 11.484.612 15.739L19.44 34.56l8.618-8.928c2.016-2.009 2.016-5.278 0-7.3z"
                      fill="#34A853"
                      transform="translate(28.8 31.68)"
                    />
                    <path
                      d="M40.32 15.84v17.28A2.88 2.88 0 0043.2 36h17.28c0-5.63-1.145-11.635-4.838-15.322-3.687-3.693-9.692-4.838-15.322-4.838zM60.156 76.615c-8.611 1.411-19.058-1.123-29.045-7.113l3.363-7.467 7.999-3.722L54 65.326l6.156 11.29z"
                      fill="#00832D"
                      transform="translate(28.8 31.68)"
                    />
                    <path
                      d="M43.258.418c-1.685 0-2.938 1.49-2.938 3.182v12.24c11.268 0 20.16 8.892 20.16 20.16h12.24c1.692 0 3.182-1.253 3.182-2.938C75.902 15.034 61.286.418 43.258.418zM73.015 69.552a4.106 4.106 0 00.144-5.76L58.313 47.966a4.104 4.104 0 00-5.731.072L42.48 58.32l17.676 18.295c4.882-.713 8.546-2.793 11.88-6.127l.98-.936zM7.51 45.9c2.721 4.493 6.321 9.029 10.39 13.118 4.082 4.09 8.718 7.755 13.211 10.476L42.48 58.32 19.44 34.56"
                      fill="#34A853"
                      transform="translate(28.8 31.68)"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.slides)}>
              <svg width="64px" height="88px" viewBox="0 0 64 88">
                <g
                  transform="translate(-1068 -277) translate(1068 277)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                >
                  <path
                    d="M58 88H6c-3.315 0-6-2.685-6-6V6c0-3.315 2.685-6 6-6h36l22 22v60c0 3.315-2.685 6-6 6z"
                    fill="#FFBA00"
                  />
                  <path fill="#FF9500" d="M42 0L64 22 42 22z" />
                  <path d="M47 34H12v27h40V34h-5zm0 22H17V39h30v17z" fill="#FFF" />
                </g>
              </svg>
            </div>
            <img
              alt="premier-partner"
              src="/images/logos/google/gsuite/gpremierpartner.png"
              width="300px"
              height="146px"
              className={clsx(style.image, style.premierpartner)}
            />
            <div className={clsx(style.image, style.keep)}>
              <svg width="100%" height="100%" viewBox="0 0 36 49">
                <g
                  transform="translate(-789 -310) translate(789 310)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  opacity={0.65}
                >
                  <path
                    d="M31.9 48.4H3.3A3.3 3.3 0 010 45.1V3.3A3.3 3.3 0 013.3 0h19.8l12.1 12.1v33a3.3 3.3 0 01-3.3 3.3z"
                    fill="#FFBA00"
                  />
                  <path fill="#FF9500" d="M23.1 0L35.2 12.1 23.1 12.1z" />
                  <path fill="#FFF" d="M21.45 32.45L13.75 32.45 13.75 35.2 21.45 35.2z" />
                  <path
                    d="M17.6 16.5a7.15 7.15 0 00-3.809 13.2h7.618A7.15 7.15 0 0017.6 16.5z"
                    fill="#FFF"
                  />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.forms)}>
              <svg width="100%" height="100%" viewBox="0 0 52 71">
                <g
                  transform="translate(-839 -394) translate(839 394)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  opacity={0.8}
                >
                  <path
                    d="M46.4 70.4H4.8A4.799 4.799 0 010 65.6V4.8C0 2.148 2.148 0 4.8 0h28.8l17.6 17.6v48c0 2.652-2.148 4.8-4.8 4.8z"
                    fill="#7248B9"
                  />
                  <path fill="#56368A" d="M33.6 0L51.2 17.6 33.6 17.6z" />
                  <path fill="#FFF" d="M40 27.2L20 27.2 20 31.2 40 31.2z" />
                  <path d="M13.6 31.6a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z" fill="#FFF" />
                  <path fill="#FFF" d="M40 36.8L20 36.8 20 40.8 40 40.8z" />
                  <path d="M13.6 41.2a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z" fill="#FFF" />
                  <path fill="#FFF" d="M40 46.4L20 46.4 20 50.4 40 50.4z" />
                  <path d="M13.6 50.8a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z" fill="#FFF" />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.calendar)}>
              <svg width="100%" height="100%" viewBox="0 0 69 69">
                <g
                  transform="translate(-1309 -370) translate(1309 370)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                >
                  <path fill="#FFF" d="M52.2 16.2L16.2 16.2 16.2 52.2 52.2 52.2z" />
                  <path
                    d="M23.584 44.127c-1.345-.909-2.276-2.236-2.785-3.992l3.123-1.286c.284 1.08.779 1.917 1.485 2.51.702.594 1.557.887 2.556.887 1.021 0 1.899-.31 2.633-.931.733-.621 1.102-1.413 1.102-2.372 0-.981-.387-1.782-1.161-2.403s-1.746-.931-2.907-.931h-1.804v-3.092h1.62c.998 0 1.84-.27 2.524-.81.684-.54 1.026-1.278 1.026-2.219 0-.837-.306-1.503-.918-2.002-.612-.5-1.386-.752-2.326-.752-.918 0-1.647.243-2.188.734a4.306 4.306 0 00-1.178 1.805l-3.092-1.288c.41-1.16 1.161-2.186 2.264-3.073 1.102-.886 2.51-1.332 4.22-1.332 1.265 0 2.404.243 3.412.734 1.007.49 1.8 1.17 2.371 2.034.572.868.855 1.84.855 2.92 0 1.102-.265 2.034-.796 2.799a5.489 5.489 0 01-1.958 1.76v.184a5.944 5.944 0 012.511 1.957c.652.878.981 1.926.981 3.15s-.31 2.318-.932 3.276c-.62.959-1.48 1.715-2.569 2.264-1.093.549-2.322.828-3.685.828-1.58.005-3.038-.45-4.384-1.359z"
                    fill="#4285F4"
                  />
                  <path
                    fill="#4285F4"
                    d="M42.768 28.629L39.339 31.1085 37.6245 28.5075 43.776 24.0705 46.134 24.0705 46.134 45 42.768 45z"
                  />
                  <path fill="#34A853" d="M52.2 52.2L16.2 52.2 16.2 68.4 52.2 68.4z" />
                  <path d="M52.2 0H5.4A5.399 5.399 0 000 5.4v46.8h16.2v-36h36V0z" fill="#4285F4" />
                  <path d="M0 52.2V63c0 2.984 2.417 5.4 5.4 5.4h10.8V52.2H0z" fill="#188038" />
                  <path fill="#FBBC04" d="M68.4 16.2L52.2 16.2 52.2 52.2 68.4 52.2z" />
                  <path
                    d="M68.4 16.2V5.4C68.4 2.416 65.984 0 63 0H52.2v16.2h16.2z"
                    fill="#1967D2"
                  />
                  <path fill="#EA4335" d="M52.2 68.4L68.4 52.2 52.2 52.2z" />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.drive)}>
              <svg width="100%" height="100%" viewBox="0 0 69 62">
                <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
                  <path
                    d="M5.378 53.032l3.015 5.21c.627 1.096 1.528 1.958 2.585 2.585L21.75 42.183H.207c0 1.215.314 2.429.94 3.525l4.23 7.324z"
                    fill="#0066DA"
                    transform="translate(-1288 -535) translate(1288 535)"
                  />
                  <path
                    d="M34.4 20.25L23.63 1.607c-1.058.626-1.96 1.488-2.586 2.585L1.147 38.658a7.097 7.097 0 00-.94 3.525H21.75L34.4 20.25z"
                    fill="#00AC47"
                    transform="translate(-1288 -535) translate(1288 535)"
                  />
                  <path
                    d="M34.4 20.25L45.17 1.607C44.114.98 42.9.667 41.647.667H27.154c-1.253 0-2.467.352-3.525.94L34.4 20.25z"
                    fill="#00832D"
                    transform="translate(-1288 -535) translate(1288 535)"
                  />
                  <path
                    d="M47.05 42.183h-25.3L10.977 60.827c1.058.626 2.272.94 3.525.94h39.794c1.253 0 2.467-.353 3.525-.94L47.05 42.183z"
                    fill="#2684FC"
                    transform="translate(-1288 -535) translate(1288 535)"
                  />
                  <path
                    d="M57.822 60.827c1.057-.627 1.958-1.489 2.585-2.585l1.253-2.154 5.992-10.38c.627-1.096.94-2.31.94-3.525H47.09l10.732 18.644z"
                    fill="#EA4335"
                    transform="translate(-1288 -535) translate(1288 535)"
                  />
                  <path
                    d="M57.704 21.425L47.756 4.192c-.627-1.097-1.528-1.959-2.585-2.585L34.4 20.25l12.65 21.933h21.503a7.097 7.097 0 00-.94-3.525l-9.909-17.233z"
                    fill="#FFBA00"
                    transform="translate(-1288 -535) translate(1288 535)"
                  />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.sites)}>
              <svg width="100%" height="100%" viewBox="0 0 39 53">
                <g
                  transform="translate(-1081 -591) translate(1081 591)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  opacity={0.684031767}
                >
                  <path
                    d="M34.8 52.8H3.6A3.599 3.599 0 010 49.2V3.6C0 1.611 1.611 0 3.6 0h21.6l13.2 13.2v36c0 1.989-1.611 3.6-3.6 3.6z"
                    fill="#4758B5"
                  />
                  <path fill="#354287" d="M25.2 0L38.4 13.2 25.2 13.2z" />
                  <path fill="#FFF" d="M30 20.4L8.4 20.4 8.4 23.4 30 23.4z" />
                  <path fill="#FFF" d="M30 26.4L27 26.4 27 36.6 30 36.6z" />
                  <path fill="#FFF" d="M24 26.4L8.4 26.4 8.4 36.6 24 36.6z" />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.docs)}>
              <svg width="100%" height="100%" viewBox="0 0 71 97">
                <g
                  transform="translate(-829 -548) translate(829 548)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                >
                  <path
                    d="M63.8 96.8H6.6A6.598 6.598 0 010 90.2V6.6C0 2.954 2.954 0 6.6 0h39.6l24.2 24.2v66c0 3.647-2.954 6.6-6.6 6.6z"
                    fill="#2684FC"
                  />
                  <path fill="#0066DA" d="M46.2 0L70.4 24.2 46.2 24.2z" />
                  <path fill="#FFF" d="M55 37.4L15.4 37.4 15.4 42.9 55 42.9z" />
                  <path fill="#FFF" d="M55 50.6L15.4 50.6 15.4 56.1 55 56.1z" />
                  <path fill="#FFF" d="M44 63.8L15.4 63.8 15.4 69.3 44 69.3z" />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.meet)}>
              <svg width="100%" height="100%" viewBox="0 0 80 66">
                <g
                  transform="translate(-941 -686) translate(941 686)"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                >
                  <path
                    d="M61 45.976V22.074l2.478-5.157L75.87 7.1C77.522 5.715 80 6.879 80 9.04v46.917c0 2.163-2.533 3.328-4.186 1.941L61 45.976z"
                    fill="#00AC47"
                  />
                  <path fill="#FE2C25" d="M20 0L0 20 20 20z" />
                  <path fill="#2684FC" d="M20 20L0 20 0 46 20 46z" />
                  <path d="M0 46v13.333C0 63 3 66 6.667 66H20V46H0z" fill="#0066DA" />
                  <path
                    d="M63.5 6.59C63.5 2.966 60.564 0 56.975 0H20v20h24.5v13l19-.598V6.59z"
                    fill="#FFBA00"
                  />
                  <path
                    d="M44.5 46H20v20h36.975c3.589 0 6.525-2.961 6.525-6.58V33h-19v13z"
                    fill="#00AC47"
                  />
                  <path fill="#00832D" d="M63.5 17L63.5 48 44.5 33z" />
                </g>
              </svg>
            </div>
            <div className={clsx(style.image, style.gmail)}>
              <svg width="100%" height="100%" viewBox="0 0 106 80">
                <g
                  transform="translate(-1138 -704) translate(1138 704)"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  fillRule="evenodd"
                >
                  <path
                    d="M7.2 79.4H24V38.6l-24-18v51.6c0 3.978 3.222 7.2 7.2 7.2z"
                    fill="#4285F4"
                    fillRule="nonzero"
                  />
                  <path
                    d="M81.6 79.4h16.8c3.978 0 7.2-3.222 7.2-7.2V20.6l-24 18v40.8z"
                    fill="#34A853"
                    fillRule="nonzero"
                  />
                  <path
                    d="M81.6 7.4v31.2l24-18V11c0-8.898-10.158-13.98-17.28-8.64L81.6 7.4z"
                    fill="#FBBC04"
                    fillRule="nonzero"
                  />
                  <path fill="#EA4335" d="M24 38.6L24 7.4 52.8 29 81.6 7.4 81.6 38.6 52.8 60.2z" />
                  <path
                    d="M0 11v9.6l24 18V7.4l-6.72-5.04C10.158-2.98 0 2.102 0 11z"
                    fill="#C5221F"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <Divider containerClassName={style.laser}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1409" height="183" viewBox="0 0 1409 183">
            <defs>
              <linearGradient
                id="divider-laser-a"
                x1="15.436%"
                x2="114.319%"
                y1="90.518%"
                y2="21.413%"
              >
                <stop offset="0%" stopColor="#FFC42A" />
                <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
              </linearGradient>
              <filter
                id="divider-laser-b"
                width="104.3%"
                height="174.2%"
                x="-2.1%"
                y="-37.1%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
              </filter>
            </defs>
            <g fill="url(#divider-laser-a)" transform="translate(0 12)">
              <polygon
                points="1.155 117.506 509.459 36.651 912.705 79.695 1071.78 78.401 1403.889 44.82 923.461 117.084 715.999 68.87 498.537 50.657"
                filter="url(#divider-laser-b)"
                opacity=".8"
                transform="rotate(-3 702.522 77.079)"
              />
              <polygon points="1.155 117.506 509.459 36.651 912.705 79.695 1071.78 78.401 1403.889 44.82 923.461 117.084 715.999 68.87 498.537 50.657" />
            </g>
          </svg>
        </Divider>
      </section>
    )
  }
}

export default Hero
