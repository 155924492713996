import * as React from 'react'

function ChatProduct(props) {
  return (
    <svg width={70} height={72} viewBox="0 0 70 72" xmlns="http://www.w3.org/2000/svg">
      <title>{'542952D4-2403-4720-ADC5-147B893C7512'}</title>
      <g fillRule="nonzero" fill="none" opacity={0.153}>
        <path
          d="M16.214 38.498V15.31H6.08a5.402 5.402 0 00-5.404 5.403v47.959c0 2.409 2.91 3.611 4.612 1.909l11.15-11.144H48.19a5.402 5.402 0 005.405-5.403V43.9H21.618a5.402 5.402 0 01-5.404-5.403z"
          fill="#00AC47"
        />
        <path
          d="M63.728 0h-42.11a5.402 5.402 0 00-5.404 5.403v9.906H48.19a5.402 5.402 0 015.405 5.403v23.19h10.133a5.402 5.402 0 005.405-5.404V5.403A5.402 5.402 0 0063.728 0z"
          fill="#5BB974"
        />
        <path
          d="M48.19 15.31H16.214v23.188a5.402 5.402 0 005.404 5.403h31.977V20.712a5.402 5.402 0 00-5.405-5.403z"
          fill="#00832D"
        />
      </g>
    </svg>
  )
}

export default ChatProduct
