const SvgHardwareFailure = (props) => (
  <svg width="80px" height="56px" viewBox="0 0 80 56" {...props}>
    <defs>
      <linearGradient x1="46.1451739%" y1="50%" x2="28.4016998%" y2="50%" id="hardware-failure-a">
        <stop stopColor="#162A40" stopOpacity={0} offset="0%" />
        <stop stopColor="#FE3F4E" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="15.4356061%"
        y1="63.5393115%"
        x2="114.318971%"
        y2="40.447725%"
        id="hardware-failure-b"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient x1="100%" y1="50%" x2="-0.346921075%" y2="50%" id="hardware-failure-c">
        <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
        <stop stopColor="#FFF" stopOpacity={0.01} offset="25%" />
        <stop stopColor="#FFF" stopOpacity={0.06} offset="47%" />
        <stop stopColor="#FFF" stopOpacity={0.13} offset="68%" />
        <stop stopColor="#FFF" stopOpacity={0.23} offset="88%" />
        <stop stopColor="#FFF" stopOpacity={0.3} offset="100%" />
      </linearGradient>
      <linearGradient x1="0%" y1="49.9477944%" x2="100%" y2="49.9477944%" id="hardware-failure-d">
        <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
        <stop stopColor="#FFF" stopOpacity={0.02} offset="12%" />
        <stop stopColor="#FFF" stopOpacity={0.07} offset="24%" />
        <stop stopColor="#FFF" stopOpacity={0.15} offset="37%" />
        <stop stopColor="#FFF" stopOpacity={0.26} offset="50%" />
        <stop stopColor="#FFF" stopOpacity={0.41} offset="63%" />
        <stop stopColor="#FFF" stopOpacity={0.6} offset="76%" />
        <stop stopColor="#FFF" stopOpacity={0.81} offset="89%" />
        <stop stopColor="#FFF" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-589 -2758) translate(106 2702) translate(208 44) translate(275 12)">
        <path
          fill="#F53D52"
          d="M39.7271552 55.3559135L0.210172414 32.5147665 0.210172414 22.8884135 39.7271552 9.67361951 79.2372845 22.8884135 79.2372845 32.5147665z"
        />
        <path
          fill="url(#hardware-failure-a)"
          d="M39.7271552 55.3559135L0.210172414 32.5147665 0.210172414 22.8884135 39.7271552 9.67361951 79.2372845 22.8884135 79.2372845 32.5147665z"
        />
        <path
          fill="url(#hardware-failure-b)"
          d="M39.7271552 45.7295604L0.210172414 22.8884135 39.7271552 0.0472664835 79.2372845 22.8884135z"
        />
        <path
          fill="#FDA432"
          d="M39.7271552 45.7295604L39.7271552 55.3559135 79.2372845 32.5147665 79.2372845 22.8884135z"
        />
        <path
          fill="url(#hardware-failure-c)"
          d="M39.7271552 45.7295604L39.7271552 55.3559135 79.2372845 32.5147665 79.2372845 22.8884135z"
        />
      </g>
      <path
        d="M56 38.732v4.406a.69.69 0 01-.268.524l-13.464 7.806c-.151.083-.268 0-.268-.213v-4.4c0-.208.1-.402.268-.524l13.464-7.806c.151-.069.268.007.268.207z"
        fill="#F53853"
        transform="translate(-589 -2758) translate(106 2702) translate(208 44) translate(275 12)"
      />
      <path
        d="M56 38.732v4.406a.69.69 0 01-.268.524l-13.464 7.806c-.151.083-.268 0-.268-.213v-4.4c0-.208.1-.402.268-.524l13.464-7.806c.151-.069.268.007.268.207z"
        fill="url(#hardware-failure-d)"
        transform="translate(-589 -2758) translate(106 2702) translate(208 44) translate(275 12)"
      />
      <path
        d="M56 38.732v4.406a.69.69 0 01-.268.524l-13.464 7.806c-.151.083-.268 0-.268-.213v-.849l12.627-7.323a.69.69 0 00.274-.524v-3.551l.831-.483c.151-.069.268.007.268.207z"
        fill="#F53354"
        transform="translate(-589 -2758) translate(106 2702) translate(208 44) translate(275 12)"
      />
      <path
        d="M76.722 28.778l-17.944 10.19c-.153.083-.278 0-.278-.211v-1.02a.68.68 0 01.278-.52l17.944-10.183c.153-.089.278 0 .278.206v1.019a.68.68 0 01-.278.52z"
        fill="#F97242"
        transform="translate(-589 -2758) translate(106 2702) translate(208 44) translate(275 12)"
      />
      <path
        d="M76.722 31.778l-17.944 10.19c-.153.083-.278 0-.278-.205v-1.025a.68.68 0 01.278-.52l17.944-10.184c.153-.089.278 0 .278.206v1.019a.68.68 0 01-.278.52z"
        fill="#F43057"
        transform="translate(-589 -2758) translate(106 2702) translate(208 44) translate(275 12)"
      />
    </g>
  </svg>
)

export default SvgHardwareFailure
