import reduce from 'lodash/reduce'

import canUseDOM from 'utils/canUseDOM'

export const getCookie = () => {
  if (canUseDOM) {
    const { cookie } = document
    const cookies = cookie.split(';')

    return reduce(
      cookies,
      (prev, curr) => {
        const values = curr.replace(/\s/, '').split('=')
        return {
          ...prev,
          [values[0]]: values[1],
        }
      },
      {}
    )
  }

  return 'Unable to get cookie.'
}

export const getSpecificCookie = (key = '') => {
  const cookie = getCookie()
  return cookie && cookie[key]
}

export const getPageTitle = () => {
  if (canUseDOM) {
    const { title } = document
    return title
  }

  return 'Unable to get page title.'
}

export const getPageUrl = () => {
  if (canUseDOM) {
    const { location } = document
    return location && location.href
  }

  return 'Unable to get page url.'
}

export default getCookie
