import * as React from 'react'

function MeetProduct(props) {
  return (
    <svg width={73} height={60} viewBox="0 0 73 60" xmlns="http://www.w3.org/2000/svg">
      <title>{'B2DFBE4C-0688-4B2A-8653-4B2989BDE151'}</title>
      <g fillRule="nonzero" fill="none" opacity={0.153}>
        <path
          d="M55.86 41.69V20.166l2.232-4.645 11.161-8.84c1.488-1.248 3.72-.199 3.72 1.749v42.25c0 1.948-2.281 2.996-3.77 1.748L55.86 41.69z"
          fill="#00AC47"
        />
        <path fill="#FE2C25" d="M18.929.287L.914 18.298h18.015z" />
        <path fill="#2684FC" d="M18.929 18.298H.914v23.414h18.015z" />
        <path
          d="M.914 41.712V53.72c0 3.302 2.702 6.004 6.005 6.004h12.01v-18.01H.914z"
          fill="#0066DA"
        />
        <path
          d="M58.111 6.222c0-3.264-2.645-5.935-5.877-5.935H18.929v18.011h22.068v11.707l17.114-.539V6.222z"
          fill="#FFBA00"
        />
        <path
          d="M40.997 41.712H18.929v18.011h33.305c3.232 0 5.877-2.667 5.877-5.926V30.005H40.997v11.707z"
          fill="#00AC47"
        />
        <path fill="#00832D" d="M58.111 15.596v27.917L40.997 30.005z" />
      </g>
    </svg>
  )
}

export default MeetProduct
