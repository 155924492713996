import PropTypes from 'prop-types'

const BackgroundLaser = ({ className }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="1276" height="179" viewBox="0 0 1276 179">
      <defs>
        <linearGradient
          id="background-laser-a"
          x1="15.436%"
          x2="114.319%"
          y1="90.518%"
          y2="21.413%"
        >
          <stop offset="0%" stopColor="#FFC42A" />
          <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
        </linearGradient>
      </defs>
      <polygon
        fill="url(#background-laser-a)"
        fillRule="evenodd"
        points="-7.371 -20.773 311.976 -92.293 638.898 62.012 1260.774 203.243 626.701 95.18 318.489 -81.78"
        opacity="0.1"
        transform="rotate(-18 626.701 55.475)"
      />
    </svg>
  </div>
)

BackgroundLaser.defaultProps = {
  className: '',
}

BackgroundLaser.propTypes = {
  className: PropTypes.string,
}

export default BackgroundLaser
