import { Component } from 'react'
import { gsap, Power0 } from 'gsap'

import Layout from 'components/Layout'
import AWSPageTemplate from 'templates/Page/AWS'
import BigData from 'components/SVG/clouds/bigDataAnalytics'

class BigDataAnalyticsPage extends Component {
  componentDidMount() {
    const bigData = gsap.timeline()
    bigData.to('.circleGraph', {
      x: '-=5',
      y: '+=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 2,
    })

    bigData.to(
      '.pieGraph',
      { x: '-=10', y: '+=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 5 },
      0
    )

    bigData.to(
      '.lineGraph',
      { x: '+=5', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 7 },
      0
    )
  }

  render() {
    return (
      <Layout
        title="AWS Big Data & Analytics | AWS Consulting in Utah"
        description="Observian can help your business avoid the mistakes of large infrastructure investments with our AWS Big Data and Analytics consulting. Learn more!"
      >
        <AWSPageTemplate
          name="AWS Big Data & Analytics"
          title="AWS Big Data Solutions"
          content="
            <br />
            <p> As an AWS Big Data partner, we can help your company build and deploy scalable and secure big data analytics applications. We’ll help you provision exactly the right type and size of resources (paying only for what you need), integrate all your data sets, and create instant access to business insights driven by data. </p>
            <br />
            <p> Observian can help you build workloads to handle any volume, velocity, and variety of data. What does this mean exactly and how does it apply to big data? Big data can be explained through the “<a href='https://aws.amazon.com/big-data/what-is-big-data/'>three V’s</a>,” which are:
            <br />
            <div>
              <ul>
                <li><strong>Volume:</strong> This encompasses a range of data from terabytes to petabytes.</li>
                <li><strong>Variety:</strong> Data is included from a wide range of formats and sources such as social media, eCommerce, online transactions, and more.</li>
                <li><strong>Velocity:</strong> This is how quickly data is collected, stored, processed, and analyzed. The faster and more efficiently data is generated to the time insights are delivered to customers is critical.</li>
              </ul>
            </div>
            <br />
            <p> With numerous AWS services and features added every year at our disposal, we organize everything you need to collect, store, process, analyze, and visualize big data on the cloud. </p>
            <br />
            <h2> How Observian Uses AWS Big Data & Analytics to Help You </h2>
            <br />
            <p> Through the tools provided by AWS, we are able to help your business in a variety of ways. </p>
            <br />
            <h3> Scaling </h3>
            <br />
            <p> Scale up and down on the fly. Resize your cluster down when you have little or no workload. Scale up to add processing power when the job gets too slow. Spend just enough to cover only the cost of your job and no more. </p>
            <br />
            <h3> Support </h3>
            <br />
            <p> We support a broad spectrum of real-time big data analytic frameworks, services, and storage, regardless of their parameters. </p>
            <br />
            <h3> Savings </h3>
            <br />
            <p> With on-demand provisioning of your Big Data applications, pay only for what you use. Make money-saving decisions for the organization, based on insights derived from real data. </p>
            <br />
            <p> In addition to these methods, Observian provides features such as:
            <br />
            <div>
              <ul>
                <li>Innovative frameworks to manage and distribute big data computing</li>
                <li>Powerful analytics services to load and interpret streaming data</li>
                <li>Cloud-native, machine-learning, and map-reducing techniques</li>
                <li>Secure, highly scalable storage through a broad set of engines</li>
                <li>Fully managed, petabyte-scale data warehousing</li>
                <li>Serverless computing to streamline compute time costs</li>
              </ul>
            </div>
            <br />
            <p> Streamline your business with AWS Big Data & Analytics and Observian. With experiences in all things AWS, we can assist you in successfully collect, store, and analyze big data with the cloud. <a href='https://www.observian.com/contact/'>Speak to one of our consultants today</a> to learn how we can help! </p>"
          headerIcon={<BigData />}
          inquiryPlaceholder="Hi! So tell me more about your experience with big data..."
        />
      </Layout>
    )
  }
}

export default BigDataAnalyticsPage
