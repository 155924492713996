const googleProducts = [
  {
    name: 'Google Editors',
    description:
      "Take advantage of Google's powerful Editor tools to collaborate on any document in real time. Using Docs allows for smart-editing and robust formatting. Sheets provide powerful data tracking capabilities including, built-in formulas, pivot tables and charting capabilities. Slides help you present your data in an effortlessly beautiful way.",
    icon: '/images/logos/google/gsuite/gworkspace/Editors_Product_Icon.svg',
  },
  {
    name: 'GMail',
    description:
      'Browser based solution that provides a secure and intuitive email for businesses of any size. Gmail is deeply integrated with other Workspace applications for communicating, scheduling, taking notes and setting tasks in a single location.',
    icon: '/images/logos/google/gsuite/gworkspace/Gmail_Product_Icon_96dp.svg',
  },
  {
    name: 'Google Calendar',
    description:
      'Browser based solution that provides a secure and intuitive email for businesses of any size. Gmail is deeply integrated with other Workspace applications for communicating, scheduling, taking notes and setting tasks in a single location.',
    icon: '/images/logos/google/gsuite/gworkspace/Calendar_Product_Icon.svg',
  },
  {
    name: 'Google Meet',
    description:
      'Communicate easily through text, voice, or video chats one-on-one or in a group with Hangouts Meet. Chatting with coworkers and other business associates is simple.',
    icon: '/images/logos/google/gsuite/gworkspace/Meet_Product_Icon-1.svg',
  },
  {
    name: 'Google Drive',
    description:
      'Store all your documents, presentations, and everything else in Google Drive. This is the one-stop shop for all your projects in the cloud. A versatile cloud storage service, Google Drive keeps your most valuable work in one place.',
    icon: '/images/logos/google/gsuite/gworkspace/Drive_Product_Icon-1.svg',
  },
  {
    name: 'Google Sites',
    description:
      'Create a site just for your team. With Google Sites, you don’t need to be a programmer or designer to create an engaging site on any device. Feature reports and team updates that look great on desktop, tablet, and mobile are easily made with Google Sites.',
    icon: '/images/logos/google/gsuite/gworkspace/Sites_Product_Icon.svg',
  },
  {
    name: 'Google Admin',
    description:
      'Centrally manage all users from the Google Workspace Admin portal. Available on any device, you can make changes for your users on-the-go. Easily control groups and resources at a granular level. Access Control and Security have never been easier.',
    icon: '/images/logos/google/gsuite/gworkspace/Admin_Product_Icon-1.svg',
  },
  {
    name: 'Google Keep',
    description:
      'Keep your random thoughts, lists, and ideas together with Google Keep. Available across all your devices, keeping track of your notes is simple with Google Keep.',
    icon: '/images/logos/google/gsuite/gworkspace/Keep_Product_Icon.svg',
  },
  {
    name: 'Google Chat',
    description:
      'Communicate and collaborate easily with Google Chat. This tool gives you the ability to send an email to multiple people with only one address, create and send invites to events, or easily share a document with a group.',
    icon: '/images/logos/google/gsuite/gworkspace/Chat_Product_Icon-1.svg',
  },
]

export default googleProducts
