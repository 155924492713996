import { useEffect } from 'react'
import clsx from 'clsx'
import { gsap, Elastic } from 'gsap'
import Divider from 'observian-ui/lib/divider'

import style from './hero.module.css'
import Registration from './Registration'

const highlightsInfo = [
  {
    icon: '/images/logos/google/gsuite/drive.png',
    title: 'Google Drive Enterpise',
    features: [
      'Create and edit documents directly from Drive Enterprise(including Word and Excel files)',
      'Share files of all types and sizes with specific users',
      'Control who can Edit or View your shared files',
      'Store valuable documents in the cloud for constant access',
    ],
  },
  {
    icon: '/images/logos/google/gsuite/meet.png',
    title: 'Google Meet',
    features: [
      'Host 250 users in a single meeting',
      'Record meetings and save recordings to Drive Enterprise',
      'Automatically create call-in links',
    ],
  },
  {
    icon: '/images/logos/google/gsuite/chat.png',
    title: 'Google Chat',
    features: [
      'Host context aware conversations with team members',
      'Share documents and start meetings from a single place',
      'Quickly message individuals inside and outside your organisation',
    ],
  },
]

const Hero = () => {
  useEffect(() => {
    gsap.set(`.${style.image}`, { perspective: 400 })

    const timeout = setTimeout(() => {
      gsap.set(`.${style.image}`, { opacity: 1 })
      gsap.from(`.${style.image}`, {
        x: '-=550',
        y: '-=120',
        rotationX: -90,
        rotationY: 20,
        opacity: 0,
        ease: Elastic.easeOut.config(1, 1),
        duration: 3,
        stagger: 0.07,
      })
    }, 1900)
    return () => {
      if (timeout) {
        clearTimeout(timeout.id)
      }
    }
  }, [])

  return (
    <section className={style.hero}>
      <div className={style.heroContent}>
        <div className={style.heroLeftContent}>
          <div className={style.header}>
            <h1>G Suite Essentials</h1>
            <div className={style.subHeader}>FREE Until Sept. 30, 2020</div>
            <p className={style.description}>
              Google and Observian have partnered up to offer Drive Enterprise + Meet + Chat for
              FREE! New business and public sector customers can utilize the Google Collaboration
              tools until September 30, 2020 without ever making a payment.
            </p>
          </div>
        </div>
        <div className={style.images}>
          <img
            alt="admin"
            src="/images/logos/google/gsuite/admin.png"
            className={clsx(style.image, style.admin)}
          />
          <img
            alt="chat"
            src="/images/logos/google/gsuite/chat.png"
            className={clsx(style.image, style.chat)}
          />
          <img
            alt="docs"
            src="/images/logos/google/gsuite/docs.png"
            className={clsx(style.image, style.docs)}
          />
          <img
            alt="drive"
            src="/images/logos/google/gsuite/drive.png"
            className={clsx(style.image, style.drive)}
          />
          <img
            alt="forms"
            src="/images/logos/google/gsuite/forms60.png"
            className={clsx(style.image, style.forms)}
          />
          <img
            alt="groups"
            src="/images/logos/google/gsuite/groups40.png"
            className={clsx(style.image, style.groups)}
          />
          <img
            alt="keep"
            src="/images/logos/google/gsuite/keep30.png"
            className={clsx(style.image, style.keep)}
          />
          <img
            alt="meet"
            src="/images/logos/google/gsuite/meet.png"
            className={clsx(style.image, style.meet)}
          />
          <img
            alt="sheets"
            src="/images/logos/google/gsuite/sheets.png"
            className={clsx(style.image, style.sheets)}
          />
          <img
            alt="slides"
            src="/images/logos/google/gsuite/slides.png"
            className={clsx(style.image, style.slides)}
          />
          <img
            alt="vault"
            src="/images/logos/google/gsuite/vault35.png"
            className={clsx(style.image, style.vault)}
          />
        </div>
      </div>
      <div className={style.registration}>
        <div className={style.content}>
          <Registration className={style.regForm} />
          <div className={style.rightContent}>
            <img src="/images/MacBookMeet.png" alt="Meet" className={style.computer} />
          </div>
        </div>
      </div>
      <div className={style.parentContainer}>
        <div className={style.divderContainer}>
          <Divider className={style.divider} containerClassName={style.laser}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1477"
              height="272"
              viewBox="0 0 1477 272"
            >
              <defs>
                <linearGradient
                  id="gsuite-essentials-laser-a"
                  x1="15.436%"
                  x2="114.319%"
                  y1="50.135%"
                  y2="49.905%"
                >
                  <stop offset="0%" stopColor="#FFC42A" />
                  <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
                </linearGradient>
                <filter
                  id="gsuite-essentials-laser-b"
                  width="104.3%"
                  height="174.2%"
                  x="-2.1%"
                  y="-37.1%"
                  filterUnits="objectBoundingBox"
                >
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
                </filter>
              </defs>
              <g fill="url(#gsuite-essentials-laser-a)" transform="rotate(-2 2396.001 -903.75)">
                <polygon
                  points="2.02 117.945 510.324 37.091 913.57 80.135 1072.646 78.84 1404.754 45.26 924.326 117.523 716.864 69.31 499.403 51.096"
                  filter="url(#gsuite-essentials-laser-b)"
                  opacity=".8"
                  transform="rotate(-3 703.387 77.518)"
                />
                <polygon points="2.02 117.945 510.324 37.091 913.57 80.135 1072.646 78.84 1404.754 45.26 924.326 117.523 716.864 69.31 499.403 51.096" />
              </g>
            </svg>
          </Divider>
        </div>
        <div className={style.highlightContainer}>
          <div className={style.highlights}>
            {highlightsInfo.map((item) => (
              <div key={`highlightsInfo:${item.title}`} className={style.highlight}>
                <div className={style.highlightImage}>
                  <img src={item.icon} alt={item.title} />
                </div>
                <h3 className={style.title}>{item.title}</h3>
                <div className={style.features}>
                  <ul className={style.feature}>
                    {item.features.map((feature, index) => (
                      <li key={`feature:${index + 1}`} className={style.featureItem}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
