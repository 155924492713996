import { number } from 'prop-types'

const ManagedProductivityPlus = ({ checks, ...props }) => (
  <svg height="20px" viewBox="0 0 354 20" {...props}>
    <defs>
      <linearGradient
        x1="14.8581364%"
        y1="50.7535005%"
        x2="85.3734672%"
        y2="49.2402223%"
        id="mp-check-a"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-953 -1856) translate(27 1459) translate(927 398)"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
      fill="none"
    >
      <path
        d="M135.85 17.825v-5.5h1.325c3.825 0 6.375-2.525 6.375-6.05 0-3.45-2.4-5.95-6.45-5.95h-7.35v17.5h6.1zm.575-10.1h-.575v-2.65h.625c.75 0 1.275.55 1.275 1.325 0 .85-.625 1.325-1.325 1.325zm14.2 10.1v-5.65l2.475 5.65h6.875l-4-7.325c1.475-.825 2.775-2.475 2.775-4.5 0-3.175-2.4-5.675-6.5-5.675h-7.775v17.5h6.15zm.75-10.3h-.75v-2.45h.75c.8 0 1.25.625 1.25 1.225 0 .6-.45 1.225-1.25 1.225zM168.9 18.15c5.975 0 9.675-3.975 9.675-9.1 0-5.05-3.7-9.05-9.675-9.05-5.975 0-9.675 4-9.675 9.05 0 5.125 3.7 9.1 9.675 9.1zm0-5.225c-1.975 0-3.05-1.6-3.05-3.875 0-2.2 1.075-3.825 3.05-3.825 1.95 0 3.05 1.625 3.05 3.825 0 2.275-1.1 3.875-3.05 3.875zm16.875 4.9c5.875 0 9.775-3.375 9.775-8.775 0-5.475-3.95-8.725-9.775-8.725h-6.25v17.5h6.25zm.275-5h-.375v-7.5h.375c1.825 0 3.025 1.175 3.025 3.725s-1.2 3.775-3.025 3.775zm18.5 5.325c5.25 0 8.1-2.975 8.1-7.2V.325h-6.35V11.1c0 1.2-.7 1.825-1.75 1.825-1.025 0-1.7-.625-1.7-1.825V.325h-6.35V10.95c0 4.225 2.825 7.2 8.05 7.2zm18.475 0c2.75 0 4.525-.7 5.975-1.725l-1.875-5.025c-.8.725-1.95 1.375-3.4 1.375-1.95 0-3.5-1.45-3.5-3.725 0-2.175 1.5-3.675 3.5-3.675 1.35 0 2.425.525 3.275 1.35l1.9-5C227.5.75 225.675 0 223.025 0c-5.7 0-9.425 4.025-9.425 9.05 0 5.45 3.95 9.1 9.425 9.1zm16.55-.325V5.25h3.45V.325H229.8V5.25h3.425v12.575h6.35zm10.9 0V.325h-6.35v17.5h6.35zm13.675 0l6.35-17.5h-6.675l-2.775 10.25-2.775-10.25H251.4l6.35 17.5h6.4zm13.625 0V.325h-6.35v17.5h6.35zm10.9 0V5.25h3.45V.325H278.9V5.25h3.425v12.575h6.35zm16.2 0V10.35L310.35.325h-6.775l-1.75 4.975-1.75-4.975h-7.025l5.475 10.025v7.475h6.35z"
        fill="#04E6AE"
      />
      <path
        d="M6.175 17.825V9.4L7.9 16.5h5.6l1.725-7.1v8.425H21.4V.325h-7.95l-2.75 9.95L7.95.325H0v17.5h6.175zm22.3 0l.775-2.675h4.1l.775 2.675h6.5l-6.05-17.5h-6.25l-6.05 17.5h6.2zm3.8-7.125H30.3l1-3.6.975 3.6zm15.5 7.125V9.6l3.8 8.225H58V.325h-6.275V8.55l-3.8-8.225H41.5v17.5h6.275zm17.3 0l.775-2.675h4.1l.775 2.675h6.5l-6.05-17.5h-6.25l-6.05 17.5h6.2zm3.8-7.125H66.9l1-3.6.975 3.6zm16.9 7.45c4.475 0 6.975-1.55 8.5-2.875V7.5h-7.65v4.225H88.6v.675c-.325.2-1.025.475-2.15.475-2.15 0-3.525-1.55-3.525-3.825 0-2.175 1.5-3.675 3.5-3.675 1.4 0 2.375.525 3.225 1.275l3.525-3.8C91.75 1.375 89.475 0 85.725 0 80.075 0 76.3 4.025 76.3 9.05c0 5.175 3.6 9.1 9.475 9.1zm20.675-.325V12.9h-4.6v-1.575h4.1V6.7h-4.1V5.25h4.6V.325H95.525v17.5h10.925zm7.75 0c5.875 0 9.775-3.375 9.775-8.775 0-5.475-3.95-8.725-9.775-8.725h-6.25v17.5h6.25zm.275-5h-.375v-7.5h.375c1.825 0 3.025 1.175 3.025 3.725s-1.2 3.775-3.025 3.775z"
        stroke="url(#mp-check-a)"
        strokeWidth={0.5}
        fillOpacity={0.75}
        fill="url(#mp-check-a)"
      />
      {checks && 0 < checks && (
        <>
          <path
            fill="#04E6AE"
            d="M327.7375 15.425L327.7375 10.85 332.0125 10.85 332.0125 7.55 327.7375 7.55 327.7375 3 324.2625 3 324.2625 7.55 319.9875 7.55 319.9875 10.85 324.2625 10.85 324.2625 15.425z"
          />
          {1 < checks && (
            <path
              fill="#04E6AE"
              d="M347.7375 15.425L347.7375 10.85 352.0125 10.85 352.0125 7.55 347.7375 7.55 347.7375 3 344.2625 3 344.2625 7.55 339.9875 7.55 339.9875 10.85 344.2625 10.85 344.2625 15.425z"
            />
          )}
        </>
      )}
    </g>
  </svg>
)

ManagedProductivityPlus.propTypes = {
  checks: number,
}

ManagedProductivityPlus.defaultProps = {
  checks: 0,
}

export default ManagedProductivityPlus
