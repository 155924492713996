const SvgManagedProductivityText = (props) => (
  <svg viewBox="0 0 937 65" {...props}>
    <defs>
      <linearGradient
        id="managed-productivity-svg-c"
        x1="14.858%"
        x2="85.373%"
        y1="50.754%"
        y2="49.24%"
      >
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="managed-productivity-svg-a"
        width="104.2%"
        height="128.5%"
        x="-2.1%"
        y="-10.6%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.75}
          result="shadowSpreadOuter1"
        />
        <feOffset dy={2} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2} />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
      </filter>
      <path
        id="managed-productivity-svg-b"
        d="M18.525 53.475V28.2L23.7 49.5h16.8l5.175-21.3v25.275H64.2V.975H40.35l-8.25 29.85L23.85.975H0v52.5h18.525zm66.9 0l2.325-8.025h12.3l2.325 8.025h19.5l-18.15-52.5h-18.75l-18.15 52.5h18.6zm11.4-21.375H90.9l3-10.8 2.925 10.8zm46.5 21.375V28.8l11.4 24.675H174V.975h-18.825V25.65L143.775.975H124.5v52.5h18.825zm51.9 0l2.325-8.025h12.3l2.325 8.025h19.5l-18.15-52.5h-18.75l-18.15 52.5h18.6zm11.4-21.375H200.7l3-10.8 2.925 10.8zm50.7 22.35c13.425 0 20.925-4.65 25.5-8.625V22.5h-22.95v12.675h5.925V37.2c-.975.6-3.075 1.425-6.45 1.425-6.45 0-10.575-4.65-10.575-11.475 0-6.525 4.5-11.025 10.5-11.025 4.2 0 7.125 1.575 9.675 3.825l10.575-11.4C275.25 4.125 268.425 0 257.175 0 240.225 0 228.9 12.075 228.9 27.15c0 15.525 10.8 27.3 28.425 27.3zm62.025-.975V38.7h-13.8v-4.725h12.3V20.1h-12.3v-4.35h13.8V.975h-32.775v52.5h32.775zm23.25 0c17.625 0 29.325-10.125 29.325-26.325 0-16.425-11.85-26.175-29.325-26.175h-18.75v52.5h18.75zm.825-15H342.3v-22.5h1.125c5.475 0 9.075 3.525 9.075 11.175s-3.6 11.325-9.075 11.325z"
      />
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        fill="#04E6AE"
        d="M412.55 56.475v-16.5h3.975c11.475 0 19.125-7.575 19.125-18.15 0-10.35-7.2-17.85-19.35-17.85h-22.05v52.5h18.3zm1.725-30.3h-1.725v-7.95h1.875c2.25 0 3.825 1.65 3.825 3.975 0 2.55-1.875 3.975-3.975 3.975zm42.6 30.3v-16.95l7.425 16.95h20.625l-12-21.975c4.425-2.475 8.325-7.425 8.325-13.5 0-9.525-7.2-17.025-19.5-17.025h-23.325v52.5h18.45zm2.25-30.9h-2.25v-7.35h2.25c2.4 0 3.75 1.875 3.75 3.675 0 1.8-1.35 3.675-3.75 3.675zM511.7 57.45c17.925 0 29.025-11.925 29.025-27.3C540.725 15 529.625 3 511.7 3c-17.925 0-29.025 12-29.025 27.15 0 15.375 11.1 27.3 29.025 27.3zm0-15.675c-5.925 0-9.15-4.8-9.15-11.625 0-6.6 3.225-11.475 9.15-11.475 5.85 0 9.15 4.875 9.15 11.475 0 6.825-3.3 11.625-9.15 11.625zm50.625 14.7c17.625 0 29.325-10.125 29.325-26.325 0-16.425-11.85-26.175-29.325-26.175h-18.75v52.5h18.75zm.825-15h-1.125v-22.5h1.125c5.475 0 9.075 3.525 9.075 11.175s-3.6 11.325-9.075 11.325zm55.5 15.975c15.75 0 24.3-8.925 24.3-21.6V3.975H623.9V36.3c0 3.6-2.1 5.475-5.25 5.475-3.075 0-5.1-1.875-5.1-5.475V3.975H594.5V35.85c0 12.675 8.475 21.6 24.15 21.6zm55.425 0c8.25 0 13.575-2.1 17.925-5.175L686.375 37.2c-2.4 2.175-5.85 4.125-10.2 4.125-5.85 0-10.5-4.35-10.5-11.175 0-6.525 4.5-11.025 10.5-11.025 4.05 0 7.275 1.575 9.825 4.05l5.7-15C687.5 5.25 682.025 3 674.075 3c-17.1 0-28.275 12.075-28.275 27.15 0 16.35 11.85 27.3 28.275 27.3zm49.65-.975V18.75h10.35V3.975H694.4V18.75h10.275v37.725h19.05zm32.7 0v-52.5h-19.05v52.5h19.05zm41.025 0l19.05-52.5h-20.025l-8.325 30.75-8.325-30.75H759.2l19.05 52.5h19.2zm40.875 0v-52.5h-19.05v52.5h19.05zm32.7 0V18.75h10.35V3.975H841.7V18.75h10.275v37.725h19.05zm48.6 0V34.05L936.05 3.975h-20.325l-5.25 14.925-5.25-14.925H884.15l16.425 30.075v22.425h19.05z"
      />
      <g transform="translate(5 3)">
        <use
          fill="#000"
          filter="url(#managed-productivity-svg-a)"
          xlinkHref="#managed-productivity-svg-b"
        />
        <use
          fill="url(#managed-productivity-svg-c)"
          fillOpacity={0.55}
          stroke="url(#managed-productivity-svg-c)"
          strokeWidth={1.5}
          xlinkHref="#managed-productivity-svg-b"
        />
      </g>
    </g>
  </svg>
)

export default SvgManagedProductivityText
