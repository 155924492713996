import { Component } from 'react'
import { gsap, Elastic } from 'gsap'
import { Link } from 'gatsby'

import Cloud404 from 'components/SVG/404'
import RainDrops from 'components/SVG/rainDrops'

import style from './error404.module.css'

const browser = 'undefined' !== typeof window && window
class Error404 extends Component {
  constructor(props) {
    super(props)

    this.timeout = this.timeout.bind(this)
  }

  componentDidMount() {
    gsap.fromTo(
      `.${style.lightning}`,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: Elastic.easeOut.config(1, 0.12),
        repeat: -1,
        repeatDelay: 5,
        duration: 2,
      }
    )

    this.timeout()

    gsap.to(`.${style.rainGroup1}`, {
      y: '+=100',
      x: '-=5',
      opacity: 0,
      repeat: -1,
      duration: 1,
      stagger: 0.05,
    })
    gsap.to(`.${style.rainGroup2}`, {
      y: '+=100',
      x: '-=5',
      opacity: 0,
      repeat: -1,
      duration: 1,
      stagger: 0.05,
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  timeout() {
    const timeout = setTimeout(() => {
      gsap.set(`.${style.rainDrops}`, { display: 'inherit' })
    }, 1100)

    this.timeout = timeout
  }

  render() {
    return (
      browser && (
        <div className={style.error404}>
          <div className={style.lightning} />
          <svg
            className={style.lightningBolts}
            xmlns="http://www.w3.org/2000/svg"
            width="1846"
            height="1320"
            viewBox="0 0 1846 1320"
          >
            <g fill="none" fillRule="evenodd">
              <polygon
                fill="#FFF"
                fillOpacity="0.02"
                points="138.794 0 347 0 465.9 0 702.929 433.854 996.904 284.01 1277.926 860.309 1639.087 681.81 1697.653 1320.018 1606.727 811.598 1275.954 1024.132 963.701 506.714 609.057 604.477"
              />
              <polygon
                fill="#1D354F"
                points="347 361.107 616.651 630.758 937.109 450.103 1365.262 876.61 1634.793 590.368 1846.016 1061.689 1634.793 456.607 1327.543 717.177 915.449 225.982 666.457 442.493 347.129 0 .329 0"
              />
            </g>
          </svg>

          <RainDrops
            className={style.rainDrops}
            rainGroups={{
              rainGroup1: style.rainGroup1,
              rainGroup2: style.rainGroup2,
            }}
          />
          <Cloud404 className={style.cloud404} />

          <h4 className={style.text}>
            It&#39;s cold out here. You should head back&nbsp;
            <Link to="/">home</Link>.
          </h4>

          <svg
            className={style.bottomSVG}
            xmlns="http://www.w3.org/2000/svg"
            width="1440"
            height="96"
            viewBox="0 0 1440 96"
          >
            <polygon
              fill="#10182E"
              fillRule="evenodd"
              points="0 0 102.396 18.142 587.635 74.923 1088.696 38.838 1410.351 60.21 1440 49.348 1440 96 0 96"
            />
          </svg>
        </div>
      )
    )
  }
}

Error404.defaultProps = {}

Error404.propTypes = {}

export default Error404
