import axios from 'axios'

import { headers } from './helper'

const getIPAddress = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'https://ipinfo.io/ip',
      headers: headers({
        'Content-Type': 'application/json',
      }),
    })
    return res.data.trim()
  } catch (error) {
    return new Error(error.message || 'Unable to fetch current IP address')
  }
}

export default getIPAddress
