import axios from 'axios'

import { env, BASE_ENV_URL, headers } from './helper'

const formId =
  'prod' === env() ? '58365bc7-72a1-494a-8ace-f4a23c571d06' : 'f07883f5-f2be-42d4-837d-f777e043e9c1'

const gsuiteFormId =
  'prod' === env() ? '4f0ac2be-9d0d-4faf-8c49-1fd24db9e460' : '9064ca09-98e6-48c5-9775-f997354efdde'

const officeFormId =
  'prod' === env() ? '44a1b801-7f6a-4d3f-b328-1836533fdb80' : '44a1b801-7f6a-4d3f-b328-1836533fdb80'

const workspaceSupportFormId =
  'prod' === env() ? 'ffff9e92-c424-4b7f-a2bb-bf7197f5c2eb' : 'ffff9e92-c424-4b7f-a2bb-bf7197f5c2eb'

const landingFormId =
  'prod' === env() ? '97509d6b-f592-4a72-a15a-ac3770cd9687' : '97509d6b-f592-4a72-a15a-ac3770cd9687'

export const submitForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, formId, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create contact')
  }
}

export const submitAppDForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create contact')
  }
}

export const specialEventsForm = async (data) => {
  if (null === data.inquiry) {
    data.inquiry = 'na'
  }
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact/special-events`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create special event contact')
  }
}

export const googleWorkshopForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact/google-workshops`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create Google workshop contact')
  }
}

export const gsuiteForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact/google-hero`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, formId: gsuiteFormId, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create G Suite contact')
  }
}

export const officeForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact/office365-hero`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, formId: officeFormId, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create Office 365 contact')
  }
}

export const workspaceForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact/workspace-support`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, formId: workspaceSupportFormId, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create support ticket')
  }
}

export const submitAdLandingForm = async (data) => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/contact`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, formId: landingFormId, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create support ticket')
  }
}

export default submitForm
