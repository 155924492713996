import PropTypes from 'prop-types'

const AzureMainCloud = ({ className, laser1, laser2, laser3 }) => (
  <svg
    viewBox="0 0 1540 376"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <defs>
      <linearGradient
        x1="15.4356061%"
        y1="50.4448156%"
        x2="114.318971%"
        y2="49.686173%"
        id="linearGradient-1"
      >
        <stop stopColor="#008AD7" offset="0%" />
        <stop stopColor="#008CDA" offset="100%" />
      </linearGradient>
      <filter
        x="-2.0%"
        y="-18.9%"
        width="104.0%"
        height="137.7%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feGaussianBlur stdDeviation="10" in="SourceGraphic" />
      </filter>
      <rect x="375.889487" y="6.35299448" width="475.780044" height="262.130973" id="rect-4" />
      <linearGradient
        x1="30.3140486%"
        y1="84.9243045%"
        x2="69.8688497%"
        y2="16.3295925%"
        id="linearGradient-5"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="12.4534884%"
        y1="53.3207564%"
        x2="87.8953488%"
        y2="46.7984697%"
        id="linearGradient-6"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <filter
        x="-7.5%"
        y="-24.4%"
        width="115.0%"
        height="148.8%"
        filterUnits="objectBoundingBox"
        id="filter-7"
      >
        <feGaussianBlur stdDeviation="4" in="SourceGraphic" />
      </filter>
      <linearGradient
        x1="7.12173655%"
        y1="51.6643602%"
        x2="46.6601647%"
        y2="53.204535%"
        id="linearGradient-8"
      >
        <stop stopColor="#3065BB" offset="0%" />
        <stop stopColor="#5A99FF" offset="100%" />
      </linearGradient>
      <filter
        x="-3.6%"
        y="-14.4%"
        width="107.3%"
        height="128.8%"
        filterUnits="objectBoundingBox"
        id="filter-9"
      >
        <feGaussianBlur stdDeviation="2" in="SourceGraphic" />
      </filter>
      <linearGradient
        x1="12.4534884%"
        y1="52.1049297%"
        x2="87.8953488%"
        y2="47.9706442%"
        id="linearGradient-10"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="12.4534884%"
        y1="51.0441606%"
        x2="87.8953488%"
        y2="48.9933281%"
        id="linearGradient-11"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <filter
        x="-6.7%"
        y="-38.6%"
        width="113.4%"
        height="177.2%"
        filterUnits="objectBoundingBox"
        id="filter-12"
      >
        <feGaussianBlur stdDeviation="3" in="SourceGraphic" />
      </filter>
      <linearGradient
        x1="12.4534884%"
        y1="52.9994946%"
        x2="87.8953488%"
        y2="47.1081971%"
        id="linearGradient-13"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <filter
        x="-9.2%"
        y="-31.6%"
        width="118.5%"
        height="163.1%"
        filterUnits="objectBoundingBox"
        id="filter-14"
      >
        <feGaussianBlur stdDeviation="6" in="SourceGraphic" />
      </filter>
      <linearGradient
        x1="12.4534884%"
        y1="50.2236264%"
        x2="87.8953488%"
        y2="49.7844025%"
        id="linearGradient-15"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Azure" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-185.000000, -92.000000)">
        <g id="Group-21" transform="translate(191.000000, 111.000000)">
          <polygon
            className={laser3}
            fill="url(#linearGradient-1)"
            opacity="0.0957263765"
            filter="url(#filter-3)"
            transform="translate(764.107824, 171.536591) rotate(7.000000) translate(-764.107824, -171.536591) "
            points="218.078889 165.334414 446.704649 119.693933 677.561145 217.563462 1089.85167 135.900988 1317.51952 198.864118 1523.21565 108.690195 1258.36681 241.442618 1089.85167 153.113281 958.034046 203.040352 652.457056 251.073183 507.809742 159.206893 397.972508 142.229027 226.661971 211.390797 5 92"
          />
          <polygon
            className={laser2}
            fill="url(#linearGradient-1)"
            opacity="0.397437686"
            filter="url(#filter-3)"
            transform="translate(764.107824, 171.536591) rotate(2.000000) translate(-764.107824, -171.536591) "
            points="218.078889 165.334414 446.704649 119.693933 677.561145 217.563462 1089.85167 135.900988 1317.51952 198.864118 1523.21565 108.690195 1258.36681 241.442618 1089.85167 153.113281 958.034046 203.040352 652.457056 251.073183 507.809742 159.206893 397.972508 142.229027 226.661971 211.390797 5 92"
          />
          <polygon
            className={laser1}
            fill="url(#linearGradient-1)"
            points="218.078889 165.334414 446.704649 119.693933 677.561145 217.563462 1089.85167 165.900988 1317.51952 198.864118 1523.21565 108.690195 1258.36681 241.442618 1089.85167 183.113281 958.034046 203.040352 652.457056 251.073183 507.809742 159.206893 427.972508 132.229027 226.661971 191.390797 5 92"
          />
          <g id="AmplifiedCloud" transform="translate(158.836848, 88.173641)">
            <g id="Low-Poly-Cloud.F02.2k">
              <image
                x="375.889487"
                y="6.35299448"
                width="475.780044"
                height="262.130973"
                xlinkHref="/images/clouds/awsmain/aws-cloud.png"
              />
            </g>
            <g id="Group-8">
              <polygon
                id="Path-9"
                fill="url(#linearGradient-5)"
                points="694.780402 34.5318709 737.215658 83.7551391 715.99803 153.661254 751.205893 72.5706139 707.467612 34.5318709 653.035971 18.0845608"
              />
              <polygon
                id="Path-10-Copy-2"
                fill="url(#linearGradient-6)"
                opacity="0.273460751"
                filter="url(#filter-7)"
                transform="translate(573.238119, 179.959267) rotate(20.000000) translate(-573.238119, -179.959267) "
                points="493.440268 204.565539 593.678549 188.434853 653.035971 155.352994 613.495372 191.264166"
              />
              <polygon
                id="Path-10"
                fill="url(#linearGradient-6)"
                transform="translate(573.238119, 171.959267) rotate(24.000000) translate(-573.238119, -171.959267) "
                points="493.440268 196.565539 593.678549 180.434853 653.035971 147.352994 613.495372 183.264166"
              />
              <polygon
                id="Path-11-Copy"
                fill="url(#linearGradient-8)"
                opacity="0.408505394"
                filter="url(#filter-9)"
                transform="translate(461.702515, 34.839934) rotate(10.000000) translate(-461.702515, -34.839934) "
                points="544.021277 14 481.482024 14.5646747 379.383753 55.679868 469.645607 28.3691223 532.575838 27.8116348"
              />
              <polygon
                id="Path-11"
                fill="url(#linearGradient-10)"
                points="546.787192 28.6111085 481.411451 14.8066608 379.313179 55.9218542 469.575033 28.6111085 532.507162 40.3874183"
              />
              <polygon
                id="Path-12-Copy-2"
                fill="url(#linearGradient-11)"
                opacity="0.194824219"
                filter="url(#filter-12)"
                transform="translate(68.391837, 124.558953) rotate(-11.000000) translate(-68.391837, -124.558953) "
                points="1 136.211666 110.285304 112.906239 135.783674 119.515286"
              />
              <polygon
                id="Path-12"
                fill="url(#linearGradient-11)"
                points="1 136.211666 110.285304 112.906239 135.783674 119.515286"
              />
              <polygon
                id="Path-14-Copy"
                fill="url(#linearGradient-13)"
                opacity="0.526948475"
                filter="url(#filter-14)"
                transform="translate(1151.844550, 98.874977) rotate(-3.000000) translate(-1151.844550, -98.874977) "
                points="1054.54689 127.389266 1169.02748 98.7393928 1249.14221 70.3606878 1150.33603 111.558641"
              />
              <polygon
                id="Path-14"
                fill="url(#linearGradient-13)"
                points="1054.54689 117.389266 1169.02748 88.7393928 1249.14221 60.3606878 1150.33603 101.558641"
              />
              <polygon
                id="Path-13"
                fill="url(#linearGradient-15)"
                points="203.529962 38.0377785 259.451693 30.6753027 307.619931 39.0045653 256.008183 34.1812492"
              />
            </g>
          </g>
          <image
            id="Bitmap"
            x="668.366809"
            y="120.526635"
            width="188"
            height="125"
            xlinkHref="/images/clouds/azuremain/azurecloud@2x.png"
          />
        </g>
      </g>
    </g>
  </svg>
)

AzureMainCloud.defaultProps = {
  className: '',
  laser1: '',
  laser2: '',
  laser3: '',
}

AzureMainCloud.propTypes = {
  className: PropTypes.string,
  laser1: PropTypes.string,
  laser2: PropTypes.string,
  laser3: PropTypes.string,
}

export default AzureMainCloud
