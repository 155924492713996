import PropTypes from 'prop-types'

import SEO from 'components/SEO'
import Header from 'components/Nav/Header'
import Footer from 'components/Nav/Footer'

import '@csstools/normalize.css'
import 'observian-ui/lib/styles/global.module.css'
import 'styles/typography.css'
import './main.css'

const Layout = ({
  children,
  footerProps,
  headerProps,
  description,
  keywords,
  ogImage,
  title,
  lang,
}) => (
  <>
    <SEO
      description={description}
      lang={lang}
      title={title}
      ogImage={ogImage}
      keywords={keywords.split(',')}
    />
    <Header {...headerProps} />
    <main>{children}</main>
    <Footer {...footerProps} />
  </>
)

Layout.defaultProps = {
  children: [],
  title: '',
  description: '',
  keywords: '',
  ogImage: '/images/seo/og-image.jpg',
  headerProps: {},
  footerProps: {},
  lang: 'en',
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  ogImage: PropTypes.string,
  headerProps: PropTypes.object,
  footerProps: PropTypes.object,
  lang: PropTypes.string,
}

export default Layout
