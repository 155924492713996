import { Hero, ManagedCloud, ManagedSecurity, Sites } from 'components/Page/ManagedService'
import Layout from 'components/Layout'

const ManagedServicesPage = () => (
  <Layout
    title="Observian Managed Cloud Services"
    description="Observian offers managed cloud services in Utah. We are experts in all things cloud, including DevOps, Big Data, Security, Migration, and more."
  >
    <Hero />
    <ManagedCloud />
    <ManagedSecurity />
    <Sites />
  </Layout>
)

export default ManagedServicesPage
