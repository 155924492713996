const ParticleGroupSvg = (props) => (
  <svg width="845px" height="267px" viewBox="0 0 845 267" {...props}>
    <defs>
      <linearGradient
        x1="0%"
        y1="16.1362799%"
        x2="101.999998%"
        y2="84.5409938%"
        id="ms-particle-group-a"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient x1="0%" y1="0%" x2="101.999998%" y2="100.999999%" id="ms-particle-group-b">
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-397 -61) translate(-18 61.775) translate(415.534)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      opacity={0.255151721}
    >
      <path
        stroke="url(#ms-particle-group-a)"
        strokeWidth={3}
        fill="url(#ms-particle-group-a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="rotate(-26 835.619 47.868)"
        d="M835.618708 43.4715881L840.961087 52.2647895 830.276329 52.2647895z"
      />
      <circle fill="url(#ms-particle-group-b)" cx={3.46328125} cy={94.5092773} r={2.68994141} />
      <circle fill="url(#ms-particle-group-b)" cx={547.6625} cy={263.310059} r={2.68994141} />
      <circle fill="url(#ms-particle-group-b)" cx={287.6625} cy={3.31005859} r={2.68994141} />
    </g>
  </svg>
)

export default ParticleGroupSvg
