import { useState } from 'react'
import Modal from 'observian-ui/lib/modal'
import ContactModal from 'observian-ui/lib/modal/contact'
import { BlogPostCard } from 'observian-ui/lib/blog-post-card'
import { useQuery } from '@apollo/client'

import ContactForm from 'components/Form/Contact'
import { transformPosts } from 'utils/blogTransform'

import { GET_BLOG_POSTS } from '../Blog/queries'

import style from './blogPosts.module.css'

const BlogPosts = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  const { data: blogPosts } = useQuery(GET_BLOG_POSTS, {
    variables: {
      offset: 0,
      topicIdFilter: '8852122590',
      limit: 10,
    },
    fetchPolicy: 'cache-and-network',
  })
  const posts = blogPosts && blogPosts.allBlogPosts && blogPosts.allBlogPosts.posts
  const topics = blogPosts && blogPosts.allTopics && blogPosts.allTopics.topics
  const authors = blogPosts && blogPosts.allAuthors && blogPosts.allAuthors.authors
  const currentBlogPosts = (posts && topics && authors && transformPosts({ posts, authors })) || []

  return (
    <div className={style.container}>
      <div className={style.appdynamicsBlogs}>
        <div className={style.blogContainer}>
          <div className={style.blogContent}>
            <div className={style.header}>
              <h3 className={style.title}>Google Workspace Related Blog Posts</h3>
            </div>
            <div className={style.blogPosts}>
              {currentBlogPosts.slice(0, 2).map((post) => (
                <BlogPostCard
                  light
                  key={`blog:post:${post.postId}`}
                  post={post}
                  topics={topics}
                  avatarProps={{ forceAspectRatio: post.blogAuthor.isExternalAuthor }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {showModal ? (
        <Modal isTransparent isOpen={showModal} onClose={() => setShowModal(!showModal)}>
          <ContactModal
            showClose
            shouldCloseOnOverlayClick
            onClose={() => setShowModal(!showModal)}
            onDismiss={() => setShowModal(!showModal)}
            isOpen={showModal}
            closeButtonPosition="right"
            ariaHideApp={false}
            success={isSuccessful}
          >
            <ContactForm
              showLogo
              headerTitle="Contact Us"
              buttonLabel="Send"
              isLandingPageForm
              ctaIntentSuffix="modal-workspace-blog-posts-cta"
              inquiryPlaceholder="I'd like to learn more about migrating to Google Workspace."
              isSuccessfulCallback={(v) => {
                setIsSuccessful(v)
                if (!v) {
                  setShowModal(!showModal)
                }
              }}
            />
          </ContactModal>
        </Modal>
      ) : null}
    </div>
  )
}

export default BlogPosts
