import * as React from 'react'

function FormsProduct(props) {
  return (
    <svg width={47} height={64} viewBox="0 0 47 64" xmlns="http://www.w3.org/2000/svg">
      <title>{'99589E44-D130-44A7-A729-3DB884602798'}</title>
      <g opacity={0.153}>
        <g opacity={0.8} fillRule="nonzero" fill="none">
          <path
            d="M41.832 63.727H4.361a4.322 4.322 0 01-4.324-4.322V4.652A4.322 4.322 0 014.361.329h25.942l15.853 15.85v43.226a4.322 4.322 0 01-4.324 4.322z"
            fill="#7248B9"
          />
          <path fill="#56368A" d="M30.303.329l15.853 15.85H30.303z" />
          <path
            fill="#FFF"
            d="M36.067 24.824H18.052v3.602h18.015zM12.288 28.786a2.162 2.162 0 100-4.323 2.162 2.162 0 000 4.323zM36.067 33.469H18.052v3.602h18.015zM12.288 37.431a2.162 2.162 0 100-4.323 2.162 2.162 0 000 4.323zM36.067 42.114H18.052v3.602h18.015zM12.288 46.077a2.162 2.162 0 100-4.324 2.162 2.162 0 000 4.324z"
          />
        </g>
      </g>
    </svg>
  )
}

export default FormsProduct
