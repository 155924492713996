import PropTypes from 'prop-types'
import Card from 'observian-ui/lib/card'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { Link } from 'gatsby'

import BG from 'components/SVG/background'

import style from './partners.module.css'

const Partners = ({ partners }) => {
  const headings = sortBy(
    uniqBy(
      reduce(partners, (previous, partner) => [...previous, { ...partner.type }], []),
      'type'
    ),
    'order'
  )
  return (
    <div className={style.content}>
      <BG type="withLasers" className={style.laserBackground} preserveAspectRatio={false} />
      {map(headings, (heading, i) => (
        <div key={`heading:card:${i + 1}`} className={style.contentSections}>
          <header className={style.header}>
            <h2 className={style.title}>{heading.title}</h2>
            <span>{heading.description.description}</span>
          </header>
          <div className={style.contentContainer}>
            {map(
              partners,
              (partner, j) =>
                heading.type === partner.type.type ? (
                  <Link
                    key={`partner:card:${j + 1}`}
                    className={style.cardLink}
                    to={`/partners/${partner.page.url}`}
                  >
                    <Card className={style.card}>
                      <div
                        className={style.image}
                        style={{ backgroundImage: `url(${partner.logo.fixed.src})` }}
                      />
                      <h5 className={style.title}>{partner.name}</h5>
                      <span className={style.description}>{partner.shortDescription}</span>
                    </Card>
                  </Link>
                ) : null
              // eslint-disable-next-line function-paren-newline
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

Partners.defaultProps = {}

Partners.propTypes = {
  partners: PropTypes.array.isRequired,
}

export default Partners
