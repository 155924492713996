import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import clsx from 'clsx'
import Card from 'observian-ui/lib/card'
import { Form as FinalForm, Field } from 'react-final-form'
import omit from 'lodash/omit'
import { buildFormUrl } from '../../../utils/formRedirect'

import submitForm from 'api/form'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isEmail, isRequired } from 'utils/validation'

import style from './simpleForm.module.css'

class SimpleForm extends Component {
  state = {
    isSuccessful: false,
  }

  handleOnSubmit = async (event) => {
    const { ctaIntentSuffix } = this.props

    const [firstName, lastName = ''] = event.fullName.split(' ')
    const ctaIntent =
      0 === ctaIntentSuffix.length ? event.ctaIntent : `${event.ctaIntent}-${ctaIntentSuffix}`

    const validEvent = omit(event, 'fullName')
    const allFormData = {
      ...validEvent,
      firstName,
      lastName,
      ctaIntent,
    }

    try {
      const res = await submitForm({ ...allFormData })

      if (200 === res.status) {
        return this.renderSubmission()
      }

      return console.error(res.data.message || 'Unknown Error.')
    } catch (error) {
      return console.error(error)
    }
  }

  renderSubmission = (value = true) => {
    this.setState({
      isSuccessful: value,
    })
  }

  handleOnDismiss = () => this.renderSubmission(false)

  render() {
    const {
      props: { className, isSuccessfulMessage, ctaIntent, headerTitle },
      state: { isSuccessful },
    } = this

    return (
      <HubspotTracking>
        {({ hsContext }) => (
          <Card disabled className={clsx(style.container, className)}>
            {!isSuccessful ? (
              <FinalForm
                validateOnBlur
                onSubmit={(e) => this.handleOnSubmit({ ...e, hsContext })}
                initialValues={{ ctaIntent }}
              >
                {({ handleSubmit, pristine, submitting }) => (
                  <>
                    <h5>{headerTitle}</h5>
                    {/*<form onSubmit={handleSubmit} className={style.form}>
                      <Field name="fullName" validate={isRequired}>
                        {({ input, meta }) => (
                          <div className={style.inputText}>
                            <label htmlFor="fullName">
                              Name:
                              <input {...input} id={input.name} />
                              {meta.error && meta.touched && (
                                <span
                                  data-testid="form-fullName-error"
                                  className={style.validationError}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </label>
                          </div>
                        )}
                      </Field>
                      <Field name="email" validate={isEmail}>
                        {({ input, meta }) => (
                          <div className={style.inputText}>
                            <label htmlFor="email">
                              Email:
                              <input {...input} id={input.name} type="email" />
                              {meta.error && meta.touched && (
                                <span
                                  data-testid="form-email-error"
                                  className={style.validationError}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </label>
                          </div>
                        )}
                      </Field>
                      <Field name="company">
                        {({ input }) => (
                          <div className={style.inputText}>
                            <label htmlFor="company">
                              Company:
                              <input {...input} id={input.name} />
                            </label>
                          </div>
                        )}
                      </Field>
                      <Field name="ctaIntent" type="hidden">
                        {({ input }) => <input {...input} type="hidden" />}
                      </Field> */}
                      <Button
                        className={style.button}
                        variant="secondary"
                        label="Contact Us"
                        /* disabled={pristine || submitting} */
                        onClick={() => window.location.href = buildFormUrl([
                          {
                            key: 'CTA',
                            value: `AWS Services - Want More Information`
                          }
                        ])}
                      />
                    {/* </form> */}
                  </>
                )}
              </FinalForm>
            ) : (
              <div className={style.success} data-testid="form-success-message">
                <span>{isSuccessfulMessage}</span>
                <Button
                  variant="cancel"
                  label="Done"
                  onClick={this.handleOnDismiss}
                  className={style.successButtonCTA}
                />
              </div>
            )}
          </Card>
        )}
      </HubspotTracking>
    )
  }
}

SimpleForm.defaultProps = {
  className: '',
  isSuccessfulMessage: "Your inquiry has been sent, we'll be in touch soon!",
  ctaIntent: 'simple-form',
  ctaIntentSuffix: '',
  headerTitle: 'Want more information?',
}

SimpleForm.propTypes = {
  className: PropTypes.string,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
  headerTitle: PropTypes.string,
}

export default SimpleForm
