const SvgCheckmark = (props) => (
  <svg width="44px" height="48px" viewBox="0 0 44 48" {...props}>
    <defs>
      <linearGradient
        x1="20.4926679%"
        y1="84.9243045%"
        x2="79.7814789%"
        y2="16.3295925%"
        id="ms-check-a"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <filter
        x="-40.9%"
        y="-36.2%"
        width="181.7%"
        height="172.4%"
        filterUnits="objectBoundingBox"
        id="ms-check-b"
      >
        <feGaussianBlur stdDeviation={3.75} in="SourceGraphic" />
      </filter>
    </defs>
    <g
      transform="translate(-140 -3774) translate(138 3562) translate(10 220) matrix(-1 0 0 1 28 0) matrix(-1 0 0 1 27.533 0)"
      fill="url(#ms-check-a)"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    >
      <path
        opacity={0.561895461}
        filter="url(#ms-check-b)"
        d="M0.1875 28.9557211L13.78125 31.0576335 27.5326831 6.24698153 17.9065988 7.99360578e-15 11.8913416 26.2060547 -1.12976295e-12 19.2788168z"
      />
      <path d="M0.1875 28.9557211L13.78125 31.0576335 27.5326831 6.24698153 17.9065988 7.99360578e-15 11.8913416 26.2060547 -1.12976295e-12 19.2788168z" />
    </g>
  </svg>
)

export default SvgCheckmark
