import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Layout from 'components/Layout'
import AppDynamics from 'components/Page/AppDynamics'

const AppDynamicsPurePage = ({ data }) => {
  const {
    allEmployees: { members },
  } = data

  return (
    <Layout title="AppDynamics" description="" keywords="appdynamics">
      <AppDynamics members={members} />
    </Layout>
  )
}

AppDynamicsPurePage.defaultProps = {}

AppDynamicsPurePage.propTypes = {
  data: PropTypes.object.isRequired,
}

const GET_TEAM_MEMBERS = graphql`
  query GET_TEAM_MEMBERS {
    allEmployees: allContentfulEmployee {
      members: nodes {
        id
        company
        employeeId
        firstName
        lastName
        fullName
        title
        photos {
          profile {
            fixed(width: 100, quality: 100) {
              src
            }
          }
        }
        emails {
          primary
          alias
        }
      }
    }
  }
`

AppDynamicsPurePage.propTypes = {
  data: PropTypes.shape({
    allEmployees: PropTypes.shape({
      members: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}

const AppDynamicsPage = (props) => (
  <StaticQuery
    query={GET_TEAM_MEMBERS}
    render={(data) => <AppDynamicsPurePage data={data} {...props} />}
  />
)

export default AppDynamicsPage
