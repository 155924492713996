import { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import style from './styles.module.css'

const AWSLevelOne = () => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    // ScrollTrigger.create({
    //   trigger: `.${style.container}`,
    //   start: 'top center',
    //   end: 'bottom center',
    //   onEnter: () => {
    //     console.log('entered')
    //   },
    //   markers: true,
    // })
  }, [])
  const data = useStaticQuery(graphql`
    {
      Clouds: file(relativePath: { eq: "services/managed/aws-level1-mssp-clouds.png" }) {
        childImageSharp {
          gatsbyImageData(width: 639, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `)
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.left}>
          <h2 className={style.heading}>
            <span>The best defense is one you don&apos;t have to build.</span>
          </h2>
          <br />
          <h4> Get rigorously tested, fully managed Fortinet Security expertise from Observian.</h4>
          <p className={style.p}>
            Observian has obtained the Level 1 MSSP competency from AWS. The AWS Managed Security
            Service Provider (MSSP) distinction is given to cyber security businesses that have
            successfully met or exceeded the ten managed security service (MSS) specializations AWS
            security experts require. These ten MSS specializations protect and monitor essential
            AWS resources and are delivered to AWS customers as a 24/7 fully managed service for
            either supplementing internal security staff or outsourcing. The ten MSS specializations
            are defined by AWS security experts to benefit cloud environments of any size and span
            six security domains: vulnerability management, cloud security best practices and
            compliance, threat detection and response, network security, host and endpoint security,
            and application security.
          </p>
        </div>
        <GatsbyImage
          image={data.Clouds.childImageSharp.gatsbyImageData}
          alt=""
          className={style.clouds}
        />
      </div>
    </div>
  )
}
export default AWSLevelOne
