import Layout from 'components/Layout'
import {
  Comparison,
  CurrentCustomer,
  Hero,
  OfficeMigration,
  WhyGoogle,
} from 'components/Page/Google/GSuite'

const GSuitePage = () => (
  <Layout
    title="Google Workspace"
    description="Observian has partnered with Google to bring our customers best in class productivity and collaboration with Google.  See how these amazing tools paired with our expertise can help transform your business."
    keywords="google,google partner,gsuite,g suite,google cloud partner,gsuite products,g suite products,gsuite migration,g suite migration, workspace, google workspace, workspace migration, exchange migration"
  >
    <Hero />
    <WhyGoogle />
    <CurrentCustomer />
    <OfficeMigration />
    <Comparison />
  </Layout>
)

export default GSuitePage
