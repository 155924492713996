import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'

import BGLines from 'components/SVG/backgroundLines'

import style from './basicSEOPage.module.css'

const Hirogen = ({ description, logo, name, url }) => (
  <div className={style.basicSEOPage}>
    <BGLines className={style.lines} />
    <div className={style.seoBackground}>
      <div className={style.seoContent}>
        <header className={style.header}>
          {logo ? (
            <a rel="noopener noreferrer" href={url}>
              <img className={style.partnersLogo} src={logo} srcSet={`${logo} 2x`} alt={name} />
            </a>
          ) : null}
        </header>
        <h1 className={style.title}>{name} by Observian</h1>
        <p className={style.description}>{description}</p>
        <img
          className={style.preview}
          src="/images/tools/hirogen.png"
          alt="Hirogen App by Observian"
        />
        <div className={style.button}>
          <Button
            className={style.btn}
            variant="secondary"
            label="Mac"
            href="https://observian-web-downloads.s3-us-west-2.amazonaws.com/Hirogen-1.0.0.dmg"
          />
          <Button
            className={style.btn}
            variant="secondary"
            label="Windows"
            href="https://observian-web-downloads.s3-us-west-2.amazonaws.com/Hirogen+Setup+1.0.0.exe"
          />
          <Button
            className={style.btn}
            variant="secondary"
            label="Linux"
            href="https://observian-web-downloads.s3-us-west-2.amazonaws.com/Hirogen-1.0.0.AppImage"
          />
        </div>
        <div className={style.moreInfo}>
          <h2>More Information</h2>
          <p>
            Note: You WILL need the AWS CLI installed in order to send CLI commands.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.aws.amazon.com/cli/latest/userguide/install-cliv2.html"
            >
              AWS CLI installation guide.
            </a>
          </p>
          <div className={style.links}>
            <a
              className={style.link}
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/observian/hirogen"
            >
              GitHub Repo
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Hirogen.defaultProps = {
  name: 'Secret AWSome',
  description: '',
  logo: false,
  url: '',
}

Hirogen.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  url: PropTypes.string,
}

export default Hirogen
