const SvgHumanError = (props) => (
  <svg width="159px" height="147px" viewBox="0 0 159 147" {...props}>
    <defs>
      <linearGradient
        x1="15.4356061%"
        y1="52.5115865%"
        x2="114.318971%"
        y2="48.2280218%"
        id="human-error-a"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="14.8581364%"
        y1="50.0687355%"
        x2="85.3734672%"
        y2="49.9306919%"
        id="human-error-b"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="14.8581364%"
        y1="85.1559232%"
        x2="85.3734672%"
        y2="14.5512004%"
        id="human-error-c"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="14.8581364%"
        y1="85.1559232%"
        x2="85.3734672%"
        y2="14.5512004%"
        id="human-error-d"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="14.8581364%"
        y1="85.1559232%"
        x2="85.3734672%"
        y2="14.5512004%"
        id="human-error-e"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="18.8129082%"
        y1="85.1559232%"
        x2="81.3926313%"
        y2="14.5512004%"
        id="human-error-f"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-157 -3002) translate(106 2702) translate(0 272) translate(51 28)"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
      fill="none"
    >
      <path
        fill="#FFF"
        d="M-1.70530257e-13 43.3575H158.42249999999984V82.80000000000001H-1.70530257e-13z"
      />
      <path
        fill="url(#human-error-a)"
        d="M-1.70530257e-13 106.7325H158.42249999999984V146.175H-1.70530257e-13z"
      />
      <path
        fill="url(#human-error-b)"
        d="M-1.70530257e-13 43.3575H158.42249999999984V50.362500000000004H-1.70530257e-13z"
      />
      <path
        fill="#F5374F"
        d="M-1.70530257e-13 106.7325H158.42249999999984V113.7375H-1.70530257e-13z"
      />
      <path
        d="M47.858 65.138a3.248 3.248 0 11-3.24-3.248 3.24 3.24 0 013.24 3.248z"
        fill="url(#human-error-c)"
      />
      <path
        d="M59.58 65.138a3.248 3.248 0 11-6.495 0 3.248 3.248 0 016.495 0zM71.295 65.138a3.248 3.248 0 11-6.495 0 3.248 3.248 0 016.495 0zM83.01 65.138a3.248 3.248 0 11-6.495 0 3.248 3.248 0 016.495 0z"
        fill="url(#human-error-d)"
      />
      <path
        d="M94.725 65.138a3.247 3.247 0 11-6.495 0 3.247 3.247 0 016.495 0z"
        fill="url(#human-error-e)"
      />
      <path
        d="M106.44 65.138a3.247 3.247 0 11-6.495 0 3.247 3.247 0 016.495 0z"
        fill="url(#human-error-d)"
      />
      <path
        d="M118.155 65.138a3.248 3.248 0 11-3.24-3.248 3.24 3.24 0 013.24 3.248zM129.87 65.138a3.248 3.248 0 11-3.24-3.248 3.24 3.24 0 013.24 3.248z"
        fill="url(#human-error-c)"
      />
      <path
        d="M41.453 130.5a3.247 3.247 0 11-6.495 0 3.247 3.247 0 016.495 0zM53.167 130.5a3.247 3.247 0 11-6.494 0 3.247 3.247 0 016.494 0zM64.882 130.5a3.247 3.247 0 11-6.494 0 3.247 3.247 0 016.494 0zM76.597 130.5a3.247 3.247 0 11-6.494 0 3.247 3.247 0 016.495 0zM88.313 130.5a3.247 3.247 0 11-6.495 0 3.247 3.247 0 016.495 0zM100.028 130.5a3.248 3.248 0 11-3.24-3.248 3.24 3.24 0 013.24 3.248zM111.75 130.5a3.248 3.248 0 11-3.24-3.248 3.24 3.24 0 013.24 3.248zM123.465 130.5a3.247 3.247 0 11-6.495 0 3.247 3.247 0 016.495 0z"
        fill="#F2EED1"
      />
      <path
        d="M26.58 69.75c-4.417-2.355-4.815-1.942-4.462-3 3.51-4.5 2.25-12.157-3.345-12.157-5.476 0-6.938 8.384-2.888 12.3a.51.51 0 01-.232.84c-3.886 1.762-6.683 2.82-6.368 6.75.007.07.025.138.053.202a.75.75 0 00.66.998c.577 0 18.585.164 18.922-.3.69-.968-.15-4.47-2.34-5.633z"
        fill="url(#human-error-f)"
      />
      <path
        d="M37.432 43.358h-2.474V38.4h2.474v4.958zm0-9.908h-2.474V28.5h2.474v4.95zm0-9.9h-2.474V18.6h2.474v4.95zm0-9.9h-2.474V8.7h2.474v4.95zm0-9.9h-2.474V0h2.474v3.75zM37.432 106.733h-2.474V102.6h2.474v4.133zm0-9.083h-2.474V92.7h2.474v4.95zm0-9.9h-2.474V82.8h2.474v4.95zM123.465 43.358h-2.475V38.4h2.475v4.958zm0-9.908h-2.475V28.5h2.475v4.95zm0-9.9h-2.475V18.6h2.475v4.95zm0-9.9h-2.475V8.7h2.475v4.95zm0-9.9h-2.475V0h2.475v3.75zM123.465 106.733h-2.475V102.6h2.475v4.133zm0-9.083h-2.475V92.7h2.475v4.95zm0-9.9h-2.475V82.8h2.475v4.95z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default SvgHumanError
