import { func } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../../utils/formRedirect';

import Floaty from './Floaty'
import TechLines from './TechLinesSvg'
import ParticleGroup from './ParticleGroupSvg'
import HeroTextSvg from './HeroText'
import SvgListIcon from './ListIcon'
import style from './hero.module.css'

const HeroManagedSecurity = ({ setShowModal }) => {
  const data = useStaticQuery(graphql`
    {
      stargate: file(relativePath: { eq: "services/managed/stargate.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1104, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      fortinet: file(relativePath: { eq: "services/managed/fortinet.png" }) {
        childImageSharp {
          gatsbyImageData(width: 154, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `)

  return (
    <section className={style.hero}>
      <ParticleGroup className={style.particleGroup} />
      <Floaty className={style.floater} />
      <TechLines className={style.techLines} />
      <div className={style.heroContent}>
        <GatsbyImage
          image={data.stargate.childImageSharp.gatsbyImageData}
          className={style.stargate}
          alt="Observian Cloud Coverage"
        />
        <div className={style.left}>
          <HeroTextSvg className={style.titleSvg} />
          <h3 className={style.subTitle}>AWS Managed Security Service (AWS MSSP Level 1)</h3>
          <GatsbyImage
            className={style.fortinetimg}
            image={data.fortinet.childImageSharp.gatsbyImageData}
            alt="Fortinet CNP"
          />
          <div className={style.lists}>
            <ul className={style.heroList}>
              <li>
                <SvgListIcon /> AWS Infrastructure Vulnerability Scanning
              </li>
              <li>
                <SvgListIcon /> AWS Resource Inventory Visibility
              </li>
              <li>
                <SvgListIcon />
                AWS Security Best Practices Monitoring
              </li>
              <li>
                <SvgListIcon />
                AWS Compliance Monitoring
              </li>
              <li>
                <SvgListIcon />
                24/7 Incident Alerting and Response
              </li>
            </ul>
            <ul className={style.heroList}>
              <li>
                <SvgListIcon />
                Monitor, Triage Security Events
              </li>
              <li>
                <SvgListIcon />
                Distributed Denial of Service (DDoS) Mitigation
              </li>
              <li>
                <SvgListIcon />
                Managed Intrusion Prevention System (IPS)
              </li>
              <li>
                <SvgListIcon />
                Managed Detection and Response for AWS Endpoints
              </li>
              <li>
                <SvgListIcon />
                Managed Web Application Firewall (WAF)
              </li>
            </ul>
          </div>
          <div className={style.ctaContainer}>
            <span className={style.getStarted}>
              <Button 
                varient="primary" 
                label="Get a Quote" 
                onClick={() => window.location.href = buildFormUrl([
                  {
                    key: 'CTA',
                    value: 'AWS - Managed Security Fortinet - Get a Quote'
                  }
                ])}
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

HeroManagedSecurity.propTypes = {
  setShowModal: func.isRequired,
}

export default HeroManagedSecurity
