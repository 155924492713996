import * as React from 'react'

function AdminProduct(props) {
  return (
    <svg width={58} height={51} viewBox="0 0 58 51" xmlns="http://www.w3.org/2000/svg">
      <title>{'322DC71F-E619-4AF8-A551-A0E85AE48F10'}</title>
      <g opacity={0.153}>
        <g opacity={0.591} fillRule="nonzero" fill="none">
          <path
            d="M16.735 25.005l5.883 10.508H4.968L.75 27.978a5.724 5.724 0 01.153-5.857l4.801-7.623h16.914l-5.883 10.507z"
            fill="#2684FC"
          />
          <path
            d="M34.384 14.498l5.883 10.507L49.09 10.15l-4.189-7.224a5.9 5.9 0 00-5.108-2.92h-9.087l-8.09 14.492h11.767z"
            fill="#2684FC"
          />
          <path
            d="M22.618 14.498L30.707.005H17.524a5.899 5.899 0 00-5.107 2.92L5.704 14.499h16.914z"
            fill="#0066DA"
          />
          <path
            d="M34.384 35.513H22.618l8.457 14.456h9.07a5.899 5.899 0 005.112-2.928l4.57-7.905-9.56-14.13-5.883 10.507z"
            fill="#2684FC"
          />
          <path
            d="M31.075 50.005H16.604a5.898 5.898 0 01-5.142-2.98L4.969 35.513h17.649l8.457 14.492zM40.267 25.005L49.09 10.15l7.121 11.988a5.724 5.724 0 01.054 5.765L49.46 39.86l-9.192-14.855z"
            fill="#0066DA"
          />
        </g>
      </g>
    </svg>
  )
}

export default AdminProduct
