import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from 'observian-ui/lib/button'

import Slider from './Slider'
import style from './styles.module.css'

const CoverageCounsling = () => {
  const data = useStaticQuery(graphql`
    {
      AWSPartnerNetwork: file(relativePath: { eq: "services/managed/aws-partner-box.png" }) {
        childImageSharp {
          gatsbyImageData(width: 253, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
      backgroundImage: file(relativePath: { eq: "services/managed/consulting-partner-bg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 506, placeholder: DOMINANT_COLOR, layout: FIXED)
        }
      }
    }
  `)
  return (
    <div className={style.outerWrapper}>
      <div className={style.container}>
        <GatsbyImage
          image={data.AWSPartnerNetwork.childImageSharp.gatsbyImageData}
          alt=""
          className={style.AWSPartnerNetwork}
        />
      </div>
      <div className={style.wrapper}>
        <GatsbyImage
          image={data.backgroundImage.childImageSharp.gatsbyImageData}
          alt=""
          className={style.backgroundImage}
        />
        <div className={style.container}>
          <div className={style.headerContainer}>
            <div className={style.header}>
              <h2>Unbiased monitoring for advanced threat detection and remediation.</h2>
              <p>
                From emerging startups, small to mid-sized businesses, to multi-national
                enterprises, adding the expertise of an AWS MSSP into your organization is a
                valuable way to increase your cloud security posture and maintain it with experts
                watching 24/7. No security personnel are required although we welcome the
                opportunity to integrate and join forces with your security teams. AWS Managed
                Security operationalizes many security tools for you that include native AWS
                security services and AWS Security Competency Technology Partner tools that have
                undergone a verification process validating their tools have met the high-quality
                requirements of AWS security experts.{' '}
              </p>
              {/* <Button
                varient="primary"
                outline
                label="See How Observian's MSSP Helped a Prominent Healthcare Company"
              /> */}
            </div>
          </div>
        </div>
        <Slider />
        <div className={style.bottomDivider} />
      </div>
    </div>
  )
}
export default CoverageCounsling
