import Layout from 'components/Layout'
import { Hero, Support } from 'components/Page/Support'

const WorkspaceSupportPage = () => (
  <Layout
    title="Observian Workspace Support Form"
    description="Workspace Support Ticket"
    keywords="contact,contact us,observian, workspace"
  >
    <Hero />
    <Support />
  </Layout>
)

export default WorkspaceSupportPage
