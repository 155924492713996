import PropTypes from 'prop-types'

const Background = ({ className, type, preserveAspectRatio, moneyClassName }) => {
  switch (type) {
    case 'withLasers':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="640"
          height="2672"
          viewBox="0 0 640 2672"
          preserveAspectRatio={preserveAspectRatio ? '' : 'none'}
        >
          <defs>
            <linearGradient
              id="background_lasers-a"
              x1="15.436%"
              x2="114.319%"
              y1="90.518%"
              y2="21.413%"
            >
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity="0.95" />
            </linearGradient>
            <linearGradient
              id="background_lasers-b"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(-65)">
            <polygon fill="#F6FBFA" points="65 99.981 705 0 705 2400 65 2532" />
            <polygon
              fill="url(#background_lasers-a)"
              points="323.101 1820.101 454.031 2005.795 334.088 2337.484 424.515 2796.101 388.566 2257.628 473.101 1970.307"
              opacity="0.15"
              transform="rotate(45 398.101 2308.101)"
            />
            <polygon
              fill="url(#background_lasers-b)"
              points="101.941 2600.856 134.956 2508.783 221.645 2438.729 134.956 2528.238"
              opacity="0.15"
              transform="rotate(-15 161.793 2519.792)"
            />
            <polygon
              fill="url(#background_lasers-b)"
              fillOpacity="0.15"
              points="289.454 2355.195 474.747 2333.576 573.744 2260.171 459.687 2350.591"
              transform="rotate(-15 431.599 2307.683)"
            />
          </g>
        </svg>
      )

    case 'teal':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="1617"
          height="711"
          viewBox="0 0 1617 711"
        >
          <defs>
            <linearGradient
              id="cost_optimization_header_background-c"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#156B8E" />
              <stop offset="100%" stopColor="#02C490" />
            </linearGradient>
            <filter
              id="cost_optimization_header_background-a"
              width="108.5%"
              height="120.5%"
              x="-4.2%"
              y="-11.8%"
              filterUnits="objectBoundingBox"
            >
              <feOffset dy="-10" in="SourceAlpha" result="shadowOffsetOuter1" />
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="20" />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0.0823529412   0 0 0 0 0.454901961   0 0 0 0 0.580392157  0 0 0 0.22877038 0"
              />
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(0 48)">
            <path
              d="M0,49.9542617 L258.500703,0 C258.500703,8.6163772e-16 364.962962,24.5076092 577.88748,73.5228276 L945.012537,30.5318539 L1301.06939,73.5228276 L1537,61.7835178 L1537,535.436711 L0,634.881826 L0,49.9542617 Z"
              fill="#000"
              filter="url(#cost_optimization_header_background-a)"
            />
            <path
              d="M0,49.9542617 L258.500703,0 C258.500703,8.6163772e-16 364.962962,24.5076092 577.88748,73.5228276 L945.012537,30.5318539 L1301.06939,73.5228276 L1537,61.7835178 L1537,535.436711 L0,634.881826 L0,49.9542617 Z"
              fill="url(#cost_optimization_header_background-c)"
            />
            <g
              fill="#FFF"
              fontFamily="GeomanistBold"
              transform="translate(546 90)"
              className={moneyClassName}
            >
              <text fontSize="400" opacity="0.015">
                <tspan x="255" y="401">
                  $
                </tspan>
              </text>
              <text fontSize="250" opacity="0.03">
                <tspan x="0" y="232">
                  $
                </tspan>
              </text>
              <text fontSize="80" opacity="0.03">
                <tspan x="204" y="119">
                  $
                </tspan>
              </text>
              <text fontSize="150" opacity="0.01">
                <tspan x="144" y="458">
                  $
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      )

    default:
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="640"
          height="2193"
          viewBox="0 0 640 2193"
        >
          <defs>
            <linearGradient id="background-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <polygon
            fill="url(#background-a)"
            fillRule="evenodd"
            points="4.414 0 640 275 640 2065 0 2192.785"
            opacity="0.05"
          />
        </svg>
      )
  }
}

Background.defaultProps = {
  className: '',
  type: '',
  preserveAspectRatio: false,
  moneyClassName: '',
}

Background.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  preserveAspectRatio: PropTypes.bool,
  moneyClassName: PropTypes.string,
}

export default Background
