import { useState } from 'react'
import ContactModal from 'observian-ui/lib/modal/contact'

import Layout from 'components/Layout'
import Hero from 'components/Page/ManagedProductivity/Hero'
import SubHero from 'components/Page/ManagedProductivity/SubHero'
import PackageOptions from 'components/Page/ManagedProductivity/PackageOptions'
import GetCovered from 'components/Page/ManagedProductivity/GetCovered'
// import InfoGraphic from 'components/Page/ManagedProductivity/InfoGraphic'
import style from 'components/Page/ManagedProductivity/styles.module.css'
import ContactForm from 'components/Form/Contact'

const ManagedProductivityPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  return (
    <Layout
      title="Observian Managed Productivity - Backup and Security for your SAAS Applications"
      description="Observian offers managed productivity solutions to keep your organization up and running with managed backup and security."
      footerProps={{ darkBackground: true }}
    >
      <div className={style.wrapper}>
        <div className={style.BGMask}>
          <Hero setShowModal={setShowModal} />
          <SubHero />
          <PackageOptions setShowModal={setShowModal} />
          <GetCovered setShowModal={setShowModal} />
        </div>
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-managed-security"
            inquiryPlaceholder="I'd love to hear more about Managed Productivity and what it can do for my organization."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </Layout>
  )
}

export default ManagedProductivityPage
