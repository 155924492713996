import * as React from 'react'

function VoiceProduct(props) {
  return (
    <svg width={125} height={125} viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
      <title>{'FDF50BE6-2F8B-42D0-A988-BDD6432CAE18'}</title>
      <g fill="none" fillRule="evenodd" opacity={0.153}>
        <path d="M.1.005h124.52v124.491H.1z" />
        <path
          d="M119.425 62.25c-.142 24.886-16.388 45.972-38.906 53.48a57.383 57.383 0 01-18.159 2.93c-31.408.007-56.896-25.222-57.071-56.41v.325c0 31.33 25.552 56.734 57.071 56.734 6.35 0 12.452-1.03 18.16-2.93 22.614-7.541 38.912-28.783 38.912-53.804 0-.11-.007-.214-.007-.324z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <g fillRule="nonzero">
          <path
            d="M26.399 43.72c-1.271 7.735 1.038 17.182 6.407 26.156l6.59-3.086 4.157-7.133-6.765-9.356L26.6 42.708c-.071.338-.15.668-.201 1.012z"
            fill="#00832D"
          />
          <path
            d="M51.316 45.043l-12.53-12.56a4.643 4.643 0 00-6.57 0l-.402.363c-2.678 2.678-4.416 6.03-5.22 9.862l16.959 16.95 7.763-8.04c1.816-1.81 1.816-4.753 0-6.575z"
            fill="#34A853"
          />
          <path
            d="M62.36 42.8v15.56a2.593 2.593 0 002.594 2.594H80.52c0-5.07-1.03-10.478-4.358-13.798-3.32-3.326-8.73-4.357-13.8-4.357zM80.228 97.53c-7.757 1.27-17.167-1.012-26.163-6.406l3.03-6.724 7.204-3.352 10.384 6.315 5.545 10.167z"
            fill="#00832D"
          />
          <path
            d="M65.006 28.91c-1.517 0-2.646 1.343-2.646 2.867v11.022c10.15 0 18.16 8.008 18.16 18.155h11.025c1.524 0 2.866-1.128 2.866-2.645 0-16.236-13.165-29.398-29.405-29.398zM91.81 91.17a3.697 3.697 0 00.13-5.188L78.567 71.73a3.697 3.697 0 00-5.162.065l-9.1 9.26L80.229 97.53c4.397-.642 7.698-2.516 10.7-5.518l.882-.843zM32.806 69.87c2.452 4.045 5.695 8.13 9.359 11.813 3.677 3.683 7.854 6.983 11.9 9.434l10.24-10.063-20.752-21.397"
            fill="#34A853"
          />
        </g>
      </g>
    </svg>
  )
}

export default VoiceProduct
