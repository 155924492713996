import CaseStudy from 'observian-ui/lib/caseStudy'

import icon from '../../../../static/images/FileIcon.svg'

import style from './caseStudies.module.css'

const caseStudies = [
  {
    logo: icon,
    title: 'AWS Architecture',
    description:
      'Observian Helps Bridge Achieve a Secure, Resilient, and Cost-Optimized Cloud Infrastructure',
    url: 'https://observian-casestudies.s3.amazonaws.com/bridge-casestudy.pdf',
  },
  {
    logo: icon,
    title: 'AWS MSSP',
    description: 'Observian and Lacework help Essentia Analytics to improve Cloud Security',
    url: 'https://observian-casestudies.s3.amazonaws.com/essentia-casestudy.pdf',
  },
  {
    logo: icon,
    title: 'AWS Migration',
    description: 'Prestige saves $1 Million Annually by moving to AWS and utilizing Aurora.',
    url: 'https://observian-casestudies.s3.amazonaws.com/PrestigeAurora.pdf',
  },
]

const CaseStudies = () => (
  <section>
    <div className={style.caseStudySection}>
      <div className={style.caseStudy}>
        <div className={style.caseStudyContent}>
          <h2>CASE STUDIES</h2>
          <div className={style.caseStudies}>
            {caseStudies.map(({ logo, description: caseStudyDescription, url, title }, index) => (
              <CaseStudy
                downloadText="Download"
                key={`case:study:${index + 1}`}
                title={title}
                description={caseStudyDescription}
                url={url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CaseStudies
