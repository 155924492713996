import PropTypes from 'prop-types'

const Ribbon = ({ className, type }) => {
  switch (type) {
    case 'amazon':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="aws_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#aws_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#aws_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#aws_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
            <image width="134" height="80" x="64" y="94" xlinkHref="/images/logos/amazon_aws.png" />
          </g>
        </svg>
      )

    case 'microsoft':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="azure_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#azure_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#azure_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#azure_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
            <image
              width="140"
              height="107"
              x="61"
              y="62"
              xlinkHref="/images/logos/microsoft_azure.png"
            />
          </g>
        </svg>
      )

    case 'cisco':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="cisco_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#cisco_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#cisco_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#cisco_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
            <image width="134" height="80" x="64" y="94" xlinkHref="/images/logos/cisco.png" />
          </g>
        </svg>
      )

    case 'docker':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="docker_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#docker_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#docker_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#docker_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
            <image width="150" height="100" x="64" y="80" xlinkHref="/images/logos/docker.png" />
          </g>
        </svg>
      )

    case 'google':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="google_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#google_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#google_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#google_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
            <image
              width="136"
              height="119"
              x="63"
              y="72"
              xlinkHref="/images/logos/google_compute.png"
            />
          </g>
        </svg>
      )

    case 'security':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="security_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#security_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#security_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#security_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
            <image width="136" height="119" x="63" y="72" xlinkHref="/images/logos/security.png" />
          </g>
        </svg>
      )

    default:
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="375"
          viewBox="0 0 261 375"
        >
          <defs>
            <linearGradient id="ribbon_cert" x1="12.5%" x2="87.9%" y1="85%" y2="16.5%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <polyline
              fill="#FFFFFF"
              points="140.46 72.72 130.38 51.89 120.36 72.709 97.42 75.835 114.14 91.825 110.01 114.578 130.38 103.65 150.75 114.578 146.67 91.842 163.33 75.835 140.46 72.72"
            />
            <path
              fill="url(#ribbon_cert)"
              d="M165.29 255.855L172.48 344.67 189.31 334.969 225.59 341.339 206.96 235.653C194.55 244.712 180.48 251.629 165.29 255.855M53.79 235.653L35.16 341.339 71.44 334.969 88.27 344.67 95.46 255.855C80.27 251.629 66.2 244.713 53.79 235.653"
            />
            <path
              fill="url(#ribbon_cert)"
              d="M130.38,260.604 C118.28,260.604 106.57,258.946 95.46,255.855 L85.82,374.874 L130.38,359.156 L174.93,374.874 L165.29,255.855 C154.18,258.946 142.47,260.604 130.38,260.604"
            />
            <path
              fill="#FFFFFF"
              d="M31.38,129.601562 C31.38,74.9257943 75.6973732,30.602 130.38,30.602 C185.053865,30.602 229.38,74.9257943 229.38,129.601562 C229.38,184.278206 185.053865,228.602 130.38,228.602 C75.6973732,228.602 31.38,184.278206 31.38,129.601562 Z M130.38,260.604 C142.47,260.604 154.18,258.946 165.29,255.855 C180.48,251.629 194.55,244.712 206.96,235.653 C239.34,212.008 260.38,173.766 260.38,130.603 C260.38,58.805 202.17,0.602 130.38,0.602 C58.58,0.602 0.38,58.805 0.38,130.603 C0.38,173.766 21.42,212.008 53.79,235.653 C66.2,244.713 80.27,251.629 95.46,255.855 C106.57,258.946 118.28,260.604 130.38,260.604 Z"
            />
            <path
              fill="url(#ribbon_cert)"
              d="M180.34,216.188 C165.65,224.726 148.59,229.639 130.38,229.639 C112.16,229.639 95.1,224.726 80.41,216.188 C73.68,212.277 67.46,207.605 61.85,202.283 C57.37,198.037 53.29,193.385 49.66,188.377 C46.49,183.996 43.67,179.349 41.23,174.472 C39,170.015 37.1,165.373 35.55,160.566 C34.1,156.063 32.97,151.419 32.17,146.661 C31.41,142.123 30.96,137.481 30.83,132.756 C30.81,131.846 30.76,130.94 30.76,130.024 C30.76,75.008 75.36,30.409 130.38,30.409 C185.39,30.409 229.99,75.008 229.99,130.024 C229.99,130.94 229.95,131.846 229.92,132.756 C229.8,137.481 229.35,142.123 228.58,146.661 C227.78,151.419 226.65,156.063 225.2,160.566 C223.66,165.373 221.75,170.015 219.52,174.472 C217.09,179.349 214.26,183.996 211.09,188.377 C207.46,193.385 203.38,198.037 198.91,202.283 C193.3,207.605 187.07,212.277 180.34,216.188 Z"
            />
          </g>
        </svg>
      )
  }
}

Ribbon.defaultProps = {
  type: '',
  className: '',
}

Ribbon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
}

export default Ribbon
