import {
  Migration,
  Hero,
  PartnerBenefits,
  Sites,
  SecurityAssessment,
  BigData,
  War,
} from 'components/Page/AzureServices'
import Layout from 'components/Layout'

const AzurePage = () => (
  <Layout
    title="Azure Consulting Services"
    description="Observian offers expert Azure consulting services in Utah. We do all things cloud, including DevOps, Big Data, Security, Migration, and more."
  >
    <Hero />
    <PartnerBenefits />
    <Migration />
    <War />
    <SecurityAssessment />
    <BigData />
    <Sites />
  </Layout>
)

export default AzurePage
