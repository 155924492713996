const SvgMaliciousInsiders = (props) => (
  <svg width="113px" height="153px" viewBox="0 0 113 153" {...props}>
    <defs>
      <linearGradient
        x1="15.4356061%"
        y1="64.9885493%"
        x2="114.318971%"
        y2="39.4252566%"
        id="mal-insiders-a"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="96.8002019%"
        y1="22.9454318%"
        x2="16.8773886%"
        y2="81.0845509%"
        id="mal-insiders-b"
      >
        <stop stopColor="#1C3148" offset="0%" />
        <stop stopColor="#1C3047" offset="4.60318317%" />
        <stop stopColor="#0F172D" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="15.4356061%"
        y1="85.4005961%"
        x2="114.318971%"
        y2="25.0241193%"
        id="mal-insiders-c"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-420 -3021) translate(106 2702) translate(0 272) translate(314 47)"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
      fill="none"
    >
      <path
        d="M22.192 64.5L6.562 48.862c-8.752-8.763-8.752-22.96 0-31.725a27.165 27.165 0 0115.645-7.8 19.088 19.088 0 0116.02 5.25l7.718 6.48C49.5 24.66 54.75 26.4 61.792 26.4c11.348 0 26.745-12.157 26.903-12.285l2.31 2.902c-.667.54-16.56 13.096-29.212 13.096-8.078 0-14.108-2.078-18.413-6.346l-7.718-6.48a15.435 15.435 0 00-13.02-4.26 23.34 23.34 0 00-13.454 6.75c-7.295 7.318-7.295 19.158 0 26.475l15.63 15.615-2.626 2.633z"
        fill="url(#mal-insiders-a)"
      />
      <circle fill="url(#mal-insiders-b)" cx={57.24} cy={96.9225} r={55.2525} />
      <path
        fill="#1A2D44"
        transform="rotate(-45 18.167 57.857)"
        d="M5.35300751 45.0435148H30.980507510000002V70.6710148H5.35300751z"
      />
      <path
        d="M56.76 41.678c-30.132.252-54.497 24.617-54.75 54.75 7.087 17.91 25.404 28.746 44.513 26.333 19.11-2.413 34.158-17.461 36.57-36.57 2.413-19.11-8.423-37.427-26.333-44.514z"
        fill="#1A2D44"
      />
      <path
        fill="url(#mal-insiders-c)"
        d="M84.66 18.4125L80.985 29.1075 91.4025 23.6925 99.375 31.14 99.375 20.1675 109.9275 20.1675 101.265 11.6325 105.855 2.835 95.82 8.25 86.55 -6.39488462e-14 86.4975 10.155 76.6125 12.9525z"
      />
      <path
        fill="#E1DEB4"
        d="M20.6925 90L22.695 90 22.695 81.075 20.6175 81.075 20.6175 78.75 25.2 78.75 25.2 90 27.0525 90 27.0525 92.145 20.6925 92.145z"
      />
      <path
        d="M28.148 84.487c0-7.98 11.722-7.957 11.722 0v1.936c0 7.965-11.722 7.98-11.722 0v-1.936zm2.497 1.936a3.375 3.375 0 006.75 0v-1.936a3.375 3.375 0 00-6.75 0v1.936zM41.445 84.487c0-7.98 11.722-7.957 11.722 0v1.936c0 7.965-11.722 7.98-11.722 0v-1.936zm2.498 1.936a3.375 3.375 0 006.75 0v-1.936a3.375 3.375 0 00-6.75 0v1.936zM54.75 84.487c0-7.98 11.722-7.957 11.722 0v1.936c0 7.965-11.722 7.98-11.722 0v-1.936zm2.498 1.936a3.375 3.375 0 006.75 0v-1.936a3.375 3.375 0 00-6.75 0v1.936z"
        fill="#E1DEB4"
      />
      <path
        fill="#E1DEB4"
        d="M67.6425 90L69.645 90 69.645 81.075 67.56 81.075 67.56 78.75 72.15 78.75 72.15 90 73.995 90 73.995 92.145 67.6425 92.145z"
      />
      <path
        fill="#E1DEB4"
        d="M74.6925 90L76.695 90 76.695 81.075 74.6175 81.075 74.6175 78.75 79.2 78.75 79.2 90 81.0525 90 81.0525 92.145 74.6925 92.145z"
      />
      <path
        d="M82.14 84.487c0-7.98 11.73-7.957 11.73 0v1.936c0 7.965-11.73 7.98-11.73 0v-1.936zm2.505 1.936a3.375 3.375 0 006.75 0v-1.936a3.375 3.375 0 00-6.75 0v1.936z"
        fill="#E1DEB4"
      />
      <path
        fill="#E1DEB4"
        d="M20.6925 112.9425L22.695 112.9425 22.695 104.0025 20.6175 104.0025 20.6175 101.7075 25.2 101.7075 25.2 112.9575 27.0525 112.9575 27.0525 115.095 20.6925 115.095z"
      />
      <path
        fill="#E1DEB4"
        d="M27.75 112.9425L29.7525 112.9425 29.7525 104.0025 27.6675 104.0025 27.6675 101.7075 32.25 101.7075 32.25 112.9575 34.1025 112.9575 34.1025 115.095 27.75 115.095z"
      />
      <path
        d="M35.197 107.422c0-7.987 11.723-7.965 11.723 0v1.928c0 7.965-11.723 7.98-11.723 0v-1.927zm2.498 1.928a3.375 3.375 0 006.75 0v-1.927a3.375 3.375 0 00-6.75 0v1.927zM48.495 107.422c0-7.987 11.723-7.965 11.723 0v1.928c0 7.965-11.723 7.98-11.723 0v-1.927zM51 109.35a3.375 3.375 0 006.75 0v-1.927a3.375 3.375 0 00-6.75 0v1.927z"
        fill="#E1DEB4"
      />
      <path
        fill="#E1DEB4"
        d="M61.395 112.9425L63.3975 112.9425 63.3975 104.0025 61.3125 104.0025 61.3125 101.7075 65.895 101.7075 65.895 112.9575 67.7475 112.9575 67.7475 115.095 61.395 115.095z"
      />
      <path
        d="M68.843 107.422c0-7.987 11.722-7.965 11.722 0v1.928c0 7.965-11.722 7.98-11.722 0v-1.927zm2.504 1.928a3.375 3.375 0 006.75 0v-1.927a3.375 3.375 0 00-6.75 0v1.927zM82.14 107.422c0-7.987 11.73-7.965 11.73 0v1.928c0 7.965-11.73 7.98-11.73 0v-1.927zm2.505 1.928a3.375 3.375 0 006.75 0v-1.927a3.375 3.375 0 00-6.75 0v1.927z"
        fill="#E1DEB4"
      />
    </g>
  </svg>
)

export default SvgMaliciousInsiders
