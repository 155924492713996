const SvgListIcon = (props) => (
  <svg viewBox="0 0 70 70" {...props}>
    <defs>
      <linearGradient
        x1="12.4534884%"
        y1="84.9243045%"
        x2="87.8953488%"
        y2="16.3295925%"
        id="managed-list-icon-c"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <circle id="managed-list-icon-b" cx={88} cy={566} r={10} />
      <filter
        x="-187.5%"
        y="-142.5%"
        width="475.0%"
        height="475.0%"
        filterUnits="objectBoundingBox"
        id="managed-list-icon-a"
      >
        <feMorphology radius={3} operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dy={9} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={7.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.213937953 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g transform="translate(-53 -540)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#managed-list-icon-a)" xlinkHref="#managed-list-icon-b" />
      <circle
        stroke="#1DECB6"
        strokeWidth={3}
        fill="url(#managed-list-icon-c)"
        cx={88}
        cy={566}
        r={11.5}
      />
      <circle
        strokeOpacity={0.217759284}
        stroke="#1DECB6"
        strokeWidth={10.5}
        cx={88}
        cy={566}
        r={15.25}
      />
      <circle
        strokeOpacity={0.178498641}
        stroke="#1DECB6"
        strokeWidth={6}
        cx={88}
        cy={566}
        r={13}
      />
    </g>
  </svg>
)

export default SvgListIcon
