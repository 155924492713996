import PropTypes from 'prop-types'

const AWSMainCloud = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1540"
    height="376"
    viewBox="0 0 1540 376"
  >
    <defs>
      <linearGradient id="aws-main-cloud-a" x1="15.436%" x2="114.319%" y1="50.445%" y2="49.686%">
        <stop offset="0%" stopColor="#FFC42A" />
        <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
      </linearGradient>
      <filter
        id="aws-main-cloud-b"
        width="104%"
        height="137.7%"
        x="-2%"
        y="-18.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="aws-main-cloud-c"
        width="104%"
        height="137.7%"
        x="-2%"
        y="-18.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <rect id="aws-main-cloud-d" width="475.78" height="262.131" x="375.889" y="6.353" />
      <linearGradient id="aws-main-cloud-e" x1="30.314%" x2="69.869%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="aws-main-cloud-f" x1="12.453%" x2="87.895%" y1="53.321%" y2="46.798%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="aws-main-cloud-g"
        width="115%"
        height="148.8%"
        x="-7.5%"
        y="-24.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
      </filter>
      <linearGradient id="aws-main-cloud-h" x1="7.122%" x2="46.66%" y1="51.664%" y2="53.205%">
        <stop offset="0%" stopColor="#3065BB" />
        <stop offset="100%" stopColor="#5A99FF" />
      </linearGradient>
      <filter
        id="aws-main-cloud-i"
        width="107.3%"
        height="128.8%"
        x="-3.6%"
        y="-14.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <linearGradient id="aws-main-cloud-j" x1="12.453%" x2="87.895%" y1="52.105%" y2="47.971%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="aws-main-cloud-k" x1="12.453%" x2="87.895%" y1="51.044%" y2="48.993%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="aws-main-cloud-l"
        width="113.4%"
        height="177.2%"
        x="-6.7%"
        y="-38.6%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
      </filter>
      <linearGradient id="aws-main-cloud-m" x1="12.453%" x2="87.895%" y1="52.999%" y2="47.108%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="aws-main-cloud-n"
        width="118.5%"
        height="163.1%"
        x="-9.2%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="6" />
      </filter>
      <linearGradient id="aws-main-cloud-o" x1="12.453%" x2="87.895%" y1="50.224%" y2="49.784%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(6 19)">
      <polygon
        fill="url(#aws-main-cloud-a)"
        points="218.079 165.334 446.705 119.694 677.561 217.563 1089.852 135.901 1317.52 198.864 1523.216 108.69 1258.367 241.443 1089.852 153.113 958.034 203.04 652.457 251.073 507.81 159.207 397.973 142.229 226.662 211.391 5 92"
        filter="url(#aws-main-cloud-b)"
        opacity=".096"
        transform="rotate(7 764.108 171.537)"
      />
      <polygon
        fill="url(#aws-main-cloud-a)"
        points="218.079 165.334 446.705 119.694 677.561 217.563 1089.852 135.901 1317.52 198.864 1523.216 108.69 1258.367 241.443 1089.852 153.113 958.034 203.04 652.457 251.073 507.81 159.207 397.973 142.229 226.662 211.391 5 92"
        filter="url(#aws-main-cloud-c)"
        opacity=".397"
        transform="rotate(2 764.108 171.537)"
      />
      <polygon
        fill="url(#aws-main-cloud-a)"
        points="218.079 165.334 446.705 119.694 677.561 217.563 1089.852 165.901 1317.52 198.864 1523.216 108.69 1258.367 241.443 1089.852 183.113 958.034 203.04 652.457 251.073 507.81 159.207 427.973 132.229 226.662 191.391 5 92"
      />
      <g transform="translate(158.837 88.174)">
        <image
          width="475.78"
          height="262.131"
          x="375.889"
          y="6.353"
          xlinkHref="/images/clouds/awsmain/aws-cloud.png"
        />
        <g>
          <polygon
            fill="url(#aws-main-cloud-e)"
            points="694.78 34.532 737.216 83.755 715.998 153.661 751.206 72.571 707.468 34.532 653.036 18.085"
          />
          <polygon
            fill="url(#aws-main-cloud-f)"
            points="493.44 204.566 593.679 188.435 653.036 155.353 613.495 191.264"
            filter="url(#aws-main-cloud-g)"
            opacity=".273"
            transform="rotate(20 573.238 179.96)"
          />
          <polygon
            fill="url(#aws-main-cloud-f)"
            points="493.44 196.566 593.679 180.435 653.036 147.353 613.495 183.264"
            transform="rotate(24 573.238 171.96)"
          />
          <polygon
            fill="url(#aws-main-cloud-h)"
            points="544.021 14 481.482 14.565 379.384 55.68 469.646 28.369 532.576 27.812"
            filter="url(#aws-main-cloud-i)"
            opacity=".409"
            transform="rotate(10 461.703 34.84)"
          />
          <polygon
            fill="url(#aws-main-cloud-j)"
            points="546.787 28.611 481.411 14.807 379.313 55.922 469.575 28.611 532.507 40.387"
          />
          <polygon
            fill="url(#aws-main-cloud-k)"
            points="1 136.212 110.285 112.906 135.784 119.515"
            filter="url(#aws-main-cloud-l)"
            opacity=".195"
            transform="rotate(-11 68.392 124.559)"
          />
          <polygon
            fill="url(#aws-main-cloud-k)"
            points="1 136.212 110.285 112.906 135.784 119.515"
          />
          <polygon
            fill="url(#aws-main-cloud-m)"
            points="1054.547 127.389 1169.027 98.739 1249.142 70.361 1150.336 111.559"
            filter="url(#aws-main-cloud-n)"
            opacity=".527"
            transform="rotate(-3 1151.845 98.875)"
          />
          <polygon
            fill="url(#aws-main-cloud-m)"
            points="1054.547 117.389 1169.027 88.739 1249.142 60.361 1150.336 101.559"
          />
          <polygon
            fill="url(#aws-main-cloud-o)"
            points="203.53 38.038 259.452 30.675 307.62 39.005 256.008 34.181"
          />
        </g>
      </g>
      <image
        width="115.3"
        height="69"
        x="713.367"
        y="154.527"
        xlinkHref="/images/clouds/awsmain/aws-cloud-logo.png"
      />
    </g>
  </svg>
)

AWSMainCloud.defaultProps = {
  className: '',
}

AWSMainCloud.propTypes = {
  className: PropTypes.string,
}

export default AWSMainCloud
