import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Layout from 'components/Layout'
import { Hero, JoinTeam, Team, WorkHard } from 'components/Page/About'

const PureAboutPage = ({ data }) => {
  const {
    allEmployees: { members },
  } = data

  return (
    <Layout
      title="About the Team of Experts | Cloud Solutions"
      description="Observian has the best people to help you with all your cloud solutions in Salt Lake City, Utah and nationwide. Meet the team that can help you Do Cloud Better."
      keywords="about,partners,team,your team,your professionals"
    >
      <div
        style={{
          background: 'linear-gradient(-233deg, #0F172D, #1C3148)',
        }}
      >
        <Hero />
        <WorkHard />
        <Team members={members} />
        <JoinTeam />
      </div>
    </Layout>
  )
}

PureAboutPage.defaultProps = {}

PureAboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const AboutPage = (props) => (
  <StaticQuery
    query={graphql`
      query EmployeeQuery {
        allEmployees: allContentfulEmployee {
          members: nodes {
            id
            employeeId
            firstName
            lastName
            fullName
            isEmployee
            bio {
              bio
            }
            title
            emails {
              primary
              alias
            }
            phoneNumbers {
              cell
            }
            certifications {
              amazon
              docker
              google
              microsoft
              security
            }
            photos {
              about {
                fixed(width: 384, quality: 100) {
                  src
                }
              }
              aboutHover {
                fixed(width: 384, quality: 100) {
                  src
                }
              }
              profile {
                file {
                  fileName
                  url
                }
              }
            }
            socialLinks {
              facebook
              github
              instagram
              linkedin
              twitter
            }
          }
        }
      }
    `}
    render={(data) => <PureAboutPage data={data} {...props} />}
  />
)

export default AboutPage
