import CaseStudy from 'observian-ui/lib/caseStudy'

import icon from '../../../../static/images/FileIcon.svg'

import style from './resources.module.css'

const caseStudies = [
  {
    logo: icon,
    title: 'Monitoring in the Cloud',
    description: 'Datadog eBook on understanding modern infrastructure monitoring.',
    url: 'https://observian-casestudies.s3.amazonaws.com/DataDog/Monitoring+in+the+Cloud+ebook.pdf',
  },
  {
    logo: icon,
    title: 'Monitoring Consolidation',
    description: 'Unify disparate monitoring initiatives and gain context.',
    url: 'https://observian-casestudies.s3.amazonaws.com/DataDog/Datadog+Solutions+Brief+-+Monitoring+Consolidation.pdf',
  },
  {
    logo: icon,
    title: 'Modern Monitoring & Analytics',
    description: 'Datadog datasheet and wide overview.',
    url: 'https://observian-casestudies.s3.amazonaws.com/DataDog/DD_datasheet_2020.pdf',
  },
]

const Resources = () => (
  <section className={style.resources}>
    <div className={style.caseStudySection}>
      <div className={style.caseStudy}>
        <div className={style.caseStudyContent}>
          <h2>Downloadable eBooks and Resources</h2>
          <div className={style.caseStudies}>
            {caseStudies.map(({ logo, description: caseStudyDescription, url, title }, index) => (
              <CaseStudy
                downloadText="Download"
                key={`case:study:${index + 1}`}
                title={title}
                description={caseStudyDescription}
                url={url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Resources
