const SvgPowerOutage = (props) => (
  <svg width="79px" height="79px" viewBox="0 0 79 79" {...props}>
    <defs>
      <linearGradient
        x1="96.8002019%"
        y1="22.9454318%"
        x2="16.8773886%"
        y2="81.0845509%"
        id="power-outage-a"
      >
        <stop stopColor="#1C3148" offset="0%" />
        <stop stopColor="#1C3047" offset="4.60318317%" />
        <stop stopColor="#0F172D" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="34.1773188%"
        y1="90.5184659%"
        x2="79.4435532%"
        y2="21.4133523%"
        id="power-outage-b"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="14.8581364%"
        y1="85.1559232%"
        x2="85.3734672%"
        y2="14.5512004%"
        id="power-outage-c"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-797 -2746) translate(106 2702) translate(208 44) translate(483)"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
      fill="none"
    >
      <circle fill="url(#power-outage-a)" cx={39.2} cy={39.2} r={38.5} />
      <circle fill="url(#power-outage-a)" cx={39.2} cy={39.2} r={31.5} />
      <path
        d="M9.8 39.2c0-17.045 13.54-30.918 30.45-31.473-.35-.012-.698-.027-1.05-.027-17.397 0-31.5 14.103-31.5 31.5s14.103 31.5 31.5 31.5c.352 0 .7-.015 1.05-.027C23.34 70.118 9.8 56.245 9.8 39.2z"
        fill="#FFF"
        opacity={0.0451329848}
      />
      <path
        fill="url(#power-outage-b)"
        d="M38.9137 13.335L51.4094 13.335 37.2001 36.4441 56.7 36.4441 29.9859 65.065 40.5069 43.2684 21.7 43.2684z"
      />
      <path
        d="M39.2 0C17.585 0 0 17.585 0 39.2c0 21.615 17.585 39.2 39.2 39.2 21.615 0 39.2-17.585 39.2-39.2C78.4 17.585 60.815 0 39.2 0zm0 77C18.357 77 1.4 60.043 1.4 39.2c0-20.843 16.957-37.8 37.8-37.8C60.042 1.4 77 18.357 77 39.2 77 60.043 60.042 77 39.2 77z"
        fill="url(#power-outage-c)"
        opacity={0.197847254}
      />
    </g>
  </svg>
)

export default SvgPowerOutage
