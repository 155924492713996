const handleBlogPostAuthors = ({ email }) => {
  if ('erik.landsness@dynatrace.com' === email) {
    return { title: 'Sales Engineer', company: 'Dynatrace' }
  }

  if ('ronald.pipkins@alertlogic.com' === email) {
    return { title: 'Sales Engineer', company: 'AlertLogic' }
  }

  if ('matt.meier@appdynamics.com' === email) {
    return { title: 'Solution Engineering Team Lead', company: 'AppDynamics' }
  }

  if ('steffen.campbell@appdynamics.com' === email) {
    return { title: 'Marketing Manager', company: 'AppDynamics' }
  }

  return { title: '', company: '' }
}

export const transformTopics = (topics) => topics.reduce((result, topic) => [...result, topic], [])

export const transformAuthors = ({ members = [], authors = [] }) =>
  authors.reduce((result, author) => {
    const formattedAuthor = members.find(
      (member) => author.email === member.emails.primary || author.email === member.emails.alias
    )

    if (formattedAuthor) {
      return [
        ...result,
        {
          ...author,
          title: `${formattedAuthor.title} @ ${formattedAuthor.company}`,
          avatar: formattedAuthor.photos.profile.fixed.src,
          isExternalAuthor: false,
        },
      ]
    }

    const { title, company } = handleBlogPostAuthors({
      email: author.email,
    })

    return [
      ...result,
      {
        ...author,
        title: `${title} @ ${company}`,
        isExternalAuthor: true,
      },
    ]
  }, [])

export const transformPosts = ({ posts = [], authors = [], members = [] }) =>
  posts.reduce((result, post) => {
    const formattedAuthors = transformAuthors({ authors, members }).find(
      (author) => author.authorId === post.blogAuthor.authorId
    )

    if (formattedAuthors) {
      return [
        ...result,
        {
          ...post,
          blogAuthor: {
            ...post.blogAuthor,
            ...formattedAuthors,
          },
        },
      ]
    }

    return [...result]
  }, [])

export const filterBlogPostsByTopic = ({ posts = [], topics = [], topicName = '' }) => {
  const selectedTopicIds = topics.reduce(
    (previous, topic) => (topic.name.toLowerCase() === topicName.toLowerCase() ? topic : previous),
    {}
  )

  const selectedWebinars = posts.reduce(
    (previous, post) =>
      post.topicIds.includes(selectedTopicIds.topicId) ? [...previous, post] : previous,
    []
  )

  return selectedWebinars
}
