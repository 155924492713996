import Logo from 'observian-ui/lib/logo'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import style from './SubHero.module.css'

const SubHero = () => {
  const data = useStaticQuery(graphql`
    {
      proofpoint: file(relativePath: { eq: "services/managed/proofpoint.png" }) {
        childImageSharp {
          gatsbyImageData(width: 227, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      datto: file(relativePath: { eq: "services/managed/datto.png" }) {
        childImageSharp {
          gatsbyImageData(width: 125, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `)

  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <div className={style.left}>
          <Logo
            colorLogo
            showText
            className={style.logoContainer}
            classNames={{ logo: style.logo, text: style.text }}
          />
          <p>Admin level support of managed systems</p>
          <p>Monthly IT State Review</p>
          <p>
            Quarterly Security Audit of managed systems to identify areas in need of additional
            security measures
          </p>
          <p>Discounted Professional Services hourly rate for SaaS related projects</p>
        </div>
        <div className={style.right}>
          <div className={clsx(style.box, style.datto)}>
            <GatsbyImage image={data.datto.childImageSharp.gatsbyImageData} alot="datto" />
            <ul>
              <li>Protection from Malicious and Accidental data deletion</li>
              <li>Data Restore</li>
              <li>Unlimited Storage Per User</li>
              <li>SOC 2 Type II, HIPAA and GDPR compliant</li>
            </ul>
          </div>
          <div className={clsx(style.box, style.proofpoint)}>
            <GatsbyImage image={data.proofpoint.childImageSharp.gatsbyImageData} alot="datto" />
            <ul>
              <li>Advanced Spam Filtering</li>
              <li>Data Loss Prevention and Content Filtering</li>
              <li>Automated End-to-End Email Encryption</li>
              <li>Security Awareness Testing and Training</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubHero
