import PropTypes from 'prop-types'

import AngledCloudImage from '../../images/home/observian-cloud-angled.png'
import MainCloudImage from '../../images/home/observian-cloud.png'

const cloud = ({ className, imageClassName, type, withLasers = false }) => {
  switch (type) {
    case 'angled':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="657"
          height="438"
          viewBox="0 0 657 438"
        >
          <defs>
            <linearGradient id="angled_cloud-a" x1="12.5%" x2="88%" y1="85%" y2="16%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="angled_cloud-b"
              width="115.3%"
              height="139.5%"
              x="-7.6%"
              y="-19.7%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="angled_cloud-c"
              width="141.4%"
              height="206%"
              x="-20.7%"
              y="-53%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="angled_cloud-d"
              width="185.5%"
              height="178.7%"
              x="-42.7%"
              y="-39.4%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(-81 -224)">
            <image width="657" height="429" x="80" y="235" xlinkHref={AngledCloudImage} />
            <polygon
              fill="url(#angled_cloud-a)"
              points="190.337 477.19 346.309 515.266 545.917 426.676 321.21 539.174 199.843 487.083 176.793 477.19 153.631 387.19"
              filter="url(#angled_cloud-b)"
              transform="rotate(5 349.774 463.182)"
            />
            <polygon
              fill="url(#angled_cloud-a)"
              points="190.337 477.19 346.309 515.266 545.917 426.676 321.21 539.174 199.843 487.083 176.793 477.19 153.631 387.19"
            />
            <polygon
              fill="url(#angled_cloud-a)"
              points="498.217 321.548 588.802 333.184 643.045 378.178 570.631 340.074"
              filter="url(#angled_cloud-c)"
              transform="rotate(6 570.631 349.863)"
            />
            <polygon
              fill="url(#angled_cloud-a)"
              points="498.217 321.548 588.802 333.184 643.045 378.178 570.631 340.074"
            />
            <polygon
              fill="url(#angled_cloud-a)"
              points="232.295 326.512 202.538 270.193 272.748 250.277 264.488 270.925 217.729 276.28"
              filter="url(#angled_cloud-d)"
              transform="rotate(-7 237.643 288.394)"
            />
            <polygon
              fill="url(#angled_cloud-a)"
              points="232.273 326.877 202.516 270.559 266.77 249.911 256.678 270.559 217.707 276.646"
            />
          </g>
        </svg>
      )

    case 'outline':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="1196"
          height="600"
          viewBox="0 0 1196 600"
        >
          <defs>
            <linearGradient id="cloud-outline-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="cloud-outline-b"
              width="128.7%"
              height="123%"
              x="-14.3%"
              y="-11.5%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud-outline-c"
              width="117.1%"
              height="156.3%"
              x="-8.6%"
              y="-28.1%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud-outline-d"
              width="148%"
              height="131.4%"
              x="-24%"
              y="-15.7%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="none" transform="translate(1)">
            <g stroke="url(#cloud-outline-a)" opacity="0.315">
              <g transform="translate(562 14)">
                <polygon
                  fill="#FFF"
                  fillOpacity="0.08"
                  points="53.93 13.047 36.391 14.047 .375 91.145 36.391 177.98 154.934 164.527 174.473 71.742 154.934 2.648 109.156 .227"
                />
                <polyline points="36.113 178.43 79.4 64.711 153.676 163.695" />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.2"
                  points="1.043 90.711 79.703 64.055 40.873 14"
                />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.2"
                  points="155.957 4.785 79.137 64.441 173.605 70.262"
                />
                <path d="M174.402344,72.4570312 L227.892578,100.566406" />
                <polyline points="141.52 182.324 153.754 164.758 172.08 188.727" />
                <path d="M227.695312,101.070312 L153.886719,165.667969" />
                <polyline points="154.988 3.282 227.375 100.451 196.557 173.545" />
                <path d="M203.173828 159.150391L209.544922 165.84375M208.34668 148.958008L213.513672 160.625977" />
              </g>
              <g fill="#FFF" fillOpacity="0.199" transform="translate(281)">
                <polyline points="306.883 .641 283.41 101 79.648 130.629" />
                <path d="M287.429688 120.386719L271.726562 221.472656M21.4140625 319.183594L.14453125 246.738281" />
                <polyline points="335.878 25.607 306.531 .572 111.056 105.631 79.565 129.757 .345 247.441" />
                <path d="M80.1552734,130.595703 L204.821289,265.067383" />
              </g>
              <g fill="#FFF" fillOpacity="0.2" transform="translate(0 342)">
                <path d="M85.015625 72.2919922C85.015625 72.2919922 70.6910807 68.3245443 42.0419922 60.3896484L5.2421875 85.0273438M41.2753906 60.2695312L.9765625 41.4052734M41.4365234 60.59375L61.0380859 4.80664062" />
                <polyline points="109.609 .857 60.87 4.275 .365 39.738 4.854 85.065 71.717 113.445 103.196 114.745" />
                <polyline points="100.354 107.521 91.622 113.861 6.607 85.522" />
              </g>
              <g fill="#FFF" fillOpacity="0.2" transform="translate(68 326)">
                <polyline points="42.468 147.039 .832 44.514 42.468 16.439" />
                <polyline points="1.12 44.873 30.418 78.193 42.166 146.458 101.505 123.672" />
                <polyline points="89.458 96.164 30.557 78.386 100.24 .249" />
                <polyline points="42.482 146.476 113.232 165.12 135.732 166.973 116.492 153.628 91.236 102.529 77.961 25.903" />
              </g>
              <g fill="#FFF" fillOpacity="0.2" transform="translate(110 263)">
                <polyline points="58.349 63.688 49.42 164.896 119.535 248.9 210.854 210.83 228.73 259.079" />
                <path d="M58.1767578,63.8232422 L101.567383,33.8613281" />
                <polyline points="132.097 13.121 154.334 15.335 166.397 61.025" />
                <path d="M154.463867,15.7011719 L178.084961,6.93164062" />
                <polyline points="49.358 165.826 95.89 132.057 90.159 110.327" />
                <path d="M211.129883 210.645508L182.574219 205.71875M160.040039 198.117188L50.1484375 165.388672M154.641602 15.5400391L102.180664 66.0722656M102.62793 66.1787109L134.72168 73.140625M102.369141 66.1748047L58.8076172 63.9404297M102.353516 66.5810547L102.353516 94.7333984" />
                <polyline points="177.47 5.149 148.274 .186 100.836 34.122 66.416 34.122 .561 79.691" />
                <polyline points="94.338 230.924 119.953 249.373 228.259 259.372 279.229 222.638 322.651 230.924" />
              </g>
              <g fill="#FFF" fillOpacity="0.2" transform="translate(200 315)">
                <polygon points="109.613 140.451 85.692 158.346 10.883 101.356 .807 58.901 12.757 42.877 32.656 25.576 44.559 20.557 118.624 .777 74.953 88.384" />
                <polyline points="80.155 96.983 28 108.64 109.036 140.051" />
                <path d="M85.5507812,157.72168 L28.1044922,108.932617" />
                <polyline points="11.48 101.695 27.739 108.571 .719 59.24 14.229 48.065 56.621 24.665 28.67 107.099" />
                <path d="M57.4677734 24.6494141L105.716797 24.6494141M56.9326172 23.9121094L119.289062.844726562" />
              </g>
              <g transform="translate(274 247)">
                <polyline
                  fill="#FFF"
                  fillOpacity="0.2"
                  points="115.595 238.934 47.791 227.233 35.108 208.918"
                />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.2"
                  points="47.726 226.708 53.439 170.105 157.203 246.405 208.459 154.04 252.922 194.968 256.187 207.465 159.708 246.656"
                />
                <path
                  fill="#FFF"
                  fillOpacity="0.2"
                  d="M53.2314453,169.291992 L0.93359375,156.739258"
                />
                <polygon
                  fill="#FFF"
                  fillOpacity="0.2"
                  points="54.418 169.506 118.629 91.621 197.82 142.763 208.203 154.271"
                />
                <polyline points="208.606 154.274 225.594 149.351 252.986 194.636" />
                <polyline points="226.008 149.012 204.771 122.778 208.232 153.94" />
                <polyline points="197.905 142.768 215.319 60.391 197.905 47.81 213.304 51.88 215.319 58.841" />
                <polyline points="196.964 48.565 118.779 91.329 47.18 64.574 44.498 69.947" />
                <polyline points="47.076 64.779 139.203 41.119 196.49 47.489" />
                <polyline points="197.228 46.723 221.783 .609 215.091 59.028" />
              </g>
              <g fill="#FFF" fillOpacity="0.22" transform="translate(478 185)">
                <polyline points="18.362 60.599 93.685 27.218 170.634 .07 235.062 12.629 277.831 22.682 290.525 36.047 299.145 38.808 338.485 79.271 361.755 118.64 350.938 123.819 358.325 162.809 344.772 197.239 334.524 214.365 336.947 248.636 354.561 263.431 363.459 272.507 356.843 287.018 318.707 301.627" />
                <polyline points="353.84 263.43 355.84 287.332 183.982 306.256 201.703 345.496" />
                <polyline points="133.447 327.416 42.104 304.467 25.576 281.129" />
                <path d="M184.179688,306.042969 L42.4296875,304.535156" />
                <polyline points="182.887 307.459 341.324 165.197 344.627 197.057" />
                <polyline points="358.584 164.23 341.68 164.23 164.559 177.344 183.941 306.98" />
                <polyline points=".713 184.586 164.988 177.703 160.115 43.264 171.066 .313" />
                <polyline points="299.766 39.168 160.434 44.047 18.254 61.092" />
                <path d="M159.654297,44.5839844 L1.09375,184.988281" />
                <polyline points="159.811 44.707 340.725 164.32 338.047 79.045" />
                <polyline points="133.212 327.782 201.734 345.497 319.799 301.17" />
              </g>
              <g transform="translate(739 164)">
                <polyline points=".021 38.424 52.15 1.346 38.924 38.424 173.389 4.232 149.766 59.17 101.877 139.404" />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.1"
                  points="28.795 56.172 39.313 38.621 149.361 60.137 234.209 103.029 173.27 4.24"
                />
                <path d="M238.796875 60.6445312L234.753906 104.712891M39.9921875 39.8378906L76.9121094 100.107422M39.8808594 39.2402344L17.3105469 43.859375" />
                <polyline points="53.229 .739 173.134 3.377 238.495 60.382 241.996 105.456 234.365 103.317" />
              </g>
              <g transform="translate(813 270)">
                <polyline
                  fill="#FFF"
                  fillOpacity="0.16"
                  points="27.308 33.423 48.879 22.063 125.917 .465 243.945 .465"
                />
                <polygon
                  fill="#FFF"
                  fillOpacity="0.307"
                  points="243.121 .947 378.07 76.604 350.826 68.785"
                />
                <polygon
                  fill="#FFF"
                  fillOpacity="0.483"
                  points="201.373 87.941 351.123 69.863 348.529 115.529 291.354 90.574 176.664 92.696"
                />
                <path
                  fill="#FFF"
                  fillOpacity="0.16"
                  d="M242.394531,1.26367188 L202.65625,87.1855469"
                />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.23"
                  points=".416 129.922 29.686 119.67 24.414 78.736"
                />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.068"
                  points="30.887 119.572 136.805 99.748 65.475 128.506 52.08 178.582 1.676 164.057"
                />
                <path
                  fill="#FFF"
                  fillOpacity="0.23"
                  d="M30.7324219,120.994141 L59.3222656,146.466797"
                />
                <polyline points="52.293 178.479 77.77 215.273 104.633 203.641" />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.114"
                  points="28.271 187.314 38.379 195.279 76.955 215.125 225.76 226.639 245.072 215.125 128.125 215.125 226.76 226.617"
                />
                <path d="M380.651367,77.8525391 L361.553711,122.46582" />
              </g>
              <g transform="translate(865 360)">
                <polyline
                  fill="#FFF"
                  fillOpacity="0.068"
                  points="157.582 37.514 103.186 3.193 84.557 10.074"
                />
                <path d="M103.628906,3.6328125 L125.832031,2.6328125" />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.087"
                  points="193.486 125.525 223.098 125.525 310.342 62.412 206.801 90.557 158.121 37.264 73.486 100.145 76.559 125.525"
                />
                <path d="M76.7304688,124.244141 L1.43164062,89.9628906" />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.114"
                  points=".207 88.055 74.221 100.299 12.908 38.768"
                />
                <polyline points="74.6 99.982 207.158 90.408 222.861 125.563 302.543 90.408 311.396 62.365 320.615 38.074 294.678 25.523" />
                <polygon
                  fill="#FFF"
                  fillOpacity="0.114"
                  points="310.197 61.941 158.002 37.691 237.338 .486"
                />
              </g>
              <g transform="translate(442 475)">
                <polyline points=".824 15.76 81.563 69.809 79.011 35.398 21.924 7.245" />
                <path d="M82.4257812 69.9433594L169.057617 37.4990234M79.5263672 35.5244141L127.483398 27.2470703M81.2617188 15.8984375L79.1533203 34.8964844" />
                <polyline
                  fill="#FFF"
                  fillOpacity="0.13"
                  points="356.376 12.288 398.828 50.641 476.768 36.261 384.609 .578"
                />
                <polyline points="460.205 12.147 476.446 35.918 484.857 14.469" />
                <polyline points="399.26 51.331 516.328 49.762 476.205 36.784 511.532 15.865" />
                <path
                  fill="#FFF"
                  fillOpacity="0.13"
                  d="M517.009766,49.9003906 L546.945312,18.3476562"
                />
              </g>
            </g>
            {withLasers ? (
              <g>
                <polygon
                  fill="url(#cloud-outline-a)"
                  points="443.27 103.982 233.847 135.768 267.269 364.885 260.304 167.842 356.867 135.768 362.949 124.451"
                  filter="url(#cloud-outline-b)"
                  opacity="0.39"
                  transform="rotate(-12 338.559 234.434)"
                />
                <polygon
                  fill="url(#cloud-outline-a)"
                  fillOpacity="0.8"
                  points="403.707 99.824 233.41 139.926 266.832 369.043 259.867 172 356.43 139.926 362.512 128.609"
                  opacity="0.8"
                />
                <polygon
                  fill="url(#cloud-outline-a)"
                  points="339.169 497.23 439.868 568.066 689.442 461.452 445.754 554.523 380.481 484.171"
                  filter="url(#cloud-outline-c)"
                  opacity="0.493"
                  transform="rotate(-7 514.305 514.759)"
                />
                <polygon
                  fill="url(#cloud-outline-a)"
                  fillOpacity="0.8"
                  points="346.629 517.844 439.84 567.609 689.414 460.996 445.727 554.066 379.234 493.641"
                  opacity="0.8"
                />
                <polygon
                  fill="url(#cloud-outline-a)"
                  fillOpacity="0.8"
                  points="628.504 325.641 737.962 196.252 753.441 134.738 749.435 201.708"
                  opacity="0.8"
                />
                <polygon
                  fill="url(#cloud-outline-a)"
                  fillOpacity="0.8"
                  points="626.635 325.909 736.094 196.52 751.573 135.007 747.566 201.977"
                  filter="url(#cloud-outline-d)"
                  opacity="0.8"
                  transform="rotate(6 689.104 230.458)"
                />
              </g>
            ) : null}
          </g>
        </svg>
      )

    default:
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="1593"
          height="402"
          viewBox="0 0 1593 402"
        >
          <defs>
            <linearGradient id="cloud_laser-a" x1="0%" x2="102%" y1="0%" y2="101%">
              <stop offset="0%" stopColor="#237BAE" />
              <stop offset="100%" stopColor="#00FFA2" stopOpacity="0.95" />
            </linearGradient>
            <filter
              id="cloud_laser-b"
              width="126.5%"
              height="242%"
              x="-13.3%"
              y="-71%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <linearGradient id="cloud_laser-c" x1="0%" x2="102%" y1="0%" y2="101%">
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity="0.95" />
            </linearGradient>
            <filter
              id="cloud_laser-d"
              width="104%"
              height="134.9%"
              x="-2%"
              y="-17.4%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud_laser-e"
              width="104%"
              height="134.9%"
              x="-2%"
              y="-17.4%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud_laser-f"
              width="137.8%"
              height="263.8%"
              x="-18.9%"
              y="-81.9%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud_laser-g"
              width="120.1%"
              height="230.5%"
              y="-65.3%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud_laser-h"
              width="120.1%"
              height="170.2%"
              y="-35.1%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="cloud_laser-i"
              width="133.5%"
              height="278.5%"
              x="-16.8%"
              y="-89.3%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <linearGradient id="cloud_laser-j" x1="0%" x2="73.95%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#237BAE" />
              <stop offset="100%" stopColor="#00FFA2" stopOpacity="0.95" />
            </linearGradient>
            <filter
              id="cloud_laser-k"
              width="166.7%"
              height="331.5%"
              x="-33.3%"
              y="-115.8%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="8.29" />
            </filter>
            <filter
              id="cloud_laser-l"
              width="133.1%"
              height="144.4%"
              x="-16.6%"
              y="-22.2%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <linearGradient id="cloud_laser-m" x1="0%" x2="60.209%" y1="0%" y2="41.143%">
              <stop offset="0%" stopColor="#237BAE" />
              <stop offset="100%" stopColor="#00FFA2" stopOpacity="0.95" />
            </linearGradient>
            <filter
              id="cloud_laser-n"
              width="219%"
              height="152.3%"
              x="-59.5%"
              y="-26.2%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(0 -10)">
            <path
              fill="#FFF"
              d="M0,181 C168.200596,162.134567 251.040146,153.548488 248.882621,155.326963 C248.42374,155.705223 463.869472,198.883253 467.936281,202.922495 C472.003089,206.961737 639.845483,195.453599 640.858814,194.960132 C641.25852,194.765485 871.916902,162.484907 872.831792,163.139802 C873.746683,163.794696 1207.37624,195.252892 1204.52664,196.418391 C1201.67703,197.58389 1356.40807,171.63968 1357.62117,173.056319 C1359.27424,174.98676 1437.59143,184.942152 1593,202.922495 L1593,414 L0,414 L0,181 Z"
            />
            <g transform="translate(48.5)">
              <polygon
                fill="url(#cloud_laser-a)"
                points="1171.742 182.828 1219.164 173.988 1280.879 140.57 1397.957 168.777 1280.879 148.738 1219.164 182.828"
              />
              <polygon
                fill="url(#cloud_laser-a)"
                points="1171.742 192.828 1219.164 183.988 1280.879 150.57 1397.957 178.777 1280.879 158.738 1219.164 192.828"
                filter="url(#cloud_laser-b)"
                transform="rotate(8 1284.85 171.7)"
              />
              <polygon
                fill="url(#cloud_laser-c)"
                points="5.465 160.063 258.633 103 482.848 220.336 740.941 208.758 863.695 246.148 1091.5 181.414 1253.711 149.777 1489.902 199.797 1253.711 160.063 1091.5 190.98 863.695 275.145 747.684 220.336 388.797 239.613 258.633 112.5"
                filter="url(#cloud_laser-d)"
                opacity="0.512"
                transform="rotate(3 747.684 189.072)"
              />
              <polygon
                fill="url(#cloud_laser-c)"
                points="5.465 160.063 258.633 103 482.848 220.336 740.941 208.758 863.695 246.148 1091.5 181.414 1253.711 149.777 1489.902 199.797 1253.711 160.063 1091.5 190.98 863.695 275.145 747.684 220.336 388.797 239.613 258.633 112.5"
                filter="url(#cloud_laser-e)"
                opacity="0.15"
                transform="rotate(8 747.684 189.072)"
              />
              <polygon
                fill="url(#cloud_laser-c)"
                points="5.465 160.063 258.633 103 482.848 220.336 740.941 208.758 863.695 246.148 1091.5 181.414 1253.711 149.777 1489.902 199.797 1253.711 160.063 1091.5 190.98 863.695 275.145 747.684 220.336 388.797 239.613 258.633 112.5"
              />
              <polygon
                fill="url(#cloud_laser-a)"
                points="46.367 173.797 180.043 137.176 204.941 146.844"
              />
              <polygon
                fill="url(#cloud_laser-a)"
                points="509.762 191.59 713.984 154.508 763.309 165.832 808.637 200.469 758.77 170.309 669.434 170.309"
              />
              <polygon
                fill="url(#cloud_laser-a)"
                points="822.508 270.012 1025.852 184.559 1121.738 201.422 1032.227 196.016 966.66 214.898"
              />
              <polygon
                fill="url(#cloud_laser-a)"
                points="295.32 194.711 361.332 205.191 474.164 217.973 443.367 228.324 361.332 211.518"
              />
              <g fill="url(#cloud_laser-a)" transform="translate(34 132)">
                <polygon
                  points="2.367 51.797 136.043 15.176 160.941 24.844"
                  filter="url(#cloud_laser-f)"
                  transform="rotate(-11 81.654 33.486)"
                />
                <polygon
                  points="465.762 69.59 669.984 32.508 719.309 43.832 764.637 78.469 714.77 48.309 625.434 48.309"
                  filter="url(#cloud_laser-g)"
                  transform="rotate(-11 615.2 55.488)"
                />
                <polygon
                  points="778.508 148.012 981.852 62.559 1077.738 79.422 988.227 74.016 922.66 92.898"
                  filter="url(#cloud_laser-h)"
                  transform="rotate(-11 928.123 105.285)"
                />
                <polygon
                  points="251.32 92.711 317.332 103.191 430.164 115.973 399.367 126.324 317.332 109.518"
                  filter="url(#cloud_laser-i)"
                  opacity="0.6"
                  transform="rotate(-11 340.742 109.518)"
                />
              </g>
            </g>
            <g transform="translate(424.5 51)">
              <g className={imageClassName}>
                <image width="692" height="362" x="0" y="0" xlinkHref={MainCloudImage} />
                <polygon
                  fill="url(#cloud_laser-j)"
                  points="62.203 208.719 97.605 223.223 136.82 223.223 97.605 230.203"
                />
                <polygon
                  fill="url(#cloud_laser-j)"
                  points="62.203 208.719 97.605 223.223 136.82 223.223 97.605 230.203"
                  filter="url(#cloud_laser-k)"
                  opacity="0.673"
                  transform="rotate(-11 99.512 219.46)"
                />
                <polygon
                  fill="url(#cloud_laser-a)"
                  points="386.802 248.67 498.767 229.84 555.113 169.35 544.101 113.501 568.041 181.086 487.505 242.803"
                />
                <polygon
                  fill="url(#cloud_laser-a)"
                  points="376.802 248.67 488.767 229.84 545.113 169.35 534.101 113.501 558.041 181.086 477.505 242.803"
                  filter="url(#cloud_laser-l)"
                  opacity="0.434"
                />
                <polygon
                  fill="url(#cloud_laser-m)"
                  points="207.098 146.398 206.651 140.408 200.977 64.258 251.406 31.727 251.406 38.23 212.605 64.258"
                  filter="url(#cloud_laser-n)"
                />
                <polygon
                  fill="url(#cloud_laser-m)"
                  points="209.098 136.398 208.651 130.408 202.977 54.258 253.406 21.727 296.296 18.135 275.818 29.097 253.406 28.23 214.605 54.258"
                />
              </g>
            </g>
          </g>
        </svg>
      )
  }
}

cloud.defaultProps = {
  type: '',
  className: '',
  imageClassName: '',
  withLasers: false,
}

cloud.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  withLasers: PropTypes.bool,
}

export default cloud
