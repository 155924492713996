import * as React from 'react'

function GmailProduct(props) {
  return (
    <svg width={96} height={72} viewBox="0 0 96 72" xmlns="http://www.w3.org/2000/svg">
      <title>{'D084DF2A-E2BD-4476-AB03-E175A45184EF'}</title>
      <g fill="none" fillRule="evenodd" opacity={0.153}>
        <path
          d="M6.846 72H21.98V35.258L.36 19.048v46.468A6.483 6.483 0 006.846 72z"
          fill="#4285F4"
          fillRule="nonzero"
        />
        <path
          d="M73.862 72h15.133a6.483 6.483 0 006.485-6.484V19.048l-21.618 16.21V72z"
          fill="#34A853"
          fillRule="nonzero"
        />
        <path
          d="M73.862 7.161v28.097l21.618-16.21v-8.645c0-8.013-9.15-12.59-15.565-7.78L73.862 7.16z"
          fill="#FBBC04"
          fillRule="nonzero"
        />
        <path fill="#EA4335" d="M21.979 35.258V7.16L47.92 26.613 73.862 7.16v28.097L47.92 54.71z" />
        <path
          d="M.361 10.403v8.645l21.618 16.21V7.16l-6.053-4.539C9.51-2.187.36 2.39.36 10.402z"
          fill="#C5221F"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default GmailProduct
