import { useState } from 'react'
import { buildFormUrl } from '../../../utils/formRedirect'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'

import ContactForm from 'components/Form/Contact'
import ObservianInTheCloud from 'components/SVG/observianInTheCloud'

import style from './war.module.css'

const War = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  return (
    <section className={style.section}>
      <div className={style.left}>
        <ObservianInTheCloud className={style.inTheCloud} />
      </div>
      <div className={style.right}>
        <header className={style.header}>
          <h2>AWS Well Architected Review (WAR)</h2>
          <p>
            Observian provides deep AWS knowledge and hands-on experience building, migrating, and
            optimizing cloud architectures. During a Well Architected Review, Observian utilizes the
            Well Architected Framework to assess a customer workload and provide remediation
            guidance that conforms to AWS best practices.
          </p>
          <Button 
            label="Schedule a Well Architected Review" 
            onClick={() => window.location.href = buildFormUrl([
              {
                key: 'CTA',
                value: 'AWS - Schedule a Well Architected Review'
              }
            ])}
          />
        </header>
        {showModal ? (
          <ContactModal
            showClose
            shouldCloseOnOverlayClick
            onClose={() => setShowModal(false)}
            onDismiss={() => setShowModal(false)}
            isOpen={showModal}
            closeButtonPosition="right"
            ariaHideApp={false}
            success={isSuccessful}
          >
            <ContactForm
              showLogo
              headerTitle="Contact Us"
              buttonLabel="Send"
              ctaIntentSuffix="modal-aws-war"
              inquiryPlaceholder="I'd love to have Observian do a Well Architected Review."
              isSuccessfulCallback={(v) => {
                setIsSuccessful(v)
                if (!v) {
                  setShowModal(false)
                }
              }}
            />
          </ContactModal>
        ) : null}
      </div>
    </section>
  )
}

export default War
