import Button from 'observian-ui/lib/button'

import style from './googleCloudPartner.module.css'

const GoogleCloudPartner = () => (
  <div className={style.content}>
    <h2 className={style.title}>What Does Observian Provide as a Google Cloud Partner?</h2>
    <p className={style.description}>
      As a G Suite partner, we provide technical support for G Suite products and administration at
      <svg xmlns="http://www.w3.org/2000/svg" width="86" height="15" viewBox="0 0 74 15">
        <text
          fill="#1D354F"
          fillOpacity="0.9"
          fontFamily="GeomanistBold, Geomanist"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="-0.94" y="14">
            NO COST
          </tspan>
        </text>
      </svg>
      to you. With our built-in G Suite migration, switching from Office 365 is simple, and
      Observian is here to help with every step along the way. Our goal is to make your use of
      Google products as easy and seamless as possible.
    </p>
    <h3 className={style.title}>Start Your G Suite Migration Today!</h3>
    <p className={style.description}>
      Get the most from G Suite and Observian all in one. We’re happy to help you get started.
    </p>
    <Button label="Schedule a Conversation" className={style.button} />
  </div>
)

export default GoogleCloudPartner
