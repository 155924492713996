import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Layout from 'components/Layout'
import { Hero, Partners } from 'components/Page/Partners'

const PurePartnersPage = ({ data }) => (
  <Layout
    title="Cloud Software Partners | Your Partners In The Cloud"
    description="Observian partners with the best cloud software companies to provide application performance, cost optimization, security, cloud orchestration, and other cloud solutions."
    keywords="partners,alertlogic,alert logic,appdynamics,app dynamics,cloudability,cloudcheckr,cloud checkr,cloud checker,cloudendure,cloud endure,cylance,netapp,net app,splunk,sumologic,sumo logic"
  >
    <Hero />
    <Partners partners={data.allPartners.partners} />
  </Layout>
)

PurePartnersPage.defaultProps = {}

PurePartnersPage.propTypes = {
  data: PropTypes.object.isRequired,
}
const partnersQuery = graphql`
  query PartnersQuery {
    allPartners: allContentfulPartners(sort: { order: ASC, fields: order }) {
      partners: nodes {
        page {
          url
        }
        shortDescription
        type {
          title
          type
          description {
            description
          }
          order
        }
        description {
          raw
        }
        logo {
          fixed(quality: 100, height: 80) {
            src
          }
        }
      }
    }
  }
`
const PartnersPage = (props) => (
  <StaticQuery
    query={partnersQuery}
    render={(data) => <PurePartnersPage data={data} {...props} />}
  />
)

export default PartnersPage
