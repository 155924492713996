import { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import clsx from 'clsx'
import ContactModal from 'observian-ui/lib/modal/contact'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import style from './partnerBenefits.module.css'

const PartnerBenefits = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "services/microsoft/msftpartner.png" }) {
        childImageSharp {
          gatsbyImageData(width: 529, height: 389, layout: CONSTRAINED)
        }
      }
    }
  `)
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <section className={style.section}>
      <div className={style.left}>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          className={style.partnerImage}
          alt=""
        />
      </div>
      <div className={style.right}>
        <header className={style.header}>
          <h2 className={style.title}>Azure Partner Benefits</h2>
          <h5 className={style.description}>Why should you use a certified Azure partner?</h5>
        </header>
        <TextUnderline />
        <div className={style.lists}>
          <ul className={clsx(style.list, style.firstList)}>
            <li>Expert Azure Support</li>
            <li>Proof of Concept Credits</li>
            <li>Custom Azure Workshops</li>
          </ul>
          <ul className={clsx(style.list, style.secondList)}>
            <li>Architecture & Security Assessments</li>
            <li>Customer Events</li>
            <li>Free Tooling & Software Products</li>
          </ul>
        </div>
        <p className={style.text}>
          Whether you are new to the cloud or a seasoned cloud enterprise, we’re confident we can
          provide the resources you need to improve your cloud experience with Azure. With a focus
          on Digital Transformation, our goal is to help you develop repeatable and automated cloud
          solutions while educating and supporting your teams needs. We will keep you up to date on
          the Microsoft and Partner Software ecosystem so you can stay on the cutting edge of
          development and cloud operations. We can help you <b>Do Cloud Better</b>.
        </p>
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-aws-partners-benefits"
            inquiryPlaceholder="I'd like to schedule an Azure discovery call for my business."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default PartnerBenefits
