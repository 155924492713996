import * as React from 'react'

const SvgSoftwareFailure = (props) => (
  <svg width="95px" height="75px" viewBox="0 0 95 75" {...props}>
    <defs>
      <linearGradient
        x1="15.4356061%"
        y1="53.836773%"
        x2="114.318971%"
        y2="47.2930743%"
        id="software-failure-a"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="96.8002019%"
        y1="22.9467244%"
        x2="16.8773886%"
        y2="81.0830658%"
        id="software-failure-b"
      >
        <stop stopColor="#1C3148" offset="0%" />
        <stop stopColor="#1C3047" offset="4.60318317%" />
        <stop stopColor="#0F172D" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="14.8581364%"
        y1="85.1559232%"
        x2="85.3734672%"
        y2="14.5512004%"
        id="software-failure-c"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="93.6020411%"
        y1="22.9454318%"
        x2="19.1408711%"
        y2="81.0845509%"
        id="software-failure-d"
      >
        <stop stopColor="#1C3148" offset="0%" />
        <stop stopColor="#1C3047" offset="4.60318317%" />
        <stop stopColor="#0F172D" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="15.4356061%"
        y1="88.5670425%"
        x2="114.318971%"
        y2="22.7901234%"
        id="software-failure-e"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="15.4356061%"
        y1="88.8849011%"
        x2="114.318971%"
        y2="22.5658673%"
        id="software-failure-f"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-367 -2747) translate(106 2702) translate(208 44) translate(53 2)"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
      fill="none"
    >
      <path
        fill="url(#software-failure-a)"
        transform="rotate(-47.37 47.644 46.721)"
        d="M37.4435897 43.5827488H57.843589699999995V49.860248799999994H37.4435897z"
      />
      <path
        d="M47.501 8.565a26.55 26.55 0 1037.5-1.549c-10.785-9.921-27.57-9.228-37.5 1.549z"
        strokeOpacity={0.179671601}
        stroke="#979797"
        fill="url(#software-failure-b)"
      />
      <path
        d="M66.146 4.991c-11.904.493-21.155 10.542-20.663 22.447.492 11.904 10.541 21.155 22.445 20.664 11.905-.492 21.157-10.54 20.666-22.444A21.574 21.574 0 0066.146 4.99z"
        fill="url(#software-failure-c)"
        opacity={0.302111098}
      />
      <path
        d="M41.541 46.82a1.95 1.95 0 011.402.512h0l4.471 4.126c.397.365.609.856.63 1.355.02.5-.149 1.007-.513 1.403h0L33.843 69.081a1.949 1.949 0 01-2.754.114h0l-4.475-4.118a1.949 1.949 0 01-.112-2.758h0l13.683-14.872a1.95 1.95 0 011.356-.627z"
        strokeOpacity={0.189500929}
        stroke="#979797"
        fill="url(#software-failure-d)"
      />
      <path
        d="M34.148 69.491l-2.592 2.817a2.452 2.452 0 01-3.465.138l-4.466-4.117a2.449 2.449 0 01-.15-3.454l2.591-2.816 8.082 7.432z"
        fill="url(#software-failure-e)"
      />
      <path
        d="M80.389 26.227a4.819 4.819 0 01-4.583 1.422c.139-.468.2-.956.184-1.444a4.447 4.447 0 00-.581-2.047 4.83 4.83 0 001.147-.9 4.747 4.747 0 001.234-2.625l.116.108 2.666-2.876-1.74-1.616-1.5 1.627a4.826 4.826 0 00-1.012-1.361 4.92 4.92 0 00-.892-.649l1.39-1.5-1.754-1.616-2.314 2.49a4.777 4.777 0 00-3.188 1.5c-.33.36-.605.769-.813 1.211a4.5 4.5 0 00-3.656-.075 4.811 4.811 0 01.978-4.965l-1.74-1.616a7.196 7.196 0 00-1.177 8.081l-3.21 3.289 11.25 10.459 3.303-3.375a7.181 7.181 0 007.628-1.905l-1.736-1.617zm-6.668-3.915l-3-2.805a2.404 2.404 0 114.11 2.145 2.437 2.437 0 01-1.11.66zM56.01 33.563a21.375 21.375 0 005.205-1.574 19.699 19.699 0 00-1.399 5.328 7.568 7.568 0 01-3.806-3.75v-.004zm13.579.938l-.731.788a7.208 7.208 0 01-1.332 7.755l-1.743-1.62a4.808 4.808 0 001.124-4.5 7.706 7.706 0 01-3.66 1.087 8.4 8.4 0 01-1.106-.049c.248-3.251 1.755-6.296 2.674-7.875l4.774 4.414zm-6.75-6.27c-2.299 1.898-5.419 2.704-7.5 3.045a7.736 7.736 0 01.843-4.545 4.823 4.823 0 00-4.724 1.395l-1.74-1.616a7.192 7.192 0 017.972-1.767l.638-.69 4.51 4.178z"
        fill="url(#software-failure-f)"
      />
      <path
        d="M40.5 27.169h-2.475V25.93H40.5v1.238zm-4.95 0h-2.475V25.93h2.475v1.238zm-4.95 0h-2.475V25.93H30.6v1.238zm-4.95 0h-2.475V25.93h2.475v1.238zm-4.95 0h-2.475V25.93H20.7v1.238zm-4.95 0h-2.475V25.93h2.475v1.238zm-4.95 0H8.325V25.93H10.8v1.238zm-4.95 0H3.375V25.93H5.85v1.238zm-4.95 0H0V25.93h.881L.9 27.17z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default SvgSoftwareFailure
