import PropTypes from 'prop-types'
import clsx from 'clsx'

import GSuiteForm from 'components/Form/GSuite'
import TextUnderline from 'components/SVG/textUnderline'

import style from './registration.module.css'

const Registration = ({ className }) => (
  <div className={clsx(className, style.registrationForm)}>
    <div className={style.content}>
      <h2>G Suite Offer Registration</h2>
      <div>
        <p>
          Our G Suite experts are ready to get you setup in no-time! Fill out the form below to get
          started.
        </p>
      </div>
      <div className={style.textUnderline}>
        <TextUnderline />
      </div>
      <GSuiteForm formId="4f0ac2be-9d0d-4faf-8c49-1fd24db9e460" />
    </div>
  </div>
)

Registration.defaultProps = {
  className: '',
}

Registration.propTypes = {
  className: PropTypes.string,
}

export default Registration
