import { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ContactIcon from 'observian-ui/lib/svg/icons/contact'
import Button from 'observian-ui/lib/button'
import Logo from 'observian-ui/lib/logo'
import { Form as FinalForm, Field } from 'react-final-form'

import { gsuiteForm } from 'api/form'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isEmail, isRequired, minValue, mustBeNumber, composeValidators } from 'utils/validation'

import style from './googleForm.module.css'

class ContactUsForm extends Component {
  state = {
    isSuccessful: false,
  }

  handleOnSubmit = async (event) => {
    const { ctaIntentSuffix } = this.props
    const ctaIntent =
      0 === ctaIntentSuffix.length ? event.ctaIntent : `${event.ctaIntent}-${ctaIntentSuffix}`

    const allFormData = {
      ...event,
      ctaIntent,
    }

    try {
      const res = await gsuiteForm({ ...allFormData })

      if (200 === res.status) {
        return this.renderSubmission()
      }

      return console.error(res.data.message || 'Unknown Error.')
    } catch (error) {
      return console.error(error)
    }
  }

  renderSubmission = (value = true) => {
    this.handleCallback(value)
    this.setState({
      isSuccessful: value,
    })
  }

  handleOnDismiss = () => this.renderSubmission(false)

  handleCallback = (value = false) => {
    const { isSuccessfulCallback } = this.props
    if (isSuccessfulCallback) {
      return isSuccessfulCallback(value)
    }

    return null
  }

  render() {
    const {
      props: {
        className,
        headerTitle,
        showLogo,
        showTitle,
        buttonLabel,
        ctaIntent,
        isSuccessfulMessage,
      },
      state: { isSuccessful },
    } = this

    return (
      <HubspotTracking>
        {({ hsContext }) => (
          <>
            {!isSuccessful ? (
              <FinalForm
                validateOnBlur
                onSubmit={(e) => this.handleOnSubmit({ ...e, hsContext })}
                initialValues={{ ctaIntent }}
              >
                {({ handleSubmit, pristine, submitting }) => (
                  <form className={clsx(style.form, className)} onSubmit={handleSubmit}>
                    <header className={style.header}>
                      {showTitle ? <h3>{headerTitle}</h3> : null}
                      {showLogo ? (
                        <Logo colorLogo className={style.logo} data-testid="form-logo" />
                      ) : null}
                    </header>
                    <div className={style.name}>
                      <Field name="firstName" placeholder="First Name" validate={isRequired}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <ContactIcon type="person" className={style.contactIcon} />
                            <input {...input} placeholder={placeholder} id={input.name} />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-firstName-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="lastName" placeholder="Last Name" validate={isRequired}>
                        {({ input, meta, placeholder }) => (
                          <div className={clsx(style.singleRow, style.secondItem)}>
                            <ContactIcon type="person" className={style.contactIcon} />
                            <input {...input} placeholder={placeholder} id={input.name} />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-lastName-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className={style.info}>
                      <Field name="email" placeholder="Email" validate={isEmail}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <ContactIcon type="email" className={style.contactIcon} />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="email"
                            />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-email-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="company" placeholder="Company Name">
                        {({ input, placeholder }) => (
                          <div className={clsx(style.singleRow, style.secondItem)}>
                            <ContactIcon type="building" className={style.contactIcon} />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="text"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className={style.count}>
                      <Field
                        name="gsuiteUsersForm"
                        placeholder="How many users? An estimate is fine."
                        validate={composeValidators(
                          isRequired,
                          minValue(5, 'We require at least 5 users in order to best serve you.'),
                          mustBeNumber
                        )}
                      >
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <ContactIcon type="person" className={style.contactIcon} />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="text"
                            />
                            {meta.error && meta.touched && (
                              <div data-testid="form-email-error" className={style.validationError}>
                                {meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className={style.radioGroup}>
                      {/* eslint-disable-next-line */}
                      <label className={style.radioLabel} htmlFor="gsuiteLicenseLevelForm">
                        Which license are you interested in?
                      </label>
                      <div className={style.radio}>
                        <div className={style.radioButton}>
                          <Field
                            name="gsuiteLicenseLevelForm"
                            component="input"
                            type="radio"
                            value="basic"
                            placeholder="Basic"
                          />
                          <span className={style.buttonText}>Basic</span>
                        </div>
                        <div className={style.radioButton}>
                          <Field
                            name="gsuiteLicenseLevelForm"
                            component="input"
                            type="radio"
                            value="business"
                            placeholder="Business"
                          />
                          <span className={style.buttonText}>Business</span>
                        </div>
                        <div className={style.radioButton}>
                          <Field
                            name="gsuiteLicenseLevelForm"
                            component="input"
                            type="radio"
                            value="enterprise"
                            placeholder="Enterprise"
                          />
                          <span className={style.buttonText}>Enterprise</span>
                        </div>
                        <div className={style.radioButton}>
                          <Field
                            name="gsuiteLicenseLevelForm"
                            component="input"
                            type="radio"
                            value="not_sure"
                            placeholder="Not Sure"
                          />
                          <span className={style.buttonText}>Not Sure</span>
                        </div>
                      </div>
                    </div>
                    <Field name="ctaIntent" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                    <div className={style.buttonContainer}>
                      <Button
                        type="primary"
                        label={buttonLabel}
                        className={style.buttonCTA}
                        disabled={pristine || submitting}
                      />
                    </div>
                  </form>
                )}
              </FinalForm>
            ) : (
              <div className={style.success}>
                <span data-testid="form-success-message">{isSuccessfulMessage}</span>
                <Button
                  variant="cancel"
                  label="Done"
                  onClick={this.handleOnDismiss}
                  className={style.successButtonCTA}
                />
              </div>
            )}
          </>
        )}
      </HubspotTracking>
    )
  }
}

ContactUsForm.defaultProps = {
  className: '',
  inquiryPlaceholder: 'Inquiry Message',
  headerTitle: 'Send Us a Message',
  showLogo: false,
  showTitle: false,
  showContactInfo: false,
  buttonLabel: 'Send Message',
  isSuccessfulMessage: "Your inquiry has been sent, we'll be in touch soon!",
  ctaIntent: 'contact-us-form',
  ctaIntentSuffix: '',
  isSuccessfulCallback: () => {},
}

ContactUsForm.propTypes = {
  className: PropTypes.string,
  inquiryPlaceholder: PropTypes.string,
  headerTitle: PropTypes.string,
  showLogo: PropTypes.bool,
  showTitle: PropTypes.bool,
  showContactInfo: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
  isSuccessfulCallback: PropTypes.func,
}

export default ContactUsForm
