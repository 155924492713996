import { useState } from 'react'

import TextUnderline from 'components/SVG/textUnderline'

import user from '../../../../static/images/datadog/user.svg'
import arrows from '../../../../static/images/datadog/arrows.svg'

import style from './isready.module.css'

const IsReady = () => (
  <section className={style.isReady}>
    <div className={style.wrapper}>
      <div className={style.isReadyContainer}>
        <div className={style.leftContainer}>
          <div className={style.headerTextContainer}>
            <img src={user} alt="datadog-user" className={style.user} />
            <h3 className={style.headerTextMain}>Digital Experience Monitoring</h3>
          </div>
          <TextUnderline className={style.textUnderline} />

          <div className={style.mainTextContainer}>
            <p className={style.mainText}>
              Companies are increasingly relying on web and mobile applications to meet their
              customers&apos; needs. To do this, they need a frontend monitoring solution that can
              help surface any issues with users in real-time so it&apos;s easier for them maintain
              the consistency of an optimized user experience across all devices.
            </p>
          </div>
        </div>
        <div className={style.rightContainer}>
          <div className={style.headerTextContainer}>
            <img src={arrows} alt="datadog-user" className={style.user} />
            <h3 className={style.headerTextMain}>Security Analytics with Datadog</h3>
          </div>
          <TextUnderline className={style.textUnderline} />

          <div className={style.mainTextContainer}>
            <p className={style.mainText}>
              Datadog&apos;s Security Monitoring service provides out-of-the box threat detection
              and security analytics solutions based on the full set of monitoring data from 450+
              turnkey, vendor supported integrations. Security Monitoring is part of the Datadog
              Cloud Security Platform, which protects an organization’s production environment with
              a full-stack offering providing threat detection, posture management, workload
              security, and application security.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className={style.bottomStyling} />
  </section>
)

export default IsReady
