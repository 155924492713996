import { Component } from 'react'
import { buildFormUrl } from '../../../utils/formRedirect'

import Button from 'observian-ui/lib/button'
import Modal from 'observian-ui/lib/modal'
import ContactModal from 'observian-ui/lib/modal/contact'
import ContactForm from 'components/Form/Contact'

//import Form from 'components/Form'

import style from './myCompany.module.css'

class MyCompany extends Component {
  constructor(props) {
    super(props)

    this.state = {
      primaryModal: false,
      secondaryModal: false,
      showModal: false,
      isSuccessful: false,
    }
  }

  handleModal = (context, value) => {
    this.setState((state) => ({
      ...state,
      [context]: value,
    }))
  }

  handleSetSuccessful = (v) => {
    this.setState({
      isSuccessful: v,
    })
  }

  render() {
    const { primaryModal, secondaryModal, isSuccessful } = this.state

    return (
      <section className={style.myCompany}>
        <header className={style.header}>
          <h2 className={style.heading}>My company is ready to...</h2>
          <div className={style.buttons}>
            <Button
              variant="secondary"
              label="Move to the cloud"
              className={style.secondaryCTA}
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: 'Home Page - Move to the cloud'
                }
              ])}
            />
            {secondaryModal ? (
              <Modal
                isOpen={secondaryModal}
                onClose={() => this.handleModal('secondaryModal', false)}
              >
                <ContactModal
                  showClose
                  shouldCloseOnOverlayClick
                  onClose={() => this.handleModal('secondaryModal', false)}
                  onDismiss={() => this.handleModal('secondaryModal', false)}
                  isOpen={secondaryModal}
                  closeButtonPosition="right"
                  ariaHideApp={false}
                  success={isSuccessful}
                >
                  <ContactForm
                    showLogo
                    headerTitle="Contact Us"
                    buttonLabel="Send"
                    ctaIntentSuffix="modal-home-mycompany-secondary"
                    inquiryPlaceholder="Hi! My company or team would love some help moving to the cloud!"
                    isSuccessfulCallback={(v) => {
                      this.handleSetSuccessful(v)
                      if (!v) {
                        this.handleModal('secondaryModal', false)
                      }
                    }}
                  />
                </ContactModal>
              </Modal>
            ) : null}
            <Button
              variant="primary"
              label="Level up our cloud game"
              className={style.primaryCTA}
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: 'Home Page - Level up our cloud game'
                }
              ])}
            />
            {primaryModal ? (
              <Modal isOpen={primaryModal} onClose={() => this.handleModal('primaryModal', false)}>
                <ContactModal
                  showClose
                  shouldCloseOnOverlayClick
                  onClose={() => this.handleModal('primaryModal', false)}
                  onDismiss={() => this.handleModal('primaryModal', false)}
                  isOpen={primaryModal}
                  closeButtonPosition="right"
                  ariaHideApp={false}
                  success={isSuccessful}
                >
                  <ContactForm
                    showLogo
                    headerTitle="Contact Us"
                    buttonLabel="Send"
                    ctaIntentSuffix="modal-home-mycompany-primary"
                    inquiryPlaceholder="Hi! We are currently in the cloud and would like to talk about how we can optimize our implementation."
                    isSuccessfulCallback={(v) => {
                      this.handleSetSuccessful(v)
                      if (!v) {
                        this.handleModal('primaryModal', false)
                      }
                    }}
                  />
                </ContactModal>
              </Modal>
            ) : null}
          </div>
        </header>
      </section>
    )
  }
}

export default MyCompany
