import PropTypes from 'prop-types'

const CertificationCount = ({
  attrClassNames,
  className,
  count,
  employeeCount,
  title,
  employeeTitle,
  type,
}) => {
  switch (type) {
    case 'aws':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="860"
          height="860"
          viewBox="0 0 860 860"
        >
          <defs>
            <linearGradient
              id="certifications-count-d"
              x1="12.5%"
              x2="87.9%"
              y1="84.9%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="certifications-count-c"
              width="135%"
              height="135%"
              x="-17.4%"
              y="-12.7%"
              filterUnits="objectBoundingBox"
            >
              <feMorphology
                in="SourceAlpha"
                operator="dilate"
                radius="6.25"
                result="shadowSpreadOuter1"
              />
              <feOffset dy="34" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="34" />
              <feComposite
                in="shadowBlurOuter1"
                in2="SourceAlpha"
                operator="out"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.176262455 0"
              />
            </filter>
            <mask id="certifications-count-e" width="731.773" height="731.773" x="-6.25" y="-6.25">
              <rect width="731.773" height="731.773" x="-6.007" y="-6.037" fill="#fff" />
              <path d="M359.879895,719.485664 C558.501596,719.485664 719.516298,558.470962 719.516298,359.849261 C719.516298,161.22756 558.501596,0.212858208 359.879895,0.212858208 C161.258194,0.212858208 0.243492285,161.22756 0.243492285,359.849261 C0.243492285,558.470962 161.258194,719.485664 359.879895,719.485664 Z" />
            </mask>
            <linearGradient
              id="certifications-count-g"
              x1="12.5%"
              x2="87.9%"
              y1="84.9%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" stopOpacity="0.444" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="certifications-count-f"
              width="133.6%"
              height="133.6%"
              x="-16.8%"
              y="-12.1%"
              filterUnits="objectBoundingBox"
            >
              <feMorphology
                in="SourceAlpha"
                operator="dilate"
                radius="2"
                result="shadowSpreadOuter1"
              />
              <feOffset dy="25" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="25" />
              <feComposite
                in="shadowBlurOuter1"
                in2="SourceAlpha"
                operator="out"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.176262455 0"
              />
            </filter>
            <mask id="certifications-count-h" width="536.795" height="536.795" x="-2" y="-2">
              <rect width="536.795" height="536.795" x="91.483" y="91.452" fill="#fff" />
              <path d="M359.879895,626.246596 C507.007081,626.246596 626.27723,506.976447 626.27723,359.849261 C626.27723,212.722075 507.007081,93.4519256 359.879895,93.4519256 C212.752709,93.4519256 93.4825596,212.722075 93.4825596,359.849261 C93.4825596,506.976447 212.752709,626.246596 359.879895,626.246596 Z" />
            </mask>
          </defs>
          <g fill="none" transform="translate(73 37)">
            <g strokeDasharray="80 318.75 156.25 312.5" strokeLinecap="round">
              <path
                d="M359.879895,719.485664 C558.501596,719.485664 719.516298,558.470962 719.516298,359.849261 C719.516298,161.22756 558.501596,0.212858208 359.879895,0.212858208 C161.258194,0.212858208 0.243492285,161.22756 0.243492285,359.849261 C0.243492285,558.470962 161.258194,719.485664 359.879895,719.485664 Z"
                fill="#000"
                filter="url(#certifications-count-c)"
              />
              <path
                className={attrClassNames.circle}
                d="M359.879895,719.485664 C558.501596,719.485664 719.516298,558.470962 719.516298,359.849261 C719.516298,161.22756 558.501596,0.212858208 359.879895,0.212858208 C161.258194,0.212858208 0.243492285,161.22756 0.243492285,359.849261 C0.243492285,558.470962 161.258194,719.485664 359.879895,719.485664 Z"
                fill="#354255"
                fillOpacity="0.11"
                stroke="url(#certifications-count-d)"
                strokeWidth="12.5"
                mask="url(#certifications-count-e)"
              />
            </g>
            <g strokeDasharray="27.5 187.5 156.25 190" strokeLinecap="round">
              <path
                d="M359.879895,626.246596 C507.007081,626.246596 626.27723,506.976447 626.27723,359.849261 C626.27723,212.722075 507.007081,93.4519256 359.879895,93.4519256 C212.752709,93.4519256 93.4825596,212.722075 93.4825596,359.849261 C93.4825596,506.976447 212.752709,626.246596 359.879895,626.246596 Z"
                fill="#000"
                filter="url(#certifications-count-f)"
              />
              <path
                d="M359.879895,626.246596 C507.007081,626.246596 626.27723,506.976447 626.27723,359.849261 C626.27723,212.722075 507.007081,93.4519256 359.879895,93.4519256 C212.752709,93.4519256 93.4825596,212.722075 93.4825596,359.849261 C93.4825596,506.976447 212.752709,626.246596 359.879895,626.246596 Z"
                fill="#F6FBFA"
                className={attrClassNames.circle1}
                fillOpacity="0.05"
                stroke="url(#certifications-count-g)"
                strokeWidth="4"
                mask="url(#certifications-count-h)"
              />
            </g>
            <text
              fill="#EBECEE"
              fontFamily="GeomanistUltra, Geomanist"
              fontSize="250"
              letterSpacing="6"
              opacity="0.3"
            >
              <tspan x="215" y="430" className={attrClassNames.awsCount}>
                {count}
              </tspan>
              <tspan x="250" y="430" className={attrClassNames.employeeCount}>
                {employeeCount}
              </tspan>
            </text>
            <text fill="#FFF" opacity="0.8" fontFamily="GeomanistBold, Geomanist" fontSize="27">
              <tspan x="247" y="476" className={attrClassNames.awsTitle}>
                {title}
              </tspan>
              <tspan x="247" y="476" className={attrClassNames.employeeTitle}>
                {employeeTitle}
              </tspan>
            </text>
          </g>
        </svg>
      )

    case 'gcp':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="427"
          height="738"
          viewBox="0 0 427 738"
        >
          <defs>
            <linearGradient
              id="gcp-certification-count-d"
              x1="43.871%"
              x2="56.186%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <linearGradient
              id="gcp-certification-count-e"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <mask id="gcp-certification-count-f" width="369.135" height="369.135" x="-4" y="-4">
              <rect width="369.135" height="369.135" x="28.555" y="182.674" fill="#fff" />
              <circle cx="213.122" cy="367.242" r="180.567" />
            </mask>
            <mask id="gcp-certification-count-g" width="427" height="427" x="-2" y="-2">
              <rect width="427" height="427" x="-1.878" y="155.242" fill="#fff" />
              <circle cx="211.622" cy="368.742" r="211.5" />
            </mask>
            <linearGradient
              id="gcp-certification-count-i"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" stopOpacity=".444" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="gcp-certification-count-h"
              width="134.4%"
              height="134.4%"
              x="-17.2%"
              y="-12.3%"
              filterUnits="objectBoundingBox"
            >
              <feMorphology
                in="SourceAlpha"
                operator="dilate"
                radius="2"
                result="shadowSpreadOuter1"
              />
              <feOffset dy="13" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
              <feGaussianBlur
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
                stdDeviation="12.5"
              />
              <feComposite
                in="shadowBlurOuter1"
                in2="SourceAlpha"
                operator="out"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.176262455 0"
              />
            </filter>
            <mask id="gcp-certification-count-j" width="271.507" height="271.507" x="-2" y="-2">
              <rect width="271.507" height="271.507" x="77.936" y="230.921" fill="#fff" />
              <circle cx="213.69" cy="366.674" r="133.754" />
            </mask>
          </defs>
          <g fill="none" transform="translate(2)">
            <polygon
              fill="url(#gcp-certification-count-d)"
              points="99.624 65.101 153.018 168.385 293.299 295.474 153.018 523.88 228.042 616.654 204.042 738 254.917 608.408 196.462 500.43 332.056 341.654 382.523 232.647 170.335 155.681 84.353 0"
              opacity=".339"
            />
            <path
              fill="#F6FBFA"
              d="M213.122253,547.809204 C312.846899,547.809204 393.689697,466.966406 393.689697,367.24176 C393.689697,267.517115 312.846899,186.674316 213.122253,186.674316 C113.397608,186.674316 32.5548096,267.517115 32.5548096,367.24176 C32.5548096,466.966406 113.397608,547.809204 213.122253,547.809204 Z"
            />
            <circle
              cx="213.122"
              cy="367.242"
              r="180.567"
              fill="#F6FBFA"
              stroke="url(#gcp-certification-count-e)"
              strokeDasharray="40 159.375 78.125 156.25"
              strokeLinecap="round"
              strokeWidth="8"
              mask="url(#gcp-certification-count-f)"
            />
            <circle
              cx="211.622"
              cy="368.742"
              r="211.5"
              stroke="url(#gcp-certification-count-e)"
              strokeDasharray="40 55"
              strokeLinecap="round"
              strokeWidth="4"
              mask="url(#gcp-certification-count-g)"
            />
            <g strokeDasharray="13.75 93.75 78.125 95" strokeLinecap="round">
              <circle
                cx="213.69"
                cy="366.674"
                r="133.754"
                fill="#000"
                filter="url(#gcp-certification-count-h)"
              />
              <circle
                cx="213.69"
                cy="366.674"
                r="133.754"
                fill="#F6FBFA"
                stroke="url(#gcp-certification-count-i)"
                strokeWidth="4"
                mask="url(#gcp-certification-count-j)"
              />
            </g>
            <text
              fill="#1A2F46"
              fontFamily="GeomanistUltra, Geomanist"
              fontSize="125"
              fontWeight="600"
              letterSpacing="3"
              opacity=".308"
            >
              <tspan x="156.375" y="400.994">
                {count}
              </tspan>
            </text>
            <text
              fill="#172A3F"
              fontFamily="GeomanistBold, Geomanist"
              fontSize="13.5"
              fontWeight="bold"
            >
              <tspan x="148.417" y="425.544">
                {title}
              </tspan>
            </text>
          </g>
        </svg>
      )

    case 'aws1':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="427"
          height="683"
          viewBox="0 0 427 683"
        >
          <defs>
            <linearGradient
              id="certification-count-d"
              x1="42.841%"
              x2="57.226%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <linearGradient
              id="certification-count-e"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <mask id="certification-count-f" width="369.135" height="369.135" x="-4" y="-4">
              <rect width="369.135" height="369.135" x="28.555" y="182.674" fill="#fff" />
              <circle cx="213.122" cy="367.242" r="180.567" />
            </mask>
            <mask id="certification-count-g" width="427" height="427" x="-2" y="-2">
              <rect width="427" height="427" x="-1.878" y="155.242" fill="#fff" />
              <circle cx="211.622" cy="368.742" r="211.5" />
            </mask>
            <linearGradient
              id="certification-count-i"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" stopOpacity=".444" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="certification-count-h"
              width="134.4%"
              height="134.4%"
              x="-17.2%"
              y="-12.3%"
              filterUnits="objectBoundingBox"
            >
              <feMorphology
                in="SourceAlpha"
                operator="dilate"
                radius="2"
                result="shadowSpreadOuter1"
              />
              <feOffset dy="13" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
              <feGaussianBlur
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
                stdDeviation="12.5"
              />
              <feComposite
                in="shadowBlurOuter1"
                in2="SourceAlpha"
                operator="out"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.176262455 0"
              />
            </filter>
            <mask id="certification-count-j" width="271.507" height="271.507" x="-2" y="-2">
              <rect width="271.507" height="271.507" x="77.936" y="230.921" fill="#fff" />
              <circle cx="213.69" cy="366.674" r="133.754" />
            </mask>
          </defs>
          <g fill="none" transform="translate(2)">
            <polygon
              fill="url(#certification-count-d)"
              points="99.624 65.101 153.018 168.385 293.299 295.474 153.018 523.88 228.042 616.654 340.655 682.842 254.917 608.408 196.462 500.43 332.056 341.654 382.523 232.647 170.335 155.681 84.353 0"
              opacity=".339"
            />
            <circle
              cx="213.122"
              cy="367.242"
              r="180.567"
              fill="#F6FBFA"
              stroke="url(#certification-count-e)"
              strokeDasharray="40 159.375 78.125 156.25"
              strokeLinecap="round"
              strokeWidth="4"
            />
            <circle
              cx="211.622"
              cy="368.742"
              r="211.5"
              stroke="url(#certification-count-e)"
              strokeDasharray="40 55"
              strokeLinecap="round"
              strokeWidth="4"
              mask="url(#certification-count-g)"
            />
            <g strokeDasharray="13.75 93.75 78.125 95" strokeLinecap="round">
              <circle
                cx="213.69"
                cy="366.674"
                r="133.754"
                fill="#000"
                filter="url(#certification-count-h)"
              />
              <circle
                cx="213.69"
                cy="366.674"
                r="133.754"
                fill="#F6FBFA"
                stroke="url(#certification-count-i)"
                strokeWidth="4"
              />
            </g>
            <text
              fill="#1A2F46"
              fontFamily="GeomanistUltra, Geomanist"
              fontSize="125"
              fontWeight="600"
              letterSpacing="3"
              opacity=".308"
            >
              <tspan x="137.75" y="400.994" className={attrClassNames.employeeCount}>
                {count}
              </tspan>
            </text>
            <text
              fill="#172A3F"
              fontFamily="GeomanistBold, Geomanist"
              fontSize="13.5"
              fontWeight="bold"
            >
              <tspan x="156.949" y="425.544" className={attrClassNames.awsTitle}>
                {title}
              </tspan>
            </text>
          </g>
        </svg>
      )

    default:
      return null
  }
}

CertificationCount.defaultProps = {
  title: 'AWS Certifications',
  count: 52,
  employeeTitle: 'Cloud Professionals',
  employeeCount: 24,
  className: '',
  attrClassNames: {
    awsTitle: 'awsTitle',
    awsCount: 'awsCount',
    employeeTitle: 'employeeTitle',
    employeeCount: 'employeeCount',
    circle: 'circle',
    circle1: 'circle1',
  },
}

CertificationCount.propTypes = {
  className: PropTypes.string,
  attrClassNames: PropTypes.shape({
    awsTitle: PropTypes.string,
    awsCount: PropTypes.string,
    employeeTitle: PropTypes.string,
    employeeCount: PropTypes.string,
    circle: PropTypes.string,
    circle1: PropTypes.string,
  }),
  title: PropTypes.string,
  count: PropTypes.number,
  employeeTitle: PropTypes.string,
  employeeCount: PropTypes.number,
  type: PropTypes.string.isRequired,
}

export default CertificationCount
