export default {
  keywords: [
    'amazon',
    'amazon web services',
    'application modernization',
    'apps',
    'architecture',
    'automation',
    'aws',
    'aws cloud',
    'aws cost optimization',
    'aws partner',
    'aws reseller',
    'azure',
    'back up',
    'big data',
    'cloud',
    'cloud architecture',
    'cloud hybrid',
    'cloud migration',
    'cloud security',
    'compliance',
    'containers',
    'dev ops',
    'development',
    'devops',
    'disaster recovery',
    'docker',
    'dynamodb',
    'ec2',
    'education',
    'efficiency',
    'etl',
    'financial',
    'hybrid',
    'iam',
    'instances',
    'kubernetes',
    'micro',
    'migration',
    'networking',
    'nodes',
    'nosql',
    'observian',
    'on premise',
    'on-premise',
    'optimization',
    'reserved',
    's3',
    'salt lake city',
    'security',
    'serverless',
    'services',
    'slc',
    'sql',
    'strategy',
    't2',
    'utah',
    'web',
    'windows workloads',
  ],
}
