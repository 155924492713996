import { useState } from 'react'
import ContactModal from 'observian-ui/lib/modal/contact'
import Button from 'observian-ui/lib/button'
import { buildFormUrl } from '../../../utils/formRedirect'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import devops from '../../../../static/images/datadog/devops.png'

import style from './devops.module.css'

const DevOps = () => {
  const [modal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <section className={style.devOps}>
      <div className={style.topTriangle} />
      <div className={style.devOpsContainer}>
        <div className={style.leftContainer}>
          <img src={devops} alt="devops-img" className={style.devopsimg} />
        </div>
        <div className={style.rightContainer}>
          <div className={style.headerTextContainer}>
            <h3 className={style.headerTextMain}>
              Accelerate Deployment Rates Without Losing Performance
            </h3>
          </div>
          <TextUnderline className={style.textUnderline} />

          <div className={style.mainTextContainer}>
            <p className={style.mainText}>
              One of the key drivers of implementing a DevOps culture is the need for faster
              development cycles in order to drive innovation and stay ahead of the market.
              Datadog’s unified monitoring platform can help ensure smooth, dependable deployments
              even in a complex and dynamic environment.
            </p>
          </div>
          <Button
            className={style.button}
            label="Let's Talk DevOps"
            onClick={() => window.location.href = buildFormUrl([
              {
                key: 'CTA',
                value: 'Datadog - Let\'s talk DevOps'
              }
            ])}
          />
        </div>
      </div>
      {modal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={modal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            isLandingPage
            ctaIntentSuffix="modal-datadog-devops-contact-top-cta"
            inquiryPlaceholder="I'd like to see how Observian and Datadog can help my business."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default DevOps
