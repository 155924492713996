import * as React from 'react'

function SitesProduct(props) {
  return (
    <svg width={35} height={49} viewBox="0 0 35 49" xmlns="http://www.w3.org/2000/svg">
      <title>{'B7DE5FD0-260D-4F17-93E1-4C56580941E9'}</title>
      <g opacity={0.153}>
        <g opacity={0.684} fillRule="nonzero" fill="none">
          <path
            d="M31.364 48.284H3.261a3.241 3.241 0 01-3.243-3.242V3.978A3.241 3.241 0 013.261.736h19.456l11.89 11.887v32.42a3.241 3.241 0 01-3.243 3.241z"
            fill="#4758B5"
          />
          <path fill="#354287" d="M22.717.736l11.89 11.887h-11.89z" />
          <path
            fill="#FFF"
            d="M27.04 19.107H7.586v2.701H27.04zM27.04 24.51H24.34v9.186h2.702zM21.636 24.51H7.585v9.186h14.051z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SitesProduct
