import PropTypes from 'prop-types'

const SingleCell = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="288"
    height="268"
    viewBox="0 0 288 268"
    className={className}
  >
    <defs>
      <linearGradient id="singlecell-c" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <circle id="singlecell-b" cx="159.273" cy="219.219" r="4.219" />
      <filter
        id="singlecell-a"
        width="464.4%"
        height="464.4%"
        x="-182.2%"
        y="-134.8%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1.25"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
        />
      </filter>
      <circle id="singlecell-e" cx="5.273" cy="143" r="5.273" />
      <filter
        id="singlecell-d"
        width="478.7%"
        height="478.7%"
        x="-189.3%"
        y="-141.9%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1.563"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="5" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
        />
      </filter>
      <circle id="singlecell-g" cx="188.091" cy="6.155" r="5.273" />
      <filter
        id="singlecell-f"
        width="478.7%"
        height="478.7%"
        x="-189.3%"
        y="-141.9%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1.563"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="5" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
        />
      </filter>
      <circle id="singlecell-i" cx="121.684" cy="110.91" r="7.91" />
      <filter
        id="singlecell-h"
        width="481.8%"
        height="481.8%"
        x="-190.9%"
        y="-140.3%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="2.344"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="8" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" opacity=".439" transform="rotate(102 112.46 143.698)">
      <polyline stroke="#1BEAB5" points="188.311 6 123.614 111.336 3.352 142.731" />
      <line x1="159.273" x2="122.785" y1="220.951" y2="110.91" stroke="#1BEAB5" />
      <use fill="#000" filter="url(#singlecell-a)" xlinkHref="#singlecell-b" />
      <circle
        cx="159.273"
        cy="219.219"
        r="4.844"
        fill="url(#singlecell-c)"
        stroke="#1DECB6"
        strokeWidth="1.25"
      />
      <circle
        cx="159.273"
        cy="219.219"
        r="6.406"
        stroke="#1DECB6"
        strokeOpacity=".218"
        strokeWidth="4.375"
      />
      <circle
        cx="159.273"
        cy="219.219"
        r="5.469"
        stroke="#1DECB6"
        strokeOpacity=".178"
        strokeWidth="2.5"
      />
      <g>
        <use fill="#000" filter="url(#singlecell-d)" xlinkHref="#singlecell-e" />
        <circle
          cx="5.273"
          cy="143"
          r="6.055"
          fill="url(#singlecell-c)"
          stroke="#1DECB6"
          strokeWidth="1.563"
        />
        <circle
          cx="5.273"
          cy="143"
          r="8.008"
          stroke="#1DECB6"
          strokeOpacity=".218"
          strokeWidth="5.469"
        />
        <circle
          cx="5.273"
          cy="143"
          r="6.836"
          stroke="#1DECB6"
          strokeOpacity=".178"
          strokeWidth="3.125"
        />
      </g>
      <g>
        <use fill="#000" filter="url(#singlecell-f)" xlinkHref="#singlecell-g" />
        <circle
          cx="188.091"
          cy="6.155"
          r="6.055"
          fill="url(#singlecell-c)"
          stroke="#1DECB6"
          strokeWidth="1.563"
        />
        <circle
          cx="188.091"
          cy="6.155"
          r="8.008"
          stroke="#1DECB6"
          strokeOpacity=".218"
          strokeWidth="5.469"
        />
        <circle
          cx="188.091"
          cy="6.155"
          r="6.836"
          stroke="#1DECB6"
          strokeOpacity=".178"
          strokeWidth="3.125"
        />
      </g>
      <g>
        <use fill="#000" filter="url(#singlecell-h)" xlinkHref="#singlecell-i" />
        <circle
          cx="121.684"
          cy="110.91"
          r="9.082"
          fill="url(#singlecell-c)"
          stroke="#1DECB6"
          strokeWidth="2.344"
        />
        <circle
          cx="121.684"
          cy="110.91"
          r="12.012"
          stroke="#1DECB6"
          strokeOpacity=".218"
          strokeWidth="8.203"
        />
        <circle
          cx="121.684"
          cy="110.91"
          r="10.254"
          stroke="#1DECB6"
          strokeOpacity=".178"
          strokeWidth="4.688"
        />
      </g>
    </g>
  </svg>
)

SingleCell.defaultProps = {
  className: '',
}

SingleCell.propTypes = {
  className: PropTypes.string,
}

export default SingleCell
