import { PureComponent } from 'react'
import { Timeline } from 'react-twitter-widgets'

class Twitter extends PureComponent {
  applyStyles = () => {
    const iframe = document.getElementsByClassName('twitter-timeline')
    const timeline = iframe[0]

    const style = document.createElement('style')
    style.type = 'text/css'
    style.textContent = '.SandboxRoot, .timeline-Header-title { color: white; }'

    timeline.contentDocument.head.appendChild(style)
  }

  render() {
    return (
      <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: 'ObservianHQ',
        }}
        options={{
          username: 'ObservianHQ',
          height: '400',
          chrome: 'transparent',
        }}
        onLoad={this.applyStyles}
      />
    )
  }
}

export default Twitter
