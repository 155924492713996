export default [
  {
    label: 'Cloud Migration',
    href: '/cloud-migration',
    description:
      'Observian has created a phased approach to our cloud migration services strategy (Assess, Plan and Educate, Execute, Optimize).',
  },
  {
    label: 'Cost Optimization',
    href: '/cost-optimization',
    description:
      'We are so confident we can save you money, we’ll happily analyze your infrastructure for free.',
  },
  {
    label: 'Application Modernization',
    href: '/devops',
    description: `DevOps, Containers, Kubernetes, Automation...These are the droids you are looking for.`,
  },
  {
    label: 'Security & Compliance',
    href: '/security-compliance',
    description:
      'Observian’s cloud security services help companies leverage security and compliance capabilities to safeguard their data and meet compliance requirements.',
  },
  {
    label: 'Big Data & Analytics',
    href: '/big-data-analytics',
    description:
      'As experts in Big Data, we can help your company build and deploy scalable and secure big data analytics applications.',
  },
]
