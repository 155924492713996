import { isProd } from './env';

export const FORM_URL = !isProd() 
? "https://www.staging.cvetech.com/contact-observian"
: "https://www.cvetech.com/contact-observian";

export const buildFormUrl = (params = []) => {
    let url = FORM_URL;
    if(!Array.isArray(params)) return `${url}?referrer=Observian`;

    if(!params.some(p => p.key === 'referrer'))
        params = [...params, {key: 'referrer', value: 'Observian'}]
    
    return`${url}?${params.map(p => `${p.key}=${p.value}`).join('&')}`;
}
