import {
  Hero,
  Quote,
  ProvidedServices,
  PartnerSites,
  EngagementProcess,
  BlogPosts,
} from 'components/Page/WorkspaceLanding'
import Layout from 'components/Layout'

const WorkspaceLanding = () => (
  <Layout
    title="Google Workspace"
    description="Google Workspace Experts at Observian. We do all things cloud, including DevOps, Big Data, Security, Migration, and more. Learn more!"
    headerProps={{ isLandingPage: true }}
    footerProps={{ isLandingPage: true }}
  >
    <Hero />
    <PartnerSites />
    <Quote />
    <ProvidedServices />
    <EngagementProcess />
    <BlogPosts />
  </Layout>
)

export default WorkspaceLanding
