/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import seoKeywords from './keywords'

function SEO({ description, lang, meta, keywords, title, ogImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'google-site-verification',
          content: 'sWHP0BlPp0LjiWrX4r0LJri_5PM2vxvMWpj1Z3yYN3k',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'og:site_name',
          content: 'Observian',
        },
        {
          property: 'og:image',
          content: ogImage,
        },
        {
          property: 'og:image:width',
          content: '1257',
        },
        {
          property: 'og:image:height',
          content: '2400',
        },
      ]
        .concat(
          0 < keywords.length
            ? {
                name: 'keywords',
                content: `${seoKeywords.keywords.toString()},${keywords.join(', ')}`,
              }
            : []
        )
        .concat(meta)}
    >
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                "name": "Observian",
                "image": "https://www.observian.com/images/logos/observian-logo-dark.png",
                "@id": "",
                "url": "https://www.observian.com/",
                "telephone": "801-895-3219",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "1338 South Gustin Road",
                  "addressLocality": "Salt Lake City",
                  "addressRegion": "UT",
                  "postalCode": "84104",
                  "addressCountry": "US"
                },
                "openingHoursSpecification": {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday"
                  ],
                  "opens": "",
                  "closes": ""
                }
              }`,
        }}
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: '',
  ogImage: '/images/seo/og-image.jpg',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
}

export default SEO
