import { useState } from 'react'
import Button from 'observian-ui/lib/button'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { buildFormUrl } from '../../../utils/formRedirect'
import clsx from 'clsx'
import ContactModal from 'observian-ui/lib/modal/contact'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import style from './partnerBenefits.module.css'

const PartnerBenefits = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "services/aws/aws-consulting-partner.png" }) {
        childImageSharp {
          gatsbyImageData(width: 470, height: 640, layout: CONSTRAINED)
        }
      }
    }
  `)
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <section className={style.section}>
      <div className={style.left}>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          className={style.partnerImage}
          alt=""
        />
      </div>
      <div className={style.right}>
        <header className={style.header}>
          <h2 className={style.title}>Partner Benefits</h2>
          <h5 className={style.description}>Why should you use an AWS partner?</h5>
        </header>
        <TextUnderline />
        <div className={style.lists}>
          <ul className={clsx(style.list, style.firstList)}>
            <li>Expert AWS Support</li>
            <li>Proof of Concept Credits</li>
            <li>Custom AWS Workshops</li>
          </ul>
          <ul className={clsx(style.list, style.secondList)}>
            <li>Free WAR & AWS Security Assessments</li>
            <li>Customer Events</li>
            <li>Free Tooling & Software Products</li>
          </ul>
        </div>
        <p className={style.text}>
          Whether you are new to the cloud or a seasoned cloud enterprise, we’re confident we can
          provide the resources you need to improve your cloud experience. Our goal is to help you
          develop usable, repeatable and automated cloud solutions while educating and supporting
          your teams needs. We will keep you up to date on top technologies and releases in the AWS
          ecosystem so you can stay on the cutting edge of development and cloud operations. We are
          your partners in the cloud.
        </p>
        <Button
          className={style.button}
          label="Schedule a Free Cloud Assessment"
          onClick={() => window.location.href = buildFormUrl([
            {
              key: 'CTA',
              value: 'AWS - Schedule a Free Cloud Assessment'
            }
          ])}
        />
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-aws-partners-benefits"
            inquiryPlaceholder="I'd love to schedule a free cloud assessment for my business."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default PartnerBenefits
