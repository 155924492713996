import {
  Hero,
  Quote,
  ProvidedServices,
  PartnerSites,
  EngagementProcess,
  BlogPosts,
} from 'components/Page/OfficeLanding'
import Layout from 'components/Layout'

const WorkspaceLanding = () => (
  <Layout
    title="Office Landing"
    description=""
    headerProps={{ isLandingPage: true }}
    footerProps={{ isLandingPage: true }}
  >
    <Hero />
    <PartnerSites />
    {/*<Quote />*/}
    <ProvidedServices />
    <EngagementProcess />
    <BlogPosts />
  </Layout>
)

export default WorkspaceLanding
