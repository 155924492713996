import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import style from './quote.module.css'

const Quote = () => {
  const data = useStaticQuery(graphql`
    {
      mobile: file(relativePath: { eq: "services/aws/william-broadhead.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      desktop: file(relativePath: { eq: "services/aws/william-broadhead-avatar.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED, layout: FIXED)
        }
      }
      background: file(relativePath: { eq: "services/aws/quote-avatar.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `)

  return (
    <section className={style.section}>
      <p className={style.quote}>
        “We wanted to convert a large scale project to dot net core to reduce ongoing fleet costs.
        The Observian team helped guide our solution to the one that fit our needs and contained
        best practices to make sure our total cost of ownership in terms of maintenance and day to
        day usability stayed low. They also helped us gain the skills in the areas we needed to make
        sure we were empowered to manage, modify, and enhance the solution after they finished the
        project.”
      </p>
      <div className={style.imageContainer}>
        <GatsbyImage
          image={data.mobile.childImageSharp.gatsbyImageData}
          className={style.partnerImageMobile}
          alt=""
        />
        <svg
          className={style.partnerImageDesktop}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="1290"
          height="445"
          viewBox="0 0 1290 445"
        >
          <defs>
            <linearGradient id="quote-d" x1="12.453%" x2="87.895%" y1="53.194%" y2="46.921%">
              <stop offset="0%" stopColor="#1D8DBA" stopOpacity=".034" />
              <stop offset="100%" stopColor="#03ECAE" stopOpacity=".033" />
            </linearGradient>
            <filter
              id="quote-b"
              width="111.6%"
              height="138.4%"
              x="-5.8%"
              y="-13.8%"
              filterUnits="objectBoundingBox"
            >
              <feMorphology
                in="SourceAlpha"
                operator="dilate"
                radius=".5"
                result="shadowSpreadOuter1"
              />
              <feOffset dy="20" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="20" />
              <feComposite
                in="shadowBlurOuter1"
                in2="SourceAlpha"
                operator="out"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24256993 0"
              />
            </filter>
            <linearGradient id="quote-f" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="quote-h"
              width="121.2%"
              height="121.2%"
              x="-10.6%"
              y="-10.6%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
            </filter>
            <pattern id="quote-j" width="101.677%" height="100%" x="-1.677%">
              <image
                width="1528"
                height="1438"
                transform="scale(.06926)"
                xlinkHref={data.background.childImageSharp.gatsbyImageData.src}
              />
            </pattern>
            <linearGradient id="quote-l" x1="15.436%" x2="114.319%" y1="50.262%" y2="49.815%">
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
            </linearGradient>
            <filter
              id="quote-m"
              width="109.7%"
              height="220.4%"
              x="-4.8%"
              y="-60.2%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="none" transform="translate(32 18)">
            <g transform="translate(5.79)">
              <g>
                <path
                  d="M48.7839622,0.593270374 L0.845609937,351.978814 C0.845609937,351.978814 178.289982,343.254062 533.178727,325.804559 L1214.43534,367.59327 L1186.92272,38.4027162 L520.374819,50.8203558 L48.7839622,0.593270374 Z"
                  fill="#000"
                  filter="url(#quote-b)"
                />
                <path
                  d="M48.7839622,0.593270374 L0.845609937,351.978814 C0.845609937,351.978814 178.289982,343.254062 533.178727,325.804559 L1214.43534,367.59327 L1186.92272,38.4027162 L520.374819,50.8203558 L48.7839622,0.593270374 Z"
                  fill="#F7FBFF"
                  stroke="url(#quote-d)"
                />
              </g>
              <g transform="translate(97 93.093)">
                <g transform="translate(11.6 11.64)">
                  <circle
                    cx="43.296"
                    cy="43.207"
                    r="43.1"
                    stroke="url(#quote-f)"
                    strokeWidth="1.25"
                    opacity=".396"
                  />
                  <mask id="quote-i" fill="#fff">
                    <circle cx="43.296" cy="43.207" r="43.1" />
                  </mask>
                  <circle
                    cx="43.296"
                    cy="43.207"
                    r="43.1"
                    stroke="url(#quote-f)"
                    strokeWidth="6.25"
                    filter="url(#quote-h)"
                    opacity=".185"
                  />
                  <image
                    width="86"
                    height="86"
                    x=".4"
                    y="2.2"
                    mask="url(#quote-i)"
                    xlinkHref={data.desktop.childImageSharp.gatsbyImageData.src}
                  />
                </g>
                <path
                  stroke="#4A5D72"
                  strokeDasharray="50 54"
                  strokeLinecap="round"
                  strokeWidth="5"
                  d="M54.8,107.739476 C84.0158633,107.739476 107.7,84.0553397 107.7,54.8394765 C107.7,25.6236132 84.0158633,1.93947648 54.8,1.93947648 C25.5841367,1.93947648 1.9,25.6236132 1.9,54.8394765 C1.9,84.0553397 25.5841367,107.739476 54.8,107.739476 Z"
                  opacity=".215"
                />
                <path
                  stroke="#4A5D72"
                  strokeDasharray="5 100"
                  strokeLinecap="round"
                  strokeWidth="5"
                  d="M54.8,107.739476 C84.0158633,107.739476 107.7,84.0553397 107.7,54.8394765 C107.7,25.6236132 84.0158633,1.93947648 54.8,1.93947648 C25.5841367,1.93947648 1.9,25.6236132 1.9,54.8394765 C1.9,84.0553397 25.5841367,107.739476 54.8,107.739476 Z"
                  opacity=".215"
                  transform="rotate(-92 54.8 54.84)"
                />
              </g>
              <text fill="#1D354F" fillOpacity=".9" fontFamily="Geomanist" fontSize="18">
                <tspan x="282" y="131.593">
                  “We wanted to convert a large scale project to dot net core to reduce ongoing
                  fleet costs. The Observian{' '}
                </tspan>
                <tspan x="282" y="158.593">
                  team helped guide our solution to the one that fit our needs and contained best
                  practices to make sure{' '}
                </tspan>
                <tspan x="282" y="185.593">
                  our total cost of ownership in terms of maintenance and day to day usability
                  stayed low. They also helped{' '}
                </tspan>
                <tspan x="282" y="212.593">
                  us gain the skills in the areas we needed to make sure we were empowered to
                  manage, modify, and{' '}
                </tspan>
                <tspan x="282" y="239.593">
                  enhance the solution after they finished the project. ”
                </tspan>
              </text>
              <text fill="#1D354F" fillOpacity=".9" fontFamily="Geomanist" fontSize="16">
                <tspan x="85.256" y="237.625">
                  William Broadhead{' '}
                </tspan>
                <tspan x="55.448" y="258.593" fontSize="13.5">
                  Sr. Dir Engineering @ CrownPeak
                </tspan>
              </text>
            </g>
            <polygon
              fill="url(#quote-l)"
              points="54.772 327.677 170.326 367.333 620.659 324.708 173.431 351.984 54.772 317.483 1 352.408"
              filter="url(#quote-m)"
              opacity=".7"
              transform="rotate(-4 310.83 342.408)"
            />
            <polygon
              fill="url(#quote-l)"
              points="54.772 323.677 170.326 363.333 620.659 320.708 173.431 347.984 54.772 313.483 1 348.408"
            />
          </g>
        </svg>
      </div>
      <div className={style.text}>
        <p className={style.name}>William Broadhead</p>
        <p className={style.title}>Sr. Dir Engineering @ CrownPeak</p>
      </div>
    </section>
  )
}

export default Quote
