import * as React from 'react'

function SlidesProduct(props) {
  return (
    <svg width={58} height={81} viewBox="0 0 58 81" xmlns="http://www.w3.org/2000/svg">
      <title>{'F30B5203-04CE-4081-A283-E9E87E35BB99'}</title>
      <g fillRule="nonzero" fill="none" opacity={0.153}>
        <path
          d="M52.552 80.213H5.713A5.402 5.402 0 01.31 74.81V6.369A5.402 5.402 0 015.713.966H38.14l19.816 19.811V74.81a5.402 5.402 0 01-5.404 5.403z"
          fill="#FFBA00"
        />
        <path fill="#FF9500" d="M38.14.966l19.816 19.811H38.14z" />
        <path
          d="M42.644 31.584H11.118V55.899h36.03V31.584h-4.504zm0 19.812H15.62v-15.31h27.023v15.31z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default SlidesProduct
