import { Component } from 'react'
import clsx from 'clsx'
import { gsap, Elastic } from 'gsap'
import { Physics2DPlugin } from 'gsap/Physics2DPlugin'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import Logo from 'observian-ui/lib/logo'
import Button from 'observian-ui/lib/button'
import Divider from 'observian-ui/lib/divider'
import ContactModal from 'observian-ui/lib/modal/contact'
import { buildFormUrl } from '../../../utils/formRedirect'

import OfficeForm from 'components/Form/Office365'
import canUseDOM from 'utils/canUseDOM'

import style from './hero.module.css'

class Hero extends Component {
  state = {
    showModal: false,
  }

  handleIsSuccessful = (value) => {
    this.setState({ isSuccessful: value })
  }

  handleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
      isSuccessful: false,
    }))
  }

  componentDidMount = () => {
    if (canUseDOM) {
      gsap.registerPlugin(Physics2DPlugin)
      const tl = gsap.timeline()
      const confettiElement = document.querySelector(`.${style.confetti}`)
      const confettiContainer = document.createElement('div')
      confettiContainer.className = style.confettiContainer
      confettiElement.appendChild(confettiContainer)

      const clearItems = () => {
        while (confettiContainer.firstChild) {
          confettiContainer.removeChild(confettiContainer.firstChild)
        }
      }
      gsap.set(`.${style.image}`, { perspective: 400 })

      this.timeout = setTimeout(() => {
        gsap.set(`.${style.image}`, { opacity: 1 })
        gsap.from(`.${style.image}`, {
          x: '-=550',
          y: '-=120',
          rotationX: -90,
          rotationY: 20,
          opacity: 0,
          ease: Elastic.easeOut.config(1, 1),
          duration: 3,
          stagger: 0.07,
        })
      }, 1900)

      const clearConfetti = debounce(clearItems, 3000, { leading: false, trailing: true })

      const confetti = () => {
        const dots = gsap.timeline({ onStart: clearConfetti })

        const colors = ['#04EAAE', '#1B92B9', '#1D354F', '#2EFFF8', '#F7FBFB']

        times(45, () => {
          const dot = document.createElement('div')
          dot.className = style.dot
          confettiContainer.appendChild(dot)
          const color = colors[(Math.random() * colors.length) | 0] // eslint-disable-line

          gsap.set(dot, {
            backgroundColor: color,
            rotation: Math.floor(Math.random() * 160) + 1,
            width: Math.floor(Math.random() * 16) + 4,
            height: Math.floor(Math.random() * 16) + 4,
            x: Math.floor(Math.random() * 16),
            y: '+=40',
          })

          dots.to(
            dot,
            {
              rotation: Math.floor(Math.random() * 360) + 1,
              physics2D: {
                velocity: Math.floor(Math.random() * 500) + 100,
                angle: Math.floor(Math.random() * 360) + 1,
                gravity: 700,
              },
              opacity: 0,
              duration: 5,
            },
            0.05
          )
        })
        return dots
      }

      tl.fromTo(`.${style.contentItem}`, { opacity: 0 }, { opacity: 1, duration: 1, stagger: 0.4 })
      tl.fromTo(
        `.${style.confetti}, .${style.thumb}`,
        { scale: 0, transformOrigin: '50% 50%', opacity: 0 },
        {
          opacity: 1,
          scale: 1,
          ease: Elastic.easeOut.config(1, 0.7),
          onStart: confetti,
          duration: 1.25,
        },
        '-=1'
      )
      tl.to(
        `.${style.title}, .${style.titlespace}, .${style.description}, .${style.button}`,
        {
          opacity: 1,
          duration: 1,
        },
        '-=0.5'
      )
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout.id)
    }
  }

  render() {
    const { showModal, isSuccessful } = this.state

    return (
      <section className={style.hero}>
        <div className={style.container}>
          <div className={style.content}>
            <header className={style.header}>
              <img
                className={clsx(style.contentItem, style.gsuite)}
                src="/images/office365/officelogo.png"
                alt="Office 365"
              />
              <span className={clsx(style.contentItem, style.add)}>+</span>
              <Logo className={clsx(style.contentItem, style.logo)} />
              <span className={clsx(style.contentItem, style.equals)}> = </span>
              <div className={style.contentItem}>
                <div className={style.confetti} />
                <img className={style.thumb} alt="Thumb Emoji" src="/images/thumb.png" />
                <svg
                  className={style.thumbBG}
                  width="116px"
                  height="123px"
                  viewBox="0 0 116 123"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <linearGradient
                      x1="12.4534884%"
                      y1="84.9243045%"
                      x2="87.8953488%"
                      y2="16.3295925%"
                      id="thumbEmojiBG"
                    >
                      <stop stopColor="#1D8DBA" offset="0%" />
                      <stop stopColor="#03ECAE" offset="100%" />
                    </linearGradient>
                  </defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity="0.289248512"
                  >
                    <g
                      transform="translate(-471.000000, -265.000000)"
                      fill="url(#thumbEmojiBG)"
                      fillRule="nonzero"
                    >
                      <g transform="translate(471.000000, 265.000000)">
                        <polygon points="37.5605469 41.7484304 12.2792969 41.7484304 24.9199219 60.1510051 0.91015625 84.213855 32.8730469 88.882459 32.8730469 123.023553 76.3339844 96.6850861 111.083984 96.6850861 72.4667969 72.2844662 115.433594 39.7129531 72.4667969 43.4166951 76.3339844 0.310770615" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </header>
            <h1 className={style.title}>Unleash Productivity with Office 365</h1>
            <svg
              width="169px"
              height="8px"
              viewBox="0 0 169 8"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className={style.titlespace}
            >
              <defs>
                <linearGradient
                  x1="-5.30225943%"
                  y1="50.287961%"
                  x2="52.3022132%"
                  y2="49.7787897%"
                  id="linearGradienttop-1"
                >
                  <stop stopColor="#1D8DBA" offset="0%" />
                  <stop stopColor="#03ECAE" offset="100%" />
                </linearGradient>
              </defs>
              <g id="Office365" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon
                  id="Path-103"
                  fill="url(#linearGradienttop-1)"
                  points="0 0 73.5859948 2.41666445 169 6.43355266 73.9928305 5.58562544 5.15557242 8"
                />
              </g>
            </svg>
            <p className={style.description}>
              Observian offers <b>free support</b> and <b>licensing discounts</b> on{' '}
              <b>Microsoft Office 365</b>
              &nbsp;solutions. Collaborate, communicate, and customize your space with all the tools
              in the Microsoft suite. With Microsoft Office, you can work confidently knowing your
              data is protected with Enterprise-level security, compliance, and scalability.
            </p>
            <Button 
              className={style.button} 
              label="Get a Quote" 
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: `Office365 - Get a Quote`
                }
              ])}
            />
            {showModal ? (
              <ContactModal
                showClose
                shouldCloseOnOverlayClick
                onClose={this.handleModal}
                onDismiss={this.handleModal}
                isOpen={showModal}
                closeButtonPosition="right"
                ariaHideApp={false}
                success={isSuccessful}
              >
                <OfficeForm />
              </ContactModal>
            ) : null}
          </div>
          <div className={style.images}>
            <img
              alt="admin"
              src="/images/office365/logos/excel.png"
              className={clsx(style.image, style.excel)}
            />
            <img
              alt="calendar"
              src="/images/office365/logos/onedrive.png"
              className={clsx(style.image, style.onedrive)}
            />
            <img
              alt="contacts"
              src="/images/office365/logos/onenote.png"
              className={clsx(style.image, style.onenote)}
            />
            <img
              alt="docs"
              src="/images/office365/logos/outlook.png"
              className={clsx(style.image, style.outlook)}
            />
            <img
              alt="drive"
              src="/images/office365/logos/powerpoint.png"
              className={clsx(style.image, style.powerpoint)}
            />
            <img
              alt="forms"
              src="/images/office365/logos/sharepoint.png"
              className={clsx(style.image, style.sharepoint)}
            />
            <img
              alt="gmail"
              src="/images/office365/logos/teams.png"
              className={clsx(style.image, style.teams)}
            />
            <img
              alt="groups"
              src="/images/office365/logos/word.png"
              className={clsx(style.image, style.word)}
            />
            <img
              alt="hangouts"
              src="/images/office365/logos/yammer.png"
              className={clsx(style.image, style.yammer)}
            />
          </div>
        </div>
        <Divider containerClassName={style.laser}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1409" height="183" viewBox="0 0 1409 183">
            <defs>
              <linearGradient
                id="divider-laser-a"
                x1="15.436%"
                x2="114.319%"
                y1="90.518%"
                y2="21.413%"
              >
                <stop offset="0%" stopColor="#FFC42A" />
                <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
              </linearGradient>
              <filter
                id="divider-laser-b"
                width="104.3%"
                height="174.2%"
                x="-2.1%"
                y="-37.1%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
              </filter>
            </defs>
            <g fill="url(#divider-laser-a)" transform="translate(0 12)">
              <polygon
                points="1.155 117.506 509.459 36.651 912.705 79.695 1071.78 78.401 1403.889 44.82 923.461 117.084 715.999 68.87 498.537 50.657"
                filter="url(#divider-laser-b)"
                opacity=".8"
                transform="rotate(-3 702.522 77.079)"
              />
              <polygon points="1.155 117.506 509.459 36.651 912.705 79.695 1071.78 78.401 1403.889 44.82 923.461 117.084 715.999 68.87 498.537 50.657" />
            </g>
          </svg>
        </Divider>
      </section>
    )
  }
}

export default Hero
