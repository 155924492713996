import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import PartnersSEO from 'templates/SEO/Partner'

const options = {
  renderText: (text) =>
    text.split('\n').reduce(
      (children, textSegment, index) => [...children, 0 < index && <br key={index} />, textSegment], // eslint-disable-line react/no-array-index-key
      []
    ),
}

const PartnersTemplate = ({ pageContext }) => (
  <>
    <Layout title={pageContext.title} description={pageContext.page.description} keywords="">
      <PartnersSEO
        logo={pageContext.logo.fluid.src}
        name={pageContext.name}
        type={pageContext.type.type}
        url={pageContext.url}
        description={documentToReactComponents(JSON.parse(pageContext.description.raw), options)}
      />
    </Layout>
  </>
)

PartnersTemplate.defaultProps = {}

PartnersTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default PartnersTemplate
