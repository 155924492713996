import PropTypes from 'prop-types'

const Lasers = ({ className, lasersClassName }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1300"
    height="541"
    viewBox="100 -20 1300 541"
  >
    <defs>
      <linearGradient id="hero_lasers-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="hero_lasers-b"
        width="109.3%"
        height="152.4%"
        x="-4.6%"
        y="-26.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="hero_lasers-c"
        width="105.9%"
        height="166.6%"
        x="-3%"
        y="-33.3%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="hero_lasers-d"
        width="105.8%"
        height="172.9%"
        x="-2.9%"
        y="-36.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
    </defs>
    <g fill="url(#hero_lasers-a)" fillRule="evenodd" transform="translate(0 6)">
      <polygon
        className={lasersClassName.laser}
        points="989.352 16.885 1287.961 131.476 978.807 73.204 639.723 68.77"
        filter="url(#hero_lasers-b)"
        opacity="0.4"
        transform="rotate(3 963.842 74.18)"
      />
      <polygon
        className={lasersClassName.laser1}
        points="0 196.502 536.787 48.935 986.34 12.29 1289.221 110.011 975.795 59.61 536.787 68.61"
        opacity="0.85"
      />
      <polygon
        className={lasersClassName.laser2}
        points="210.172 385.528 555.621 428.792 1219.828 377.737 562.237 467.784"
        filter="url(#hero_lasers-c)"
        opacity="0.6"
        transform="rotate(6 715 422.76)"
      />
      <polygon
        className={lasersClassName.laser3}
        points="198.535 375.961 543.984 419.225 1234.293 387.651 550.601 458.218"
        filter="url(#hero_lasers-d)"
        opacity="0.1"
        transform="rotate(14 716.414 417.09)"
      />
      <polygon
        className={lasersClassName.laser4}
        points="219 393.974 564.449 437.239 1211 369.29 571.065 476.231"
        opacity="0.77"
      />
    </g>
  </svg>
)

Lasers.defaultProps = {
  className: '',
  lasersClassName: {
    laser: 'laser',
    laser1: 'laser1',
    laser2: 'laser2',
    laser3: 'laser3',
    laser4: 'laser4',
  },
}

Lasers.propTypes = {
  className: PropTypes.string,
  lasersClassName: PropTypes.shape({
    laser: PropTypes.string,
    laser1: PropTypes.string,
    laser2: PropTypes.string,
    laser3: PropTypes.string,
    laser4: PropTypes.string,
  }),
}

export default Lasers
