import { useState } from 'react'
import DoItBetter from 'components/DoItBetter'
import MainCloud from 'components/SVG/clouds/mainCloud'

import SubHero from './SubHero/index'
import style from './hero.module.css'

const Partners = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <section className={style.hero}>
        <span role="presentation" alt="Hero Background Left" className={style.topLeftBG} />
        <span role="presentation" alt="Hero Background Right" className={style.bottomRightBG} />
        <header className={style.header}>
          <DoItBetter />

          <svg
            className={style.particles}
            width="967px"
            height="336px"
            viewBox="0 0 967 336"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <linearGradient x1="0%" y1="0%" x2="102%" y2="101%" id="homeHeroGradient">
                <stop stopColor="#FFC42A" offset="0%" />
                <stop stopColor="#FF0061" stopOpacity="0.95" offset="100%" />
              </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.26">
              <g transform="translate(-259.000000, -112.000000)" fill="url(#homeHeroGradient)">
                <g transform="translate(-26.000000, -337.000000)">
                  <g transform="translate(282.533999, 448.945750)">
                    <polygon
                      stroke="url(#homeHeroGradient)"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(11.064195, 50.474883) rotate(-13.000000) translate(-11.064195, -50.474883) "
                      points="11.0641954 43.4096187 20.0265698 57.5401469 2.10182103 57.5401469"
                    />
                    <polygon
                      stroke="url(#homeHeroGradient)"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(97.305436, 326.732565) rotate(20.000000) translate(-97.305436, -326.732565) "
                      points="97.305436 321.033707 104.230209 332.431424 90.3806629 332.431424"
                    />
                    <polygon
                      stroke="url(#homeHeroGradient)"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(858.543590, 179.109875) rotate(625.000000) translate(-858.543590, -179.109875) "
                      points="858.54359 170.379406 869.618297 187.840344 847.468883 187.840344"
                    />
                    <polygon
                      stroke="url(#homeHeroGradient)"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(252.269588, 304.287841) rotate(302.000000) translate(-252.269588, -304.287841) "
                      points="252.269588 301.274696 255.930908 307.300986 248.608268 307.300986"
                    />
                    <polygon
                      stroke="url(#homeHeroGradient)"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(960.618708, 47.868189) rotate(334.000000) translate(-960.618708, -47.868189) "
                      points="960.618708 43.4715881 965.961087 52.2647895 955.276329 52.2647895"
                    />
                    <circle cx="128.463281" cy="94.5092773" r="2.68994141" />
                    <circle cx="672.6625" cy="263.310059" r="2.68994141" />
                    <circle cx="412.6625" cy="3.31005859" r="2.68994141" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </header>
        <MainCloud className={style.cloud} />
      </section>
      <section>
        <SubHero showModal={showModal} setShowModal={setShowModal} />
      </section>
    </>
  )
}

export default Partners
