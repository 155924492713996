import { Contact } from 'observian-ui/lib/svg/icons'

import TextUnderline from 'components/SVG/textUnderline'
import SupportForm from 'components/Form/Support'

import style from './contact.module.css'

const ContactInfo = () => (
  <>
    <section className={style.container}>
      <div className={style.contact}>
        <h2 className={style.contactTitle}>Observian Workspace Support</h2>
        <svg
          className={style.topLaser}
          xmlns="http://www.w3.org/2000/svg"
          width="543"
          height="153"
          viewBox="0 0 543 153"
        >
          <defs>
            <linearGradient
              id="top-form-laser-a"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="top-form-laser-b"
              width="111.6%"
              height="173.1%"
              x="-5.8%"
              y="-36.6%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="url(#top-form-laser-a)" transform="translate(4 16)">
            <polygon
              points="521.165 78.973 416.054 18 2.23 100.047 370.516 49.801 415.775 71.99"
              filter="url(#top-form-laser-b)"
              opacity=".323"
              transform="rotate(-4 261.698 59.023)"
            />
            <polygon points="521.932 67.973 416.053 17.973 2.229 100.02 370.515 49.774 416.053 67.973" />
          </g>
        </svg>
        <SupportForm className={style.form} ctaIntentSuffix="page" />
        <svg
          className={style.bottomEdge}
          xmlns="http://www.w3.org/2000/svg"
          width="624"
          height="66"
          viewBox="0 0 624 66"
        >
          <path
            fill="#F6FBFA"
            d="M-1.50102153e-13,0 L624,0 L624,53.0333555 C624,54.6902098 622.656854,56.0333555 621,56.0333555 C620.876131,56.0333555 620.75238,56.0256837 620.629459,56.0103842 L504.479385,41.5535796 L332.239491,38.8944625 L104.379582,66 L1.74140405,18.56354 C0.679723123,18.0728612 -1.53436322e-13,17.0099047 -1.54098956e-13,15.8403184 L-1.50102153e-13,0 Z"
          />
        </svg>
        <svg
          className={style.bottomLaser}
          xmlns="http://www.w3.org/2000/svg"
          width="465"
          height="138"
          viewBox="0 0 465 138"
        >
          <defs>
            <linearGradient
              id="bottom-form-laser-a"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="bottom-form-laser-b"
              width="110%"
              height="146.1%"
              x="-5%"
              y="-23%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="7.5" />
            </filter>
          </defs>
          <g fill="url(#bottom-form-laser-a)" transform="translate(6 3)">
            <polygon
              points="3.557 76.454 215.565 117.347 451.588 19.653 215.565 90.532"
              filter="url(#bottom-form-laser-b)"
              opacity=".5"
              transform="rotate(-2 227.572 68.5)"
            />
            <polygon
              points="3.557 76.454 215.565 117.347 451.588 19.653 215.565 90.532"
              transform="rotate(-5 227.572 68.5)"
            />
          </g>
        </svg>
      </div>
    </section>
    <section className={style.location}>
      <div className={style.info}>
        <span className={style.title}>Observian</span>
        <TextUnderline className={style.textUnderline} />
        <a
          className={style.address}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.google.com/maps/place/Observian,+Inc./@40.7400222,-111.9863894,17z/data=!3m1!4b1!4m5!3m4!1s0x8752f3110eb2c4db:0x744729f4723d085e!8m2!3d40.7400182!4d-111.9842007"
        >
          1338 South Gustin Road
          <br />
          Salt Lake City, UT 84104
        </a>
        <a className={style.phone} rel="noopener noreferrer" href="tel:801-895-3219">
          <Contact type="phone" className={style.icon} /> 801-895-3219
        </a>
      </div>
      <div className={style.map}>
        <img
          className={style.mapImage}
          src="/images/contact/observian-map.png"
          alt="Observian Physical Location"
        />
      </div>
    </section>
  </>
)

ContactInfo.defaultProps = {}

ContactInfo.propTypes = {}

export default ContactInfo
