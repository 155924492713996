import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import { gsap, Power0 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import Modal from 'observian-ui/lib/modal'
import { Link } from 'gatsby'
import { buildFormUrl } from '../../../utils/formRedirect'
import CaseStudy from 'observian-ui/lib/caseStudy'

import { playAnimation, pauseAnimation } from 'utils/gsapAnimations'
import BigData from 'components/SVG/clouds/bigDataAnalytics'
import CloudMigration from 'components/SVG/clouds/cloudMigration'
import CostOptimization from 'components/SVG/clouds/costOptimization'
import DevOps from 'components/SVG/clouds/devOps'
import MicrosoftOnAws from 'components/SVG/clouds/microsoftOnAws'
import SecurityCompliance from 'components/SVG/clouds/securityCompliance'
//import HubspotMeeting from 'components/Hubspot/MeetingPlugin'

import ContactForm from 'components/Form/Contact'
import ContactModal from 'observian-ui/lib/modal/contact'

import BackgroundLaser from 'components/SVG/lasers/backgroundLaser'

import style from './sites.module.css'

const caseStudies = [
  {
    logo: '/images/logos/evidon.png',
    description: 'Evidon guides companies to better data governance by utilizing AWS services.',
    url: 'https://observian-casestudies.s3.amazonaws.com/AWS+DevOps+-+Evidon+1.1.pdf',
  },
  {
    logo: '/images/logos/traeger.png',
    description:
      'Traeger is reinventing the grilling industry with wifi (WiFIRE) connected grills.',
    url: 'https://observian-casestudies.s3.amazonaws.com/AWS+Big+Data+-+Traeger+1.1.pdf',
  },
  {
    logo: '/images/logos/cve.png',
    description: 'CVE gained service reliability by migrating to a windows EC2 instance.',
    url: 'https://observian-casestudies.s3.amazonaws.com/AWS+Windows+EC2+-+CVE+1.1.pdf',
  },
]

class Sites extends Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  componentDidMount = () => {
    gsap.registerPlugin(DrawSVGPlugin)
    const devOps = gsap.timeline({ paused: true })
    const bigData = gsap.timeline({ paused: true })
    const securityCompliance = gsap.timeline({ paused: true })
    const microsoft = gsap.timeline({ paused: true })
    const cost = gsap.timeline({ paused: true })
    const cost1 = gsap.timeline({ repeat: -1, paused: true })
    const cloud = gsap.timeline({ repeat: -1, paused: true })
    const cloud1 = gsap.timeline({ repeat: -1, paused: true })
    const cloud2 = gsap.timeline({ repeat: -1, paused: true })
    const cloud3 = gsap.timeline({ repeat: -1, paused: true })

    devOps.to(`.${style.cog1}`, {
      rotation: 360,
      transformOrigin: '50% 50%',
      repeat: -1,
      ease: Power0.easeNone,
      duration: 6,
    })

    devOps.to(
      `.${style.cog2}`,
      { rotation: 360, transformOrigin: '50% 50%', repeat: -1, ease: Power0.easeNone, duration: 5 },
      0
    )

    devOps.to(
      `.${style.cog3}`,
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        repeat: -1,
        ease: Power0.easeNone,
        duration: 3,
      },
      0
    )

    devOps.to(
      `.${style.cog4}`,
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        repeat: -1,
        ease: Power0.easeNone,
        duration: 2,
      },
      0
    )

    bigData.to(`.${style.circleGraph}`, {
      x: '-=5',
      y: '+=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 2,
    })

    bigData.to(
      `.${style.pieGraph}`,
      { x: '-=10', y: '+=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 5 },
      0
    )

    bigData.to(
      `.${style.lineGraph}`,
      { x: '+=5', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 7 },
      0
    )

    securityCompliance.to(`.${style.key}, .${style.lock}`, {
      x: '+=5',
      y: '-=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 2,
    })

    securityCompliance.to(
      `.${style.safe}`,
      { x: '-=10', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 5 },
      0
    )

    microsoft.to(`.${style.logo1}`, { x: '+=10', y: '-=5', repeat: -1, yoyo: true, duration: 7 }, 0)
    microsoft.to(`.${style.logo2}`, { x: '-=5', y: '+=5', repeat: -1, yoyo: true, duration: 4 }, 0)
    microsoft.to(`.${style.logo3}`, { x: '+=5', y: '-=8', repeat: -1, yoyo: true, duration: 4 }, 0)

    cost.to(
      `.${style.line2}`,
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.65 },
      0
    )

    cost.from(
      `.${style.line1}`,
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.85 },
      0
    )

    cost.to(
      `.${style.dollar1}`,
      { x: '+=8', y: '-=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0
    )

    cost.to(
      `.${style.dollar2}`,
      { x: '-=5', y: '+=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0
    )

    cost1.fromTo(
      `.${style.dollars}`,
      { opacity: 0.25 },
      { opacity: 1, duration: 0.25, stagger: 0.25 }
    )

    cloud.to(`.${style.cloudLine1}`, { opacity: 1, duration: 0.15 })
    cloud.fromTo(
      `.${style.cloudLine2}`,
      { drawSVG: '0% 0%', opacity: 0 },
      { drawSVG: '0% 5%', opacity: 1, ease: Power0.easeNone, duration: 0.1 }
    )

    cloud.fromTo(
      `.${style.cloudLine2}`,
      { drawSVG: '0% 10%' },
      { drawSVG: '50% 50%', ease: Power0.easeNone, duration: 0.35 }
    )

    cloud.to(`.${style.cloudLine2}`, { opacity: 0, duration: 0.15 })

    cloud1.to(`.${style.cloudLine3}`, { opacity: 1, duration: 0.1 })

    cloud1.fromTo(
      `.${style.cloudLine4}`,
      { drawSVG: '0% 0%', opacity: 0 },
      { opacity: 1, drawSVG: '0% 5%', ease: Power0.easeNone, duration: 0.1 }
    )

    cloud1.fromTo(
      `.${style.cloudLine4}`,
      { drawSVG: '0% 5%' },
      { drawSVG: '50% 50%', ease: Power0.easeNone, duration: 0.45 }
    )

    cloud1.to(`.${style.cloudLine4}`, { opacity: 0, duration: 0.1 })

    cloud2.to(`.${style.smallImage}`, {
      x: '+=10',
      y: '+=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 3,
    })

    cloud3.to(`.${style.largeImage}`, {
      x: '+=5',
      y: '+=8',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 4,
    })

    this.position1 = bigData
    this.position2 = cloud
    this.position21 = cloud1
    this.position22 = cloud2
    this.position23 = cloud3
    this.position3 = cost
    this.position31 = cost1
    this.position4 = devOps
    this.position5 = microsoft
    this.position6 = securityCompliance
  }

  handleOpenModal = () => {
    this.setState({
      showModal: true,
    })
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    })
  }

  handleSetSuccessful = (v) => {
    this.setState({
      isSuccessful: v,
    })
  }

  render() {
    const { showModal, isSuccessful } = this.state
    const { title, subTitle, description } = this.props
    const moreText = 'Learn More &rarr;'

    return (
      <section>
        <section className={style.caseStudySection}>
          <div className={style.caseStudy}>
            <div className={style.caseStudyContent}>
              <h2>AWS & OBERVIAN CASE STUDIES</h2>
              <div className={style.caseStudies}>
                {caseStudies.map(({ logo, description: caseStudyDescription, url }, index) => (
                  <CaseStudy
                    downloadText="Read More"
                    key={`case:study:${index + 1}`}
                    logo={logo}
                    description={caseStudyDescription}
                    url={url}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className={style.section}>
          <div className={style.header}>
            <h5>{title}</h5>
            <h5>{subTitle}</h5>
          </div>
          <div
            className={style.position1}
            onMouseEnter={() => playAnimation(this.position1)}
            onMouseLeave={() => pauseAnimation(this.position1)}
          >
            <Link className={style.link} to="/aws/big-data-analytics">
              <BigData
                classNames={{
                  pieGraph: style.pieGraph,
                  circleGraph: style.circleGraph,
                  lineGraph: style.lineGraph,
                }}
              />
              <h2 className={style.serviceTitle}>Big Data & Analytics</h2>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />
            </Link>
          </div>
          <div
            className={style.position2}
            onMouseEnter={() =>
              playAnimation([this.position2, this.position21, this.position22, this.position23])
            }
            onMouseLeave={() =>
              pauseAnimation([this.position2, this.position21, this.position22, this.position23])
            }
          >
            <Link className={style.link} to="/aws/cloud-migration">
              <CloudMigration
                classNames={{
                  line1: style.cloudLine1,
                  line2: style.cloudLine2,
                  line3: style.cloudLine3,
                  line4: style.cloudLine4,
                  smallImage: style.smallImage,
                  largeImage: style.largeImage,
                }}
              />
              <h2 className={style.serviceTitle}>Cloud Migration</h2>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />
            </Link>
          </div>
          <div
            className={style.position3}
            onMouseEnter={() => playAnimation([this.position3, this.position31])}
            onMouseLeave={() => pauseAnimation([this.position3, this.position31])}
          >
            <Link className={style.link} to="/aws/cost-optimization">
              <CostOptimization
                classNames={{
                  dollar1: style.dollar1,
                  dollar2: style.dollar2,
                  dollars: style.dollars,
                  line1: style.line1,
                  line2: style.line2,
                }}
              />
              <h2 className={style.serviceTitle}>Cost Optimization</h2>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />
            </Link>
          </div>
          <BackgroundLaser className={style.laser} />
          <div
            className={style.position4}
            onMouseEnter={() => playAnimation(this.position4)}
            onMouseLeave={() => pauseAnimation(this.position4)}
          >
            <Link className={style.link} to="/aws/devops">
              <DevOps
                classNames={{
                  cog1: style.cog1,
                  cog2: style.cog2,
                  cog3: style.cog3,
                  cog4: style.cog4,
                }}
              />
              <h2 className={style.serviceTitle}>DevOps</h2>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />
            </Link>
          </div>
          <div
            className={style.position5}
            onMouseEnter={() => playAnimation(this.position5)}
            onMouseLeave={() => pauseAnimation(this.position5)}
          >
            <Link className={style.link} to="/aws/microsoft-on-aws">
              <MicrosoftOnAws
                classNames={{
                  logo1: style.logo1,
                  logo2: style.logo2,
                  logo3: style.logo3,
                }}
              />
              <h2 className={style.serviceTitle}>Microsoft On AWS</h2>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />
            </Link>
          </div>
          <div
            className={style.position6}
            onMouseEnter={() => playAnimation(this.position6)}
            onMouseLeave={() => pauseAnimation(this.position6)}
          >
            <Link className={style.link} to="/aws/security-compliance">
              <SecurityCompliance
                classNames={{
                  lock: style.lock,
                  key: style.key,
                  safe: style.safe,
                }}
              />
              <h2 className={style.serviceTitle}>Security & Compliance</h2>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />
            </Link>
          </div>
          <div className={style.description}>
            <p>{description}</p>
          </div>
          <div className={style.button}>
            <Button
              role="button"
              label="Contact Observian"
              onClick={() => window.location.href = buildFormUrl([
                {
                  key: 'CTA',
                  value: 'AWS - Contact Observian'
                }
              ])}
            />
          </div>
          {showModal ? (
            <Modal isTransparent isOpen={showModal} onClose={(e) => this.handleCloseModal(e)}>
              <ContactModal
                showClose
                shouldCloseOnOverlayClick
                onClose={(e) => this.handleCloseModal(e)}
                onDismiss={(e) => this.handleCloseModal(e)}
                isOpen={showModal}
                closeButtonPosition="right"
                ariaHideApp={false}
                success={isSuccessful}
              >
                <ContactForm
                  showLogo
                  headerTitle="Contact Us"
                  buttonLabel="Send"
                  ctaIntentSuffix="modal-aws-partners-benefits"
                  inquiryPlaceholder="I'd like to schedule a discovery call with Observian."
                  isSuccessfulCallback={(v) => {
                    this.handleSetSuccessful(v)
                    if (!v) {
                      this.handleCloseModal(false)
                    }
                  }}
                />
              </ContactModal>
            </Modal>
          ) : null}
        </section>
      </section>
    )
  }
}

Sites.defaultProps = {
  title:
    "If you've read this far, you have probably figured out that Observian knows AWS really well.",
  subTitle: 'Here are the AWS consulting services we offer:',
  description:
    'As an AWS Consulting Partner, we help you succeed in your journey to the cloud. Whether you’re looking to the cloud for innovation, cost savings, agility, ops efficiency, or all the above, we can help you do it right. No matter the scale of your migration, we’ve got your back. Let’s talk today about your tomorrow.',
}

Sites.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
}

export default Sites
