import { func, bool } from 'prop-types'
import { Link } from 'gatsby'
import Button from 'observian-ui/lib/button'

import Floaty from './Floaty'
import TechLines from './TechLinesSvg'
import ParticleGroup from './ParticleGroupSvg'
import SvgListIcon from './ListIcon'
import style from './subHero.module.css'

const HeroManagedSecurity = ({ showModal, setShowModal }) => (
  <section className={style.subHero}>
    <div className={style.subHeroContent}>
      <ParticleGroup className={style.particleGroup} />
      <Floaty className={style.floater} />
      <TechLines className={style.techLines} />
      <span className={style.msspLearnMore}>
        <Link to="/aws/managed-security">
          <Button varient="primary" label="Learn More" />
        </Link>
      </span>
      <div className={style.msList}>
        <h3 className={style.title}>AWS Launch Partner</h3>
        <p className={style.subTitle}>Level 1 Managed Security Service</p>

        <div className={style.lists}>
          <ul className={style.subHeroList}>
            <li>
              <SvgListIcon /> AWS Infrastructure Vulnerability Scanning
            </li>
            <li>
              <SvgListIcon /> AWS Resource Inventory Visibility
            </li>
            <li>
              <SvgListIcon />
              AWS Security Best Practices Monitoring
            </li>
            <li>
              <SvgListIcon />
              AWS Compliance Monitoring
            </li>
            <li>
              <SvgListIcon />
              24/7 Incident Alerting and Response
            </li>
          </ul>
          <ul className={style.subHeroList}>
            <li>
              <SvgListIcon />
              Monitor, Triage Security Events
            </li>
            <li>
              <SvgListIcon />
              Distributed Denial of Service (DDoS) Mitigation
            </li>
            <li>
              <SvgListIcon />
              Managed Intrusion Prevention System (IPS)
            </li>
            <li>
              <SvgListIcon />
              Managed Detection and Response for AWS Endpoints
            </li>
            <li>
              <SvgListIcon />
              Managed Web Application Firewall (WAF)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

HeroManagedSecurity.propTypes = {
  setShowModal: func.isRequired,
  showModal: bool.isRequired,
}

export default HeroManagedSecurity
