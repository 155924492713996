import Divider from 'observian-ui/lib/divider'
import Button from 'observian-ui/lib/button'

import GoogleCloud from 'components/SVG/clouds/googleCloud'
import BGGlyph from 'components/SVG/backgroundGlyph'

import style from './hero.module.css'

const Hero = () => (
  <section className={style.hero}>
    <BGGlyph className={style.glyph} />
    <div className={style.content}>
      <header>
        <h1 className={style.title}>Google Cloud Platform</h1>
      </header>
      <p className={style.description}>
        Observian can help your team achieve the agility you want along with the security and
        reliability that your business needs with Google Cloud. From team workshops to full cloud
        migrations, Observian has you covered.
        <br />
        <br />
        Interested in Managed GCP Services? &nbsp; &nbsp;
        <Button
          varient="primary"
          outline
          label="Managed Google Cloud"
          href="/google/managed-service"
        />
      </p>
    </div>
    {/* <TechLines className={style.techLines} /> */}
    <Divider containerClassName={style.googleCloudContainer}>
      <GoogleCloud className={style.googleCloud} />
    </Divider>
  </section>
)

export default Hero
