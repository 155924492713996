import style from './partnerSites.module.css'

const PartnerSites = () => (
  <div>
    <section className={style.section}>
      <img className={style.image} src="/images/logos/jazz-logo.png" alt="jazz-logo" />
      <img className={style.image} src="/images/logos/talkdesk-logo.png" alt="talkdesk-logo" />
      <img className={style.image} src="/images/logos/filevine-logo.png" alt="filevine-logo" />
      <img className={style.image} src="/images/logos/liveview-logo.png" alt="liveview-logo" />
    </section>
    <section className={style.section2}>
      <img className={style.image} src="/images/logos/orem-logo.png" alt="orem-logo" />
      <img className={style.image} src="/images/logos/stgeorge-logo.png" alt="stgeorge-logo" />
      <img className={style.image} src="/images/logos/advanced-logo.png" alt="advanced-logo" />
    </section>
  </div>
)

export default PartnerSites
