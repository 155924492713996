import { Component } from 'react'
import { gsap, Power0 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import CaseStudy from 'observian-ui/lib/caseStudy'

import style from './sites.module.css'

const caseStudies = [
  {
    logo: '/images/logos/evidon.png',
    description: 'Evidon guides companies to better data governance by utilizing AWS services.',
    url: 'https://observian-casestudies.s3.amazonaws.com/AWS+DevOps+-+Evidon+1.1.pdf',
  },
  {
    logo: '/images/logos/traeger.png',
    description:
      'Traeger is reinventing the grilling industry with wifi (WiFIRE) connected grills.',
    url: 'https://observian-casestudies.s3.amazonaws.com/AWS+Big+Data+-+Traeger+1.1.pdf',
  },
  {
    logo: '/images/logos/cve.png',
    description: 'CVE gained service reliability by migrating to a windows EC2 instance.',
    url: 'https://observian-casestudies.s3.amazonaws.com/AWS+Windows+EC2+-+CVE+1.1.pdf',
  },
]

class Sites extends Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  componentDidMount = () => {
    gsap.registerPlugin(DrawSVGPlugin)
    const devOps = gsap.timeline({ paused: true })
    const bigData = gsap.timeline({ paused: true })
    const securityCompliance = gsap.timeline({ paused: true })
    const microsoft = gsap.timeline({ paused: true })
    const cost = gsap.timeline({ paused: true })
    const cost1 = gsap.timeline({ repeat: -1, paused: true })
    const cloud = gsap.timeline({ repeat: -1, paused: true })
    const cloud1 = gsap.timeline({ repeat: -1, paused: true })
    const cloud2 = gsap.timeline({ repeat: -1, paused: true })
    const cloud3 = gsap.timeline({ repeat: -1, paused: true })

    devOps.to(`.${style.cog1}`, {
      rotation: 360,
      transformOrigin: '50% 50%',
      repeat: -1,
      ease: Power0.easeNone,
      duration: 6,
    })

    devOps.to(
      `.${style.cog2}`,
      { rotation: 360, transformOrigin: '50% 50%', repeat: -1, ease: Power0.easeNone, duration: 5 },
      0
    )

    devOps.to(
      `.${style.cog3}`,
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        repeat: -1,
        ease: Power0.easeNone,
        duration: 3,
      },
      0
    )

    devOps.to(
      `.${style.cog4}`,
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        repeat: -1,
        ease: Power0.easeNone,
        duration: 2,
      },
      0
    )

    bigData.to(`.${style.circleGraph}`, {
      x: '-=5',
      y: '+=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 2,
    })

    bigData.to(
      `.${style.pieGraph}`,
      { x: '-=10', y: '+=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 5 },
      0
    )

    bigData.to(
      `.${style.lineGraph}`,
      { x: '+=5', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 7 },
      0
    )

    securityCompliance.to(`.${style.key}, .${style.lock}`, {
      x: '+=5',
      y: '-=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 2,
    })

    securityCompliance.to(
      `.${style.safe}`,
      { x: '-=10', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 5 },
      0
    )

    microsoft.to(`.${style.logo1}`, { x: '+=10', y: '-=5', repeat: -1, yoyo: true, duration: 7 }, 0)
    microsoft.to(`.${style.logo2}`, { x: '-=5', y: '+=5', repeat: -1, yoyo: true, duration: 4 }, 0)
    microsoft.to(`.${style.logo3}`, { x: '+=5', y: '-=8', repeat: -1, yoyo: true, duration: 4 }, 0)

    cost.to(
      `.${style.line2}`,
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.65 },
      0
    )

    cost.from(
      `.${style.line1}`,
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.85 },
      0
    )

    cost.to(
      `.${style.dollar1}`,
      { x: '+=8', y: '-=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0
    )

    cost.to(
      `.${style.dollar2}`,
      { x: '-=5', y: '+=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0
    )

    cost1.fromTo(
      `.${style.dollars}`,
      { opacity: 0.25 },
      { opacity: 1, duration: 0.25, stagger: 0.25 }
    )

    cloud.to(`.${style.cloudLine1}`, { opacity: 1, duration: 0.15 })
    cloud.fromTo(
      `.${style.cloudLine2}`,
      { drawSVG: '0% 0%', opacity: 0 },
      { drawSVG: '0% 5%', opacity: 1, ease: Power0.easeNone, duration: 0.1 }
    )

    cloud.fromTo(
      `.${style.cloudLine2}`,
      { drawSVG: '0% 10%' },
      { drawSVG: '50% 50%', ease: Power0.easeNone, duration: 0.35 }
    )

    cloud.to(`.${style.cloudLine2}`, { opacity: 0, duration: 0.15 })

    cloud1.to(`.${style.cloudLine3}`, { opacity: 1, duration: 0.1 })

    cloud1.fromTo(
      `.${style.cloudLine4}`,
      { drawSVG: '0% 0%', opacity: 0 },
      { opacity: 1, drawSVG: '0% 5%', ease: Power0.easeNone, duration: 0.1 }
    )

    cloud1.fromTo(
      `.${style.cloudLine4}`,
      { drawSVG: '0% 5%' },
      { drawSVG: '50% 50%', ease: Power0.easeNone, duration: 0.45 }
    )

    cloud1.to(`.${style.cloudLine4}`, { opacity: 0, duration: 0.1 })

    cloud2.to(`.${style.smallImage}`, {
      x: '+=10',
      y: '+=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 3,
    })

    cloud3.to(`.${style.largeImage}`, {
      x: '+=5',
      y: '+=8',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 4,
    })

    this.position1 = bigData
    this.position2 = cloud
    this.position21 = cloud1
    this.position22 = cloud2
    this.position23 = cloud3
    this.position3 = cost
    this.position31 = cost1
    this.position4 = devOps
    this.position5 = microsoft
    this.position6 = securityCompliance
  }

  render() {
    return (
      <section>
        <section className={style.caseStudySection}>
          <div className={style.caseStudy}>
            <div className={style.caseStudyContent}>
              <h2>AWS & OBSERVIAN CASE STUDIES</h2>
              <div className={style.caseStudies}>
                {caseStudies.map(({ logo, description: caseStudyDescription, url }, index) => (
                  <CaseStudy
                    downloadText="Read More"
                    key={`case:study:${index + 1}`}
                    logo={logo}
                    description={caseStudyDescription}
                    url={url}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

export default Sites
