import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Contact from 'observian-ui/lib/svg/icons/contact'
import Button from 'observian-ui/lib/button'
import Logo from 'observian-ui/lib/logo'
import { Form as FinalForm, Field } from 'react-final-form'
import omit from 'lodash/omit'

import { submitForm, submitAdLandingForm } from 'api/form'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isEmail, isRequired } from 'utils/validation'

import style from './contactForm.module.css'

const ContactUsForm = ({
  className,
  inquiryPlaceholder,
  headerTitle,
  showLogo,
  showContactInfo,
  buttonLabel,
  ctaIntent,
  isSuccessfulMessage,
  ctaIntentSuffix,
  isSuccessfulCallback,
  isLandingPageForm,
}) => {
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [domLoaded, setDomLoaded] = useState(false)

  const handleOnSubmit = async (event) => {
    const [firstName, lastName = ''] = event.fullName.split(' ')

    const inquiry = undefined === event.inquiry ? inquiryPlaceholder : event.inquiry
    const ctaIntentValue =
      0 === ctaIntentSuffix.length ? event.ctaIntent : `${event.ctaIntent}-${ctaIntentSuffix}`

    const validEvent = omit(event, 'fullName')
    const allFormData = {
      ...validEvent,
      firstName,
      lastName,
      inquiry,
      ctaIntent: ctaIntentValue,
      googleKeyword: localStorage.getItem('keyword') || '',
      keywordDate: localStorage.getItem('date') || '',
      utmSource: localStorage.getItem('utm_source') || '',
      utmMedium: localStorage.getItem('utm_medium') || '',
      utmCampaign: localStorage.getItem('utm_campaign') || '',
      utmContent: localStorage.getItem('utm_content') || '',
      utmTerm: localStorage.getItem('utm_term') || '',
    }

    // data-layer script stuff
    if (window) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'leadSubmit',
        email: allFormData.email,
        firstName: allFormData.firstName,
        lastName: allFormData.lastName,
      })
    }

    try {
      let res = null
      if (isLandingPageForm) {
        res = await submitAdLandingForm({ ...allFormData })
        if (200 === res.status) {
          return renderSubmission()
        }
      } else {
        res = await submitForm({ ...allFormData })
        if (200 === res.status) {
          return renderSubmission()
        }
        return console.error(res.data.message || 'Unknown Error.')
      }
    } catch (error) {
      return console.error(error)
    }
  }

  const renderSubmission = (value = true) => {
    handleCallback(value)
    setIsSuccessful(value)
  }

  const handleOnDismiss = () => renderSubmission(false)

  const handleCallback = (value = false) => {
    if (isSuccessfulCallback) {
      window?.dataLayer?.push('Ad Landing Submit')
      return isSuccessfulCallback(value)
    }

    return null
  }

  return (
    <HubspotTracking>
      {({ hsContext }) => (
        <>
          {!isSuccessful ? (
            <FinalForm
              validateOnBlur
              onSubmit={(e) => handleOnSubmit({ ...e, hsContext })}
              initialValues={{ ctaIntent }}
            >
              {({ handleSubmit, pristine, submitting }) => (
                <form className={clsx(style.form, className)} onSubmit={handleSubmit}>
                  <header className={style.header}>
                    <h3>{headerTitle}</h3>
                    {showLogo ? (
                      <Logo colorLogo className={style.logo} data-testid="form-logo" />
                    ) : null}
                  </header>
                  <div className={style.contactForm}>
                    <Field name="fullName" placeholder="Name" validate={isRequired}>
                      {({ input, meta, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact type="person" className={style.inputIcon} />
                          <input {...input} placeholder={placeholder} id={input.name} />
                          {meta.error && meta.touched && (
                            <span
                              data-testid="form-fullName-error"
                              className={style.validationError}
                            >
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="email" placeholder="email@example.com" validate={isEmail}>
                      {({ input, meta, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact type="email-alt" className={style.inputIcon} />
                          <input
                            {...input}
                            placeholder={placeholder}
                            id={input.name}
                            type="email"
                          />
                          {meta.error && meta.touched && (
                            <span data-testid="form-email-error" className={style.validationError}>
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="company" placeholder="Company Name" validate={isRequired}>
                      {({ input, meta, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact type="building" className={style.inputIcon} />
                          <input {...input} placeholder={placeholder} id={input.name} />
                          {meta.error && meta.touched && (
                            <span
                              data-testid="form-company-error"
                              className={style.validationError}
                            >
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="inquiry" placeholder={inquiryPlaceholder}>
                      {({ input, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact
                            type="message"
                            className={clsx(style.inputIcon, style.multiLineIcon)}
                          />
                          <textarea {...input} rows="5" placeholder={placeholder} id={input.name} />
                        </div>
                      )}
                    </Field>
                    <Field name="ctaIntent" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                  </div>
                  <div className={clsx(style.actions, showContactInfo && style.showContactInfo)}>
                    {showContactInfo ? (
                      <>
                        <div data-testid="form-contact-info" className={style.contactInfo}>
                          <a rel="noopener noreferrer" href="mailto:info@observian.com">
                            info@observian.com
                          </a>
                          <a
                            className={style.phone}
                            rel="noopener noreferrer"
                            href="tel:801-895-3219"
                          >
                            801-895-3219
                          </a>
                          <a
                            className={style.address}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.google.com/maps/place/Observian,+Inc./@40.7400222,-111.9863894,17z/data=!3m1!4b1!4m5!3m4!1s0x8752f3110eb2c4db:0x744729f4723d085e!8m2!3d40.7400182!4d-111.9842007"
                          >
                            1338 South Gustin Road
                            <br />
                            Salt Lake City, UT 84104
                          </a>
                        </div>
                        <div className={style.hrBlock} />
                      </>
                    ) : null}
                    <Button
                      type="primary"
                      label={buttonLabel}
                      className={clsx(
                        style.buttonCTA,
                        showContactInfo && style.showContactInfoButton,
                        `cta-tracking`
                      )}
                      disabled={pristine || submitting}
                    />
                  </div>
                </form>
              )}
            </FinalForm>
          ) : (
            <div className={style.success}>
              <span data-testid="form-success-message">{isSuccessfulMessage}</span>
              <Button
                type="cancel"
                label="Done"
                onClick={() => handleOnDismiss()}
                className={style.successButtonCTA}
              />
            </div>
          )}
        </>
      )}
    </HubspotTracking>
  )
}

ContactUsForm.defaultProps = {
  className: '',
  inquiryPlaceholder: 'Inquiry Message',
  headerTitle: 'Send Us a Message',
  showLogo: false,
  showContactInfo: false,
  buttonLabel: 'Send Message',
  isSuccessfulMessage: "Your inquiry has been sent, we'll be in touch soon!",
  ctaIntent: 'contact-us-form',
  ctaIntentSuffix: '',
  isSuccessfulCallback: () => {},
  isLandingPageForm: false,
}

ContactUsForm.propTypes = {
  className: PropTypes.string,
  inquiryPlaceholder: PropTypes.string,
  headerTitle: PropTypes.string,
  showLogo: PropTypes.bool,
  showContactInfo: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
  isSuccessfulCallback: PropTypes.func,
  isLandingPageForm: PropTypes.bool,
}

export default ContactUsForm
