import CaseStudy from 'observian-ui/lib/caseStudy'

import icon from '../../../../static/images/FileIcon.svg'

import style from './CaseStudies.module.css'

const caseStudies = [
  {
    logo: icon,
    title: 'AWS MSSP Level 1',
    description: 'Product Intelligence Platform Securely Scales on Observian Managed Security.',
    url: 'https://observian-casestudies.s3.amazonaws.com/ANON-Observian-MSSP-CaseStudy21.pdf',
  },
  {
    logo: icon,
    title: 'FortiCNP with AWS Solutions Guide',
    description: 'Manage Cloud-Native Risk Through Actionable Insights',
    url: 'https://www.fortinet.com/content/dam/fortinet/assets/solution-guides/sb-forticnp-for-aws.pdf',
  },
  {
    logo: icon,
    title: 'FortiCNF with AWS Solutions Guide',
    description: 'Secure Your Migration to AWS with a Cloud-Native Managed Firewall Service',
    url: 'https://www.fortinet.com/content/dam/fortinet/assets/solution-guides/sb-fortigate-cloud-native-firewall.pdf',
  },
]

const CaseStudies = () => (
  <section className={style.wrapper}>
    <div className={style.caseStudySection}>
      <div className={style.caseStudy}>
        <div className={style.caseStudyContent}>
          <h2>Case Studies For AWS Managed Security</h2>
          <div className={style.caseStudies}>
            {caseStudies.map(({ logo, description: caseStudyDescription, url, title }, index) => (
              <CaseStudy
                downloadText="Download"
                key={`case:study:${index + 1}`}
                title={title}
                description={caseStudyDescription}
                url={url}
              />
            ))}
          </div>
          <div className={style.fortiVideo}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/KTQ_n9HjYuA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CaseStudies
