const googleProducts = [
  {
    name: 'Microsoft Outlook',
    description:
      'Organize mail, calendar, contacts, and tasks with Microsoft Outlook. Download the desktop app to take full advantage of this program or use the mobile app on the go to stay fully connected.',
    icon: '/images/office365/logos/outlooklogo.png',
  },
  {
    name: 'Microsoft Word',
    description:
      'Use the power of Word to work on documents on your own or collaborate with your peers. Makes notes in the margins to keep track of any changes or messages you need to send to other collaborators.',
    icon: '/images/office365/logos/wordlogo.png',
  },
  {
    name: 'Microsoft Excel',
    description:
      'With Excel’s advanced calculation, graphing tools, pivot tables and macro programming you’ll get the data you need in no time.',
    icon: '/images/office365/logos/excellogo.png',
  },
  {
    name: 'Microsoft PowerPoint',
    description:
      'Tired of reading slide after slide in a presentation? Use PowerPoint to organize slide presentations and keep separate notes only you can see to make your presentations more engaging and exciting.',
    icon: '/images/office365/logos/powerpointlogo.png',
  },
  {
    name: 'OneDrive',
    description:
      'OneDrive is your personal storage located in the cloud and able to be accessed from any device you have. With the ability to store files offline and sync when you return to internet access, feel the confidence to work anywhere regardless of network connectivity.',
    icon: '/images/office365/logos/onedrivelogo.png',
  },
  {
    name: 'Sharepoint',
    description:
      'Securely store, organize, share, and access any data across your whole organization and from any device. Unlike traditional storage, have the ability to have permissions, version control, and share files within or outside your organization.',
    icon: '/images/office365/logos/sharepointlogo.png',
  },
  {
    name: 'Teams',
    description:
      'Teams is the ultimate collaboration tool. Put everything together with chat, call, meet, and file sharing to increase productivity and employee satisfaction in your organization.',
    icon: '/images/office365/logos/teamslogo.png',
  },
  {
    name: 'OneNote',
    description:
      'Take all your notes and ideas and put them all in one place. Keep all things organized with different sheets and pages. Forgot where you put something? Try using the powerful search feature to never lose a step with your notes.',
    icon: '/images/office365/logos/onenotelogo.png',
  },
]

export default googleProducts
