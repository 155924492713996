import * as React from 'react'

function DriveProduct(props) {
  return (
    <svg width={63} height={56} viewBox="0 0 63 56" xmlns="http://www.w3.org/2000/svg">
      <title>{'4D51C532-52D9-402E-B8EB-208035E1FD9F'}</title>
      <g fillRule="nonzero" fill="none" opacity={0.153}>
        <path
          d="M5.317 48.063l2.716 4.691c.565.988 1.376 1.764 2.329 2.328l9.702-16.789H.66a6.39 6.39 0 00.847 3.175l3.81 6.595z"
          fill="#0066DA"
        />
        <path
          d="M31.459 18.541L21.757 1.752c-.952.565-1.764 1.34-2.328 2.328L1.507 35.12a6.39 6.39 0 00-.847 3.174h19.404l11.395-19.752z"
          fill="#00AC47"
        />
        <path
          d="M31.459 18.541L41.16 1.752a6.204 6.204 0 00-3.175-.846H24.932a6.57 6.57 0 00-3.175.846l9.702 16.79z"
          fill="#00832D"
        />
        <path
          d="M42.854 38.293h-22.79l-9.702 16.79a6.203 6.203 0 003.175.846h35.844a6.57 6.57 0 003.175-.847l-9.702-16.789z"
          fill="#2684FC"
        />
        <path
          d="M52.556 55.082c.952-.564 1.764-1.34 2.328-2.328l1.13-1.94 5.397-9.346a6.39 6.39 0 00.847-3.175H42.889l9.667 16.79z"
          fill="#EA4335"
        />
        <path
          d="M52.45 19.6L43.49 4.08c-.565-.987-1.377-1.763-2.33-2.328l-9.701 16.79 11.395 19.751h19.368a6.39 6.39 0 00-.846-3.174l-8.926-15.52z"
          fill="#FFBA00"
        />
      </g>
    </svg>
  )
}

export default DriveProduct
