const SvgMalwareViruses = (props) => (
  <svg width="117px" height="144px" viewBox="0 0 117 144" {...props}>
    <defs>
      <linearGradient
        x1="15.4356061%"
        y1="68.1975427%"
        x2="114.318971%"
        y2="37.1612429%"
        id="malware-viruses-a"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="96.8002019%"
        y1="46.9617483%"
        x2="16.8773886%"
        y2="53.4908223%"
        id="malware-viruses-b"
      >
        <stop stopColor="#1C3148" offset="0%" />
        <stop stopColor="#1C3047" offset="4.60318317%" />
        <stop stopColor="#0F172D" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="15.4356061%"
        y1="54.5485123%"
        x2="114.318971%"
        y2="46.7909269%"
        id="malware-viruses-c"
      >
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity={0.946473053} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="49.9710113%"
        y1="16.4208607%"
        x2="49.9710113%"
        y2="95.6048979%"
        id="malware-viruses-d"
      >
        <stop stopColor="#F2EED1" offset="0%" />
        <stop stopColor="#F0ECCE" offset="33%" />
        <stop stopColor="#E8E4C5" offset="57%" />
        <stop stopColor="#DBD7B5" offset="78%" />
        <stop stopColor="#C9C59F" offset="97%" />
        <stop stopColor="#C6C29B" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-1170 -3031) translate(106 2702) translate(0 272) translate(1064 57)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="url(#malware-viruses-a)"
        fillRule="nonzero"
        d="M-3.55271368e-14 65.1675H116.57999999999996V143.29500000000002H-3.55271368e-14z"
      />
      <path
        fill="#FFFAFB"
        opacity={0.337913603}
        d="M-3.55271368e-14 143.295L58.29 104.235 116.58 143.295z"
      />
      <path
        fill="url(#malware-viruses-b)"
        d="M-3.55271368e-14 65.1675L58.29 104.235 116.58 65.1675z"
      />
      <path
        fill="url(#malware-viruses-c)"
        d="M116.58 65.1675L58.29 26.1075 -3.55271368e-14 65.1675z"
      />
      <path
        d="M74.94 52.965a6.202 6.202 0 00-6.203 6.202v17.79a6.202 6.202 0 0012.406 0v-17.79a6.202 6.202 0 00-6.203-6.202zM64.5 76.957v-17.79a6.202 6.202 0 00-6.203-6.202V83.16a6.202 6.202 0 006.203-6.203z"
        fill="#C6C29B"
        fillRule="nonzero"
      />
      <path
        d="M95.063 36.75C95.045 19.603 83.172 4.746 66.45.95 49.728-2.847 32.603 5.424 25.182 20.883c-7.42 15.458-3.165 33.994 10.256 44.667v11.385a6.202 6.202 0 1012.404 0V72a36 36 0 004.245.99v3.938a6.203 6.203 0 1012.406 0V73.02c17.672-3.015 30.59-18.342 30.57-36.27z"
        fill="url(#malware-viruses-d)"
        fillRule="nonzero"
      />
      <path
        d="M58.29 0v73.545c20.309 0 36.772-16.464 36.772-36.773C95.062 16.465 78.6 0 58.29 0z"
        fill="#C6C29B"
        fillRule="nonzero"
      />
      <path
        d="M49.583 28.755v9.75s0 2.723-5.1 2.723H35.94s-5.73.112-2.287-5.836c3.442-5.947 7.41-11.557 7.41-11.557s.314-.93 3.292-.93c2.978 0 5.228.12 5.228 5.85zM58.29 45.877h-.307c-3 0-3.293.69-3.293.69s-1.44 3.683-3.052 8.475c-1.613 4.793 2.294 4.283 2.294 4.283h4.358V45.877z"
        fill="#5B4559"
        fillRule="nonzero"
      />
      <path
        d="M66.998 28.755v9.75s0 2.723 5.1 2.723h8.534s5.738.112 2.295-5.836c-3.442-5.947-7.417-11.557-7.417-11.557s-.315-.93-3.285-.93-5.227.12-5.227 5.85zM58.29 45.877h.307c3 0 3.285.69 3.285.69s1.448 3.66 3.06 8.475c1.613 4.816-2.294 4.283-2.294 4.283H58.29V45.877z"
        fill="#3E2F3C"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgMalwareViruses
