import * as React from 'react'

function DocsProduct(props) {
  return (
    <svg width={64} height={88} viewBox="0 0 64 88" xmlns="http://www.w3.org/2000/svg">
      <title>{'4F56B9F3-5A16-4AAC-8289-58F350C760D6'}</title>
      <g fillRule="nonzero" fill="none" opacity={0.153}>
        <path
          d="M57.498 87.185H5.975A5.943 5.943 0 01.03 81.24V5.956A5.943 5.943 0 015.975.012h35.67l21.798 21.794V81.24a5.943 5.943 0 01-5.945 5.944z"
          fill="#2684FC"
        />
        <path fill="#0066DA" d="M41.644.012l21.799 21.794H41.644z" />
        <path
          fill="#FFF"
          d="M49.571 33.693h-35.67v4.953h35.67zM49.571 45.58h-35.67v4.953h35.67zM39.663 57.467H13.9v4.953h25.762z"
        />
      </g>
    </svg>
  )
}

export default DocsProduct
