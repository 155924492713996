import style from './partnerSites.module.css'

const PartnerSites = () => (
  <section className={style.section}>
    <img className={style.image} src="/images/logos/evidon.png" alt="evidon-logo" />
    <img className={style.image} src="/images/logos/traeger.png" alt="traeger-logo" />
    <img className={style.image} src="/images/logos/chg-logo.png" alt="chg-logo" />
    <img className={style.image} src="/images/logos/webflow-logo.png" alt="webflow-logo" />
    <img className={style.image} src="/images/logos/amplitude-logo.png" alt="amplitude-logo" />

    <img className={style.image} src="/images/logos/wgu-logo.png" alt="evidon-logo" />
    <img className={style.image} src="/images/logos/prestige-logo.png" alt="prestige-logo" />
    <img className={style.image} src="/images/logos/lhmmegaplex-logo.png" alt="lhmmegaplex-logo" />
    <img className={style.image} src="/images/logos/cve.png" alt="cve-logo" />
    <img className={style.image} src="/images/logos/merrick-logo.png" alt="merrick-logo" />
  </section>
)

export default PartnerSites
