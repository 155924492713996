const submitGsuiteFormMutation = `
  mutation SubmitHubspotForm($form: HubspotFormSubmission!) {
    submitHubspotForm(form: $form) {
      redirectUrl
      inlineMessage
      errors {
        message
        errorType
      }
    }
  }
`

export default submitGsuiteFormMutation
