import PropTypes from 'prop-types'

const CloudMigration = ({ className, classNames }) => (
  <svg
    className={className}
    width="221px"
    height="185px"
    viewBox="0 0 221 185"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="12.4534884%"
        y1="84.9243045%"
        x2="87.8953488%"
        y2="16.3295925%"
        id="cloud-migration-gradient"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <filter
        x="-119.4%"
        y="-86.4%"
        width="338.8%"
        height="338.6%"
        filterUnits="objectBoundingBox"
        id="cloud-migration-filter"
      >
        <feMorphology
          radius="0.375"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dx="0" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.230666893 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <polygon points="15.13225 83.814875 10.16425 94.747625 21.09475 89.781875" />
      <filter
        x="-99.5%"
        y="-72.0%"
        width="299.0%"
        height="298.9%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology
          radius="0.375"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dx="0" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.230666893 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-5.000000, 0.000000)">
        <image
          className={classNames.smallImage}
          x="100.232422"
          y="0.47421875"
          width="91.1425781"
          height="48.0515625"
          xlinkHref="/images/clouds/migration-cloud.png"
        />
        <g transform="translate(34.871884, 32.500000)" fillRule="nonzero">
          <g id="bottom" transform="translate(0.000000, 55.000000)">
            <path
              d="M71.6281161,37 C71.5701161,27.408 71.5701161,22.407 71.6281161,21.997 L3.7515,21.997 L3.7515,37 L7.497,37 L11.2485,29.5 L64.0539274,29.4985 L67.7726794,37 L71.6281161,37 Z"
              fillOpacity="0.32"
              fill="#04EAAE"
            />
            <rect fill="url(#cloud-migration-gradient)" x="0" y="0" width="75.0015" height="22.5" />
            <circle fill="#FFFFFF" cx="67.5" cy="15.5" r="1.5" />
            <circle fill="#FFFFFF" cx="61.5015" cy="15.5015" r="1.5015" />
            <circle fill="#FFFFFF" cx="55.5015" cy="15.5015" r="1.5015" />
            <rect fill="#04EAAE" x="6" y="11" width="18.75" height="2.253" />
            <rect fill="#04EAAE" x="28" y="11" width="22.5" height="2.253" />
            <rect fill="#04EAAE" x="65" y="11" width="4.5" height="2.253" />
            <rect fill="#04EAAE" x="28" y="17" width="22.5" height="2.25" />
            <rect fill="#04EAAE" x="6" y="17" width="5.6235" height="2.25" />
            <rect
              strokeOpacity="0.191293025"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fillOpacity="0.459663723"
              fill="#4A5D72"
              x="6.3125"
              y="6.3125"
              width="18.125"
              height="3.8735"
            />
            <rect
              strokeOpacity="0.186509284"
              stroke="#145186"
              strokeWidth="0.625"
              fillOpacity="0.265030571"
              fill="#04EAAE"
              x="6.3125"
              y="14.3125"
              width="4.9985"
              height="2.378"
            />
            <rect fill="#157494" x="28" y="6" width="4.5015" height="4.4985" />
            <rect
              strokeOpacity="0.191293025"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fillOpacity="0.459663723"
              fill="#4A5D72"
              x="33.3125"
              y="6.3125"
              width="17.378"
              height="3.8735"
            />
            <rect
              strokeOpacity="0.186509284"
              stroke="#145186"
              strokeWidth="0.625"
              fillOpacity="0.634199502"
              fill="#F6FBFA"
              x="28.3125"
              y="14.3125"
              width="21.875"
              height="2.378"
            />
            <rect
              strokeOpacity="0.169100996"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fill="#329DC0"
              x="65.3125"
              y="6.3125"
              width="3.875"
              height="3.8735"
            />
          </g>
          <g id="middle" transform="translate(0.000000, 27.500000)">
            <rect
              fillOpacity="0.315698596"
              fill="#04EAAE"
              x="4"
              y="22"
              width="67.4985"
              height="5.625"
            />
            <rect fill="url(#cloud-migration-gradient)" x="0" y="0" width="75.0015" height="22.5" />
            <circle fill="#FFFFFF" cx="67.5" cy="15.5" r="1.5" />
            <circle fill="#FFFFFF" cx="61.5015" cy="15.5015" r="1.5015" />
            <circle fill="#FFFFFF" cx="55.5015" cy="15.5015" r="1.5015" />
            <rect fill="#04EAAE" x="6" y="11" width="18.75" height="2.253" />
            <rect fill="#04EAAE" x="28" y="11" width="22.5" height="2.253" />
            <rect fill="#04EAAE" x="65" y="11" width="4.5" height="2.253" />
            <rect fill="#04EAAE" x="28" y="17" width="22.5" height="2.25" />
            <rect fill="#04EAAE" x="6" y="17" width="5.6235" height="2.25" />
            <rect
              strokeOpacity="0.191293025"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fillOpacity="0.459663723"
              fill="#4A5D72"
              x="6.3125"
              y="6.3125"
              width="18.125"
              height="3.8735"
            />
            <rect
              strokeOpacity="0.186509284"
              stroke="#145186"
              strokeWidth="0.625"
              fillOpacity="0.265030571"
              fill="#04EAAE"
              x="6.3125"
              y="14.3125"
              width="4.9985"
              height="2.378"
            />
            <rect fill="#157494" x="28" y="6" width="4.5015" height="4.4985" />
            <rect
              strokeOpacity="0.191293025"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fillOpacity="0.459663723"
              fill="#4A5D72"
              x="33.3125"
              y="6.3125"
              width="17.378"
              height="3.8735"
            />
            <rect
              strokeOpacity="0.186509284"
              stroke="#145186"
              strokeWidth="0.625"
              fillOpacity="0.634199502"
              fill="#F6FBFA"
              x="28.3125"
              y="14.3125"
              width="21.875"
              height="2.378"
            />
            <rect
              strokeOpacity="0.169100996"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fill="#329DC0"
              x="65.3125"
              y="6.3125"
              width="3.875"
              height="3.8735"
            />
          </g>
          <g id="top">
            <rect
              fillOpacity="0.315698596"
              fill="#04EAAE"
              x="3.7515"
              y="22.7515"
              width="67.4985"
              height="5.625"
            />
            <rect
              fill="url(#cloud-migration-gradient)"
              x="0"
              y="0.25"
              width="75.0015"
              height="22.5"
            />
            <circle fill="#FFFFFF" cx="67.8735" cy="15.625" r="1.5" />
            <circle fill="#FFFFFF" cx="61.8735" cy="15.625" r="1.5015" />
            <circle fill="#FFFFFF" cx="55.8735" cy="15.625" r="1.5015" />
            <rect fill="#04EAAE" x="5.625" y="10.3735" width="18.75" height="2.253" />
            <rect fill="#04EAAE" x="28.1265" y="10.3735" width="22.5" height="2.253" />
            <rect fill="#04EAAE" x="64.8735" y="10.3735" width="4.5" height="2.253" />
            <rect fill="#04EAAE" x="28.1265" y="17.125" width="22.5" height="2.25" />
            <rect fill="#04EAAE" x="5.625" y="17.125" width="5.6235" height="2.25" />
            <rect
              strokeOpacity="0.191293025"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fillOpacity="0.459663723"
              fill="#4A5D72"
              x="5.9375"
              y="6.1875"
              width="18.125"
              height="3.8735"
            />
            <rect
              strokeOpacity="0.186509284"
              stroke="#145186"
              strokeWidth="0.625"
              fillOpacity="0.265030571"
              fill="#04EAAE"
              x="5.9375"
              y="14.4375"
              width="4.9985"
              height="2.378"
            />
            <rect fill="#157494" x="28.1265" y="5.875" width="4.5015" height="4.4985" />
            <rect
              strokeOpacity="0.191293025"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fillOpacity="0.459663723"
              fill="#4A5D72"
              x="32.9405"
              y="6.1875"
              width="17.378"
              height="3.8735"
            />
            <rect
              strokeOpacity="0.186509284"
              stroke="#145186"
              strokeWidth="0.625"
              fillOpacity="0.634199502"
              fill="#F6FBFA"
              x="28.439"
              y="14.4375"
              width="21.875"
              height="2.378"
            />
            <rect
              strokeOpacity="0.169100996"
              stroke="#4A5D72"
              strokeWidth="0.625"
              fill="#329DC0"
              x="65.186"
              y="6.1875"
              width="3.875"
              height="3.8735"
            />
          </g>
        </g>
        <image
          className={classNames.largeImage}
          x="0.0400390625"
          y="65.2285156"
          width="227.856445"
          height="120.128906"
          xlinkHref="/images/clouds/migration-cloud.png"
        />
        <g id="dottedlines" transform="translate(108.871884, 22.500000)">
          <g id="top-arrow" strokeLinecap="round" strokeLinejoin="round" fillRule="nonzero">
            <polygon
              points="23.3378125 9.3149375 27.4721875 0.2005625 18.3653125 4.3424375"
              fill="black"
              fillOpacity="1"
              filter="url(#cloud-migration-filter)"
            />
            <polygon
              points="23.3378125 9.3149375 27.4721875 0.2005625 18.3653125 4.3424375"
              strokeOpacity="0.5"
              stroke="#4A5D72"
              strokeWidth="0.75"
              fill="url(#cloud-migration-gradient)"
            />
          </g>
          <path
            className={classNames.line1}
            d="M0.9955,15.875 C8.359,15.875 16.0965731,12.1689481 20.8410731,7.26094813 C16.0965731,12.1674481 8.359,15.875 0.9955,15.875 Z"
            strokeOpacity="0.5"
            stroke="#4A5D72"
            strokeWidth="1"
          />
          <path
            className={classNames.line2}
            d="M0.9955,15.875 C8.359,15.875 16.0965731,12.1689481 20.8410731,7.26094813 C16.0965731,12.1674481 8.359,15.875 0.9955,15.875 Z"
            stroke="#4A5D72"
            strokeWidth="3"
            strokeLinecap="round"
            strokeDasharray="1"
          />
          <g id="bottom-arrow" strokeLinecap="round" strokeLinejoin="round" fillRule="nonzero">
            <polygon
              points="15.13225 83.814875 10.16425 94.747625 21.09475 89.781875"
              fill="black"
              fillOpacity="1"
              filter="url(#cloud-migration-filter)"
            />
            <polygon
              points="15.13225 83.814875 10.16425 94.747625 21.09475 89.781875"
              strokeOpacity="0.5"
              stroke="#4A5D72"
              strokeWidth="0.75"
              fill="url(#cloud-migration-gradient)"
            />
          </g>
          <path
            className={classNames.line3}
            d="M0.9955,42.1235 C15.1405,42.1235 26.6005,53.5865 26.6065,67.7285 C26.6065,74.9525 23.2759354,82.2309872 18.4654354,86.8899872 C23.2759354,82.2324872 26.6065,74.954 26.6065,67.7285 C26.6005,53.5865 15.1405,42.1235 0.9955,42.1235 Z"
            strokeOpacity="0.5"
            stroke="#4A5D72"
            strokeWidth="1"
            strokeLinecap="round"
          />
          <path
            className={classNames.line4}
            d="M0.9955,42.1235 C15.1405,42.1235 26.6005,53.5865 26.6065,67.7285 C26.6065,74.9525 23.2759354,82.2309872 18.4654354,86.8899872 C23.2759354,82.2324872 26.6065,74.954 26.6065,67.7285 C26.6005,53.5865 15.1405,42.1235 0.9955,42.1235 Z"
            stroke="#4A5D72"
            strokeWidth="3"
            strokeLinecap="round"
            strokeDasharray="1"
          />
        </g>
        <polygon
          className={classNames.largeImage}
          fill="url(#cloud-migration-gradient)"
          points="122.347168 144.6875 171.066406 146.1875 196.371582 116.180176 168.066406 143.328125 138.800293 139.39209"
        />
        <polygon
          className={classNames.largeImage}
          fill="url(#cloud-migration-gradient)"
          points="45.5714111 139.858643 24.7310791 110.306885 43.4605713 143.881104 54.6109619 142.003174 51.5963135 139.858643"
        />
        <polygon
          className={classNames.largeImage}
          fill="url(#cloud-migration-gradient)"
          points="154.24231 90.296936 164.516724 89.6557007 150.134923 107.089355 161.355591 91.8758545 158.890442 91.8758545 157.325823 90.5941162"
        />
        <polygon
          className={classNames.smallImage}
          fill="url(#cloud-migration-gradient)"
          points="154.024414 31.7019043 170.802734 25.3063965 170.802734 16.0251465 172.618408 26.8063965"
        />
        <polygon
          className={classNames.smallImage}
          fill="url(#cloud-migration-gradient)"
          points="128.997925 10.2934875 122.347168 12.7232056 130.161743 19.4677124 125.389282 13.2670288 128.019775 11.8724976"
        />
      </g>
    </g>
  </svg>
)

CloudMigration.defaultProps = {
  className: '',
  classNames: {
    line1: 'line1',
    line2: 'line2',
    line3: 'line3',
    line4: 'line4',
    smallImage: 'smallImage',
    largeImage: 'largeImage',
  },
}

CloudMigration.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    smallImage: PropTypes.string,
    largeImage: PropTypes.string,
  }),
}

export default CloudMigration
