import { Component } from 'react'
import { TimelineMax, Power0 } from 'gsap'

import Layout from 'components/Layout'
import ServicesPageTemplate from 'templates/Page/Services'
import SecurityCompliance from 'components/SVG/clouds/securityCompliance'

class ContainersPage extends Component {
  componentDidMount() {
    const securityCompliance = new TimelineMax()
    securityCompliance.to('.key, .lock', 2, {
      x: '+=5',
      y: '-=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
    })

    securityCompliance.to(
      '.safe',
      5,
      { x: '-=10', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone },
      0
    )
  }

  render() {
    return (
      <Layout title="Containers" description="">
        <ServicesPageTemplate
          name="Containers"
          title="Containers"
          content="
            <p> Containers allow you to develop, deploy, and execute applications on many different operating systems and cloud providers. </p>
            <br />
            <ul>
            <li>Containers are lightweight, sharing most resources that are native to the hosts operating system.</li>
            <li>Flexible, allowing for simple to complex applications to be containerized for use anywhere you need them.</li>
            <li>Scalable, adding more containers on the fly for when your demands increase, or vice versa. </li>
            <li>Portable, build on your local environment, then easily deploy to your choice of cloud, or on-premis hardware.</li>
            </ul>
            <p> Let us help you containerize your applications for a scalable and maintainable future. </p>
            "
          headerIcon={<SecurityCompliance />}
          inquiryPlaceholder="Hi! We would like to start using containers with our application."
        />
      </Layout>
    )
  }
}

export default ContainersPage
