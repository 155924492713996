import PropTypes from 'prop-types'

const BackgroundLines = ({ className, linesClassName }) => (
  <svg
    className={className}
    width="1500px"
    height="950px"
    viewBox="0 0 1500 950"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
      <g strokeWidth="2">
        <path
          className={linesClassName.hLine}
          d="M0,184 L1500,184"
          stroke="#157494"
          opacity="0.200000003"
        />
        <path
          className={linesClassName.hLine1}
          d="M0,499 L1500,499"
          stroke="#157494"
          opacity="0.200000003"
        />
        <path
          className={linesClassName.hLine2}
          d="M0,329 L1500,329"
          stroke="#157494"
          opacity="0.200000003"
        />
        <path
          className={linesClassName.hLine3}
          d="M0,561 L1500,561"
          stroke="#157494"
          opacity="0.200000003"
        />
        <path
          className={linesClassName.hLine4}
          d="M0,831 L1500,831"
          stroke="#157494"
          opacity="0.200000003"
        />
        <path
          className={linesClassName.vLine}
          d="M917.388408,0 L917.388408,949.801888"
          stroke="#1D354F"
        />
        <path
          className={linesClassName.vLine1}
          d="M514.656895,0 L514.656895,949.801888"
          stroke="#1A2F46"
        />
        <path
          className={linesClassName.vLine2}
          d="M380.746169,0 L380.746169,949.801888"
          stroke="#172A3F"
        />
      </g>
    </g>
  </svg>
)

BackgroundLines.defaultProps = {
  className: '',
  linesClassName: {
    hLine: 'hLine',
    hLine1: 'hLine1',
    hLine2: 'hLine2',
    hLine3: 'hLine3',
    hLine4: 'hLine4',
    vLine: 'vLine',
    vLine1: 'vLine1',
    vLine2: 'vLine2',
  },
}

BackgroundLines.propTypes = {
  className: PropTypes.string,
  linesClassName: PropTypes.shape({
    hLine: PropTypes.string,
    hLine1: PropTypes.string,
    hLine2: PropTypes.string,
    hLine3: PropTypes.string,
    hLine4: PropTypes.string,
    vLine: PropTypes.string,
    vLine1: PropTypes.string,
    vLine2: PropTypes.string,
  }),
}

export default BackgroundLines
