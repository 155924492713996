import {
  Hero,
  Features,
  CloudMonitoring,
  IsReady,
  Migrate,
  DevOps,
  Resources,
} from 'components/Page/DataDog'
import Layout from 'components/Layout'

const DataDog = () => (
  <Layout
    title="DataDog Partner"
    description="DataDog Managed Services at Observian. We do all things cloud, including DevOps, Big Data, Security, Migration, and more. Learn more!"
    headerProps={{ isLandingPage: false }}
    footerProps={{ isLandingPage: false }}
  >
    <Hero />
    <Features />
    <CloudMonitoring />
    <IsReady />
    <Migrate />
    <DevOps />
    <Resources />
  </Layout>
)

export default DataDog
