import { useState } from 'react'
import ContactModal from 'observian-ui/lib/modal/contact'

import SubHero from 'components/Page/ManagedSecurity/SubHero'
import AWSLevelOne from 'components/Page/ManagedSecurity/AWSLevelOne'
import Coverage from 'components/Page/ManagedSecurity/CoverageConsulting'
import RapidOnboarding from 'components/Page/ManagedSecurity/RapidOnboarding'
import CaseStudies from 'components/Page/ManagedSecurity/CaseStudies'
import style from 'components/Page/ManagedSecurity/styles.module.css'
import Layout from 'components/Layout'
import Hero from 'components/Page/ManagedSecurity/Hero'
import ContactForm from 'components/Form/Contact'

const ManagedSecurityPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return (
    <Layout
      title="Observian Managed Cloud Services"
      description="Observian offers managed cloud security services in Utah. We are experts in all things cloud, including DevOps, Big Data, Security, Migration, and more."
    >
      <div className={style.wrapper}>
        <div className={style.BGMask}>
          <Hero setShowModal={setShowModal} />
          <SubHero />
          <AWSLevelOne />
          <Coverage />
          <RapidOnboarding setShowModal={setShowModal} />
          <CaseStudies />
        </div>
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-managed-security"
            inquiryPlaceholder="We want to take our security to the next level with Observian's managed AWS security service."
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </Layout>
  )
}

export default ManagedSecurityPage
