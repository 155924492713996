import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import { useState, useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import ContactForm from 'components/Form/Contact'

import style from './engagementProcess.module.css'

const EngagementProcess = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  let isMobile = false
  useEffect(() => {
    setText()
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  function handleWindowSizeChange() {
    isMobile = 1000 >= window.innerWidth
    setText()
  }

  const dot1 = useRef(null)
  const dot2 = useRef(null)
  const dot3 = useRef(null)
  const dot4 = useRef(null)

  let setText = () => {
    if (!isMobile) {
      gsap.to('.text1', {
        x: dot1.current.getBoundingClientRect().x - 50,
        y: -10,
      })
      gsap.to('.text2', {
        x: dot2.current.getBoundingClientRect().x - 50,
        y: -25,
      })
      gsap.to('.text3', { x: dot3.current.getBoundingClientRect().x - 50, y: -90 })
      gsap.to('.text4', { x: dot4.current.getBoundingClientRect().x - 50, y: -140 })
    } else {
      gsap.to('.text1', {
        x: 0,
        y: 50,
      })
      gsap.to('.text2', {
        x: 0,
        y: 200,
      })
      gsap.to('.text3', { x: 0, y: 350 })
      gsap.to('.text4', { x: 0, y: 500 })
    }
  }

  return (
    <section className={style.section}>
      <div className={style.bottom} />
      <div className={style.container}>
        <h2>Typical Migration Process</h2>
        <div style={{ textAlign: 'center' }}>
          <svg
            style={{ paddingLeft: '50px' }}
            width="95%"
            height="100%"
            viewBox="0 0 1105 120"
            version="1.1"
            className={style.engagementProcessSVG}
          >
            <defs>
              <linearGradient
                x1="15.4356061%"
                y1="50.4209489%"
                x2="114.318971%"
                y2="49.7030115%"
                id="linearGradient-1"
              >
                <stop stopColor="#FFC42A" offset="0%" />
                <stop stopColor="#FF0061" stopOpacity="0.946473053" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="-83.7192118%"
                y1="50.4441345%"
                x2="87.8953488%"
                y2="49.8465408%"
                id="linearGradient-2"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="12.4534884%"
                y1="84.9243045%"
                x2="87.8953488%"
                y2="16.3295925%"
                id="linearGradient-3"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
              <circle id="path-4" cx="13.5" cy="13.5" r="13.5" />
              <filter
                x="-185.2%"
                y="-140.7%"
                width="470.4%"
                height="470.4%"
                filterUnits="objectBoundingBox"
                id="filter-5"
              >
                <feMorphology
                  radius="4"
                  operator="dilate"
                  in="SourceAlpha"
                  result="shadowSpreadOuter1"
                />
                <feOffset dx="0" dy="12" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  stdDeviation="10"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                />
                <feComposite
                  in="shadowBlurOuter1"
                  in2="SourceAlpha"
                  operator="out"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                />
              </filter>
              <linearGradient
                x1="-83.7192118%"
                y1="51.3084138%"
                x2="87.8953488%"
                y2="49.5479116%"
                id="linearGradient-6"
              >
                <stop stopColor="#1D8DBA" offset="0%" />
                <stop stopColor="#03ECAE" offset="100%" />
              </linearGradient>
              <circle id="path-7" cx="372.573058" cy="31.5" r="13.5" />
              <filter
                x="-185.2%"
                y="-140.7%"
                width="470.4%"
                height="470.4%"
                filterUnits="objectBoundingBox"
                id="filter-8"
              >
                <feMorphology
                  radius="4"
                  operator="dilate"
                  in="SourceAlpha"
                  result="shadowSpreadOuter1"
                />
                <feOffset dx="0" dy="12" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  stdDeviation="10"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                />
                <feComposite
                  in="shadowBlurOuter1"
                  in2="SourceAlpha"
                  operator="out"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                />
              </filter>
              <circle id="path-9" cx="692.573058" cy="13.5" r="13.5" />
              <filter
                x="-185.2%"
                y="-140.7%"
                width="470.4%"
                height="470.4%"
                filterUnits="objectBoundingBox"
                id="filter-10"
              >
                <feMorphology
                  radius="4"
                  operator="dilate"
                  in="SourceAlpha"
                  result="shadowSpreadOuter1"
                />
                <feOffset dx="0" dy="12" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  stdDeviation="10"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                />
                <feComposite
                  in="shadowBlurOuter1"
                  in2="SourceAlpha"
                  operator="out"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                />
              </filter>
              <linearGradient
                x1="15.4356061%"
                y1="90.5184659%"
                x2="114.318971%"
                y2="21.4133523%"
                id="linearGradient-11"
              >
                <stop stopColor="#FFC42A" offset="0%" />
                <stop stopColor="#FF0061" stopOpacity="0.946473053" offset="100%" />
              </linearGradient>
              <circle id="path-12" cx="1012.07306" cy="24" r="17" />
              <filter
                x="-183.8%"
                y="-139.7%"
                width="467.6%"
                height="467.6%"
                filterUnits="objectBoundingBox"
                id="filter-13"
              >
                <feMorphology
                  radius="5"
                  operator="dilate"
                  in="SourceAlpha"
                  result="shadowSpreadOuter1"
                />
                <feOffset dx="0" dy="15" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  stdDeviation="12.5"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                />
                <feComposite
                  in="shadowBlurOuter1"
                  in2="SourceAlpha"
                  operator="out"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                />
              </filter>
            </defs>
            <g id="DigitalAds-AWS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                id="amazon-web-services-ad-landing"
                transform="translate(-178.000000, -2022.000000)"
              >
                <g id="Group-15" transform="translate(61.000000, 2044.000000)">
                  <g id="Group-3" transform="translate(150.926942, -0.000000)">
                    <path
                      d="M697.766485,5.28564453 L697.766485,19.3928223 C754.740822,21.5178577 858.456981,27.3869169 1008.91496,37 L1005.86828,7 L697.766485,5.28564453 Z"
                      id="Path-20"
                      fill="url(#linearGradient-1)"
                    />
                    <polygon
                      id="Path-18"
                      fill="url(#linearGradient-2)"
                      points="24.4665177 13.5 372.559795 37 371.466518 23.5"
                    />
                    <g ref={dot1} id="Oval-4">
                      <use fill="black" fillOpacity="1" filter="url(#filter-5)" />
                      <circle
                        stroke="#1DECB6"
                        strokeWidth="4"
                        fill="url(#linearGradient-3)"
                        fillRule="evenodd"
                        cx="13.5"
                        cy="13.5"
                        r="15.5"
                      />
                      <circle
                        strokeOpacity="0.217759284"
                        stroke="#1DECB6"
                        strokeWidth="14"
                        cx="13.5"
                        cy="13.5"
                        r="20.5"
                      />
                      <circle
                        strokeOpacity="0.178498641"
                        stroke="#1DECB6"
                        strokeWidth="8"
                        cx="13.5"
                        cy="13.5"
                        r="17.5"
                      />
                    </g>
                    <polygon
                      id="Path-18-Copy"
                      fill="url(#linearGradient-6)"
                      points="371.466518 38.5 703.72281 27 700.073058 -1.13686838e-13 372.543211 26.7359168"
                    />
                    <g ref={dot2} id="Oval-4-Copy">
                      <use fill="black" fillOpacity="1" filter="url(#filter-8)" />
                      <circle
                        stroke="#1DECB6"
                        strokeWidth="4"
                        fill="url(#linearGradient-3)"
                        fillRule="evenodd"
                        cx="372.573058"
                        cy="31.5"
                        r="15.5"
                      />
                      <circle
                        strokeOpacity="0.217759284"
                        stroke="#1DECB6"
                        strokeWidth="14"
                        cx="372.573058"
                        cy="31.5"
                        r="20.5"
                      />
                      <circle
                        strokeOpacity="0.178498641"
                        stroke="#1DECB6"
                        strokeWidth="8"
                        cx="372.573058"
                        cy="31.5"
                        r="17.5"
                      />
                    </g>
                    <g ref={dot3} id="Oval-4-Copy-6">
                      <use fill="black" fillOpacity="1" filter="url(#filter-10)" />
                      <circle
                        stroke="#1DECB6"
                        strokeWidth="4"
                        fill="url(#linearGradient-3)"
                        fillRule="evenodd"
                        cx="692.573058"
                        cy="13.5"
                        r="15.5"
                      />
                      <circle
                        strokeOpacity="0.217759284"
                        stroke="#1DECB6"
                        strokeWidth="14"
                        cx="692.573058"
                        cy="13.5"
                        r="20.5"
                      />
                      <circle
                        strokeOpacity="0.178498641"
                        stroke="#1DECB6"
                        strokeWidth="8"
                        cx="692.573058"
                        cy="13.5"
                        r="17.5"
                      />
                    </g>
                    <g ref={dot4} id="Oval-4-Copy-3">
                      <use fill="black" fillOpacity="1" filter="url(#filter-13)" />
                      <circle
                        stroke="#FF385B"
                        strokeWidth="5"
                        fill="url(#linearGradient-11)"
                        fillRule="evenodd"
                        cx="1012.07306"
                        cy="24"
                        r="19.5"
                      />
                      <circle
                        strokeOpacity="0.31567029"
                        stroke="#FFA235"
                        strokeWidth="17.5"
                        cx="1012.07306"
                        cy="24"
                        r="25.75"
                      />
                      <circle
                        strokeOpacity="0.253425045"
                        stroke="#FF4D53"
                        strokeWidth="10"
                        cx="1012.07306"
                        cy="24"
                        r="22"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={style.engagementProcessContainer}>
          <div className="text1">
            <h3 className={style.sectionTitle}>Communicate Changes to Users</h3>
            <p className={style.engagementProcessText}>
              Set Migration Timeline <br />
              Provide Training <br />
            </p>
          </div>
          <div className="text2">
            <h3 className={style.sectionTitle}>Initial Migration</h3>

            <p className={style.engagementProcessText}>
              Mail, Calendar and Contacts <br />
              Phased Migration Strategy <br />
              Capture All Data <br />
            </p>
          </div>
          <div className="text3">
            <h3 className={style.sectionTitle}>
              Google Drive Migration &amp; <br />
              Infrastructure
            </h3>

            <p className={style.engagementProcessText}>
              Create Shared Drives <br />
              Implement Drive Security Policies <br />
              Migrate Data <br />
            </p>
          </div>
          <div className="text4">
            <h3 className={style.sectionTitle}>Optimize</h3>

            <p className={style.engagementProcessText}>
              Analyze Workloads <br />
              Implement Cost Savings <br />
              Prepare Hand Off <br />
            </p>
          </div>
        </div>
        <div className={style.buttonContainer}>
          <Button
            className={style.button}
            label="See How Observian Can Help Transform Your Business"
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-workspace-engagement-process-cta"
            inquiryPlaceholder="I'd like to chat about Google Workspace."
            isLandingPageForm
            isSuccessfulCallback={(v) => {
              setIsSuccessful(v)
              if (!v) {
                setShowModal(false)
              }
            }}
          />
        </ContactModal>
      ) : null}
    </section>
  )
}

export default EngagementProcess
